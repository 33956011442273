<template>
<section>
    <!-- <n-skeleton v-if="gl.loading" :width="width" :sharp="false" size="large" /> -->
    <div ref="filter" class="w-max flex flex-row items-center" :class="{'opacity-40': gl.loading}">
        <div class="text-sm lg:text-base text-current mr-4">{{ title }}</div>
        <n-radio-group v-model:value="radioCheck" :size="gl.screenWidth < 1024 ? 'small' : 'large'">
            <n-radio-button
                v-for="radio in radios"
                :key="radio.value"
                :value="radio.value"
                :disabled="localLoading"
                @click.prevent="radio.method">
                {{ radio.label }}
            </n-radio-button>
        </n-radio-group>
        <n-popover
            v-if="!selectedBotsIsEmpty"
            trigger="hover"
            :disabled="localLoading">
            <template #trigger>
                <n-icon
                    class="text-red-500 text-2xl ml-4 cursor-pointer"
                    @click="cleanFilter">
                    <DeleteDismiss24Regular />
                </n-icon>
            </template>
            <span>{{ t('cleanFilter') }}</span>
        </n-popover>
    </div>

    <n-modal
        v-model:show="showModal"
        transform-origin="center">
        <n-card
            style="width: 1000px;"
            size="huge"
            role="dialog"
            :aria-modal="true"
            :segmented="{
                content: true,
                footer: 'soft'
            }">
            <template #default>
                <div class="flex justify-between">
                    <div class="w-5/12">
                        <div class="flex justify-between items-center mb-4">
                            <div class="text-base uppercase">{{ refs.localization.filters.master_slave.available_title.replace('%s', refs.localization.filters.labels.bots.filter_title) }}</div>
                            <n-button
                                strong
                                class="ml-4 text-white"
                                :color="gl.mainColor"
                                :disabled="allBotsIsEmpty"
                                @click="addAll">
                                {{ refs.localization.filters.master_slave.btn__add_all }}
                            </n-button>
                        </div>
                        <n-input
                            clearable
                            type="text"
                            size="large"
                            v-model:value="firstFilter"
                            :placeholder="refs.localization.filters.master_slave.search" />
                        <n-scrollbar class="mt-4" style="max-height: 444px;">
                            <template
                                v-for="(item, i) in allBots"
                                :key="item.value">
                                <div v-if="item.children.length">
                                    <div class="flex cursor-pointer">
                                        <n-icon class="text-2xl mr-4" :class="item.checked ? 'text-main' : ''" @click="item.show = !item.show">
                                            <AddSquare20Regular v-if="!item.show" />
                                            <ScanDash20Filled v-else />
                                        </n-icon>
                                        <div class="text-base" :class="item.checked ? 'text-main' : ''" @click="setCheckAll(item)">{{ item.label }}</div>
                                    </div>
                                    <n-collapse-transition :show="item.show" class="mt-4">
                                        <template
                                            v-for="bot in item.children"
                                            :key="bot.value">
                                            <rb-checkbox
                                                class="ml-2 pl-2 py-1 odd:bg-gray-700 even:bg-gray-600"
                                                :label="bot.label"
                                                v-model:checked="bot.checked" />
                                        </template>
                                    </n-collapse-transition>
                                    <n-divider v-if="i < allBots.length - 1" />
                                </div>
                            </template>
                        </n-scrollbar>
                    </div>
                    <div class="w-2/12 flex flex-col items-center justify-center">
                        <n-button
                            class="flex justify-center items-center mb-2"
                            size="large"
                            :color="gl.mainColor"
                            @click="addSelected"
                            :disabled="!someAllBotsChecked">
                            <n-icon
                                class="text-white text-xl">
                                <IosArrowRtl24Filled />
                            </n-icon>
                        </n-button>
                        <n-button
                            class="flex justify-center items-center mt-2"
                            size="large"
                            :color="gl.mainColor"
                            @click="removeSelected"
                            :disabled="!someSelectedBotsChecked">
                            <n-icon
                                class="text-white text-xl">
                                <IosArrowLtr24Filled />
                            </n-icon>
                        </n-button>
                    </div>
                    <div class="w-5/12">
                        <div class="flex justify-between items-center mb-4">
                            <div class="text-base uppercase">{{ refs.localization.filters.master_slave.selected_title.replace('%s', refs.localization.filters.labels.bots.filter_title) }}</div>
                            <n-button
                                strong
                                class="ml-4 text-white"
                                :color="gl.mainColor"
                                :disabled="selectedBotsIsEmpty"
                                @click="removeAll">
                                {{ refs.localization.filters.master_slave.btn__remove_all }}
                            </n-button>
                        </div>
                        <n-input
                            clearable
                            type="text"
                            size="large"
                            v-model:value="secondFilter"
                            :placeholder="refs.localization.filters.master_slave.search" />
                        <n-scrollbar class="mt-4" style="max-height: 444px;">
                            <template
                                v-for="(item, i) in selectedBots"
                                :key="item.value">
                                <div v-if="item.children.length">
                                    <div class="flex cursor-pointer">
                                        <n-icon class="text-2xl mr-4" :class="item.checked ? 'text-main' : ''" @click="item.show = !item.show">
                                            <AddSquare20Regular v-if="!item.show" />
                                            <ScanDash20Filled v-else />
                                        </n-icon>
                                        <div class="text-base" :class="item.checked ? 'text-main' : ''" @click="setCheckAll(item)">{{ item.label }}</div>
                                    </div>
                                    <n-collapse-transition :show="item.show" class="mt-4">
                                        <template
                                            v-for="bot in item.children"
                                            :key="bot.value">
                                            <rb-checkbox
                                                class="ml-2 pl-2 py-1 odd:bg-gray-700 even:bg-gray-600"
                                                :label="bot.label"
                                                v-model:checked="bot.checked" />
                                        </template>
                                    </n-collapse-transition>
                                    <n-divider v-if="i < selectedBots.length - 1" />
                                </div>
                            </template>
                        </n-scrollbar>
                    </div>
                </div>
                
            </template>
            <template #footer>
                <div class="flex flex-row justify-end">
                    <n-button
                        strong
                        class="text-white"
                        color="#D03A52"
                        @click="cacnel">
                        {{ refs.localization.filters.all_select.btn__cancel }}
                    </n-button>
                    <n-button
                        strong
                        class="ml-4 text-white"
                        color="#4949D9"
                        @click="apply">
                        {{ refs.localization.filters.all_select.btn__apply }}
                    </n-button>
                </div>
            </template>
        </n-card>
    </n-modal>
</section>
</template>

<script>
// vue
import { h, ref, reactive, computed, watch, onMounted } from 'vue';

// store
import { useGl } from '@/store/gl';
import { useRefs } from '@/store/refs';
import { useStats } from '@store/stats';
import { useMarketplace } from '@store/ts/marketplace';

// naive
import {
    NTag,
    NCard,
    NText,
    NTree,
    NIcon,
    NModal,
    NAvatar,
    NInput,
    NButton,
    NDivider,
    NPopover,
    NTransfer,
    NSkeleton,
    NCheckbox,
    NScrollbar,
    NRadioGroup,
    NRadioButton,
    NCollapseTransition } from 'naive-ui';

// components
import RbCoin from '@components/rb-coin';
import RbCheckbox from '@components/rb-checkbox';

// icons
import {
    Dismiss16Filled,
    ScanDash20Filled,
    AddSquare20Regular,
    IosArrowRtl24Filled,
    IosArrowLtr24Filled,
    DeleteDismiss24Regular } from '@vicons/fluent';

// i18n
import { useI18n } from 'vue-i18n';

export default {
    name: 'Filter',
    props: {
        botFilters: {
            type: [ Array, Object],
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        RbCoin,
        NTag,
        NTree,
        NCard,
        NText,
        NIcon,
        NModal,
        NInput,
        NAvatar,
        NButton,
        NDivider,
        NPopover,
        NSkeleton,
        NCheckbox,
        NTransfer,
        NScrollbar,
        RbCheckbox,
        NRadioGroup,
        NRadioButton,
        Dismiss16Filled,
        ScanDash20Filled,
        AddSquare20Regular,
        NCollapseTransition,
        IosArrowRtl24Filled,
        IosArrowLtr24Filled,
        DeleteDismiss24Regular,
    },
    setup(props, { emit }) {
        const gl = useGl();
        const refs = useRefs();
        const stats = useStats();
        const marketplace = useMarketplace();

        const { t } = useI18n();
        
        // vars
        const radioCheck = ref('all');
        const valueRef = ref({});
        const selectedInSelected = ref([]);

        const localLoading = computed(() => gl.loading || props.loading);

        const showModal = ref(false);

        const filter = ref();
        const width = ref();
        const firstFilter = ref();
        const secondFilter = ref();

        const radios = computed(() => [
            {
                value: 'all',
                label:  refs.localization.filters.all_select.all,
                method: () => {
                    cleanFilter();
                },
            }, {
                value: 'select',
                label:  refs.localization.filters.all_select.select,
                method: () => {
                    showModal.value = true;
                    radioCheck.value = 'select';
                },
            },
        ]);

        const allBots = computed(() => {
            const res = [];

            props.botFilters.forEach((el, i) => {
                const curExchange = exchange(el.exchange);

                if (curExchange)
                    res.push(reactive({
                        id: curExchange.id,
                        label: curExchange.title,
                        show: true,
                        checked: false,
                        children: el.inner.map(item => ({
                            label: item.title,
                            value: item.id,
                            checked: false,
                        })).filter(item => !firstFilter.value
                            ? true
                            : item.label.toLowerCase().includes(firstFilter.value.toLowerCase()))
                            .filter(item => {
                                if (!valueRef.value[curExchange.id]) return true;

                                return !~valueRef.value[curExchange.id].findIndex(el => item.value === el);
                            }),
                    }));
            });
            
            return res;
        });

        const allBotsIsEmpty = computed(() => {
            if (!allBots.value.length) return;
            return allBots.value.every(({ children }) => !children.length);
        });

        const someAllBotsChecked = computed(() => {
            if (!allBots.value.length) return false;
            return allBots.value.some(({ children }) => children.some(({ checked }) => checked));
        });

        const selectedBots = computed(() => {
            const res = [];

            props.botFilters.forEach((el, i) => {
                const curExchange = exchange(el.exchange);
                
                if (curExchange)
                    res.push(reactive({
                        id: curExchange.id,
                        label: curExchange.title,
                        value: i + 'key',
                        show: true,
                        checked: false,
                        children: el.inner.map(item => ({
                            label: item.title,
                            value: item.id,
                            checked: !!~selectedInSelected.value.findIndex(el => el === item.id),
                        })).filter(el => !secondFilter.value
                            ? true
                            : el.label.toLowerCase().includes(secondFilter.value.toLowerCase()))
                            .filter(item => {
                                if (!valueRef.value[curExchange.id]) return false;

                                return !!~valueRef.value[curExchange.id].findIndex(el => item.value === el);
                            }),
                    }));
            });

            return res;
        });

        const someSelectedBotsChecked = computed(() => {
            if (!selectedBots.value.length) return false;
            return selectedBots.value.some(({ children }) => children.some(({ checked }) => checked));
        });

        const selectedBotsIsEmpty = computed(() => !Object.keys(valueRef.value).length);

        const setCheckAll = obj => {
            obj.checked = !obj.checked;

            obj.children.forEach(el => {
                el.checked = obj.checked;
            });
        };

        const cacnel = () => {
            showModal.value = false;
        };

        const apply = () => {
            showModal.value = false;
            emit('apply', valueRef.value);
        };

        const cleanFilter = () => {
            valueRef.value = {};
            apply();
            radioCheck.value = 'all';
        };

        const exchange = (exchange) => {
            return refs.exchanges.find(el => el.id === exchange);
        };

        const showBots = children => children.some(({ show }) => show);

        const addSelected = () => {
            allBots.value.forEach(({ id, children }) => {
                children.forEach(({ checked, value }) => {
                    if (checked) {
                        valueRef.value[id]
                            ? valueRef.value[id].push(value)
                            : valueRef.value[id] = [value];
                    };
                });
            });
        };

        const removeSelected = () => {
            selectedBots.value.forEach(({ children, id }) => {
                children.forEach(({ checked, value }) => {
                    if (checked) {
                        const i = valueRef.value[id].findIndex(el => el === value);
                        
                        if (~i) valueRef.value[id].splice(i, 1);

                        if (!valueRef.value[id].length)
                            delete valueRef.value[id];
                    }
                });
            });
        };

        const addAll = () => {
            valueRef.value = {};

            allBots.value.forEach(({ children, id }) => {
                children.forEach(({ value }) => {
                    valueRef.value[id]
                        ? valueRef.value[id].push(value)
                        : valueRef.value[id] = [value];
                });
            });
        };

        const removeAll = () => {
            valueRef.value = {};
        };

        onMounted(() => {
            width.value = filter.value.clientWidth;
        });

        return {
            gl,
            refs,
            stats,
            width,
            filter,
            radios,
            allBots,
            valueRef,
            showModal,
            radioCheck,
            firstFilter,
            localLoading,
            selectedBots,
            secondFilter,
            allBotsIsEmpty,
            someAllBotsChecked,
            selectedBotsIsEmpty,
            someSelectedBotsChecked,
            t,
            apply,
            addAll,
            cacnel,
            showBots,
            removeAll,
            cleanFilter,
            addSelected,
            setCheckAll,
            removeSelected,
        };
    },
};
</script>

<style lang="scss">
@import '@/css/stock-icon.scss';
</style>`