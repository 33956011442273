<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 69 78" width="69" height="78">
    <path id="icon-total-bots" class="bot-icon"
          d="M25,0h9c9.51,0 17.78,5.31 22.01,13.13c3.76,2.78 9,8.44 9.99,9.87c2.29,3.29 3,10 3,10v22c0,0 1.04,3.48 -2.11,5.52c1.33,1.82 2.11,4.06 2.11,6.48c0,6.08 -4.92,11 -11,11c-5.04,0 -9.28,-3.38 -10.59,-8h-39.41c-4.42,0 -8,-3.58 -8,-8v-37c0,-13.81 11.19,-25 25,-25zM67,55v-24c0,0 -0.15,-4.46 -4,-8c0.81,1.5 1,5 1,5l0,29.78c0.49,0.32 0.95,0.68 1.38,1.07c2.15,-1.04 1.62,-3.84 1.62,-3.84zM62,28c0,0 0.55,-6.36 -4,-10c0.31,1.58 1,5.33 1,7l0,31.04c1.05,0.09 2.06,0.34 3,0.71zM2,25v37c0,3.31 2.69,6 6,6l39.04,0c-0.03,-0.33 -0.04,-0.66 -0.04,-1c0,-5.74 4.39,-10.45 10,-10.96l0,-31.04c0,-12.7 -10.3,-23 -23,-23h-9c-12.7,0 -23,10.3 -23,23zM14,50h31c1.66,0 3,1.34 3,3v1c0,1.66 -1.34,3 -3,3h-31c-1.66,0 -3,-1.34 -3,-3v-1c0,-1.66 1.34,-3 3,-3zM13,53v1c0,0.55 0.45,1 1,1h31c0.55,0 1,-0.45 1,-1v-1c0,-0.55 -0.45,-1 -1,-1h-31c-0.55,0 -1,0.45 -1,1zM18.5,20c3.59,0 6.5,2.91 6.5,6.5c0,3.59 -2.91,6.5 -6.5,6.5c-3.59,0 -6.5,-2.91 -6.5,-6.5c0,-3.59 2.91,-6.5 6.5,-6.5zM14,26.5c0,2.49 2.01,4.5 4.5,4.5c2.49,0 4.5,-2.01 4.5,-4.5c0,-2.49 -2.01,-4.5 -4.5,-4.5c-2.49,0 -4.5,2.01 -4.5,4.5zM40.5,20c3.59,0 6.5,2.91 6.5,6.5c0,3.59 -2.91,6.5 -6.5,6.5c-3.59,0 -6.5,-2.91 -6.5,-6.5c0,-3.59 2.91,-6.5 6.5,-6.5zM36,26.5c0,2.49 2.01,4.5 4.5,4.5c2.49,0 4.5,-2.01 4.5,-4.5c0,-2.49 -2.01,-4.5 -4.5,-4.5c-2.49,0 -4.5,2.01 -4.5,4.5zM49,67c0,4.97 4.03,9 9,9c4.97,0 9,-4.03 9,-9c0,-4.97 -4.03,-9 -9,-9c-4.97,0 -9,4.03 -9,9zM62,61v2h-5l4,4l-4,4h5v2h-9v-1l5,-5l-5,-5v-1z"/>
  </svg>
</template>

<script>
export default {
    name: 'BotsTotal',
    setup() {
    // 
    },
};
</script>