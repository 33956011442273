<template>
<div ref="tableContainer" class="pt-4 text-left">
    <slot name="tableTitle"></slot>
    <slot name="afterLabel"></slot>
    <slot name="stats"></slot>

    <filters
        v-if="refFilters"
        class="mb-4"
        :filters="filters"
        :refFilters="refFilters"
        :filtersChanged="filtersChanged"
        :filter-id="drawer-target"
        @getData="$emit('getData')"
        @reset-filters="$emit('reset-filters')"
        @updateDate="$emit('updateDate', $event)"
        @changeFilter="$emit('applyFilters', $event)"/>

    <table-header
        v-if="showHeader"
        zoomButton
        :filters="filters"
        :refresh="refresh"
        :columns="localColumns"
        :showSearch="showSearch"
        :refFilters="refFilters"
        :filtersChanged="filtersChanged"
        :columnsStorageName="columnsStorageName"
        filter-id="drawer-target"
        @getData="$emit('getData')"
        @show-modal="showModal = $event"
        @reset-filters="$emit('reset-filters')"
        @updateDate="$emit('updateDate', $event)"
        @changeFilter="$emit('applyFilters', $event)">
        <template #afterSearch><slot name="afterSearch"></slot></template>
    </table-header>

    <n-card
        :bordered="false"
        class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg"
        :class="showHeader ? 'mt-4' : ''"
        content-style="padding: 0;"
        :segmented="{
            content: true,
            footer: 'soft'
        }">
        <n-data-table
            ref="dataTableInst"
            class="super-table"
            row-class-name="p-0"
            :id="type"
            :bordered="false"
            :single-line="false"
            :data="localRecords"
            :columns="tableColumns"
            @update:sorter="sortColumn($event)" />

        <div
            v-if="showFooter"
            class="flex items-center justify-between  my-6 px-4">
            <slot name="actionButtons"></slot>
            <div class="flex items-center">
                <div class="text-sm text-gray-600 dark:text-white/75">
                    {{ refs.localization.grids.pager.rows_per_page_label }}
                </div>
                <rb-select
                    class="w-[80px]"
                    labelPosition="left"
                    :defaultValue="false"
                    :options="perPageOptions"
                    :value="filters.perPage"
                    @update:value="$emit('applyFilters', [ 'perPage', $event ], true)" />
            </div>

            <div
                v-if="pager?.total > 1"
                class="flex flex-row justify-between">
                <n-pagination
                    size="medium"
                    :page="filters.page"
                    :page-count="pager.total"
                    @update:page="$emit('applyFilters', ([ 'page', $event ]), true)" />
            </div>
        </div>
    </n-card>

    <n-modal v-model:show="showModal" transform-origin="center">
        <n-card
            style="width: 99vw; height: 98vh;"
            :bordered="false"
            role="dialog"
            class="flex flex-col rounded-lg bg-gray-100 dark:bg-darkbg"
            content-style="overflow: hidden;"
            aria-modal="true">
            <template v-if="showHeader" #header>
                <table-header
                    :filters="filters"
                    :refresh="refresh"
                    :columns="localColumns"
                    :showSearch="showSearch"
                    :refFilters="refFilters"
                    :columnsStorageName="columnsStorageName"
                    filter-id="drawer-target2"
                    @getData="$emit('getData')"
                    @show-modal="showModal = $event"
                    @updateDate="$emit('updateDate', $event)"
                    @changeFilter="$emit('applyFilters', $event)">
                    <template #afterSearch><slot name="afterSearch"></slot></template>
                </table-header>
            </template>
            <section class="h-full">
                <n-data-table
                    ref="dataTableInst"
                    class="modal-table rounded-lg overflow-hidden"
                    row-class-name="p-0"
                    :id="type"
                    :bordered="false"
                    :single-line="false"
                    :data="localRecords"
                    :columns="tableColumns"
                    @update:sorter="sortColumn($event)" />

                <div
                    v-if="showFooter"
                    class="flex items-center justify-between mt-4">
                    <slot name="actionButtons"></slot>
                    <div class="flex items-center">
                        <div class="text-sm text-gray-600 dark:text-white/75">
                            {{ refs.localization.grids.pager.rows_per_page_label }}
                        </div>
                        <rb-select
                            class="w-[80px]"
                            labelPosition="left"
                            :defaultValue="false"
                            :options="perPageOptions"
                            :value="filters.perPage"
                            @update:value="$emit('applyFilters', [ 'perPage', $event ], true)" />
                    </div>

                    <div
                        v-if="pager?.total > 1"
                        class="flex flex-row justify-between">
                        <n-pagination
                            size="large"
                            :page="filters.page"
                            :page-count="pager.total"
                            @update:page="$emit('applyFilters', ([ 'page', $event ]), true)" />
                    </div>
                </div>
            </section>
        </n-card>
    </n-modal>
</div>
</template>

<script>
// vue
import { h, ref, computed, reactive, watch, onMounted } from 'vue';

// shared
import sorters from '@shared/sorters';
import { getNavigatorLanguage, localeDateOpts } from '@shared/util';

// store
import { useGl } from '@store/ts/gl';
import { useEnv } from '@store/ts/env';
import { useRefs } from '@store/ts/refs';
import { useBots } from '@store/bots';
import { useStats } from '@store/stats';
import { useProfile } from '@store/profile';
import { useMarketplace } from '@store/ts/marketplace';

// icons
import {
    ChevronLeft24Filled,
    ClipboardDataBar24Regular } from '@vicons/fluent';

// components
import RbCoin from '@components/rb-coin';
import Filters from './filters/desktop.vue';
import RbSelect from '@components/rb-select';
import TableHeader from './table-header/desktop.vue';
import GridSettings from '@components/base/grid-settings.vue';

// cells
import CellKey from '@components/cells/cell-key';
import CellTags from '@components/cells/cell-tags';
import CellText from '@components/cells/cell-text/desktop.vue';
import CellPair from '@components/cells/cell-pair';
import CellState from '@components/cells/cell-state';
import CellNotes from '@components/cells/cell-notes';
import CellError from '@components/cells/cell-error';
import CellSwitch from '@components/cells/cell-switch';
import CellStatus from '@components/cells/cell-status';
import CellActions from '@components/cells/cell-actions';
import CellExchange from '@components/cells/cell-exchange';
import CellExchanges from '@components/cells/cell-exchanges/desktop.vue';
import CellInfoModal from '@components/cells/cell-info-modal';
import CellProfitCoin from '@components/cells/cell-profit-coin';

// router
import { useRoute, useRouter } from 'vue-router';

// naive-ui
import {
    NIcon,
    NCard,
    NModal,
    NCheckbox,
    NDataTable,
    NPagination } from 'naive-ui';

export default {
    name: 'table-desktop',
    props: {
        filters: {
            type: [ Object, Array ],
            required: true,
        },
        refFilters: {
            type: [ Object, Array ],
        },
        columns: {
            type: Array,
            required: true,
        },
        records: {
            type: Array,
            required: true,
        },
        pager: {
            type: Object,
            required: true,
        },
        actions: {
            type: Array,
            default: () => [],
        },
        type: {
            type: String,
            default: 'marketplace.buy',
        },
        columnsStorageName: {
            type: String,
            required: true,
        },
        showSearch: {
            type: Boolean,
            default: false,
        },
        refresh: {
            type: Boolean,
            default: true,
        },
        showHeader: {
            type: Boolean,
            default: true,
        },
        showFooter: {
            type: Boolean,
            default: true,
        },
        filtersChanged: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        NCard,
        NModal,
        Filters,
        RbSelect,
        NDataTable,
        NPagination,
        TableHeader,
        GridSettings,
    },
    setup(props, { emit, slots }) {
        // store
        const gl = useGl();
        const env = useEnv();
        const refs = useRefs();
        const bots = useBots();
        const stats = useStats();
        const profile = useProfile();
        const marketplace = useMarketplace();

        // router
        const router = useRouter();

        // vars
        const showModal = ref();
        const dataTableInst = ref();
        const tableContainer = ref();

        const localRecords = computed(() => props.records.length ? props.records : []);

        const perPageOptions = computed(() =>
            [10, 25, 50, 75, 100, '*'].map(el => ({
                label: el === '*' ? refs.localization.grids.pager.all_label : el,
                value: el,
            })));

        const coloredTable = computed(() => ['marketplace.i_mentor', 'marketplace.my_mentors', 'bots', 'stats', 'cycles_orders_table'].includes(props.type));

        const localColumns = computed(() => {
            if (props.columns) {
                const arr = [...props.columns]
                    .map(el => Object.prototype.hasOwnProperty.call(el, 'isHidden')
                        ? reactive({
                            ...el,
                            sorter: el?.server_sort
                                ? el?.isSortable
                                : sorters(el),
                        })
                        : reactive({
                            isHidden: false,
                            sorter: el?.server_sort
                                ? el?.isSortable
                                : sorters(el),
                            ...el,
                        }))
                    .filter(({ title }) => title);
                    
                if (!arr.find(el => el.name === 'checked')) {
                    if (coloredTable.value)
                        arr.unshift({
                            isHidden: false,
                            name: 'colorLine',
                        });
                }
            
                return arr;
            } else return [];
        });

        const paintLine = (row, i) => {
            if ([
                'marketplace.i_mentor',
                'marketplace.my_mentors'].includes(props.type)) {
                switch (row.state) {
                    case 'finished':
                        return 'bg-green-300 dark:bg-green-700';
                    case 'confirmed':
                        return 'bg-blue-300 dark:bg-blue-700';
                    case 'await_confirmation':
                        return 'bg-yellow-200';
                    case 'canceled':
                        return 'bg-red-200';
                }
            } else if (['bots', 'stats'].includes(props.type)) {
                // paused
                if (row.state == 7 || row.state == 8 || row.status == -5 || row.simulate) {
                    return 'bg-gray-300 dark:bg-gray-600';
                }

                if (row.algo == 'short' && row.status == 1) {
                    return 'bg-blue-300 dark:bg-blue-700';
                }

                if (row.algo == 'long' && row.status == 1) {
                    return 'bg-green-300 dark:bg-green-700';
                }

                if (!!row.exit_error || row.status == 5) {
                    return 'bg-red-200 dark:bg-red-300';
                }
            } else if (props.type == 'cycles_orders_table') {
                if (row.order_type === 'sell' && row.status === 1) {
                    return 'bg-red-200 dark:bg-red-300';
                } else if (row.order_type === 'buy' && row.status === 1) {
                    return 'bg-green-300 dark:bg-green-700';
                } else if (row.order_type === 'buy' && row.status === 0) {
                    return 'bg-green-200 dark:bg-green-600';
                } else if (row.order_type === 'sell' && row.status === 0) {
                    return 'bg-red-100 dark:bg-red-200';
                }
            }

            if (i % 2) {
                return 'dark:bg-[#242424] bg-gray-50';
            } else {
                return 'bg-white dark:bg-darkCardBg';
            }
        };

        const paintCell = (row, i) => {
            if ([
                'marketplace.i_mentor',
                'marketplace.my_mentors'].includes(props.type)) {
                switch (row.state) {
                    case 'finished':
                        return 'img-green-400';
                    case 'confirmed':
                        return 'img-blue-400';
                    case 'await_confirmation':
                        return 'img-yellow-200';
                    case 'canceled':
                        return 'img-red-200';
                    // case 'expired':
                    //     return 'text-gray-800 bg-red-200';
                }
            } else if (['bots', 'stats'].includes(props.type)) {
                // paused
                if (row.state == 7 || row.state == 8 || row.status == -5 || row.simulate) {
                    return 'img-gray-300';
                }

                if (row.algo == 'short' && row.status == 1) {
                    return 'img-blue-400';
                }

                if (row.algo == 'long' && row.status == 1) {
                    return 'img-green-400';
                }

                if (!!row.exit_error || row.status == 5) {
                    return 'img-red-200';
                }

                // if (row.status == -5)
                //     return 'bg-gray-100 dark:bg-gray-100';

                // if (row.algo == 'long')
                //     return 'text-green-900 bg-green-400 dark:text-green-900 dark:bg-green-400 bg-opacity-60 dark:bg-opacity-90';
                    
                // if (row.simulate)
                //     return 'text-gray-700 bg-gray-200 dark:text-gray-700 dark:bg-gray-200 bg-opacity-60 dark:bg-opacity-90';
            } else if (props.type == 'cycles_orders_table') {
                if (row.order_type === 'sell' && row.status === 1) {
                    return 'img-red-200';
                } else if (row.order_type === 'buy' && row.status === 1) {
                    return 'img-green-400';
                } else if (row.order_type === 'buy' && row.status === 0) {
                    return 'img-green-200';
                } else if (row.order_type === 'sell' && row.status === 0) {
                    return 'img-red-100';
                }
            }

            if (i % 2) {
                return 'dark:bg-[#242424] bg-gray-50';
            } else {
                return 'bg-white dark:bg-darkCardBg';
            }
        };
        
        watch(() => props.records, () => {
            // setTimeout(() => {
            //     tableContainer.value
            //         .scrollIntoView({ block: 'start', behavior: 'smooth' });
            // });
        });

        const tableColumns = computed(() => localColumns.value
            .filter(el => !el.isHidden)
            .map(el => {
                return {
                    key: el.name,
                    sorter: el.isSortable,
                    order: false,
                    fixed: el.name === 'actions' ? 'right' : null || ['checked', 'colorLine'].includes(el.name) ? 'left' : null,
                    render: (row, i) => {
                        if (el.name === 'colorLine') {
                            return h('div', {
                                class: 'h-full w-full ' + paintLine(row, i),
                            });
                        } else if (el.name === 'id') {
                            return h(CellText, {
                                isFooter: isFooter(i),
                                data: '#' + row[el.name],
                                classes: 'font-semibold text-gray-600 dark:text-white/75 ' + paintCell(row, i),
                                dataFooter: row[el.name],
                            });
                        } else if (['config_price'].includes(el.name)) {
                            return h(CellProfitCoin, {
                                isFooter: isFooter(i),
                                data: row[el.name],
                                coin: env.profitSymbol,
                                profitSymbol: env.profitSymbol,
                                dataFooter: row[el.name],
                                classes: 'text-main ' + paintCell(row, i),
                            });
                        } else if (el.name === 'checked') {
                            return isFooter(i)
                                ? h('div', { class: 'h-full dark:bg-darkCardBg bg-gray-50' })
                                : h('div', {
                                    class: 'h-full flex items-center justify-center relative ' + paintCell(row, i),
                                }, [
                                    coloredTable.value
                                        ? h('div', {
                                            class: 'h-full w-[6px] absolute left-0 top-0 ' + paintLine(row, i),
                                        }) : null,
                                    h(NCheckbox, {
                                        checked: row.checked,
                                        onUpdateChecked() {
                                            row.checked = !row.checked;
                                        },
                                    }),
                                ]);
                        } else if (['number_id', 'name'].includes(el.name)) {
                            const interactive = !['wallets', 'apiKeys'].includes(props.type);

                            const link = {
                                'bots': { name: 'bots.edit', params: { id: row.id } },
                                'stats': { name: 'trade-statistic.management', params: { id: row.id } },
                            };

                            return h(CellText, {
                                isFooter: isFooter(i),
                                row,
                                link: link[props.type],
                                data: row[el.name],
                                interactive,
                                fieldName: el.name,
                                classes: paintCell(row, i),
                                dataFooter: row[el.name],
                                hasError: row?.error || false,
                                onClick() {
                                    const fns = {
                                        'marketplace.buy': () => router.push({ name: 'marketplace.buy', params: { id: row.id } }),
                                        'marketplace.sell': () => router.push({ name: 'marketplace.sell', params: { id: row.id } }),
                                        'marketplace.mentorship': () => emit('doSomething', ['needHelp', row.name]),
                                        'marketplace.avail.signals': () => emit('doSomething', ['showInfo', row]),
                                        'cycles_table': () => emit('doSomething', ['setCurrentCycle', row[el.name]]),
                                    };

                                    if (fns?.[props.type])
                                        fns[props.type]();
                                },
                            });
                        } else if (el.name === 'bot_name') {
                            return h(CellText, {
                                isFooter: isFooter(i),
                                data: row[el.name],
                                interactive: true,
                                noWhiteSpace: true,
                                classes: paintCell(row, i),
                                dataFooter: row[el.name],
                                onClick() {
                                    router.push({ name: 'trade-statistic.management', params: { id: row.bot_id } });
                                },
                            });
                        } else if (['exchange', 'exchange_id'].includes(el.name)) {
                            return h(CellExchange, {
                                isFooter: isFooter(i),
                                data: +row[el.name],
                                dataFooter: row[el.name],
                                classes: paintCell(row, i),
                            });
                        } else if (el.name === 'pair') {
                            return h(CellPair, {
                                isFooter: isFooter(i),
                                coin: row[el.name],
                                data: row[el.name],
                                dataFooter: row[el.name],
                                classes: paintCell(row, i),
                            });
                        } else if (el.name === 'algo') {
                            return h(CellText, {
                                isFooter: isFooter(i),
                                data: algos(row[el.name])?.title,
                                dataFooter: row[el.name],
                                classes: paintCell(row, i),
                            });
                        } else if (el.name === 'ts') {
                            const prepare = localeDate(row[el.name]);
                                    
                            return h(CellText, {
                                isFooter: row?.type === 'footer',
                                type: prepare !== 'Invalid Date' ? 'date' : 'text',
                                data: prepare !== 'Invalid Date'
                                    ? prepare?.split(' ')
                                    : row[el.name],
                                dataFooter: row[el.name],
                                classes: paintCell(row, i),
                            });
                        } else if ([
                            'expire',
                            'works_since',
                            'date_stopped',
                            'date_created',
                            'date_updated',
                            'mentor_since',
                            'date_published',
                            'date_restarted',
                            'last_visit_date',
                            'registered_since',
                            'mentorship_requested',
                            'mentorship_confirmed'].includes(el.name)) {
                            return h(CellText, {
                                isFooter: isFooter(i),
                                type: 'date',
                                data: row[el.name]?.split(' '),
                                dataFooter: row[el.name],
                                classes: paintCell(row, i),
                            });
                        } else if (el.name === 'dates') {
                            const fDate = row.date_start;
                            const eDate = row.date_end;

                            // return h(CellText, {
                            //     isFooter: isFooter(i),
                            //     type: 'date',
                            //     data: row[el.name]?.split(' '),
                            //     dataFooter: row[el.name],
                            //     // classes: paintCell(row, i),
                            // });

                            return isFooter(i)
                                ? h('div',
                                    { class: 'h-full flex justify-center items-center text-main text-md dark:bg-darkCardBg bg-gray-50 font-semibold relative overflow-hidden' },
                                    h('div', {
                                        class: 'absolute w-full top-0 left-0 h-[2px] bg-main shadow-xl',
                                    }))
                                : h('div', {
                                    class: 'text-center flex flex-col justify-center items-center px-2 h-full ' + paintCell(row, i),
                                }, [
                                    h('div', {
                                        class: 'font-semibold whitespace-nowrap',
                                    }, [
                                        h('span', null, fDate.split(' ')[0]),
                                        h('span', { class: 'opacity-50 mx-1' }, fDate.split(' ')[1]),
                                        h('span', null, '/'),
                                    ]),
                                    h('div', {
                                        class: 'font-semibold whitespace-nowrap',
                                    }, [
                                        h('span', null, eDate.split(' ')[0]),
                                        h('span', { class: 'opacity-50 mx-1' }, eDate.split(' ')[1]),
                                    ]),
                                ]);
                        } else if (el.name === 'first_coin_revenue' || el.name === 'second_coin_revenue') {
                            return h(CellProfitCoin, {
                                isFooter: isFooter(i),
                                data: row[el.name],
                                coin: row[`${el.name}_symbol`],
                                profitSymbol: row[`${el.name}_symbol`],
                                dataFooter: row[el.name],
                                secondData: row[[el.name] + '_btc'],
                                secondCoin: row[[el.name] + '_btc_coin'],
                                classes: 'curspor-pointer ' + paintCell(row, i),
                            });
                        } else if(el.name === 'depo') {
                            return h(CellProfitCoin, {
                                isFooter: isFooter(i),
                                interactive: true,
                                data: row[el.name],
                                coin: row[`_${el.name}_symbol`],
                                profitSymbol: row[`_${el.name}_symbol`],
                                dataFooter: row[el.name],
                                classes: 'curspor-pointer ' + paintCell(row, i),
                                leverage: row?.leverage,
                                onClick() {
                                    router.push({ name: 'wallets', params: { id: row.depo_id } });
                                },
                            });
                        } else if ([
                            'depo_usdt',
                            'total_profit',
                            'profit_wo_comm',
                            'mentorship_price',
                            'profit_usdt_wo_comm'].includes(el.name)) {
                            return h(CellProfitCoin, {
                                isFooter: isFooter(i),
                                data: row[el.name],
                                coin: row[`_${el.name}_symbol`],
                                profitSymbol: row[`_${el.name}_symbol`],
                                dataFooter: row[el.name],
                                classes: `${row[el.name] >= 0 ? 'text-green-600' : 'text-red-500'} ` + paintCell(row, i),
                            });
                        } else if ([
                            // 'profit',
                            'profit_usdt',
                            'comm_amount_btc',
                            'comm_amount_usdt',
                            'rb_comm_amount_usdt',
                            'rb_comm_amount_btc' ].includes(el.name)) {
                            return h(CellProfitCoin, {
                                isFooter: isFooter(i),
                                data: row[el.name],
                                coin: row[`${el.name}_coin`],
                                profitSymbol: row[`${el.name}_coin`],
                                dataFooter: row[el.name],
                                classes: `${row[el.name] >= 0 ? 'text-green-600' : 'text-red-500'} ` + paintCell(row, i),
                            });
                        } else if (['profit_coin', 'currency'].includes(el.name)) {
                            return h(CellProfitCoin, {
                                isFooter: isFooter(i),
                                coin: row[el.name],
                                profitSymbol: row[el.name],
                                dataFooter: row[el.name],
                                classes: paintCell(row, i),
                            });
                        } else if (['price_amount', 'price_amount_usdt'].includes(el.name)) {
                            const profitSymbol = {
                                price_amount: '_price_symbol',
                                price_amount_usdt: '_price_symbol_usdt',
                            };
                            
                            return h(CellProfitCoin, {
                                isFooter: isFooter(i),
                                data: row[el.name],
                                coin: row[profitSymbol[el.name]],
                                profitSymbol: row[profitSymbol[el.name]],
                                dataFooter: row[el.name],
                                classes: paintCell(row, i),
                            });
                        } else if (['notes', 'question'].includes(el.name)) {
                            return h(CellNotes, {
                                isFooter: isFooter(i),
                                data: row[el.name],
                                dataFooter: row[el.name],
                                classes: 'text-main ' + paintCell(row, i),
                            });
                        } else if (el.name === 'status') {
                            const statuses = {
                                'bots': refs.botStatuses,
                                'marketplace.buy': refs.botStatuses,
                                'marketplace.sell': refs.botStatuses,
                                'wallets': refs.walletStatuses,
                                'apiKeys': refs.apikeyStatuses,
                                'marketplace_settings': profile.transactionStatuses,
                                'profile_balance_history': profile.transactionStatuses,
                                'stats': refs.botStatuses,
                                'cycles_table': stats.cycleFilters,
                                'cycles_orders_table': stats.cycleFilters,
                            };

                            return h(CellStatus, {
                                row,
                                isFooter: isFooter(i),
                                data: row[el.name],
                                refs: statuses[props.type],
                                classes: paintCell(row, i),
                            });
                        } else if (['in_market', 'show_dashboard'].includes(el.name)) {
                            const actions = {
                                'marketplace.sell': () => {
                                    row[el.name]
                                        ? emit('doSomething', [ 'tryDeleteBot', row.id ])
                                        : router.push({ name: 'marketplace.sell', params: { id: row.id } });
                                },
                                'apiKeys': async () => {
                                    emit('doSomething', [ 'updateApiKey', row ]);
                                },
                            };

                            const data = {
                                'marketplace.sell': row[el.name],
                                'apiKeys': row['show_dashboard'],
                            };

                            return h(CellSwitch, {
                                isFooter: isFooter(i),
                                data: data[props.type],
                                classes: paintCell(row, i),
                                checked: 'Yes',
                                unchecked: 'No',
                                loading: row.loadings?.show_dashboard,
                                onClick(e) {
                                    e.preventDefault();

                                    actions[props.type]();
                                },
                            });
                        } else if (el.name === 'actions') {
                            return h(CellActions, {
                                row,
                                type: props.type,
                                data: props.actions,
                                isFooter: isFooter(i),
                                classes: 'text-main ' + paintCell(row, i),
                                onDoSomething($event) {
                                    emit('doSomething', $event);
                                },
                                onGetData() {
                                    emit('getData');
                                },
                            });
                        } else if (el.name === 'exchanges') {
                            return h(CellExchanges, {
                                isFooter: isFooter(i),
                                data: row[el.name],
                                classes: paintCell(row, i),
                            });
                        } else if (el.name === 'state') {
                            return h(CellState, {
                                row,
                                type: props.type,
                                isFooter: isFooter(i),
                                classes: paintCell(row, i),
                                onDoSomething($event) {
                                    emit('doSomething', $event);
                                },
                            });
                        } else if (el.name === 'sign') {
                            return h(CellKey, {
                                data: row[el.name],
                                isFooter: isFooter(i),
                                classes: paintCell(row, i),
                            });
                        } else if (el.name === 'exit_error') {
                            return h(CellError, {
                                row,
                                isFooter: isFooter(i),
                                classes: paintCell(row, i),
                            });
                        } else if (el.name === 'tags') {
                            const tags = {
                                'bots': bots.tags,
                                'stats': stats.tags,
                            };

                            return h(CellTags, {
                                row,
                                tags: tags[props.type],
                                type: props.type,
                                isFooter: isFooter(i),
                                classes: paintCell(row, i),
                                onUpdateTags($event) {
                                    row.tags = $event.map(({ id }) => id);

                                    $event.forEach(el => {
                                        if (!tags[props.type].find(({ title }) => title === el.title))
                                            tags[props.type].push(el);
                                    });
                                },
                                onGetData($event) {
                                    emit('getData');
                                },
                            });
                        } else if (el.name === 'type') {
                            const type = {
                                'wallets': id => refs.walletTypes.find(cur => cur.id === id),
                                'marketplace_settings': id => profile.transactionTypes.find(cur => cur.id === id),
                                'profile_balance_history': id => profile.transactionTypes.find(cur => cur.id === id),
                            };
                            
                            return h(CellText, {
                                isFooter: isFooter(i),
                                data: type[props.type](row[el.name]).title,
                                dataFooter: row[el.name],
                                classes: paintCell(row, i),
                            }, {
                                afterData: () =>
                                    ['marketplace_settings', 'profile_balance_history'].includes(props.type)
                                        ? h('div', {
                                            class: `meu-col-ref rbg-profile__type--${row[el.name]} ml-3`,
                                        })
                                        : null,
                            });
                        } else if (['cycle_profit', 'amount'].includes(el.name)) {
                            return h(CellText, {
                                isFooter: isFooter(i),
                                data: row[el.name],
                                dataFooter: row[el.name],
                                classes: paintCell(row, i) + ' text-right',
                                pofitLosses: true,
                            }, {
                                afterData: () =>
                                    ['marketplace_settings', 'profile_balance_history'].includes(props.type) 
                                        ? h(RbCoin, {
                                            class: 'ml-2 w-5 h-5',
                                            coin: currency(row[`${el.name}_currency`]),
                                        })
                                        : null,
                            });
                        } else if (el.name === 'reg_referrer') {
                            const link = row[el.name] ? row[el.name].replace(/(<([^>]+)>)/ig, '') : '';

                            return h(CellText, {
                                isFooter: isFooter(i),
                                data: link,
                                interactive: !!link,
                                noWhiteSpace: false,
                                classes: paintCell(row, i),
                                onClick() {
                                    if (link)
                                        window.open(link, '_blank');
                                },
                            });
                        } else if (el.name === 'provider') {
                            const { title } = profile.oauthProviders.find(({ id }) => id == row[el.name]);

                            return h(CellText, {
                                isFooter: isFooter(i),
                                data: title,
                                noWhiteSpace: false,
                                classes: paintCell(row, i),
                            });
                        } else if (el.name.indexOf('vi_') === 0) {
                            return h(CellText, {
                                isFooter: isFooter(i),
                                data: row[el.name],
                                vitData: row[`${el.name}_rate`],
                                classes: paintCell(row, i),
                                dataFooter: row[el.name],
                            });
                        } else if (el.name === 'info') {
                            return h(CellInfoModal, {
                                isFooter: isFooter(i),
                                data: row[el.name],
                                classes: paintCell(row, i),
                                dataFooter: row[el.name],
                            });
                        } else if (el.name === 'subscription_type') {
                            const { title } = marketplace.signalSubscriptionTypes.find(({ id }) => id == row[el.name]);

                            return h(CellText, {
                                isFooter: isFooter(i),
                                data: title,
                                classes: paintCell(row, i),
                            });
                        } else if (el.name === 'prices') {
                            const data = row[el.name]?.replaceAll('\n', '<br />');

                            return h(CellText, {
                                isFooter: isFooter(i),
                                data,
                                classes: paintCell(row, i),
                                dataFooter: data,
                            });
                        } else {
                            return h(CellText, {
                                isFooter: isFooter(i),
                                data: row[el.name],
                                classes: paintCell(row, i),
                                dataFooter: row[el.name],
                            });
                        }
                    },
                    ...el,
                };
            }));

        const currency = icon => refs.currencies.find(el => el.id === icon);
        const exchange = exchange => refs.exchanges.find(el => el.id === exchange);

        const algos = algo => {
            if (['marketplace.buy', 'marketplace.sell'].includes(props.type)) {
                return marketplace.algos.find(el => el.id === algo);
            } else if (['bots'].includes(props.type)) {
                return {
                    title: bots.algos.find(el => el.id === algo)?.title?.split(' - ')[1],
                };
            } else if (['stats'].includes(props.type)) {
                return {
                    title: algo,
                };
            } else return '';
        };
        const isFooter = i => props.records[i]?.type === 'footer';

        const getViClasses = (rec, name) => {
            const key = `${name}_rate`;
            let rate = parseInt(rec[key], 10);
            return rate > 0 ? 'rbg-vol__vi--up' : (rate < 0 ? 'rbg-vol__vi--down' : 'rbg-vol__vi--none');
        };

        const getViRate = (rec, name) => {
            const key = `${name}_rate`;
            return rec[key];
        };

        const sortColumn = $event => {
            if (!$event) return;

            if ($event.sorter === true) {
                const sorts = {
                    ascend: 'asc',
                    descend: 'desc',
                };

                emit('sortColumn', {
                    ...$event,
                    order: sorts[$event.order],
                });
            }
        };

        const localeDate = value => {
            const lang = getNavigatorLanguage();
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const opts = {...localeDateOpts, timeZone};

            return (new Date(value * 1000)).toLocaleString(lang, opts);
        };

        onMounted(() => {
            // console.log('refFilters', props.refFilters);
        });

        return {
            gl,
            refs,
            slots,
            showModal,
            tableColumns,
            localRecords,
            localColumns,
            dataTableInst,
            tableContainer,
            perPageOptions,
            sortColumn,
            localeDate,
        };
    },
};
</script>

<style lang="scss" scoped>
@import '@/css/stock-icon.scss';

// :deep(.n-data-table) .n-data-table-th {
//     background-color: #141414;

//     &:hover {
//         background-color: #202020;
//     }
// }

// :deep(.n-scrollbar > .n-scrollbar-rail.n-scrollbar-rail--horizontal, .n-scrollbar + .n-scrollbar-rail.n-scrollbar-rail--horizontal) {
//     top: 0;
//     position: sticky !important;
// }

:deep(.n-data-table-wrapper) {
  border-radius: 8px;
}

:deep(td) {
    height: 70px;
    padding: 0 !important;

    & > * {
        padding: 4px;
    }
}

:deep(.checkbox-border-white .n-checkbox-box__border) {
    border: 1px solid #fff !important;
}

:deep(.n-data-table .n-data-table-th) {
    white-space: nowrap;
    // vertical-align: baseline;

    &[data-col-key="rate_cover"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 80px;
        white-space: normal;
    }

    &[data-col-key="order_matrix"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 80px;
        white-space: normal;
    }

    &[data-col-key="last_cycle_status"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 80px;
        white-space: normal;
    }    

    &[data-col-key="profit"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 60px;
        white-space: normal;
    }

    &[data-col-key="profit_usdt"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 60px;
        white-space: normal;
    }

    &[data-col-key="comm_amount_btc"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 60px;
        white-space: normal;
    }

    &[data-col-key="comm_amount_usdt"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 60px;
        white-space: normal;
    }

    &[data-col-key="profit_wo_comm"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 60px;
        white-space: normal;
    }

    &[data-col-key="profit_usdt_wo_comm"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 60px;
        white-space: normal;
    }

    &[data-col-key="current_orders_info"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 70px;
        white-space: normal;
    }

    &[data-col-key="prices"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 60px;
        white-space: normal;
    }

    &[data-col-key="sl"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 60px;
        white-space: normal;
    }

    &[data-col-key="sl"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 60px;
        white-space: normal;
    }

    &[data-col-key="autoswitcher"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 50px;
        white-space: normal;
    }

    &[data-col-key="autoinversion"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 70px;
        white-space: normal;
    }

    &[data-col-key="completed_cycles_avg"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 50px;
        white-space: normal;
    }

    &[data-col-key="last_cycle_duration"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 50px;
        white-space: normal;
    }

    &[data-col-key="percent_per_day"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 50px;
        white-space: normal;
    }

    &[data-col-key="manual_cancels"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 50px;
        white-space: normal;
    }

    &[data-col-key="filters"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 55px;
        white-space: normal;
    }

    &[data-col-key="risk"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 55px;
        white-space: normal;
    }

    &[data-col-key="profit_coin"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 55px;
        white-space: normal;
    }

    &[data-col-key="has_feedback"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 60px;
        white-space: normal;
    }

    &[data-col-key="in_market"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 60px;
        white-space: normal;
    }

    &[data-col-key="completed_cycles_days"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 50px;
        white-space: normal;
    }

    &[data-col-key="total_profit"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 50px;
        white-space: normal;
    }

    &[data-col-key="total_profit_percent"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 60px;
        white-space: normal;
    }

    &[data-col-key="total_profit_percent_spot"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 60px;
        white-space: normal;
    }

    &[data-col-key="total_profit_percent_futures"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 80px;
        white-space: normal;
    }

    &[data-col-key="price_amount_usdt"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 60px;
        white-space: normal;
    }

    &[data-col-key="running_bots"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 60px;
        white-space: normal;
    }

    &[data-col-key="profit_day"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 50px;
        white-space: normal;
    }

    &[data-col-key="profit_week"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 60px;
        white-space: normal;
    }

    &[data-col-key="profit_month"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 60px;
        white-space: normal;
    }

    &[data-col-key="profit_year"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 60px;
        white-space: normal;
    }

    &[data-col-key="first_order_indent"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 60px;
        white-space: normal;
    }

    &[data-col-key="total_profit_usdt"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 50px;
        white-space: normal;
    }

    &[data-col-key="total_comm_amount_btc"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 50px;
        white-space: normal;
    }

    &[data-col-key="total_comm_amount_usdt"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 50px;
        white-space: normal;
    }

    &[data-col-key="total_profit_usdt_wo_comm"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 50px;
        white-space: normal;
    }

    &[data-col-key="filter"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 65px;
        white-space: normal;
    }

    &[data-col-key="first_coin__first_coin_comm"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 50px;
        white-space: normal;
    }

    &[data-col-key="second_coin__second_coin_comm"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 50px;
        white-space: normal;
    }

    &[data-col-key="currency"] .n-data-table-th__title-wrapper .n-data-table-th__title {
        min-width: 50px;
        white-space: normal;
    }
}

:deep(th[data-col-key="actions"]) {
    & > div {
        width: 75px;
    };
}

.super-table {
    :deep(thead) {
        position: sticky;
        top: 0;
        z-index: 50;
    }

    :deep(.n-scrollbar-container) {
        max-height: 70vh;
    }
}

.modal-table {
    height: calc(100% - 56px);

    :deep(thead) {
        position: sticky;
        top: 0;
        z-index: 50;
    }

    :deep(.n-scrollbar-container) {
        max-height: 100%;
    }

    :deep(.n-data-table-wrapper) {
        height: 100%;

        & > .n-data-table-base-table {
            height: 100%;
        }
    }
}

:deep(.n-data-table-th) {
    padding: 0;
}

:deep(.n-data-table-th:not([data-col-key="colorLine"])) {
    padding: 10px 12px;
    font-size: 0.75rem;
    line-height: 1rem;
}

:deep(.img-green-400) {
    background-image: url('../../assets/img/green-400.png');
}

:deep(.img-blue-400) {
    background-image: url('../../assets/img/blue-400.png');
}

:deep(.img-yellow-200) {
    background-image: url('../../assets/img/yellow-200.png');
}

:deep(.img-red-200) {
    background-image: url('../../assets/img/red-200.png');
}

:deep(.img-red-100) {
    background-image: url('../../assets/img/red-100.png');
}

:deep(.img-gray-300) {
    background-image: url('../../assets/img/gray-300.png');
}

:deep(.img-green-200) {
    background-image: url('../../assets/img/green-200.png');
}
</style>