import Api from './index';

export default class Marketplace {

    static async getRefs() {
        return await Api.getData({
            action: 'marketplace.getRefs',
        });
    };

    static async buyGet(payload) {
        return await Api.getData({
            action: 'marketplace.buy.get',
            payload,
        });
    };

    static async buyBotConfiguration(payload) {
        return await Api.getData({
            action: 'marketplace.buy.buyConfiguration',
            payload,
        });
    };

    static async getFullInfoForBuy(payload) {
        return await Api.getData({
            action: 'marketplace.buy.getFullInfo',
            payload,
        });
    };

    static async sellGet(payload) {
        return await Api.getData({
            action: 'marketplace.sell.get',
            payload,
        });
    };

    static async removeFromMarketplace(id) {
        return await Api.getData({
            action: 'marketplace.sell.removeFromMarketplace',
            payload: { id },
        });
    };

    static async getFullInfoForSell(id) {
        return await Api.getData({
            action: 'marketplace.sell.getFullInfo',
            payload: { id },
        });
    };

    static async sellBotConfiguration(payload) {
        return await Api.getData({
            action: 'marketplace.sell.sellConfiguration',
            payload,
        });
    };

    static async mentorshipGet(payload) {
        return await Api.getData({
            action: 'marketplace.mentorship.mentors.get',
            payload,
        });
    };

    static async requestMentorship(payload) {
        return await Api.getData({
            action: 'marketplace.mentorship.mentors.requestMentorship',
            payload,
        });
    };

    static async myMentorsGet(payload) {
        return await Api.getData({
            action: 'marketplace.mentorship.myMentors.get',
            payload,
        });
    };

    static async mentorshipDoneFromMentee(payload) {
        return await Api.getData({
            action: 'marketplace.mentorship.myMentors.mentorshipDone',
            payload,
        });
    };

    static async iMentor(payload) {
        return await Api.getData({
            action: 'marketplace.mentorship.iMentor.get',
            payload,
        });
    };

    static async confirmMentorship(payload) {
        return await Api.getData({
            action: 'marketplace.mentorship.iMentor.confirmMentorship',
            payload,
        });
    };

    static async mentorshipDoneFromMentor(payload) {
        return await Api.getData({
            action: 'marketplace.mentorship.iMentor.mentorshipDone',
            payload,
        });
    };

    static async getMySignals(pager) {
        return await Api.getData({
            action: 'marketplace.signals.my.get',
            payload: { pager },
        });
    };

    static async getAvailSignals(pager) {
        return await Api.getData({
            action: 'marketplace.signals.avail.get',
            payload: { pager },
        });
    };

    static async getSignalsDetails(pager) {
        return await Api.getData({
            action: 'marketplace.signals.details.get',
            payload: { pager },
        });
    };

    static async signalsAvailBuy(id) {
        return await Api.getData({
            action: 'marketplace.signals.avail.buy',
            payload: { id },
        });
    };

    static async signalsMyRebill(id) {
        return await Api.getData({
            action: 'marketplace.signals.my.rebill',
            payload: { id },
        });
    };
}