<template>
<div v-if="visible" class="flex">
    <n-popover
        trigger="click"
        class="p-0 rounded-md"
        width="300"
        placement="bottom-start"
        :class="options.length >= 6 ? 'h-[300px]' : ''"
        v-model:show="show">
        <template #trigger>
            <n-button
                class="rounded-md flex-grow whitespace-nowrap"
                @click="show = true">
                <section class="w-full flex justify-between items-center">
                    <div class="mr-4">{{ label }}</div>
                    <div class="flex justify-end items-center">
                        <span class="text-main mr-2">{{ checkeds }}</span>
                        <n-icon size="14"><ArrowRight12Regular /></n-icon>
                    </div>
                </section>
            </n-button>
        </template>
        <template #default>
            <section class="bg-white dark:bg-darkCardBg overflow-hidden p-0 flex flex-col h-full">
                <div class="flex-grow flex flex-wrap pb-2 overflow-y-auto">
                    <div
                        class="w-full px-3 py-2 dark:odd:bg-[#242424] odd:bg-gray-50 rounded-none sticky top-0 z-50">
                        <rb-checkbox
                            :label="refs.localization.filters.all_select.all"
                            :checked="allChecked"
                            @update:checked="setCheckAll" />
                    </div>
                    <div
                        v-for="(item, i) in options"
                        :key="i"
                        class="w-full pr-3 pl-6 py-2 dark:odd:bg-[#242424] odd:bg-gray-50 rounded-none">
                        <rb-checkbox
                            :label="item.title"
                            v-model:checked="item.checked" />
                    </div>
                </div>

                <div v-if="options.length" class="w-full flex justify-end items-center py-2 px-3">
                    <n-button
                        secondary
                        quaternary
                        type="default"
                        class="rounded-md flex items-center text-main"
                        @click="show = false">
                        {{ refs.localization.filters.general.filters_done_label }}
                    </n-button>
                </div>
            </section>
        </template>
    </n-popover>

    <n-button
        secondary
        type="default"
        class="px-2 ml-1 rounded-md"
        @click="$emit('setVisibleFilters', data.filter)">
        <n-icon size="24" class="text-red-500">
            <DeleteDismiss24Regular />
        </n-icon>
    </n-button>
</div>
</template>

<script>
// vue
import { defineComponent } from 'vue';

// general
import general from './general';

// naive-ui
import {
    NIcon,
    NRadio,
    NButton,
    NDrawer,
    NPopover,
    NDrawerContent } from 'naive-ui';

// icons
import {
    ArrowLeft12Regular,
    ArrowRight12Regular,
    DeleteDismiss24Regular } from '@vicons/fluent';

// components
import RbCheckbox from '@components/rb-checkbox';

export default defineComponent({
    name: 'checkbox-group-desktop',
    props: {
        data: {
            type: Object,
            required: true,
        },
        filters: {
            type: Object,
            required: true,
        },
        drawerId: {
            type: String,
            required: true,
        },
        visible: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        NIcon,
        NRadio,
        NDrawer,
        NButton,
        NPopover,
        RbCheckbox,
        NDrawerContent,
        ArrowLeft12Regular,
        ArrowRight12Regular,
        DeleteDismiss24Regular,
    },
    setup(props, context) {
        
        return {
            ...general(props, context),
        };
    },
});
</script>