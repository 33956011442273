<template>
<!-- bg-white dark:bg-darkbg  -->
<div class="relative z-50">
    <div class="absolute top-4 left-1/2 -translate-x-1/2 z-[100]">
        <n-dropdown
            :options="options"
            placement="right-start"
            trigger="click"
            size="small"
            @select="changeLanguage">
            <n-button
                round
                size="tiny"
                tag="span"
                color="#fff"
                icon-placement="right">
                <n-icon>
                    <component :is="icons[currrentLang]"></component>
                </n-icon>
                <div class="text-xs font-semibold uppercase ml-1 text-main">{{ currrentLang }}</div>
            </n-button>
        </n-dropdown>
    </div>
    <div
        class="flex-col items-center relative justify-center border-r border-gray-100 dark:border-[#272633] bg-main w-20 hidden lg:flex h-full">
        <n-popover
            v-for="(item, i) in items"
            :key="i"
            size="small"
            placement="right">
            <template #trigger>
                <router-link
                    :to="item.link"
                    class="hover:opacity-50 relative w-10 h-10 flex items-center rounded-lg"
                    :class="[bgStyle(item.name), i ? 'mt-6' : '']">
                    <div class="flex w-full justify-center items-center">
                        <div v-if="!gl.loading" class="flex justify-center text-md">
                            <component :class="`${routeNamePath?.includes(item.name) ? 'fill-[#1A6DE0]' : 'fill-[#fff]'} hover:cursor-pointer w-5`" :is="item.icon"></component>
                        </div>
                        <n-skeleton v-else :width="32" :height="23" :sharp="false" />
                    </div>
                </router-link>
            </template>
            <span>{{ item.title }}</span>
        </n-popover>
    
        <div
            class="absolute bottom-4 left-1/2 -translate-x-1/2 flex flex-row justify-center mb-6"
            @click="changeTheme">
            <n-switch
                :rail-style="railStyle"
                :value="gl.darkTheme"
                size="medium">
                <template #checked-icon>
                    <n-icon><WeatherMoon28Regular /></n-icon>
                </template>
                <template #unchecked-icon>
                    <n-icon><WeatherSunny32Regular /></n-icon>
                </template>
            </n-switch>
        </div>
    </div>
    <div class="lg:hidden block">
        <div class="flex flex-col">
            <template
                v-for="(item, i) in items"
                :key="i">
                <router-link
                    :to="item.link"
                    class="flex items-center w-full py-2 outline-none"
                    :class="bgStyle(item.name, true)"
                    @click="$emit('close')">
                    <div class="flex justify-center text-xl" v-html="item.icon"></div>
                    <div class="text-xs pl-2">{{ item.title }}</div>
                </router-link>
                <n-divider class="mb-2 mt-3" />
            </template>
        </div>
    </div>
</div>
</template>

<script>
// vue
import { h, ref, computed } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useEnv } from '@/store/env';
import { useUser } from '@store/user';
import { useRefs } from '@store/ts/refs';

// icons
import ruIcon from '@components/lang-icons/ru.vue';
import enIcon from '@components/lang-icons/en.vue';
import esIcon from '@components/lang-icons/es.vue';

// services
import Api from '@services/api';

// i18n
import { useI18n } from 'vue-i18n';

// naive-ui
import { NPopover, NDropdown, NButton, NIcon, NSwitch, NSkeleton, NDivider } from 'naive-ui';

// router
import { useRoute } from 'vue-router';

// icons
import {
    WeatherMoon28Regular,
    WeatherSunny32Regular,
    ArrowCircleRight20Regular,
    ArrowCircleLeft20Regular } from '@vicons/fluent';

// storage
import LocalStorage from '@/services/LocalStorage';

// components
import BotsIcon from '@components/icons/bots.vue';
import HomeIcon from '@components/icons/home.vue';
import ApiKeysIcon from '@components/icons/api-keys.vue';
import WalletsIcon from '@components/icons/wallets.vue';
import ToolsIcon from '@components/icons/tools.vue';
import MarketplaceIcon from '@components/icons/marketplace.vue';
import QuickStartIcon from '@components/icons/quick-start.vue';
import TradeStatisticIcon from '@components/icons/trade-statistic.vue';

export default {
    name: 'left-panel',
    components: {
        NIcon,
        NSwitch,
        NButton,
        NDivider,
        NPopover,
        NDropdown,
        NSkeleton,
        WeatherMoon28Regular,
        WeatherSunny32Regular,
        ArrowCircleLeft20Regular,
        ArrowCircleRight20Regular,
    },
    setup() {
        // store
        const env = useEnv();
        const user = useUser();
        const refs = useRefs();

        // vars
        const currrentLang = ref(refs.lang);
        const fullWidth = ref(false);
        const route = useRoute();
        const gl = useGl();
        const theme = ref(false);

        const railStyle = ({
            focused,
            checked,
        }) => {
            const style = {};
            if (checked) {
                style.background = '#2B2C39';
                if (focused) {
                    style.boxShadow = '0';
                }
            } else {
                style.background = '#fff';
                if (focused) {
                    style.boxShadow = '0';
                }
            }
            return style;
        };

        const { t, tm, locale } = useI18n({ useScope: 'global' });

        const renderIcon = (icon) => {
            return () => {
                return h(NIcon, null, {
                    default: () => h(icon),
                });
            };
        };

        const icons = computed(() => {
            return  {
                'ru': ruIcon,
                'en': enIcon,
                'es': esIcon,
            };
        });

        const options = computed(() => {
            return refs.langs.map(el => ({
                label: el.title,
                key: el.id,
                ...el,
                icon: renderIcon(icons.value[el.id]),
            }));
        });

        const routeNamePath = computed(() => route.name?.split('.'));

        const items = computed(() => {
            return [
                {
                    icon: HomeIcon,
                    title: refs.localization.sidebar.dashboard,
                    active: routeNamePath.value?.includes('home'),
                    link: '/',
                    name: 'home',
                }, {
                    icon: MarketplaceIcon,
                    title: refs.localization.sidebar.marketplace,
                    active: routeNamePath.value?.includes('marketplace'),
                    link: '/marketplace/buy',
                    name: 'marketplace',
                }, {
                    icon: QuickStartIcon,
                    title: refs.localization.sidebar.quickstart,
                    active: routeNamePath.value?.includes('quick-start'),
                    link: '/quick-start',
                    name: 'quick-start',
                }, {
                    icon: BotsIcon,
                    title: refs.localization.sidebar.bots,
                    active: routeNamePath.value?.includes('bots'),
                    link: '/bots',
                    name: 'bots',
                }, {
                    icon: TradeStatisticIcon,
                    title: refs.localization.sidebar.trade_statistic,
                    active: routeNamePath.value?.includes('trade-statistic'),
                    link: '/trade-statistic',
                    name: 'trade-statistic',
                }, {
                    icon: WalletsIcon,
                    title: refs.localization.sidebar.wallets,
                    active: routeNamePath.value?.includes('wallets'),
                    link: '/wallets',
                    name: 'wallets',
                }, {
                    icon: ApiKeysIcon,
                    title: refs.localization.sidebar.api_keys,
                    active: routeNamePath.value?.includes('api-keys'),
                    link: '/api-keys',
                    name: 'api-keys',
                }, {
                    icon: ToolsIcon,
                    title: refs.localization.sidebar.tools,
                    active: routeNamePath.value?.includes('tools'),
                    link: '/tools',
                    name: 'tools',
                },
            ];});

        const changeTheme = () => {
            if (!~[...document.documentElement.classList].indexOf('dark')) {
                LocalStorage.setItem('darkTheme', true);
                gl.darkTheme = true;
            } else {
                LocalStorage.setItem('darkTheme', false);
                gl.darkTheme = false;
            }

            document.documentElement.classList.toggle('dark');
        };

        const bgStyle = (v, isMobile = false) => {
            if (routeNamePath.value?.includes(v))
                return gl.darkTheme
                    ? !isMobile ? 'bg-[#2B2C39] ' : ''
                    : !isMobile ? 'bg-[#f2f3f4] ' : '';
        };

        const changeLanguage = async key => {
            gl.showLoading = true;
            
            currrentLang.value = key;

            await Api.changeLang({ lang: key });

            // const result = await Promise.allSettled([
            //     Api.getRefs(user),
            //     Api.getEnv(user),
            // ]);

            location.reload();

            // refs.refs = result[0].value.data;
            // env.data = result[1].value.data;
                
            gl.showLoading = false;
        };

        return {
            t,
            gl,
            theme,
            items,
            icons,
            options,
            fullWidth,
            currrentLang,
            routeNamePath,
            overlap: ref(false),
            bgStyle,
            railStyle,
            changeTheme,
            changeLanguage,
        };
    },
};
</script>

<style lang="scss" scoped>

</style>