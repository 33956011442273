<template>
<section>
    <!-- <n-skeleton v-if="gl.loading" :width="width" :sharp="false" size="large" /> -->
    <div ref="filter" class="w-max flex flex-row items-center">
        <div class="text-sm lg:text-base text-current mr-4" :class="{'opacity-40': gl.loading}">{{ title }}</div>
        <n-radio-group v-model:value="radioCheck" :size="gl.screenWidth < 1024 ? 'small' : 'large'">
            <n-radio-button
                v-for="radio in radios"
                :key="radio.value"
                :value="radio.value"
                :disabled="localLoading"
                @click.prevent="radio.method">
                {{ radio.label }}
            </n-radio-button>
        </n-radio-group>
        <n-popover
            trigger="hover"
            :disabled="localLoading"
            v-if="checkeds.length">
            <template #trigger>
                <n-icon
                    class="text-red-500 text-2xl ml-4 cursor-pointer"
                    @click="cleanFilter">
                    <DeleteDismiss24Regular />
                </n-icon>
            </template>
            <span>{{ t('cleanFilter') }}</span>
        </n-popover>
    </div>

    <n-modal
        v-model:show="showModal"
        transform-origin="center">
        <n-card
            style="width: 800px"
            size="huge"
            role="dialog"
            :aria-modal="true"
            :segmented="{
                content: true,
                footer: 'soft'
            }">
            <template #default>
              <n-select
                multiple
                filterable
                size="large"
                class="w-full"
                v-model:value="checkeds"
                :options="options"
                :render-label="renderLabel"
                :placeholder="`- ${t('select')} -`"/>
            </template>
            <template #footer>
                <div class="flex flex-row justify-end">
                    <n-button
                        strong
                        class="text-white"
                        color="#D03A52"
                        @click="cacnel">
                        {{ refs.localization.filters.all_select.btn__cancel }}
                    </n-button>
                    <n-button
                        strong
                        class="ml-4 text-white"
                        color="#4949D9"
                        @click="apply">
                        {{ refs.localization.filters.all_select.btn__apply }}
                    </n-button>
                </div>
            </template>
        </n-card>
    </n-modal>
</section>
</template>

<script>
// vue
import { h, ref, reactive, computed, watch, onMounted } from 'vue';

// store
import { useGl } from '@/store/gl';
import { useRefs } from '@/store/refs';
import { useMarketplace } from '@store/ts/marketplace';

// naive
import {
    NTag,
    NCard,
    NText,
    NIcon,
    NModal,
    NAvatar,
    NButton,
    NSelect,
    NPopover,
    NSkeleton,
    NCheckbox,
    NRadioGroup,
    NRadioButton } from 'naive-ui';

// components
import RbCoin from '@components/rb-coin';

// icons
import { Dismiss16Filled, DeleteDismiss24Regular } from '@vicons/fluent';

// i18n
import { useI18n } from 'vue-i18n';

export default {
    name: 'FilterCoins',
    props: {
        data: {
            type: [ Array, Object],
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        defaultValues: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        RbCoin,
        NTag,
        NCard,
        NText,
        NIcon,
        NModal,
        NAvatar,
        NButton,
        NSelect,
        NPopover,
        NSkeleton,
        NCheckbox,
        NRadioGroup,
        NRadioButton,
        Dismiss16Filled,
        DeleteDismiss24Regular,
    },
    setup(props, { emit }) {
        const gl = useGl();
        const refs = useRefs();
        const marketplace = useMarketplace();

        const { t } = useI18n();

        const filter = ref();
        const width = ref();
        
        const radioCheck = ref(props.defaultValues.length ? 'select' : 'all');

        const localLoading = computed(() => gl.loading || props.loading);

        const showModal = ref(false);
        const checkeds = ref(props.defaultValues);

        const radios = computed(() => [
            {
                value: 'all',
                label:  refs.localization.filters.all_select.all,
                method: () => {
                    cleanFilter();
                },
            }, {
                value: 'select',
                label:  refs.localization.filters.all_select.select,
                method: () => {
                    showModal.value = true;
                    radioCheck.value = 'select';
                },
            },
        ]);

        const options = computed(() => props.data.map(el => ({
            label: el?.title,
            value: el?.id,
            ...el,
        })));

        const cacnel = () => {
            showModal.value = false;
        };

        const apply = (__, all = false) => {
            showModal.value = false;
            emit('apply', all ? null : checkeds.value);
        };

        const renderLabel = (option) => {
            return h(
                'div',
                {
                    class: 'flex flex-row items-center py-3',
                },
                [
                    h(RbCoin, {
                        coin: option,
                        class: 'mr-4',
                    }),
                    h(
                        'div',
                        {
                            class: {},
                        },
                        [
                            h('div', null, [option.label]),
                        ],
                    ),
                ],
            );
        };

        const cleanFilter = () => {
            checkeds.value.length = 0;
            apply(null, true);
            radioCheck.value = 'all';
        };

        onMounted(() => {
            width.value = filter.value.clientWidth;
        });

        return {
            gl,
            refs,
            width,
            filter,
            radios,
            options,
            checkeds,
            showModal,
            radioCheck,
            localLoading,
            t,
            apply,
            cacnel,
            renderLabel,
            cleanFilter,
        };
    },
};
</script>

<style lang="scss">
@import '@/css/stock-icon.scss';
</style>