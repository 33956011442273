<template>
<div
    v-if="isFooter"
    class="h-full flex justify-center items-center text-main text-md dark:bg-darkCardBg bg-gray-50 font-semibold relative overflow-hidden">
    {{ dataFooter }} <div class="absolute w-full top-0 left-0 h-[2px] bg-main shadow-xl"></div>
</div>
<div
    v-else
    class="h-full flex justify-between items-center px-3"
    :class="classes">
    <div class="flex flex-col items-center">
        <rb-coin class="w-5" :coin="currency(coin?.split('/')[0])" />
        <div class="uppercase text-[10px] text-gray-600 dark:text-white/75 font-semibold whitespace-nowrap">{{ data?.split('/')[0] }}</div>
    </div>
    <div class="opacity-50 px-1">/</div>
    <div class="flex flex-col items-center">
        <rb-coin class="w-5" :coin="currency(coin?.split('/')[1])" />
        <div class="uppercase text-[10px] text-gray-600 dark:text-white/75 font-semibold whitespace-nowrap">{{ data?.split('/')[1] }}</div>
    </div>
</div>
</template>

<script>
// vue
import { computed } from 'vue';

// store
import { useRefs } from '@store/ts/refs';

// components
import RbCoin from '@components/rb-coin';

export default {
    name: 'cell-pair',
    props: {
        isFooter: {
            type: Boolean,
            default: false,
            required: true,
        },
        dataFooter: {
            default: '',
        },
        data: {
            required: true,
        },
        coin: {
            type: String,
            required: true,
        },
        classes: {
            type: String,
        },
    },
    components: {
        RbCoin,
    },
    setup(props) {
        // store
        const refs = useRefs();

        const currency = icon => refs.currencies.find(el => el.id === icon);
        // const classes = computed(() => {
        //     return props.interactive
        //         ? 'text-center underline cursor-pointer hover:no-underline hover:text-main'
        //         : null;
        // });

        return {
            currency,
        };
    },
};
</script>