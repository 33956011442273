<template>
<base-modal-desktop
    v-if="refs.localization"
    width="500px"
    :label="refs.localization.topUpBalance.dialog_title"
    :show="show"
    @update:show="$emit('update:show', $event)">
    <template #default>
        <!-- v-if="!selectionCurrency" -->
        <n-alert
            class="bg-[#eaf0fe] dark:bg-blue-900 dark:bg-opacity-20 rounded-md mb-4 mt-2" type="info">
            <div class="font-semibold text-xs mt-[2px]">{{ refs.localization.topUpBalance.select_method }}</div>
        </n-alert>
        <section class="flex">
            <div
                class="border hover:border-main rounded-md cursor-pointer flex items-center justify-center w-4/12 mx-1 first:ml-0 last:mr-0 p-2"
                :class="currentMethodPay == 'Stripe' ? 'border-main' : 'border-gray-200 dark:border-gray-800'"
                @click="currentMethodPay = 'Stripe'">
                <img class="w-4/12" src="@assets/icons/visa.svg" />
                <img class="w-4/12" src="@assets/icons/mastercard.svg" />
            </div>
            <div
                class="border hover:border-main rounded-md cursor-pointer flex items-center justify-center w-4/12 mx-1 first:ml-0 last:mr-0 p-2"
                :class="currentMethodPay == 'PayPal' ? 'border-main' : 'border-gray-200 dark:border-gray-800'"
                @click="currentMethodPay = 'PayPal'">
                <img class="w-7/12" src="@assets/icons/paypal.svg" />
            </div>
            <div
                class="border hover:border-main rounded-md cursor-pointer flex items-center justify-center w-4/12 mx-1 first:ml-0 last:mr-0 p-2"
                :class="currentMethodPay == 'crypt' ? 'border-main' : 'border-gray-200 dark:border-gray-800'"
                @click="currentMethodPay = 'crypt'">
                <rb-coin class="w-6" :coin="currency(env.profitSymbol)" />
                <rb-coin class="w-6 mx-3" :coin="currency('eth')" />
                <rb-coin class="w-6" :coin="currency('usdt')" />
            </div>
        </section>
        <section class="mt-4">
            <template v-if="currentMethodPay == 'crypt'">
                <section>
                    <div class="text-gray-600 dark:text-white/75 whitespace-nowrap pr-3">
                        {{ refs.localization.topUpBalance.topup_method }}
                    </div>
                    <div class="flex mt-2">
                        <n-select
                            :options="topupSelectMethods"
                            :render-label="renderLabel"
                            :placeholder="`- ${t('select')} -`"
                            :value="selectionCurrency?.id"
                            @update:value="setSelectionCurrency"/>
                        <n-button
                            v-if="selectionCurrency && !selectionCurrency?.account"
                            class="rounded-md text-white/90 ml-2"
                            :color="gl.mainColor"
                            :loading="loadings.generateWallet"
                            :disabled="loadings.generateWallet"
                            @click="generateWallet">
                            {{ refs.localization.topUpBalance?.generate_wallet_label || 'Сгенерировать кошелек' }}
                        </n-button>
                    </div>
                </section>
                
                <div v-if="selectionCurrency?.account" class="flex flex-row items-start justify-between mt-6">
                    <div class="mr-4">
                        <div
                            class="flex flex-row items-center justify-between bg-gray-100 dark:bg-darkbg text-main p-4 rounded-md text-base cursor-pointer"
                            @click="copy(selectionCurrency.account)">
                                {{ selectionCurrency.account }}
                            <n-icon class="ml-4" size="25">
                                <Copy16Regular />
                            </n-icon>
                        </div>
                        <div class="text-xs mt-4 text-gray-500 dark:text-white/60" v-html="selectionCurrency.message"></div>
                    </div>
                    <img class="w-3/12 rounded-md" :src="'data:image/png;base64,'+selectionCurrency.qrcode" />
                </div>
            </template>
            <template v-else-if="currentMethodPay == 'PayPal'">
                <n-alert class="rounded-md mb-4" type="warning" size="small">
                    <div class="notes text-xs">
                        <div>
                            {{ refs.localization.topUpBalance?.min_replenishment_amount || 'Минимальная сумма пополнения:' }}
                            <strong>{{ topUpBalanceDefaultAmounts.min_amount }} USDT</strong>
                        </div>
                        <div>
                            {{ refs.localization.topUpBalance?.max_replenishment_amount || 'Максимальная сумма пополнения:' }}
                            <strong>{{ topUpBalanceDefaultAmounts.max_amount }} USDT</strong>
                        </div>
                    </div>
                </n-alert>
                <rb-input
                    type="price"
                    :label="refs.localization.topUpBalance.enter_amount + ':'"
                    :status="dataForm.PayPal.status"
                    :msg="dataForm.PayPal.msg"
                    v-model:value="dataForm.PayPal.value"
                    @update:value="dataForm.PayPal.status = undefined, dataForm.PayPal.msg = undefined" />
            </template>
            <template v-else>
                <n-alert class="rounded-md mb-4" type="warning" size="small">
                    <div class="notes text-xs">
                        <div>
                            {{ refs.localization.topUpBalance?.min_replenishment_amount || 'Минимальная сумма пополнения:' }}
                            <strong>{{ topUpBalanceDefaultAmounts.min_amount }} USDT</strong>
                        </div>
                        <div>
                            {{ refs.localization.topUpBalance?.max_replenishment_amount || 'Максимальная сумма пополнения:' }}
                            <strong>{{ topUpBalanceDefaultAmounts.max_amount }} USDT</strong>
                        </div>
                    </div>
                </n-alert>
                <rb-input
                    type="price"
                    :label="refs.localization.topUpBalance.enter_amount + ':'"
                    :status="dataForm.Stripe.status"
                    :msg="dataForm.Stripe.msg"
                    v-model:value="dataForm.Stripe.value"
                    @update:value="dataForm.Stripe.status = undefined, dataForm.Stripe.msg = undefined" />
            </template>
        </section>
    </template>
    <template v-if="currentMethodPay != 'crypt'" #footer>
        <section class="flex justify-end">
            <n-button
                class="rounded-md text-white/90"
                :color="gl.mainColor"
                @click="btns[currentMethodPay].fn">
                {{ btns[currentMethodPay].label }}
            </n-button>
        </section>
    </template>
</base-modal-desktop>
</template>

<script>
// general
import general from './general';

// icons
import {
    Copy16Regular } from '@vicons/fluent';

// naive-ui
import {
    NIcon,
    NAlert,
    NSelect,
    NButton } from 'naive-ui';

// components
import RbCoin from '@components/rb-coin';
import RbInput from '@components/rb-input';

export default {
    name: 'topup-balance-desktop',
    components: {
        NIcon,
        NAlert,
        RbCoin,
        NSelect,
        RbInput,
        NButton,
        Copy16Regular,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
      
        return {
            ...general(props),
        };
    },
};
</script>
