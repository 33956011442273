// vue
import { ref, computed, watch } from 'vue';

// interfaces
import { IRange } from './interfaces';

// moment
import moment from 'moment';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';

export default function (props: any, context: any) {
    // store
    const gl = useGl();
    const refs = useRefs();

    // vars
    const show = ref(false);
    const isSomeRange = computed((): (IRange | null) => {
        const currentPeriod = [props.data.value[0], props.data.value[1]];
        
        for (const range of ranges.value) {
            const currentRange = [ new Date(range.value[0]).getTime(), new Date(range.value[1]).getTime() ];

            if (currentPeriod[0] == currentRange[0] && currentPeriod[1] == currentRange[1]) {
                return range;
            }
        }

        return null;
    });

    watch(show, v => {
        context.emit('set-visibile', v);
    });

    const ranges = computed(() : IRange[] => refs.ranges);

    return {
        gl,
        refs,
        show,
        ranges,
        isSomeRange,
    };
}