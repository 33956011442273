import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex justify-between items-center" }
const _hoisted_2 = {
  key: 1,
  class: "text-sm text-gray-600 dark:text-white/75 font-semibold text-left py-[1px]"
}
const _hoisted_3 = { class: "flex flex-wrap items-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_skeleton = _resolveComponent("n-skeleton")!
  const _component_n_button = _resolveComponent("n-button")!
  const _component_date_picker = _resolveComponent("date-picker")!
  const _component_bots = _resolveComponent("bots")!
  const _component_exchanges = _resolveComponent("exchanges")!
  const _component_checkbox_group = _resolveComponent("checkbox-group")!
  const _component_radio_group = _resolveComponent("radio-group")!
  const _component_filter_coins = _resolveComponent("filter-coins")!
  const _component_n_dropdown = _resolveComponent("n-dropdown")!
  const _component_n_card = _resolveComponent("n-card")!

  return (_openBlock(), _createBlock(_component_n_card, {
    size: "small",
    bordered: false,
    class: "overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg h-full",
    segmented: {
        content: true,
        footer: 'soft'
    },
    "content-style": "padding-top: 0;"
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (!_ctx.refs.localization)
          ? (_openBlock(), _createBlock(_component_n_skeleton, {
              key: 0,
              class: "inline-block",
              width: 215,
              height: 20,
              sharp: false
            }))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.refs.localization.filters.general.filters_label), 1)),
        (_ctx.filtersChanged)
          ? (_openBlock(), _createBlock(_component_n_button, {
              key: 2,
              secondary: "",
              type: "error",
              size: "tiny",
              class: "rounded-md",
              onClick: _ctx.resetAllFilters
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.refs.localization.filters.general.filters_reset_label), 1)
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modifiedRefFilters, (filter, i) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
            (filter.mode === 'date_picker')
              ? (_openBlock(), _createBlock(_component_date_picker, {
                  key: 0,
                  data: filter,
                  filters: _ctx.filters,
                  label: filter.title,
                  class: "w-2/12 mr-3 mt-3",
                  onUpdateDate: ($event: any) => (_ctx.onUpdateDate($event, filter))
                }, null, 8, ["data", "filters", "label", "onUpdateDate"]))
              : _createCommentVNode("", true),
            (filter.mode === 'bots')
              ? (_openBlock(), _createBlock(_component_bots, {
                  key: 1,
                  class: "mr-3 mt-3",
                  data: filter,
                  filters: _ctx.filters,
                  visible: _ctx.visibleFilters.includes(filter.filter),
                  onChangeFilter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('changeFilter', $event))),
                  onSetVisibleFilters: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setVisibleFilters($event)))
                }, null, 8, ["data", "filters", "visible"]))
              : _createCommentVNode("", true),
            (filter.mode === 'exchanges')
              ? (_openBlock(), _createBlock(_component_exchanges, {
                  key: 2,
                  class: "mr-3 mt-3",
                  data: filter,
                  filters: _ctx.filters,
                  visible: _ctx.visibleFilters.includes(filter.filter),
                  onChangeFilter: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('changeFilter', $event))),
                  onSetVisibleFilters: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setVisibleFilters($event)))
                }, null, 8, ["data", "filters", "visible"]))
              : _createCommentVNode("", true),
            (filter.mode === 'checkbox_group')
              ? (_openBlock(), _createBlock(_component_checkbox_group, {
                  key: 3,
                  class: "mr-3 mt-3",
                  data: filter,
                  filters: _ctx.filters,
                  visible: _ctx.visibleFilters.includes(filter.filter),
                  onChangeFilter: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('changeFilter', $event))),
                  onSetVisibleFilters: _cache[5] || (_cache[5] = ($event: any) => (_ctx.setVisibleFilters($event)))
                }, null, 8, ["data", "filters", "visible"]))
              : _createCommentVNode("", true),
            (filter.mode === 'radio_group')
              ? (_openBlock(), _createBlock(_component_radio_group, {
                  key: 4,
                  class: "mr-3 mt-3",
                  data: filter,
                  filters: _ctx.filters,
                  visible: _ctx.visibleFilters.includes(filter.filter),
                  onChangeFilter: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('changeFilter', $event))),
                  onSetVisibleFilters: _cache[7] || (_cache[7] = ($event: any) => (_ctx.setVisibleFilters($event)))
                }, null, 8, ["data", "filters", "visible"]))
              : _createCommentVNode("", true),
            (filter.mode === 'filter_coins')
              ? (_openBlock(), _createBlock(_component_filter_coins, {
                  key: 5,
                  class: "mr-3 mt-3",
                  data: filter,
                  filters: _ctx.filters,
                  visible: _ctx.visibleFilters.includes(filter.filter),
                  onChangeFilter: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('changeFilter', $event))),
                  onSetVisibleFilters: _cache[9] || (_cache[9] = ($event: any) => (_ctx.setVisibleFilters($event)))
                }, null, 8, ["data", "filters", "visible"]))
              : _createCommentVNode("", true)
          ], 64))
        }), 128)),
        _createVNode(_component_n_dropdown, {
          scrollable: "",
          trigger: "click",
          style: {"max-height":"300px"},
          class: "bg-white dark:bg-darkCardBg",
          placement: "bottom-start",
          show: _ctx.showDropdownRef,
          options: _ctx.modifiedRefFilters,
          "render-label": _ctx.renderDropdownLabel,
          onClickoutside: _cache[11] || (_cache[11] = ($event: any) => (_ctx.showDropdownRef = false))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_n_button, {
              class: "rounded-md mr-2 mt-3",
              onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.showDropdownRef = true))
            }, {
              default: _withCtx(() => [
                _createTextVNode(" + " + _toDisplayString(_ctx.refs.localization.filters.general.filters_add_filters_label), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["show", "options", "render-label"]),
        _createVNode(_component_n_button, {
          strong: "",
          class: "rounded-md font-semibold mt-3 text-white/90",
          color: _ctx.gl.mainColor,
          onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.$emit('getData')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.refs.localization.filters.general.filters_apply_label), 1)
          ]),
          _: 1
        }, 8, ["color"])
      ])
    ]),
    _: 1
  }))
}