// vue
import { ref, computed, reactive, watch } from 'vue';

// store
import { useRefs } from '@store/ts/refs';

export default function (props: any, context: any) {
    // store
    const refs = useRefs();

    // vars
    const { _ } = window;
    const show = ref(false);
    const searchFilter = ref();
    const init = ref(false);
    const options = ref([]);

    watch(show, $event => {
        if ($event) {
            setTimeout(() => {
                prepareCoins();
            }, 200);
        } else {
            addSelected();
        }
    });

    watch(() => props.visible, v => {
        if (v) {
            show.value = true;
        } else {
            context.emit('changeFilter', [props.data.filter, [ -1 ]]);
        }
    });

    const checkeds = computed(() => _.get(props.filters, props.data.filter)[0] === -1
        ? refs.localization.filters.all_select.all
        : options.value.reduce((accum: number, el: any) => accum + +el.checked , 0) || _.get(props.filters, props.data.filter).length,
        // : 
    );

    const label = computed(() =>
        props.data.label[props.data.label.length - 1] === ':'
            ? props.data.label.slice(0, props.data.label.length - 1)
            : props.data.label,
    );

    const prepareCoins = async () => {
        options.value = props.data.refs.map((el: any) => reactive({
            checked: !!_.get(props.filters, props.data.filter)?.find((item: any) => item == el?.id),
            show: computed(() => !searchFilter.value
                ? true
                : el.title.toLowerCase().includes(searchFilter.value.toLowerCase())),
            ...el,
        }));
    };

    const setCheckAll = ($event: any) => {
        options.value.forEach((el: any) => {
            el.checked = $event;
        });
    };

    const addSelected = () => {
        const arr = options.value.filter(({ checked }: { checked: boolean }) => checked).map(({ id }: { id: string | number }) => id);
        context.emit('changeFilter', [ props.data.filter, arr.length ? arr : [ -1 ] ]);
    };

    const showItem = (item: any) => {
        return !searchFilter.value
            ? true
            : item.title.toLowerCase().includes(searchFilter.value.toLowerCase());
    };

    return {
        show,
        refs,
        init,
        label,
        options,
        checkeds,
        searchFilter,
        _,
        showItem,
        setCheckAll,
    };
}