<template>
<div
    v-if="isFooter"
    class="h-full flex justify-center items-center text-main text-md dark:bg-darkCardBg bg-gray-50 font-semibold relative overflow-hidden">
    {{ dataFooter }} <div class="absolute w-full top-0 left-0 h-[2px] bg-main shadow-xl"></div>
</div>
<div
    v-else
    class="h-full flex justify-center items-center px-2"
    :class="classes">
    <n-switch
        size="small"
        :loading="loading"
        :disabled="loading"
        :value="data">
        <template #checked>{{ checked }}</template>
        <template #unchecked>{{ unchecked }}</template>
    </n-switch>
</div>
</template>

<script>
// vue
import { computed, onMounted } from 'vue';

// naive-ui
import {
    NSwitch,
} from 'naive-ui';

export default {
    name: 'cell-switch',
    props: {
        isFooter: {
            type: Boolean,
            default: false,
            required: true,
        },
        data: {
            required: true,
        },
        checked: {
            type: String,
            default: 'yes',
        },
        unchecked: {
            type: String,
            default: 'no',
        },
        classes: {
            type: String,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        NSwitch,
    },
    setup() {
    // 
    },
};
</script>