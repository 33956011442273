import { defineStore } from 'pinia';

export const useStats = defineStore('stats', {
    state: () => ({
        refs: null,
        forceUpdate: true,
        botsDataTable: null,
        datesDataTable: null,
        cyclesDataTable: null,
        ordersDataTable: null,
        isBots: true,
        forAllCycles: false,
        currentCycle: null,
    }),
    getters: {
        localization(state) {
            return state.refs?.data?.localization[Object.keys(state.refs.data.localization)[0]];
        },
        botFilters(state) {
            return state.refs?.data.bot_filters;
        },
        tags(state) {
            return state.refs?.data.tags;
        },
        targetFilters(state) {
            return state.refs?.data.target_filters;
        },
        cycleFilters(state) {
            return state.refs?.data.cycle_filters;
        },
        orderTypes(state) {
            return state.refs?.data.order_types;
        },
        orderStatuses(state) {
            return state.refs?.data.order_statuses;
        },
        orderExecutionTypes(state) {
            return state.refs?.data.order_execution_types;
        },
    },
});