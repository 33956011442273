<template>
<div
    v-if="isFooter"
    class="h-full flex justify-center items-center text-main text-md dark:bg-darkCardBg bg-gray-50 font-semibold relative overflow-hidden">
    {{ dataFooter }} <div class="absolute w-full top-0 left-0 h-[2px] bg-main shadow-xl"></div>
</div>
<div
    v-else
    class="h-full flex p-1 min-w-[210px]"
    :class="classes">
    <div
        v-for="(item, i) in data.slice(0, 3)"
        :key="i"
        class="w-4/12 p-0 pr-1 last:pr-0">
        <n-card size="small" class="rounded-md bg-transparent px-0">
            <span class="w-full inline-block rb-td-stock-icon" :class="getClass(item)"></span>
        </n-card>
    </div>
    
    <n-popover
        v-if="data.length > 3"
        trigger="click"
        placement="left"
        :class="[classes, 'p-0 rounded-lg']">
        <template #trigger>
            <n-button
                strong
                secondary
                size="small"
                class="px-1 h-full rounded-md">
                <span class="font-semibold text-base -mt-2">...</span>
            </n-button>
        </template>
        <template #default>
            <section class="flex max-w-[196px] flex-wrap pt-1 pl-1">
                <div
                    v-for="(item, i) in data"
                    :key="i"
                    class="w-[96px] p-0 pr-1 mb-1">
                    <n-card size="small" class="rounded-md bg-transparent px-2">
                        <span class="w-full inline-block rb-td-stock-icon" :class="getClass(item)"></span>
                    </n-card>
                </div>
            </section>
        </template>
    </n-popover>
</div>
</template>

<script>
// vue
import { computed } from 'vue';

// store
import { useRefs } from '@store/ts/refs';

// naive-ui
import {
    NCard,
    NButton,
    NPopover } from 'naive-ui';

export default {
    name: 'cell-exchanges-mobile',
    props: {
        isFooter: {
            type: Boolean,
            default: false,
            required: true,
        },
        data: {
            required: true,
            type: Array,
        },
        classes: {
            type: String,
        },
    },
    components: {
        NCard,
        NButton,
        NPopover,
    },
    setup() {
        // store
        const refs = useRefs();

        const getClass = item => `rb-td-stock-icon-${exchange(item)?.code_name}`;

        const exchange = exchange => refs.exchanges.find(({ id }) => id === exchange);
        
        return {
            getClass,
        };
    },
};
</script>

<style lang="scss" scoped>
:deep(.n-card) > .n-card__content {
    padding: 0 4px !important;
}

.rb-td-stock-icon {
  height: 30px;
}
</style>