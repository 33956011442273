export default class LocalStorage {
    static getItem(key: string) {
        try {
            const result = JSON.parse(localStorage.getItem(key) as string);
            return result;
        } catch(e) {
            console.log(e);
            return null;
        }
    };

    static setItem(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    };

    static has(key: string) {
        return localStorage.getItem(key) !== null;
    }
}