<template>
<div ref="filter" class="lg:flex lg:flex-row items-center justify-start" >
    <div class="text-sm lg:text-base text-current mr-4" :class="{'opacity-40': gl.loading}" v-html="title"></div>
    <n-radio-group
        v-model:value="radioCheck"
        class="overflow-x-scroll overflow-y-hidden mt-4 lg:mt-0"
        :size="gl.screenWidth < 1024 ? 'small' : 'large'">
        <n-radio-button
            v-for="radio in radios"
            :key="radio.value"
            :value="radio.value"
            :disabled="localLoading"
            @click.prevent="radio.method">
            {{ radio.label }}
        </n-radio-button>
    </n-radio-group>
    <n-popover
        trigger="hover"
        v-if="showDelete"
        :disabled="localLoading">
        <template #trigger>
            <n-icon
                class="text-red-500 text-2xl ml-4 cursor-pointer"
                @click="cleanFilter">
                <DeleteDismiss24Regular />
            </n-icon>
        </template>
        <span>{{ t('cleanFilter') }}</span>
    </n-popover>
</div>
</template>

<script>
// vue
import { ref, computed, watch, onMounted } from 'vue';

// store
import { useGl } from '@/store/gl';
import { useRefs } from '@/store/refs';

// naive
import {
    NTag,
    NCard,
    NText,
    NIcon,
    NModal,
    NAvatar,
    NButton,
    NSelect,
    NPopover,
    NSkeleton,
    NCheckbox,
    NRadioGroup,
    NRadioButton } from 'naive-ui';

// components
import RbCoin from '@components/rb-coin';

// icons
import { Dismiss16Filled, DeleteDismiss24Regular } from '@vicons/fluent';

// i18n
import { useI18n } from 'vue-i18n';

export default {
    name: 'ButtonGroup',
    props: {
        data: {
            type: [ Array, Object],
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        RbCoin,
        NTag,
        NCard,
        NText,
        NIcon,
        NModal,
        NAvatar,
        NButton,
        NSelect,
        NPopover,
        NSkeleton,
        NCheckbox,
        NRadioGroup,
        NRadioButton,
        Dismiss16Filled,
        DeleteDismiss24Regular,
    },
    setup(props, { emit }) {
        const gl = useGl();
        const refs = useRefs();

        const filter = ref();
        const width = ref();
        const height = ref();

        const { t } = useI18n();
        
        const radioCheck = ref('all');

        const localLoading = computed(() => gl.loading || props.loading);

        const showDelete = computed(() => radioCheck.value !== 'all');

        const radios = computed(() => [
            {
                value: 'all',
                label:  refs.localization.filters.all_select.all,
                method: () => {
                    cleanFilter();
                },
            }, ...props.data.map(el => {
                return {
                    value: el.id,
                    label: el.title,
                    ...el,
                    method: () => {
                        radioCheck.value = el.id;
                        emit('apply', el.id);
                    },
                };
            }),
        ]);

        const options = computed(() => props.data.map(el => ({
            label: el.title,
            value: el.id,
            ...el,
        })));

        const cleanFilter = () => {
            radioCheck.value = 'all';
            emit('apply', null);
        };

        onMounted(() => {
            width.value = filter.value.clientWidth;
            height.value = filter.value.clientHeight;
        });

        return {
            gl,
            refs,
            width,
            height,
            filter,
            radios,
            options,
            radioCheck,
            showDelete,
            localLoading,
            t,
            cleanFilter,
        };
    },
};
</script>

<style lang="scss">
@import '@/css/stock-icon.scss';
</style>