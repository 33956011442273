import { defineStore } from 'pinia';

export const useQuickStart = defineStore('quickStart', {
    state: () => ({
        refs: null,
        loading: false,
    }),
    getters: {
        localization(state) {
            return state.refs.data?.localization[Object.keys(state.refs.data.localization)[0]];
        },
        assignmentTypes(state) {
            return state.refs.data.assignment_types;
        },
        algos(state) {
            return state.refs.data.algos;
        },
        positionmodes(state) {
            return state.refs.data.positionmodes;
        },
        ordersMatrix(state) {
            return state.refs.data.orders_matrix;
        },
        tradeModes(state) {
            return state.refs.data.tradeModes;
        },
        tradeModesF(state) {
            return state.refs.data.tradeModes_f;
        },
    },
});