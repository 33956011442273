<template>
<n-card
    v-if="init && revenueStats"
    size="small"
    :bordered="false"
    class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg"
    content-style="padding: 0;"
    :segmented="{
        content: true,
        footer: 'soft'
    }">
    <template #header>
        <div v-if="dashProfit.title" class="flex justify-center text-sm text-gray-600 dark:text-white/75 font-semibold">
            <!-- <n-skeleton v-if="gl.showLoading" :width="215" :height="20" :sharp="false" /> -->
            {{ dashProfit.title }}
        </div>
    </template>
    <div class="flex flex-col" :class="revenueStats.coins.length > 6 ? 'h-[306px]' : ''">
        <!-- <div v-if="gl.showLoading" class="h-[80%]">
            <div
                v-for="(n, i) in 6"
                :key="i"
                class="w-full dark:odd:bg-[#242424] odd:bg-gray-50 h-[40px]">
            </div>
        </div> -->
        <n-scrollbar class="h-[75%]">
            <div
                v-for="(stat, coin) in revenueStats.coins"
                :key="coin"
                class="w-full dark:odd:bg-[#242424] odd:bg-gray-50">
                <div class="py-1 flex flex-row justify-start items-center overflow-x-hidden relative">
                    <div class="w-4/12 flex items-center">
                        <rb-coin class="mx-2 w-6" :coin="currency(coin)" />
                        <div class="text-sm font-semibold uppercase">{{ coin }}</div>
                    </div>
                    
                    <div class="text-sm font-semibold" :class="stat[0] > 0 ? 'text-green-600' : 'text-red-500'">{{ stat[0] }}</div>

                    <div
                        v-if="coin !== env.profitSymbol"
                        class="flex flex-row opacity-60 hover:opacity-100 items-center text-white justify-start absolute w-4/5 h-full bg-main right-[-72%] rounded-l-xl hover:right-0 transition-all cursor-pointer">
                        <n-icon class="absolute left-2 top-1/2 -translate-y-1/2">
                            <ChevronLeft24Filled />
                        </n-icon>

                        <div class="w-3/12 flex items-center ml-6">
                            <rb-coin class="mx-2 w-6" :coin="currency(env.profitSymbol)" />
                            <div class="text-sm font-semibold uppercase">{{ env.profitSymbol }}</div>
                        </div>
                        
                        <div class="text-sm font-semibold">{{ stat[1] }}</div>
                    </div>
                </div>
            </div>
        </n-scrollbar>
        <div class="h-[25%] flex flex-col justify-center items-center py-4">
            <!-- <n-skeleton v-if="gl.showLoading" :width="200" :height="18" :sharp="false" /> -->
            <div class="text-sm font-semibold">{{ revenueStats?.btc }} {{ env.profitSymbol.toUpperCase() }} / {{ revenueStats?.usdt || '---'}} USDT</div>

            <!-- <n-skeleton v-if="gl.showLoading" class="mt-2" :width="130" :height="16" :sharp="false" /> -->
            <div class="text-sm">
                ( {{ revenueStats.cycles?.completed }} {{ stats.localization['dashboard_profit_total_cycles_completed'] }} )
            </div>
        </div>
    </div>
</n-card>

<super-table
    v-if="init"
    showSearch
    class="mt-4"
    :filters="filters"
    :actions="actions"
    :refFilters="refFilters"
    :pager="currentTable.pager"
    :columns="columns"
    :records="currentTable.records"
    :filtersChanged="filtersChanged"
    columnsStorageName="__columns_stats"
    type="stats"
    @getData="botsGet"
    @doSomething="doSomething"
    @reset-filters="resetFilters"
    @applyFilters="changeFilter">
    <template #tableTitle>
        <div class="text-base text-left mb-2 text-gray-600 dark:text-white/75">
            {{ stats.localization['trade_statistics_available_bots_title'] }}
        </div>
    </template>
    <template #stats>
        <n-card
            size="small"
            :bordered="false"
            class="shadow bg-white dark:bg-darkCardBg rounded-lg my-3"
            :segmented="{
                content: true,
                footer: 'soft'
            }">
            <div class="text-md text-gray-600 dark:text-white/75">
                <span>{{ stats.localization['trade_statistics_bots_total'] }}</span>
                <n-tag round size="small" :bordered="false" type="warning" class="ml-2">
                    <span class="text-yellow-500 font-semibold">{{ currentTable.stats.total }}</span>
                </n-tag>
                
                <span class="ml-4">{{ stats.localization['trade_statistics_bots_running'] }}</span>
                <n-tag round size="small" :bordered="false" type="success" class="ml-2">
                    <span class="text-green-500 font-semibold">{{ currentTable.stats.running }}</span>
                </n-tag>
                
                <span class="ml-4">{{ stats.localization['trade_statistics_bots_stopped'] }}</span>
                <n-tag round size="small" :bordered="false" class="ml-2">
                    <span class="font-semibold">{{ currentTable.stats.stopped }}</span>
                </n-tag>
                
                <span class="ml-4">{{ stats.localization['trade_statistics_bots_error'] }}</span>
                <n-tag round size="small" :bordered="false" type="error" class="ml-2">
                    <span class="text-red-400 font-semibold">{{ currentTable.stats.error }}</span>
                </n-tag>
            </div>
        </n-card>
    </template>
    <template #actionButtons>
        <section v-if="currentTable.records && stats.isBots" class="flex flex-wrap">
            <n-button
                strong
                class="text-white/90 rounded-md mr-3 mb-2"
                :color="gl.mainColor"
                :disabled="!actionButtonsDisabled"
                @click="letsShowConfirmation('start')">
                {{ stats.localization['trade_statistics_bots_table_mass_start_button'] }}
            </n-button>
            <n-button
                strong
                class="mr-3 mb-2 text-white/90 rounded-md"
                :color="gl.mainColor"
                :disabled="!actionButtonsDisabled"
                @click="letsShowConfirmation('stop')">
                {{ stats.localization['trade_statistics_bots_table_mass_stop_button'] }}
            </n-button>
            <n-button
                strong
                class="mr-3 mb-2 text-white/90 rounded-md"
                :color="gl.mainColor"
                :disabled="!actionButtonsDisabled"
                @click="letsShowConfirmation('pause')">
                {{ stats.localization['trade_statistics_bots_table_mass_pause_button'] }}
            </n-button>
            <n-button
                strong
                class="mr-3 mb-2 text-white/90 rounded-md"
                :color="gl.mainColor"
                :disabled="!actionButtonsDisabled"
                @click="letsShowConfirmation('archive')">
                {{ stats.localization['trade_statistics_bots_table_mass_archive_button'] }}
            </n-button>
            <n-button
                strong
                class="mr-3 mb-2 text-white/90 rounded-md"
                :color="gl.mainColor"
                :disabled="!actionButtonsDisabled"
                @click="letsShowConfirmation('delete')">
                {{ stats.localization['trade_statistics_bots_table_mass_delete_button'] }}
            </n-button>
            <n-button
                strong
                class="mr-3 mb-2 text-white/90 rounded-md"
                :color="gl.mainColor"
                :disabled="!actionButtonsDisabled"
                @click="letsShowConfirmation('markLastAsCanceled')">
                {{ stats.localization['trade_statistics_management_markcyclecanceled_button'] }}
            </n-button>
            <n-button
                strong
                class="mr-3 mb-2 text-white/90 rounded-md"
                :color="gl.mainColor"
                :disabled="!actionButtonsDisabled"
                @click="letsShowConfirmation('restart')">
                {{ stats.localization['trade_statistics_management_restart_button'] }}
            </n-button>
            <n-button
                strong
                class="mr-3 mb-2 text-white/90 rounded-md"
                :color="gl.mainColor"
                :disabled="!actionButtonsDisabled"
                @click="letsShowConfirmation('force_stop')">
                {{ stats.localization['trade_statistics_bots_table_mass_force_stop'] }}
            </n-button>
        </section>
    </template>
</super-table>
<base-dialog-desktop
    v-model:show="showConfirmation"
    @positive-click="showConfirmation = false, groupAction(actionType)"
    @negative-click="showConfirmation = false">
    <div class="text-md" v-html="refs.localization.confirmations.bot[actionType]"></div>
</base-dialog-desktop>
</template>

<script>
// general
import general from './general';

// icon
import { ChevronLeft24Filled } from '@vicons/fluent';

// components
import RbCoin from '@components/rb-coin';
import RbSwitch from '@components/rb-switch';
import FilterBots from '@components/filters/bots';
import FilterStates from '@components/filters/states';
import FilterStatuses from '@components/filters/statuses';
import FilterExchanges from '@components/filters/exchanges';
import SuperTable from '@components/super-table/desktop.vue';
import FilterTaksonomiya from '@components/filters/taksonomiya';

// UI
import {
    NTag,
    NIcon,
    NCard,
    NTabs,
    NButton,
    NTabPane,
    NDivider,
    NSkeleton,
    NScrollbar,
    NDatePicker,
    NRadioGroup,
    NRadioButton } from 'naive-ui';

export default {
    name: 'trade-statistic-desktop',
    components: {
        NTag,
        NIcon,
        NCard,
        NTabs,
        RbCoin,
        NButton,
        NDivider,
        RbSwitch,
        NTabPane,
        SuperTable,
        NSkeleton,
        NScrollbar,
        FilterBots,
        NRadioGroup,
        NDatePicker,
        NRadioButton,
        FilterStates,
        FilterStatuses,
        FilterExchanges,
        FilterTaksonomiya,
        ChevronLeft24Filled,
    },
    setup(props, context) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>