import { defineStore } from 'pinia';

// interfaces
import {
    IRefs,
    IGetInfo } from './interfaces';

export const useMarketplace = defineStore('marketplace', {
    state: () => ({
        refs: {
            localization: {},
        } as IRefs,
        buyGet: {} as IGetInfo,
        sellGet: {} as IGetInfo,
        iMentor: {} as IGetInfo,
        mentorshipGet: {} as IGetInfo,
        myMentors: {} as IGetInfo,
        loading: false,
    }),
    getters: {
        localization(state) {
            return state.refs.localization[Object.keys(state.refs.localization)[0]];
        },
        mentorshipRequestStates(state) {
            return state.refs.mentorship_request_states;
        },
        filters(state) {
            return state.buyGet.pager.filters;
        },
        algos(state) {
            return state.refs.algos;
        },
        periods(state) {
            return state.refs.periods;
        },
        stats(state) {
            return state.buyGet.stats;
        },
        total(state) {
            return state.buyGet.stats.total;
        },
        totals(state) {
            return state.buyGet.totals;
        },
        sellTotal(state) {
            return state.sellGet.stats.total;
        },
        mentorshipTotal(state) {
            return state.mentorshipGet.stats.total;
        },
        myMentorsTotal(state) {
            return state.myMentors.stats.total;
        },
        iMentorTotal(state) {
            return state.iMentor.stats.total;
        },
        columns(state) {
            return state.buyGet.columns;
        },
        sellColumns(state) {
            return state.sellGet.columns;
        },
        mentorshipColumns(state) {
            return state.mentorshipGet.columns;
        },
        myMentorsColumns(state) {
            return state.myMentors.columns;
        },
        iMentorColumns(state) {
            return state.iMentor.columns;
        },
        records(state) {
            return state.buyGet.records;
        },
        sellRecords(state) {
            return state.sellGet.records;
        },
        mentorshipRecords(state) {
            return state.mentorshipGet.records;
        },
        myMentorsRecords(state) {
            return state.myMentors.records;
        },
        iMentorRecords(state) {
            return state.iMentor.records;
        },
        pager(state) {
            return state.buyGet.pager;
        },
        sellPager(state) {
            return state.sellGet.pager;
        },
        mentorshipPager(state) {
            return state.mentorshipGet.pager;
        },
        myMentorsPager(state) {
            return state.myMentors.pager;
        },
        iMentorPager(state) {
            return state.iMentor.pager;
        },
        assignmentTypes(state) {
            return state.refs.assignment_types;
        },
        inMarketStates(state) {
            return state.refs.in_market_states;
        },
        signalSubscriptionTypes(state) {
            return state.refs.signal_subscription_types;
        },
        refsLocalizationNotEmpty(state): boolean {
            return !!(Object.keys( state.refs.localization).length);
        },
        buyGetNotEmpty(state): boolean {
            return !!(Object.keys( state.buyGet).length);
        },
        sellGetNotEmpty(state): boolean {
            return !!(Object.keys( state.sellGet).length);
        },
        iMentorNotEmpty(state): boolean {
            return !!(Object.keys( state.iMentor).length);
        },
        mentorshipGetNotEmpty(state): boolean {
            return !!(Object.keys( state.mentorshipGet).length);
        },
        myMentorsNotEmpty(state): boolean {
            return !!(Object.keys( state.myMentors).length);
        },
    },
});