<template>
<!-- <pre>{{ dataForm }}</pre> -->
<section>
    <div class="text-gray-600 dark:text-white/75 text-xs mt-4">
        {{ profile.localization['profile_main_info_email'] }}
    </div>
    <div class="text-base mt-2 font-semibold">{{ profile.fullInfo.email }}</div>
</section>
<n-divider class="my-4" />
<section>
    <rb-input
        id="profile__full_name"
        :status="profile.errors?.full_name ? 'error' : undefined"
        :msg="profile.errors?.full_name?.msg"
        :label="profile.localization['profile_main_info_fullname_f']"
        :placeholder="profile.localization['profile_main_info_fullname_i']"
        v-model:value="dataForm.full_name"
        @update:value="profile.cleanError('full_name')" />
</section>
<section class="mt-3">
    <rb-input
        :label="profile.localization['profile_main_info_company_f']"
        :placeholder="profile.localization['profile_main_info_company_i']"
        v-model:value="dataForm.company" />
</section>
<section class="mt-3">
    <rb-select
        :label="profile.localization['profile_main_info_timezone']"
        :options="timezonesOptions"
        v-model:value="dataForm.timezone" />
</section>
<section class="mt-3">
    <rb-input
        :label="profile.localization['profile_main_info_phone_number_f']"
        :placeholder="profile.localization['profile_main_info_phone_number_i']"
        v-model:value="dataForm.phone" />
</section>
<section class="mt-3">
    <rb-select
        :label="profile.localization['profile_main_info_preferred_language']"
        :options="languagesOptions"
        v-model:value="dataForm.language" />
</section>
<n-divider class="my-4" />

<section class="mt-3">
    <n-skeleton v-if="!profile.localization" class="inline-block" :width="215" :height="20" :sharp="false" />
    <div v-else class="text-md text-gray-600 dark:text-white/75 font-semibold text-left">
        {{ profile.localization['profile_main_info_additional_contacts_title'] }}
    </div>

    <section class="mt-4">
        <div
            v-for="(messenger, i) in dataForm.messengers"
            :key="i"
            class="w-full mb-2">
            <n-card
                size="small"
                :bordered="false"
                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg relative"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <n-icon
                    size="20"
                    class="text-red-500 absolute top-2 right-2"
                    @click="onRemoveMessenger(i)">
                    <DeleteDismiss24Regular />
                </n-icon>
                
                <div class="flex mt-4">
                    <rb-select
                        class="w-6/12 pr-1"
                        :label="profile.localization['profile_main_info_additional_contacts_contact_type_f']"
                        :options="messengersOptions"
                        v-model:value="messenger.id" />
                    <rb-input
                        class="w-6/12 pl-1"
                        :label="messengerName(messenger.id)"
                        :placeholder="messengerName(messenger.id)"
                        :disabled="messenger.id == -1"
                        v-model:value="messenger.value" />
                </div>
            </n-card>
        </div>
        <div class="w-full">
            <n-button
                secondary
                size="large"
                type="default"
                class="w-full rounded-md"
                @click="onAddMessenger">
                + {{ profile.localization['profile_main_info_additional_contacts_add_contact_button'] }}
            </n-button>
        </div>
    </section>
</section>
<n-divider class="my-4" />

<section class="mt-3">
    <n-skeleton v-if="!profile.localization" class="inline-block" :width="215" :height="20" :sharp="false" />
    <div v-else class="text-md text-gray-600 dark:text-white/75 font-semibold text-left">
        {{ profile.localization['profile/dashboard_settings/title'] }}
    </div>
    
    <n-card
        size="small"
        :bordered="false"
        class="shadow bg-white dark:bg-darkCardBg rounded-lg mt-4"
        :segmented="{
            content: true,
            footer: 'soft'
        }">
        <rb-select
            class="w-full"
            :label="profile.localization['profile/dashboard_settings/stat_views/label']"
            :options="statViewsOptions"
            v-model:value="dataForm.stat_view" />
    </n-card>
</section>
<n-divider class="my-4" />

<section class="mt-3">
    <n-skeleton v-if="!profile.localization" class="inline-block" :width="215" :height="20" :sharp="false" />
    <div v-else class="text-md text-gray-600 dark:text-white/75 font-semibold text-left">
        {{ profile.localization['profile/dashboard_settings/enabled_blocks'] }}
    </div>

    <n-card
        size="small"
        :bordered="false"
        class="shadow bg-white dark:bg-darkCardBg rounded-lg mt-4"
        :segmented="{
            content: true,
            footer: 'soft'
        }">
        <section class="flex flex-wrap">
            <div
                v-for="block in dataForm.dashboard_blocks"
                :key="block.id"
                class="py-2 pr-4">
                <rb-switch
                    :id="'dbblock_'+block.id"
                    :label="block.title[0].toUpperCase() + block.title.slice(1).toLowerCase()"
                    v-model:value="block.enabled" />
            </div>

            <rb-switch
                id="is_chat_enabled"
                class="py-2 pr-4"
                :label="profile.localization['profile/chat/label'][0].toUpperCase() + profile.localization['profile/chat/label'].slice(1)"
                v-model:value="dataForm.is_chat_enabled" />
        </section>
    </n-card>
</section>

<section class="mt-3">
    <n-card
        size="small"
        :bordered="false"
        class="shadow bg-white dark:bg-darkCardBg rounded-lg mt-4"
        :segmented="{
            content: true,
            footer: 'soft'
        }">
        <rb-input
            class="w-full"
            :label="profile.localization['profile/main_info/tv_ips']"
            :placeholder="profile.localization['profile/main_info/tv_ips/placeholder']"
            v-model:value="dataForm.tv_ips" />

        <rb-switch
            id="autorestart"
            class="mt-4"
            :label="profile.localization['profile/autorestart/label']"
            v-model:value="dataForm.autorestart" />
    </n-card>
</section>
<n-button
    strong
    size="large"
    class="rounded-md text-white/90 sticky bottom-0 w-full"
    :disabled="loading"
    :loading="loading"
    :color="gl.mainColor"
    @click="onUpdate">
    {{ profile.localization['profile_save_changes_button'] }}
</n-button>

<current-password
    :loading="loading"
    v-model:show="showPassModal"
    @confirm="onUpdate" />
</template>

<script>
// vue
import { ref, reactive, onMounted } from 'vue';

// general
import general from './general';

// services
import ProfileService from '@services/profileService';

// icons
import { Dismiss16Filled, IosArrowRtl24Filled, DeleteDismiss24Regular } from '@vicons/fluent';

// components
import RbInput from '@components/rb-input/mobile.vue';
import RbSelect from '@components/rb-select/mobile.vue';
import RbSwitch from '@components/rb-switch/mobile.vue';
import CurrentPassword from '@components/current-password/mobile.vue';

// naive-ui
import {
    NIcon,
    NCard,
    NAlert,
    NButton,
    NDivider,
    NCollapseTransition } from 'naive-ui';

export default {
    name: 'main-info-mobile',
    components: {
        NIcon,
        NCard,
        NAlert,
        NButton,
        RbInput,
        RbSelect,
        NDivider,
        RbSwitch,
        Dismiss16Filled,
        CurrentPassword,
        IosArrowRtl24Filled,
        NCollapseTransition,
        DeleteDismiss24Regular,
    },
    setup(props, context) {
        // general
        const {
            gl,
            refs,
            profile,
            notification,
        } = general(props, context);

        // vars
        const loading = ref(false);
        const currentLang = ref();
        const showPassModal = ref(false);
        const dataForm = reactive({
            full_name: profile.fullInfo.full_name,
            company: profile.fullInfo.company,
            timezone: profile.fullInfo.timezone,
            phone: profile.fullInfo.phone,
            language: profile.fullInfo.language,
            messengers: profile.fullInfo.messengers,
            stat_view: profile.fullInfo.stat_view,
            dashboard_blocks: profile.fullInfo.dashboard_blocks,
            is_chat_enabled: profile.fullInfo.is_chat_enabled,
            tv_ips: profile.fullInfo.tv_ips,
            autorestart: profile.fullInfo.autorestart,
        });

        const onRemoveMessenger = i => {
            dataForm.messengers.splice(i, 1);
        };

        const onAddMessenger = () => {
            dataForm.messengers.push({id: -1, value: ''});
        };

        const onUpdate = async () => {
            loading.value = true;

            if (!profile.fullInfo.current_password || profile.errors.current_password ) {
                showPassModal.value = true;
                loading.value = false;
                return;
            }
        
            let payload = { ...profile.fullInfo, ...dataForm, _formPath: 'profile.update' };
            payload.messengers = payload.messengers.map((messenger, index) => ({...messenger, _formPath: `messenger#${index}`}));

        
            const data = await ProfileService.update({ profile: payload, ...gl.stageExtra?.extra});
            if (!data) {
                showPassModal.value = false;
            } else {
                if (!data.data.status) {
                    profile.errors = data.data.errors_form['profile.update']?.fields;

                    if (!Object.keys(profile.errors).includes('current_password')) {
                        showPassModal.value = false;

                        Object.keys(profile.errors).forEach(el => {
                            setTimeout(() => {
                                document
                                    .querySelector(`#profile__${el}`)
                                    .scrollIntoView({ block: 'start', behavior: 'smooth' });
                            }, 500);
                        });

                        // show messages
                        if (data.postMessages)
                            data.postMessages.forEach(el => {
                                if (el.success)
                                    notification.success({
                                        content: el.msg,
                                        duration: 2500,
                                        keepAliveOnHover: true,
                                    });
                            });
                    }
                } else {
                    profile.errors = {};
                    showPassModal.value = false;

                    for (const [ key, value ] of Object.entries(data.data)) {
                        profile.fullInfo[key] = value;
                    }

                    // show messages
                    if (data.postMessages)
                        data.postMessages.forEach(el => {
                            notification[el.success ? 'success' : 'error']({
                                content: el.msg,
                                duration: 2500,
                                keepAliveOnHover: true,
                            });
                        });

                    if (currentLang.value !== data.data.language) {
                        location.reload();
                    }
                }
            }
            
            loading.value = false;
        };
    
        onMounted(() => {
            currentLang.value = profile.fullInfo.language;
        });

        return {
            ...general(props, context),
            refs,
            loading,
            profile,
            dataForm,
            showPassModal,
            onUpdate,
            onAddMessenger,
            onRemoveMessenger,
        };
    },
};
</script>