<template>
<section>
    <div :class="labelPosition === 'left' ? 'flex items-center' : ''">
        <div class="flex flex-wrap" :style="labelPosition === 'left' ? `width: ${labelWidth + 'px' || 'fit-content'};` : ''">
            <div
                v-if="label"
                class="text-md whitespace-nowrap"
                :class="status === 'error' ? 'text-red-400' : ''">
                {{ label }}
            </div>
            <slot name="afterTitle"></slot>
            <n-popover
                v-if="help"
                trigger="hover"
                class="max-w-[500px] bg-main rounded-md"
                :placement="helpPosition">
                <template #trigger>
                    <n-icon class="text-main cursor-pointer text-xl ml-2">
                        <QuestionCircle20Filled />
                    </n-icon>
                </template>
                <span class="text-xs text-white" v-html="help"></span>
            </n-popover>
        </div>
        <div class="flex flex-grow items-center">
            <n-select
                v-if="!hideSelect"
                filterable
                class="text-left"
                :class="labelPosition === 'left' ? 'ml-2' : 'mt-2'"
                :size="size"
                :multiple="multiple"
                :clearable="clearable"
                :value="value != -1 ? value : null"
                :options="optionsRef"
                :placeholder="placeholder"
                :loading="loading"
                :disabled="loading"
                :status="status"
                :render-label="renderLabel"
                @update:value="$emit('update:value', $event)" />
            <slot name="after"></slot>
        </div>
    </div>
    <div
        v-if="$slots.info"
        class="flex-grow pl-2"
        :style="`margin-left: ${labelWidth}px;`">
        <slot name="info"></slot>
    </div>
    <slot name="messages"></slot>
    <div v-if="status === 'error'" class="w-full mt-2 text-red-400 text-right">{{ msg }}</div>
</section>
</template>

<script>
import { h, ref, computed } from 'vue';

// i18n
import { useI18n } from 'vue-i18n';

// naive-ui
import {
    NIcon,
    NSelect,
    NPopover } from 'naive-ui';

// icons
import { QuestionCircle20Filled } from '@vicons/fluent';

// components
import RbCoin from '@components/rb-coin';

export default {
    name: 'rb-currencies',
    emits: ['update:value'],
    props: {
        label: {
            type: String,
        },
        labelWidth: {
            type: [ String, Number ],
        },
        labelPosition: {
            type: String,
            default: 'top',
            validator: prop => ['left', 'top'].includes(prop),
        },
        size: {
            type: String,
            default: 'medium',
            validator: prop => ['tiny', 'small', 'medium', 'large'].includes(prop),
        },
        help: {
            type: String,
        },
        largeHelp: {
            type: Boolean,
        },
        helpPosition: {
            type: String,
            default: 'top',
        },
        value: {
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        placeholder: {
            type: String,
            default: () => useI18n().t('select'),
        },
        clearable: {
            type: Boolean,
        },
        multiple: {
            type: Boolean,
        },
        defaultValue: {
            type: Boolean,
            default: true,
        },
        loading: {
            type: Boolean,
        },
        hideSelect: {
            type: Boolean,
        },
        status: {
            type: String,
            default: undefined,
        },
        msg: {
            type: String,
        },
    },
    components: {
        NIcon,
        NSelect,
        NPopover,
        QuestionCircle20Filled,
    },
    setup(props) {
        // vars
        const { _ } = window;

        // i18n
        const { t } = useI18n();

        const optionsRef = computed(() => {
            const arr = _.cloneDeep(props.options);

            if (props.defaultValue)
                arr.unshift({
                    label: `- ${t('select')} -`,
                    value: props.defaultValue,
                    type: 'default',
                    style: {
                        color: 'gray',
                    },
                });

            return arr;
        });

        const renderLabel = (option) => {
            return h(
                'div',
                {
                    style: {
                        display: 'flex',
                        alignItems: 'center',
                    },
                },
                [
                    option?.type !== 'default'
                        ? h(RbCoin, {
                            class: 'my-1 h-6',
                            coin: option,
                        }) : null,
                    h(
                        'div',
                        {
                            class: 'ml-2',
                            style: {
                                padding: '4px 0',
                            },
                        },
                        [
                            h('div', null, [option.label]),
                        ],
                    ),
                ],
            );
        };

        return {
            optionsRef,
            renderLabel,
        };
    },
};
</script>