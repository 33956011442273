import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mobile = _resolveComponent("mobile")!
  const _component_desktop = _resolveComponent("desktop")!

  return (_ctx.gl.isMobile)
    ? (_openBlock(), _createBlock(_component_mobile, {
        key: 0,
        class: "mt-4"
      }))
    : (_openBlock(), _createBlock(_component_desktop, { key: 1 }))
}