<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 69 78" width="69" height="78">
    <path id="icon-running-bots" class="bot-icon"
          d="M25,0h9c13.81,0 25,11.19 25,25l0,31.04c5.61,0.51 10,5.22 10,10.96c0,6.08 -4.92,11 -11,11c-5.04,0 -9.28,-3.38 -10.59,-8h-39.41c-4.42,0 -8,-3.58 -8,-8v-37c0,-13.81 11.19,-25 25,-25zM2,25v37c0,3.31 2.69,6 6,6l39.04,0c-0.03,-0.33 -0.04,-0.66 -0.04,-1c0,-5.74 4.39,-10.45 10,-10.96l0,-31.04c0,-12.7 -10.3,-23 -23,-23h-9c-12.7,0 -23,10.3 -23,23zM14,50h31c1.66,0 3,1.34 3,3v1c0,1.66 -1.34,3 -3,3h-31c-1.66,0 -3,-1.34 -3,-3v-1c0,-1.66 1.34,-3 3,-3zM13,53v1c0,0.55 0.45,1 1,1h31c0.55,0 1,-0.45 1,-1v-1c0,-0.55 -0.45,-1 -1,-1h-31c-0.55,0 -1,0.45 -1,1zM18.5,20c3.59,0 6.5,2.91 6.5,6.5c0,3.59 -2.91,6.5 -6.5,6.5c-3.59,0 -6.5,-2.91 -6.5,-6.5c0,-3.59 2.91,-6.5 6.5,-6.5zM14,26.5c0,2.49 2.01,4.5 4.5,4.5c2.49,0 4.5,-2.01 4.5,-4.5c0,-2.49 -2.01,-4.5 -4.5,-4.5c-2.49,0 -4.5,2.01 -4.5,4.5zM40.5,20c3.59,0 6.5,2.91 6.5,6.5c0,3.59 -2.91,6.5 -6.5,6.5c-3.59,0 -6.5,-2.91 -6.5,-6.5c0,-3.59 2.91,-6.5 6.5,-6.5zM36,26.5c0,2.49 2.01,4.5 4.5,4.5c2.49,0 4.5,-2.01 4.5,-4.5c0,-2.49 -2.01,-4.5 -4.5,-4.5c-2.49,0 -4.5,2.01 -4.5,4.5zM49,67c0,4.97 4.03,9 9,9c4.97,0 9,-4.03 9,-9c0,-4.97 -4.03,-9 -9,-9c-4.97,0 -9,4.03 -9,9zM64,67l-9,5v-10z"/>
  </svg>
</template>
  
<script>
export default {
    name: 'BotsRunning',
    setup() {
        // 
    },
};
</script>