import { defineStore } from 'pinia';
import LocalStorage from '@/services/LocalStorage';

export const useGl = defineStore('gl', {
    state: () => ({
        init: false,
        stage: null,
        darkTheme: LocalStorage.has('darkTheme') ? LocalStorage.getItem('darkTheme') : window.matchMedia('(prefers-color-scheme: dark)').matches,
        isPrivate: false,
        loading: false,
        openPanel: false,
        homeLoading: false,
        mainColor: '#1A6DE0',
        showLoading: false,
        loadingMessage: '',
        screenWidth: 0,
        secondMenu: false,
        isMobile: window.innerWidth <= 768,
        serverError: false,
        stageErrors: {},
        stageExtra: null,
        stageState: 'inactive',
        showTopUpYourBalance: false,
        showPassModal: false,
        twoFactorAuth: false,
        postAdviceMessages: null,
        postDialogMessages: null,
        new_build: null,
        build_timestamp: null,
        canAutoUpdate: false,
        ticker: [],
        notifications: [],
        balance: null,
        timeToShutdown: null,
    }),
    getters: {
        inited(state) {
            return state.init;
        },
    },
    actions: {
        cleanError(obj, key) {
            delete this[obj][key];
        },
        setNewBuild(data) {
            if (!data) return;
		
            let { build_timestamp, message, autorefresh_in } = data;
		
            const curUrl = window.location.href;
            const anchorStartIdx = message.indexOf('|');
            const anchorEndIdx = message.lastIndexOf('|');
            const anchorText = message.substring(anchorStartIdx + 1, anchorEndIdx);
            const link = `<a href="${window.location.href}" onclick="window.location.reload();return false;">${anchorText}</a>`;
            const newMessage = `${message.substring(0, anchorStartIdx - 1)} ${link}${message.substring(anchorEndIdx + 1)}`;

            this.new_build = {...data, message: newMessage};

            const nbInterval = setInterval(() => {
                if (autorefresh_in <= 0) {
                    clearInterval(nbInterval);
                    return window.location.reload();
                }
                
                autorefresh_in = parseInt(autorefresh_in, 10) - 1;
                this.new_build.autorefresh_in = autorefresh_in;
            }, 1000);
        },
    },
});