<template>
<div
    v-if="isFooter"
    class="h-full flex justify-center items-center text-main text-md dark:bg-darkCardBg bg-gray-50 font-semibold relative overflow-hidden">
    {{ dataFooter }} <div class="absolute w-full top-0 left-0 h-[2px] bg-main shadow-xl"></div>
</div>
<div
    v-else
    class="h-full flex justify-center items-center"
    :class="classes">
    <n-popover
        scrollable
        trigger="click"
        class="bg-main"
        placement="left"
        style="width: 200px; max-height: 240px"
        :disabled="!data">
        <template #trigger>
            <div class="px-1">
                <n-icon class="inline">
                    <CommentNote20Filled :class="[ data ? 'cursor-pointer text-current' : 'text-gray-500', ' text-lg' ]" />
                </n-icon>
            </div>
        </template>
        <template #default>
            <div class="max-w-screen-sm text-white back_note text-[10px]" v-html="data"></div>
        </template>
    </n-popover>
</div>
</template>

<script>
// vue
import { computed, onMounted } from 'vue';

// naive-ui
import {
    NIcon,
    NPopover,
} from 'naive-ui';

// icons
import {
    CommentNote20Filled } from '@vicons/fluent';

export default {
    name: 'cell-notes-mobile',
    props: {
        isFooter: {
            type: Boolean,
            default: false,
            required: true,
        },
        dataFooter: {
            default: '',
        },
        data: {
            required: true,
        },
        classes: {
            type: String,
        },
    },
    components: {
        NIcon,
        NPopover,
        CommentNote20Filled,
    },
    setup(props) {

        return {
        };
    },
};
</script>