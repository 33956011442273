<template>
<section>
    <div class="flex flex-wrap">
        <div
            v-if="label"
            class="text-gray-600 dark:text-white/75 text-xs whitespace-nowrap"
            :class="status === 'error' ? 'text-red-400' : ''">
            {{ label }}
        </div>
        <slot name="afterTitle"></slot>
        <n-popover
            v-if="help"
            trigger="hover"
            class="max-w-[500px]"
            :placement="helpPosition">
            <template #trigger>
                <n-icon size="20" class="text-main mx-2">
                    <QuestionCircle20Filled />
                </n-icon>
            </template>
            <span v-html="help"></span>
        </n-popover>
    </div>
    
    <n-button
        :size="size"
        tertiary
        class="select-button w-full rounded-md pl-2 pr-2"
        :class="label ? 'mt-2' : ''"
        @click="showDrawer = true">
        <div class="w-full flex justify-between items-center">
            <div
                v-if="value"
                class="flex items-center w-[calc(100%-24px)] overflow-x-auto scrollbar-hide">
                <div class="flex items-center">
                    <rb-coin class="w-4" :coin="currentValue" />
                    <div class="ml-2 font-semibold whitespace-nowrap">{{ currentValue.amount }}</div>
                    <div class="ml-2 uppercase whitespace-nowrap">{{ currentValue.currency }}</div>
                </div>
                <!-- <div class="ml-2 opacity-70 text-xs text-right">{{ currentValue.label }}</div> -->
            </div>
            <span v-else
                class="text-gray-600/50 dark:text-white/40">{{ placeholder }}</span>
            <n-icon size="18"><CaretDown12Filled /></n-icon>
        </div>
    </n-button>
    <n-drawer
        v-model:show="showDrawer"
        width="100%"
        display-directive="show"
        class="bg-gray-100 dark:bg-darkbg"
        :auto-focus="false"
        @after-enter="showDrawerContent = true">
        <n-drawer-content body-content-style="padding: 0">
            <div class="flex flex-col h-full">
                <div class="w-full flex justify-between px-3 items-center bg-gray-100 dark:bg-darkbg text-sm text-center text-gray-600 dark:text-white/75 py-2">
                    <n-icon
                        size="20"
                        class="text-gray-600 dark:text-white/75"
                        @click="showDrawer = false">
                        <ArrowLeft12Regular />
                    </n-icon>
                    <rb-input v-if="showSearch" v-model:value="query" class="ml-3" />
                    <n-button
                        quaternary
                        class="bg-transparent text-main pr-0"
                        @click="showDrawer = false">
                        {{ t('done') }}
                    </n-button>
                </div>
                <div class="pb-4 flex-grow overflow-y-scroll overscroll-none scrollbar-hide">
                <template
                    v-for="(item, i) in options"
                    :key="i">
                        <div
                            v-if="item.show"
                            class="w-full flex justify-between items-center px-3 py-1 rounded-none dark:odd:bg-[#242424] odd:bg-gray-50"
                            @click="setValue(item)">
                            <div class="flex flex-grow items-center justify-between">
                                <div class="flex items-center">
                                    <rb-coin class="w-4" :coin="item" />
                                    <div class="ml-2 font-semibold whitespace-nowrap">{{ item.amount }}</div>
                                    <div class="ml-2 uppercase whitespace-nowrap">{{ item.currency }}</div>
                                </div>
                                <div class="ml-2 opacity-70 text-xs text-right">{{ item.label }}</div>
                            </div>
                            <div class="w-[20px] ml-2 flex items-center justify-center">
                                <n-icon v-if="isChecked(item)" size="20" class="text-main">
                                    <Checkmark12Filled />
                                </n-icon>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </n-drawer-content>
    </n-drawer>
</section>
</template>

<script>
// vue
import { h, ref, reactive, computed, watch, onMounted } from 'vue';

// naive-ui
import {
    NIcon,
    NDrawer,
    NButton,
    NPopover,
    NSelect } from 'naive-ui';

// components
import RbCoin from '@components/rb-coin';

// icons
import {
    Checkmark12Filled,
    CaretDown12Filled,
    ArrowLeft12Regular,
    QuestionCircle20Filled } from '@vicons/fluent';

// i18n
import { useI18n } from 'vue-i18n';

export default {
    name: 'rb-wallet-selec-mobile',
    components: {
        NIcon,
        RbCoin,
        NDrawer,
        NButton,
        NSelect,
        NPopover,
        Checkmark12Filled,
        ArrowLeft12Regular,
        CaretDown12Filled,
        QuestionCircle20Filled,
    },
    props: {
        value: {
            required: true,
        },
        placeholder: {
            type: String,
            default: () => useI18n().t('select'),
        },
        label: {
            type: String,
            default: '',
        },
        currencies: {
            type: Object,
            required: true,
        },
        items: {
            default: () => [],
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        showSearch: {
            type: Boolean,
            default: true,
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n();

        // vars
        const { _ } = window;
        const query = ref('');
        const showDrawer = ref(false);

        const options = computed(() => props.items.map(el => ({
            label: el.name,
            value: el.id,
            class: 'w-full',
            code_name: 'rb-cc__' + el.currency,
            show: props.showSearch
                ? el.name.includes(query.value)
                : true,
            ...el,
        })));

        const currentValue = computed(() => {
            let result;

            for (const item of options.value) {
                if (item.value != props.value && Object.prototype.hasOwnProperty.call(item, 'children')) {
                    const child = item.children.find(({ value }) => value == props.value);

                    if (child) {
                        result = child;
                        break;
                    }
                } else if (item.value == props.value) {
                    result = item;
                    break;
                }
            }

            return result;
        });

        const isChecked = v => {
            return Array.isArray(props.value)
                ? ~props.value.findIndex(el => el == v.value)
                : props.value == v.value;
        };

        const addOrRemove = (array, value) => {
            var index = array.indexOf(value);

            if (index === -1) {
                array.push(value);
            } else {
                array.splice(index, 1);
            }

            return array;
        };

        const setValue = v => {
            if (props.multiple) {
                const copy = Array.isArray(props.value)
                    ? _.cloneDeep(props.value)
                    : [ props.value ];

                emit('update:value', addOrRemove(copy, v.value));
            } else {
                emit('update:value', v.value);
                showDrawer.value = false;
            }
        };

        return {
            query,
            options,
            showDrawer,
            currentValue,
            t,
            setValue,
            isChecked,
        };
    },
};
</script>

<style lang="scss" scoped>
.select-button :deep(.n-button__content) {
    width: 100%;
}
</style>