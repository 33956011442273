import Api from './index';

export default class Marketplace {

    static async getRefs() {
        return await Api.getData({
            action: 'quickStart.getRefs',
        });
    };

    static async quickCreateBot(payload) {
        return await Api.getData({
            action: 'quickStart.quickCreateBot',
            payload,
        });
    };
}