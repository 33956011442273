import Api from './index';

export default class Referral {

    static async getFullInfo() {
        return await Api.getData({
            action: 'referral.getFullInfo',
        });
    };

    static async get(payload) {
        return await Api.getData({
            payload: {
                pager: payload,
            },
            action: 'referral.get',
        });
    };

    static async requestWithdraw(payload) {
        return await Api.getData({
            payload,
            action: 'referral.requestWithdraw',
        });
    };
}