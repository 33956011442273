import { defineStore } from 'pinia';
import { useEnv } from './env';

export const useRefs = defineStore('refs', {
    state: () => ({
        refs: {},
    }),
    getters: {
        lang(state) {
            return Object.keys(state.refs.data.localization)[0];
        },
        langs(state) {
            return state.refs.data.langs;
        },
        header(state) {
            return state.refs.data.localization[Object.keys(state.refs.data.localization)[0]].header;
        },
        privateMessage(state) {
            return state.refs.data.localization[Object.keys(state.refs.data.localization)[0]].privateMessage;
        },
        status(state) {
            const env = useEnv();
            const { title } = state.refs.data.user_statuses.find(({ id }) => id === env.statusCode);

            return title;
        },
        currencies(state) {
            return state.refs.data.currencies;
        },
        exchanges(state) {
            return state.refs.data.exchanges;
        },
        localization(state) {
            return state.refs.data.localization[Object.keys(state.refs.data.localization)[0]];
        },
        botStatuses(state) {
            return state.refs.data.bot_statuses;
        },
        walletStatuses(state) {
            return state.refs.data.wallet_statuses;
        },
        botStates(state) {
            return state.refs.data.bot_states;
        },
        walletTypes(state) {
            return state.refs.data.wallet_types;
        },
        apikeyStatuses(state) {
            return state.refs.data.apikey_statuses;
        },
        timezones(state) {
            return state.refs.data.timezones;
        },
    },
});