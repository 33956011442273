<template>
<section v-if="visible">
    <section class="flex w-full">
        <n-button
            class="rounded-md flex-grow"
            @click="show = true">
            <section class="w-full flex justify-between items-center">
                <div class="mr-4 text-xs">{{ label }}</div>
                <div class="flex justify-end items-center">
                    <span class="text-main mr-2 text-xs">{{ checkeds || '' }}</span>
                    <n-icon size="14"><ArrowRight12Regular /></n-icon>
                </div>
            </section>
        </n-button>

        <n-button
            secondary
            type="default"
            class="px-2 ml-1 rounded-md"
            @click="$emit('setVisibleFilters', data.filter)">
            <n-icon size="24" class="text-red-500">
                <DeleteDismiss24Regular />
            </n-icon>
        </n-button>
    </section>
    <n-drawer
        v-model:show="show"
        width="100%"
        class="bg-gray-100 dark:bg-darkbg"
        :auto-focus="false">
        <n-drawer-content body-content-style="padding: 0">
            <div class="flex justify-between items-center w-full bg-gray-100 dark:bg-darkbg text-sm text-center text-gray-600 dark:text-white/75 px-3 py-3">
                <n-icon
                    size="20"
                    class="text-gray-600 dark:text-white/75"
                    @click="show = false">
                    <ArrowLeft12Regular />
                </n-icon>
                <div class="flex justify-end">
                    <n-button
                        quaternary
                        size="small"
                        type="default"
                        class="rounded-md flex items-center text-main"
                        @click="show = false">
                        {{ refs.localization.filters.general.filters_done_label }}
                    </n-button>
                </div>
            </div>
            <div class="px-3 pb-2">
                <rb-input
                    :placeholder="refs.localization.filters.master_slave.search"
                    v-model:value="searchFilter" />
            </div>
            <div class="w-full h-[calc(100%-92px)] pb-3 px-3 overflow-y-auto">
                <div v-if="options.length" class="w-full px-3 py-2 dark:odd:bg-[#242424] odd:bg-gray-50 rounded-md sticky top-0 z-50">
                    <rb-checkbox
                        :checked="allChecked"
                        :label="refs.localization.filters.all_select.all"
                        @update:checked="setCheckAll" />
                </div>

                <section class="flex flex-wrap">
                    <div
                        v-for="option in options"
                        :key="option.value"
                        class="w-6/12 even:pl-1 odd:pr-1 mt-2">
                        <n-card
                            size="small"
                            class="rounded-lg h-full bg-white dark:bg-darkCardBg cursor-pointer border-opacity-50"
                            :class="option.checked ? 'border border-main' : ''"
                            @click="option.checked = !option.checked"
                            :segmented="{
                              content: true,
                              footer: 'soft'
                            }">
                            <section class="w-full h-full text-left">
                                <div class="w-full flex items-center justify-start">
                                    <rb-checkbox
                                        :checked="option.checked" />
                                    <span
                                        class="inline-block rb-td-stock-icon h-7 max-w-[90px]"
                                        :class="`rb-td-stock-icon-${option.code_name}`">
                                    </span>
                                </div>
                                <a
                                    class="text-main hover:underline text-xs hover:opacity-100"
                                    :class="option.checked ? 'opacity-100' : 'opacity-70'"
                                    :href="option.url" target="_blank">
                                    {{ option.url }}
                                </a>
                            </section>
                        </n-card>
                    </div>
                </section>
            </div>
        </n-drawer-content>
    </n-drawer>
</section>
</template>

<script>
// general
import general from './general';

// naive
import {
    NIcon,
    NCard,
    NDrawer,
    NButton,
    NDrawerContent } from 'naive-ui';

// components
import RbInput from '@components/rb-input/mobile.vue';
import RbCheckbox from '@components/rb-checkbox/mobile.vue';

// icons
import {
    ArrowLeft12Regular,
    ArrowRight12Regular,
    DeleteDismiss24Regular  } from '@vicons/fluent';

export default {
    name: 'Filter',
    props: {
        data: {
            type: Object,
            required: true,
        },
        filters: {
            type: Object,
            required: true,
        },
        visible: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        NIcon,
        NCard,
        RbInput,
        NDrawer,
        NButton,
        RbCheckbox,
        NDrawerContent,
        ArrowLeft12Regular,
        ArrowRight12Regular,
        DeleteDismiss24Regular,
    },
    setup(props, context) {
        
        return {
            ...general(props, context),
        };
    },
};
</script>

<style lang="scss" scoped>
@import '@/css/stock-icon.scss';

:deep(.n-button__content) {
  width: 100%;
  height: 100%;
}
</style>