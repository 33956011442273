<template>
<div
    v-if="isFooter"
    class="h-full flex justify-center items-center text-main text-md dark:bg-darkCardBg bg-gray-50 font-semibold relative overflow-hidden">
    {{ dataFooter }} <div class="absolute w-full top-0 left-0 h-[2px] bg-main shadow-xl"></div>
</div>
<div
    v-else
    class="h-full flex justify-center items-center px-2"
    :class="classes">
    <n-tag
        class="text-[10px] font-semibold rounded-full"
        size="small"
        :bordered="false"
        :type="!gl.darkTheme ? localData.type : ''">
        <span :class="setColor[localData.type]">
            {{ localData.title }}
        </span>
    </n-tag>
</div>
</template>

<script>
// vue
import { computed, onMounted } from 'vue';

// store
import { useGl } from '@store/ts/gl';

// naive-ui
import {
    NTag,
} from 'naive-ui';

export default {
    name: 'cell-status-mobile',
    props: {
        isFooter: {
            type: Boolean,
            default: false,
            required: true,
        },
        row: {
            type: Object,
            required: true,
        },
        data: {
            required: true,
        },
        refs: {
            type: Array,
            required: true,
        },
        classes: {
            type: String,
        },
    },
    components: {
        NTag,
    },
    setup(props) {
        // store
        const gl = useGl();
        // const classes = computed(() => {
        //     return [
        //         props.interactive
        //             ? 'text-center underline cursor-pointer hover:no-underline hover:text-main'
        //             : null,
        //         props.noWhiteSpace
        //             ? 'whitespace-nowrap'
        //             : null,
        //     ];
        // });
        
        const localData = computed(() => {
            const { id, title } = props.refs.find(cur => cur.id === props.data);

            const types = {
                '0': 'warning',
                '1': 'success',
                '3': 'error',
                '-5': undefined,
                '-10': undefined,
            };

            return {
                type: props.row?.error && id == 0 ? types['3'] : types[id],
                title,
            };
        });

        const setColor = computed(() => ({
            'warning': 'text-yellow-500',
            'success': gl.darkTheme ? 'text-green-500' : 'text-green-700',
            'error': 'text-red-500',
            'info': 'text-blue-500',
        }));

        return {
            gl,
            setColor,
            localData,
        };
    },
};
</script>