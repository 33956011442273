<template>
<section class="flex justify-start w-full mt-4">
    <n-tag v-if="gl.timeToShutdown?.timeToShutdown || gl.timeToShutdown?.timeToStop" class="w-full" type="error" round>
        <span class="mr-1">{{ gl.timeToShutdown.status === 2 ? refs.localization.header.stop : refs.localization.header.suspend }}</span>
        <strong @click="gl.showTopUpYourBalance = true" class="underline cursor-pointer hover:no-underline">
            {{ gl.timeToShutdown.status === 2 ? gl.timeToShutdown?.timeToStop : gl.timeToShutdown?.timeToShutdown }}    
        </strong>
    </n-tag>
</section>
<n-card
    size="small"
    :bordered="false"
    class="mt-4 overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-md"
    :segmented="{
        content: true,
        footer: 'soft'
    }">
    <section class="flex">
        <!-- <div class="flex items-center justify-center bg-gray-100 dark:bg-darkbg rounded-full w-10 h-10">
            <n-icon size="25" class="text-gray-600 dark:text-white/75">
                <Person16Regular />
            </n-icon>
        </div> -->

        <section class="flex">
            <div class="text-left">
                <n-skeleton v-if="!profile.localization" class="inline-block" :width="50" :height="14" :sharp="false" />
                <div v-else class="text-gray-400 dark:text-white/50">{{ profile.localization?.profile_account_info_status }}</div>

                <n-skeleton v-if="loading" class="inline-block mt-1" :width="70" :height="18" :sharp="false" />
                <div v-else class="text-gray-600 dark:text-white/75 text-md font-semibold mt-1">{{ refs.status }}</div>

                <n-badge v-if="refs.tasks.enabled" :value="refs.countUnshiftedTask" class="mt-2">
                    <n-button
                        size="tiny"
                        class="rounded-md"
                        :disabled="loading"
                        @click="router.push({ name: 'tasks' })">
                        <div class="text-xs font-semibold">{{ refs.header?.tasks || 'Задания' }}</div>
                    </n-button>
                </n-badge>
            </div>
            <div class="text-left ml-6">
                <n-skeleton v-if="!profile.localization" class="inline-block" :width="30" :height="14" :sharp="false" />
                <div v-else class="text-gray-400 dark:text-white/50">{{ profile.localization?.account_info_id }}</div>

                <n-skeleton v-if="loading" class="inline-block mt-1" :width="15" :height="18" :sharp="false" />
                <div v-else class="text-gray-600 dark:text-white/75 text-md font-semibold mt-1">{{ env.ID }}</div>
            </div>
            <div class="text-left ml-6">
                <n-skeleton v-if="!profile.localization" class="inline-block" :width="120" :height="14" :sharp="false" />
                <div v-else class="text-gray-400 dark:text-white/50">{{ profile.localization?.profile_account_info_balance }}</div>

                <n-skeleton v-if="loading" class="inline-block mt-1" :width="100" :height="18" :sharp="false" />
                <div v-else class="flex items-center text-md font-semibold" :class="gl.balance?.balance > 0 ? 'text-green-600' : 'text-red-500'">
                    {{ gl.balance?.balance }}
                    <rb-coin class="w-4 ml-2" :coin="currency" />
                </div>

                <div class="text-xs -mt-1">&asymp; {{ gl.balance?.balance_usdt }} USDT</div>
            </div>
        </section>
    </section>
    <template #footer>
        <section class="flex flex-grow">
            <n-button
                secondary
                size="tiny"
                type="default"
                class="rounded-md mx-2"
                :disabled="loading"
                @click="window.open('https://desk.revenuebot.io/en/%D0%B1%D0%B0%D0%B7%D0%B0-%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B9', '_blank')">
                {{ refs.localization.header.knowledge_base }}
            </n-button>

            <n-button
                secondary
                size="tiny"
                type="default"
                class="rounded-md flex-grow"
                :disabled="loading"
                @click="window.open('https://www.youtube.com/channel/UCrAyFAxvDzPIwYKgdSDwCFg', '_blank')">
                {{ refs.localization.header.educational_videos }}
            </n-button>
        </section>
        <section class="w-full flex flex-wrap">
            <div class="pr-1 w-8/12 mt-2">
                <n-button
                    secondary
                    size="tiny"
                    type="default"
                    class="rounded-md w-full"
                    :disabled="loading"
                    @click="window.open('https://appt.link/consultation_from_support/revenuebot', '_blank')">
                    {{ refs.localization.header.free_consultations }}
                </n-button>
            </div>

            <div class="pl-1 w-4/12 mt-2">
                <n-button
                    secondary
                    size="tiny"
                    type="default"
                    class="rounded-md w-full"
                    :disabled="loading"
                    @click="window.open(refs.lang === 'ru'
                        ? 'https://t.me/revenuebot_user'
                        : 'https://t.me/revenuebot_users_en', '_blank')">
                    <template #icon>
                        <telegram-icon class="fill-[#1A6DE0]" />
                    </template>
                    UsersChat
                </n-button>
            </div>
        </section>
    </template>
</n-card>
<n-skeleton v-if="!profile.localization" class="inline-block mt-2 w-full rounded-md" :height="48" :sharp="false" />
<n-button
    v-else
    strong
    size="large"
    class="mt-2 w-full rounded-md border border-main border-solid h-12"
    :disabled="loading"
    @click="showModal = true">
    <span class="text-gray-600 dark:text-white/75">
        + {{ profile.localization?.profile_account_info_topup_balance_button }}
    </span>
</n-button>

<div class="text-base text-left text-gray-600 dark:text-white/75 font-semibold mt-6">
    <n-skeleton v-if="!profile.localization" :width="160" :height="20" :sharp="false" />
    <div v-else class="text-md text-gray-600 dark:text-white/75 font-semibold text-left">
        {{ t('comission') }}
    </div>
</div>
<section v-if="profile" class="flex flex-wrap mt-2 items-stretch">
    <div class="w-6/12 pr-1">
        <n-card
            size="small"
            :bordered="false"
            class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg h-full"
            :segmented="{
                content: true,
                footer: 'soft'
            }">
            <template #default>
                <section class="flex">
                    <div class="text-left">
                        <n-skeleton v-if="loading" class="inline-block mt-1" :width="20" :height="26" :sharp="false" />
                        <div
                            v-else
                            class="text-gray-600 dark:text-white/75 text-xl font-semibold">
                            {{ (profile.fullInfo?.commission)?.split(' %')[0] }}
                            <span class="text-base dark:text-white/50 -ml-1">%</span>
                        </div>

                        <n-skeleton v-if="!profile.localization" class="inline-block mt-1" :width="30" :height="14" :sharp="false" />
                        <div v-else class="text-gray-500 dark:text-white/40 mt-1 text-xs">{{ profile.localization?.profile_account_info_commission }}</div>
                    </div>
                </section>
            </template>
            <template #footer>
                <section class="flex items-center text-left text-xs">
                    <div class="text-gray-500 dark:text-white/40 mt-1">
                        {{ profile.localization?.profile_account_info_commission_total_month_label || 'В текущем месяце списано' }}:</div>
                    <div 
                        class="flex items-center text-sm text-lightMain ml-2 whitespace-nowrap mt-1">
                        <span class="font-semibold">{{ profile.fullInfo?.current_month_usd }}</span>
                        <rb-coin class="w-4 ml-2" :coin="getCurrency('usdt')" />
                    </div>
                </section>
            </template>
        </n-card>
    </div>
    <div class="w-6/12 pl-1">
        <n-card
            size="small"
            :bordered="false"
            class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg h-full"
            :segmented="{
                content: true,
                footer: 'soft'
            }">
            <template #default>
                <section class="flex">
                    <div class="text-left">
                        <n-skeleton v-if="loading" class="inline-block mt-1" :width="20" :height="26" :sharp="false" />
                        <div
                            v-else
                            class="text-gray-600 dark:text-white/75 text-xl font-semibold">
                            {{ (profile.fullInfo?.commission_f)?.split(' %')[0] }}
                            <span class="text-xs dark:text-white/50 -ml-1">%</span>
                        </div>

                        <n-skeleton v-if="!profile.localization" class="inline-block mt-1" :width="30" :height="14" :sharp="false" />
                        <div v-else class="text-gray-500 dark:text-white/40 mt-1 text-xs">{{ profile.localization?.profile_account_info_commission_f }}</div>
                    </div>
                </section>
            </template>
            <template #footer>
                <section class="flex items-center text-left text-xs">
                    <div class="text-gray-500 dark:text-white/40 mt-1">
                        {{ profile.localization?.profile_account_info_commission_total_month_label || 'В текущем месяце списано' }}:</div>
                    <div 
                        class="flex items-center text-xs text-lightMain ml-2 whitespace-nowrap mt-1">
                        <span class="font-semibold">{{ profile.fullInfo?.current_month_usd_f }}</span>
                        <rb-coin class="w-5 ml-2" :coin="getCurrency('usdt')" />
                    </div>
                </section>
            </template>
        </n-card>
    </div>
    <div class="w-6/12 pr-1 mt-2">
        <n-card
            size="small"
            :bordered="false"
            class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg h-full"
            :segmented="{
                content: true,
                footer: 'soft'
            }">
            <template #default>
                <section class="flex">
                    <div class="text-left">
                        <n-skeleton v-if="loading" class="inline-block mt-1" :width="20" :height="26" :sharp="false" />
                        <div
                            v-else
                            class="text-gray-600 dark:text-white/75 text-xl font-semibold">
                            {{ (profile.fullInfo?.commission_fc)?.split(' %')[0] }}
                            <span class="text-xs dark:text-white/50 -ml-1">%</span>
                        </div>

                        <n-skeleton v-if="!profile.localization" class="inline-block mt-1" :width="30" :height="14" :sharp="false" />
                        <div v-else class="text-gray-500 dark:text-white/40 mt-1 text-xs">{{ profile.localization?.profile_account_info_commission_fc }}</div>
                    </div>
                </section>
            </template>
            <template #footer>
                <section class="flex items-center text-left text-xs">
                    <div class="text-gray-500 dark:text-white/40 mt-1">
                        {{ profile.localization?.profile_account_info_commission_total_month_label || 'В текущем месяце списано' }}:</div>
                    <div 
                        class="flex items-center text-xs text-lightMain ml-2 whitespace-nowrap mt-1">
                        <span class="font-semibold">{{ profile.fullInfo?.current_month_usd_fc }}</span>
                        <rb-coin class="w-5 ml-2" :coin="getCurrency('usdt')" />
                    </div>
                </section>
            </template>
        </n-card>
    </div>
    <div class="w-6/12 pl-1 mt-2">
        <n-card
            size="small"
            :bordered="false"
            class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg h-full"
            :segmented="{
                content: true,
                footer: 'soft'
            }">
            <template #default>
                <section class="flex">
                    <div class="text-left">
                        <n-skeleton v-if="loading" class="inline-block mt-1" :width="20" :height="26" :sharp="false" />
                        <div
                            v-else
                            class="text-gray-600 dark:text-white/75 text-xl font-semibold">
                            {{ (profile.fullInfo?.commission_m)?.split(' %')[0] }}
                            <span class="text-base dark:text-white/50 -ml-1">%</span>
                        </div>

                        <n-skeleton v-if="!profile.localization" class="inline-block mt-1" :width="30" :height="14" :sharp="false" />
                        <div v-else class="text-gray-500 dark:text-white/40 mt-1 text-xs">{{ profile.localization?.profile_account_info_commission_m }}</div>
                    </div>
                </section>
            </template>
        </n-card>
    </div>
</section>
<topup-balance
    v-model:show="showModal" />
</template>

<script>
// vue
import { ref, computed, watch } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useEnv } from '@store/ts/env';
import { useRefs } from '@store/ts/refs';
import { useProfile } from '@store/profile';

// naive-ui
import {
    NTag,
    NIcon,
    NCard,
    NBadge,
    NButton,
    NDrawer,
    NPopover,
    NSkeleton,
    NScrollbar,
    NDrawerContent } from 'naive-ui';

// i18n
import { useI18n } from 'vue-i18n';

// router
import { useRouter } from 'vue-router';

// icons
import {
    Person16Regular,
    CaretDown12Filled,
    ArrowLeft12Regular,
    ArrowRight12Regular }  from '@vicons/fluent';

// components
import RbCoin from '@components/rb-coin';
import TopupBalance from '@components/topup-balance';
import TelegramIcon from '@components/icons/telegram.vue';

export default {
    name: 'account-info-mobile',
    components: {
        NTag,
        NIcon,
        NCard,
        NBadge,
        RbCoin,
        NButton,
        NSkeleton,
        TelegramIcon,
        TopupBalance,
        Person16Regular,
    },
    setup() {
        // store
        const gl = useGl();
        const env = useEnv();
        const refs = useRefs();
        const profile = useProfile();

        // router
        const router = useRouter();

        // i18n
        const { t } = useI18n();

        // vars
        const showModal = ref(false);
        const loading = computed(() => gl.showLoading);
        const currency = computed(() => refs.currencies.find(el => el.id === env.profitSymbol));

        const getCurrency = icon => refs.currencies.find(el => el.id === icon);

        return {
            gl,
            env,
            refs,
            window,
            router,
            loading,
            currency,
            profile,
            showModal,
            t,
            getCurrency,
        };
    },
};
</script>