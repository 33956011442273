
// vue
import { defineComponent } from 'vue';

// general
import general from './general';

import { CalendarLtr32Regular } from '@vicons/fluent';

// naive-ui
import {
    NIcon,
    NButton,
    NDivider,
    NPopover,
    NDatePicker,
} from 'naive-ui';

export default defineComponent({
    name: 'date-picker-desktop',
    props: {
        data: {
            type: Object,
            required: true,
        },
        filters: {
            type: Object,
            required: true,
        },
        drawerId: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
    },
    components: {
        NIcon,
        NButton,
        NPopover,
        NDivider,
        NDatePicker,
        CalendarLtr32Regular,
    },
    setup(props, context) {
        
        return {
            ...general(props, context),
        };
    },
});
