interface IFilters {
    [key: string]: any,
}

interface IPager {
    page?: number,
    perPage?: number | string,
    total?: number,
    query?: string,
    sort_dir?: string,
    sort_column?: string,
}

interface ICrudPagerFactory extends IPager {
    sort_dir: string,
    sort_column: string,
    filters: IFilters,
}

export const crudPagerFactory = (
    filters: IFilters = {},
    pager: IPager = {}): ICrudPagerFactory => ({
    // pager
    page: pager.page || 1,
    perPage: pager.perPage || 10,
    total: pager.total || 0,
    query: pager.query || '',
    sort_dir: pager.sort_dir || 'asc',
    sort_column: pager.sort_column || '',

    // filters
    filters: { ...filters },
});

// export function BOTS_DATA_FORM_FACTORY(preset = {}, n = []) {
//   // eslint-disable-next-line no-param-reassign
//   n = Array.isArray(n) ? n : Object.values(n);

//   return {
//       reloaded_message: null,
//       name: '',
//       exchange: -1,
//       api_key: -1,
//       pair: -1,
//       wallet: -1,
//       leverage: 0,
//       comm_type: 'standard',
//       algo: -1,
  
  
//       settings: {
//           tradingview_enabled: false,
//           tradingview_hook_url: '',
//           tradingview_msg: '',
    
//           trailingstop_enabled: false,
//           trailingstop_rate: -1,
    
//           simulate: false,
    
//           // #2.1.13
//           profit_coin: -1,
    
//           // #2.1.21.1
//           number_of_executed_orders: -1,
    
//           positionmode: 1,
//         positionmode_oneposition: true,
//         positionmode_reduce: true,
    
//           autorestart: -1,

//           ...SET_SETTINGS_FACTORY(preset),
//       },
  
//       use_custom_notifications: false,
  
//       notifications: JSON.parse(JSON.stringify(n)),
  
//       notifications_contacts: {
//           email: [],
//           sms: [],
//       },
  
//       algo_autoinversion_enabled: false,
//       algo_autoinversion_bot_id: -1,
  
//       start_filters_enabled: false,
//       start_filters: [],
  
//       switch_tp: SWITCH_TP_FACTORY(),
  
//       //token_to_pay_for_fees_value: 0,
  
//       notes: '',
//       tags: [],
//   };
// };

// export function SET_SETTINGS_FACTORY(preset = {}) {
//   return {
//       tradingview_enabled: false,
//       tradingview_hook_url: '',
//       tradingview_msg: '',
      
//       trailingstop_enabled: false,
//       trailingstop_rate: -1,
      
//       simulate: false,
      
//       // #2.1.13
//       profit_coin: -1,
      
//       // #2.1.21.1
//       number_of_executed_orders: -1,
      
//       positionmode: 1,
//       positionmode_oneposition: true,
//       positionmode_reduce: true,
      
//       autorestart: -1,
      
//       ...PRESET_FACTORY(preset),
//   };
// }

// export function PRESET_FACTORY(preset = {}) {
//   return {
//       // #2.1.9
//       rate_cover: -1,
  
//       // откуда этот селект?
//       //revenue_to_depo: true,
  
//       // #2.1.10
//       first_order_indent: -1,
  
//       rate_mode: 'avg',
  
//       // #2.1.11
//       order_matrix: -1, // ID из 2.1.11.1 в ТЗ
  
//       // #2.1.12
//       profit: -1,
  
//       // #2.1.14
//       cycle_up: -1,
  
//       // #2.1.15
//       sleep_before_cancel: -1,
  
//       // #2.1.16
//       sleep_before_up: -1,
  
//       // #2.1.17
//       sleep_after_done: -1,
  
//       revenue_to_depo: true,
//       revenue_to_depo_factor: -1,
  
//       // #2.1.18
//       logarithmic_scale: false,
//       logarithmic_factor: -1,
  
//       // #2.1.19
//       cost_limit_enabled: false,
//       scalping: false,
//       cost_limit: '0.0001',
  
//       // #2.1.20
//       fix_first_partial_order: false, // #2.1.20 - состояние чекбокса
//       fix_first_partial_order_value: -1,  // #2.1.20 - выделенное значение селекта для текущего пресета
  
//       // #2.1.21
//       restruct_cycle: false,
  
//       // #2.1.21.2
//       restruct_order_matrix: -1,
  
//       part_orders_enabled: false,
//       part_orders_value: 0,
//       part_orders_unstopable: false,
  
//       restruct_cycle_enabled: false,
//       restruct_cycle_i: -1,
//       restruct_cycle_rate_cover: -1,
  
//       ...preset,
//   };
// }

// export function SWITCH_TP_FACTORY(defs = {}) {
//   let switch_tp = {
//       enabled: false,
//       type: -1,
//       switch_check_running: false,
//       switch_check_running_type: false,
//       search_new_pair_for_order_matrix: false,
//       user_list: [],
  
//       use_sl_from_signal: false,
//       use_profit_from_signal: false,
  
//       signals: [],
  
  
//       //va_use_preset: false,
//       //va_preset: null,
//       ...VA_SETTINGS_FORM_FACTORY(),
//       ...defs,
//   };

//   delete switch_tp.exchange;

//   return switch_tp;
// };

// export function VA_PD_FILTER_FACTORY() {
//   return {pd_period: 24, pd_rate_change: 10};
// }

// export function VA_SETTINGS_FORM_FACTORY(exchange = -1, market = -1, coin_list_strategy = 'local') {
//   return {
//       //exchange: 1, market: "btc", use_bw_list: false, bw_list: [], is_white_list: true,
//       id: null, exchange, market, use_bw_list: false, bw_list: [], shared_coin_list: -1, is_white_list: true, coin_list_strategy,
//       period_value: '', period_unit: -1, interval_value: '', interval_unit: -1,
//       rate_change: '', min_rate_changes_count: '', min_trade_volume: '', min_price: '0',
//       pd_filters: [VA_PD_FILTER_FACTORY()],
//       title: '', description: '',
//   };
// }

// export const WALLET_DATA_FORM_FACTORY = (proto = {}) => ({
//   id: proto?.id || null,
//   name: proto?.name || '',
//   comment: proto?.comment || '',
//   currency: proto?.currency || -1,
//   exchange: proto?.exchange || -1,
//   type: proto?.type || 1,
//   amount: proto?.amount || 0,
//   api_key: proto?.api_key || -1,
// });

export const BOTS_DATA_FORM_FACTORY_PREPARE = (data: any) => {
    // eslint-disable-next-line no-param-reassign
    data = JSON.parse(JSON.stringify(data));

    interface IData {
        value: { value: any }
    }

    for (const [key, value] of Object.entries<IData>(data)) {
        if (data[key]?.customField) {
            data[key] = value.value;
        }
    };

    return data;
};