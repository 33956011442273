<template>
<section>
    <rb-checkbox
        :label="checkBoxTitle"
        :checked="checked"
        @update:checked="$emit('update:checked', $event)" />

    <div class="mt-4">
        <div class="w-full flex justify-between items-center mt-2">
            <n-button
                strong
                class="rounded-md text-white/90"
                :color="gl.mainColor"
                :disabled="disabled"
                @click.prevent="onDec" >
                <strong class="text-lg">-</strong>
            </n-button>

            <div class="flex flex-grow items-center">
                <n-input
                  type="text"
                  class="flex-grow mx-2 text-center font-bold rounded-md"
                  :placeholder="''"
                  :disabled="disabled || hasIntervals"
                  :allow-input="onlyAllowNumber"
                  :value="modelValue"
                  @keyup="onKeyUp"
                  @input="onInput" />
                  <div class="text-main text-2xl font-bold pr-2">x</div>
            </div>
            
            <n-button
                strong
                class="rounded-md text-white/90"
                :color="gl.mainColor"
                :disabled="disabled"
                @click.prevent="onInc" >
                <strong class="text-lg">+</strong>
            </n-button>
        </div>
      <n-slider
          class="w-full"
          :step="1"
          :value="modelValue"
          :min="minMax.min"
          :max="minMax.max"
          :marks="sliderMarks"
          :disabled="disabled"
          @update:value="onInput"/>
    </div>
</section>
<div v-if="!isExchangeFutures && checked" class="mt-12">
    <rb-checkbox
        popoverType="warning"
        :help="help"
        :nowrap="false"
        :label="partOrdersUnstopableTitle"
        :checked="partOrdersUnstopable"
        @update:checked="$emit('update:partOrdersUnstopable', $event)" />
    <slot name="partOrdersUnstopableAlerts"></slot>
</div>
</template>

<script>
// vue
import { computed } from 'vue';

// naive-ui
import {
    NCard,
    NIcon,
    NAlert,
    NInput,
    NSelect,
    NDivider,
    NButton,
    NSlider,
    NCheckbox,
    useMessage } from 'naive-ui';

// store
import { useGl } from '@/store/gl';

// components
import RbCheckbox from '@components/rb-checkbox';

// icons
import { CellularData320Filled } from '@vicons/fluent';

export default {
    name: 'RbPartOrders',
    emits: ['update:checked', 'update:partOrdersUnstopable'],
    props: {
        checked: Boolean,
        isExchangeFutures: Boolean,
        partOrdersUnstopable: Boolean,
        checkBoxTitle: String,
        help: String,
        partOrdersUnstopableTitle: String,
        modelValue: [String, Number],
        pair: {
            type: Object,
            default: () => ({
                min: 1,
                max: 0,
            }),
        },
    },
    components: {
        NIcon,
        NInput,
        NButton,
        NSlider,
        NCheckbox,
        RbCheckbox,
        CellularData320Filled,
    },
    setup(props, { emit }) {
        // store
        const gl = useGl();

        const disabled = computed(() => !props.checked);

        const minMax = computed(() => {
            const min = parseInt(props.pair.min, 10);
            const max = parseInt(props.pair.max, 10);
            return { min, max };
        });

        const sliderMarks = computed(() => {
            const marks = {};

            for (let i = minMax.value.min; i <= minMax.value.max; i++) {
                if (i === 0 || i === minMax.value.max) {
                    marks[i] = i;
                    continue;
                }

                const sliderStep = Math.ceil((minMax.value.max - minMax.value.min) / 6);
                if ((i - minMax.value.min) % sliderStep === 0) {
                    marks[i] = i;
                    continue;
                }
            }

            return marks;
        });

        const onDec = () => {
            const { min, max } = minMax.value;
				    emit('update:modelValue', Math.min(Math.max(props.modelValue - 1, min), max));
        };

        const onInc = () => {
            const { min, max } = minMax.value;
				    emit('update:modelValue', Math.max(Math.min(props.modelValue + 1, max), min));
        };

        const onInput = $event => {
            if ($event === '') {
                return void emit('update:modelValue', $event);
            }

            const { min, max } = minMax.value;

            if ($event < min) {
                emit('update:modelValue', 1);
            } else if ($event > max) {
                emit('update:modelValue', minMax.value.max);
            } else {
                emit('update:modelValue', $event);
            }
        };

        return {
            gl,
            minMax,
            disabled,
            sliderMarks,
            onDec,
            onInc,
            onInput,
            onlyAllowNumber: v => !v || /^\d+$/.test(v),
        };
    },
};
</script>