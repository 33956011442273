<template>
<section class="w-full">
    <div v-if="loading">
        <div class="flex border-b dark:border-gray-600 border-white/75 py-2">
            <n-skeleton class="ml-3" :width="100" :height="16" :sharp="false" />
            <n-skeleton class="ml-4" :width="120" :height="16" :sharp="false" />
        </div>

        <div class="flex justify-between px-3 mt-5 mb-3">
            <n-skeleton :width="150" :height="16" :sharp="false" />
            <n-skeleton :width="120" :height="16" :sharp="false" />
        </div>

        <div
            v-for="(n, i) in 7"
            :key="i"
            class="w-full dark:odd:bg-[#242424] odd:bg-gray-50 h-[36px]">
        </div>
    </div>
    <section v-else>
        <div class="flex justify-end px-3">
            <n-button
                circle
                tertiary
                @click="update"
                :disabled="loading || updateLoading"
                :loading="updateLoading">
                <n-icon v-if="!updateLoading" size="25"><ArrowSyncCircle16Regular /></n-icon>
            </n-button>
        </div>
        <n-tabs
            type="line" animated>
            <n-tab-pane
                v-for="item in data"
                :key="item.id"
                :tab="item.title"
                :name="item.id">
                <div class="flex flex-col">
                    <div class="flex justify-between items-center px-3 py-2 text-gray-600 dark:text-white/75">
                        <div class="text-xs font-semibold">{{ item.data?.btc }} {{ env.profitSymbol.toUpperCase() }} / {{item.data?.usdt || '---'}} USDT</div>

                        <div class="text-xs">{{ item.ciclos }}</div>
                    </div>

                    <div
                        v-for="(stat, i) in item.stats.slice(0, 7)"
                        :key="i"
                        class="w-full dark:odd:bg-[#242424] odd:bg-gray-50">
                        <div class="py-[2px] px-3 flex flex-row justify-start items-center overflow-x-hidden relative">
                            <div class="w-4/12 flex items-center text-gray-600 dark:text-white/75">
                                <rb-coin class="w-5 mr-3" :coin="currency(stat[2])" />
                                <div class="text-xs font-semibold uppercase">{{ stat[2] }}</div>
                            </div>
                            
                            <div class="text-xs font-semibold" :class="stat[0] > 0 ? 'text-green-600' : 'text-red-500'">{{ stat[0] }}</div>

                            <div
                                v-if="stat[2] !== env.profitSymbol"
                                class="flex flex-row opacity-60 items-center text-white justify-start absolute w-4/5 h-full bg-main right-[-72%] rounded-l-md transition-all cursor-pointer z-50"
                                @click="mouseover">
                                <n-icon class="absolute left-3 top-1/2 -translate-y-1/2 pointer-events-none">
                                    <ChevronLeft24Filled />
                                </n-icon>

                                <div class="w-3/12 flex items-center ml-8">
                                    <rb-coin class="mx-2 w-5" :coin="currency(env.profitSymbol)" />
                                    <div class="text-xs font-semibold uppercase">{{ env.profitSymbol }}</div>
                                </div>
                                
                                <div class="text-xs font-semibold">{{ stat[1] }}</div>
                            </div>
                        </div>
                    </div>
                    <n-button
                        v-if="item.stats.length > 7"
                        strong
                        secondary
                        size="large"
                        class="rounded-none"
                        @click="letsShowMore(item)">
                        <div class="flex">
                            <span class="text-xs mr-2">{{ t('more') }}</span>
                            <n-icon><ArrowRight12Regular /></n-icon>
                        </div>
                    </n-button>
                </div>
            </n-tab-pane>
        </n-tabs>
        <n-drawer
            v-model:show="showMore"
            width="100%"
            class="bg-gray-100 dark:bg-darkbg">
            <n-drawer-content body-content-style="padding: 0">
                <div v-if="info" class="flex flex-col h-full">
                    <div class="relative w-full bg-gray-100 dark:bg-darkbg text-sm text-center text-gray-600 dark:text-white/75 py-4">
                        <n-icon
                            size="20"
                            class="text-gray-600 dark:text-white/75 absolute top-1/2 left-2 -translate-y-1/2"
                            @click="showMore = false">
                            <ArrowLeft12Regular />
                        </n-icon>
                        {{ info.title }}
                    </div>
                    <div class="pb-4 flex-grow overflow-y-scroll overscroll-none scrollbar-hide">
                        <div
                            v-for="(stat, i) in info.stats"
                            :key="i"
                            class="w-full dark:odd:bg-[#242424] odd:bg-gray-50">
                            <div class="py-[2px] px-3 flex flex-row justify-start items-center overflow-x-hidden relative">
                                <div class="w-4/12 flex items-center text-gray-600 dark:text-white/75">
                                    <rb-coin class="w-5 mr-3" :coin="currency(stat[2])" />
                                    <div class="text-xs font-semibold uppercase">{{ stat[2] }}</div>
                                </div>
                                
                                <div class="text-xs font-semibold" :class="stat[0] > 0 ? 'text-green-600' : 'text-red-500'">{{ stat[0] }}</div>

                                <div
                                    v-if="stat[2] !== env.profitSymbol"
                                    class="flex flex-row opacity-60 hover:opacity-100 items-center text-white justify-start absolute w-4/5 h-full bg-main right-[-72%] rounded-l-md profit transition-all"
                                    @click="mouseover">
                                    <n-icon class="absolute left-3 top-1/2 -translate-y-1/2 pointer-events-none">
                                        <ChevronLeft24Filled />
                                    </n-icon>

                                    <div class="w-3/12 flex items-center ml-8">
                                        <rb-coin class="mx-2 w-5" :coin="currency(env.profitSymbol)" />
                                        <div class="text-xs font-semibold uppercase">{{ env.profitSymbol }}</div>
                                    </div>
                                    
                                    <div class="text-xs font-semibold">{{ stat[1] }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </n-drawer-content>
        </n-drawer>
    </section>
</section>
</template>

<script>
// vue
import { ref } from 'vue';

// general
import general from './general';

// components
import RbCoin from '@/components/rb-coin';

// icons
import {
    ArrowLeft12Regular,
    ArrowRight12Regular,
    ChevronLeft24Filled,
    ArrowSyncCircle16Regular } from '@vicons/fluent';

// naive-ui
import {
    NCard,
    NIcon,
    NTabs,
    NDrawer,
    NButton,
    NTabPane,
    NSkeleton,
    NScrollbar,
    NDrawerContent } from 'naive-ui';

export default {
    name: 'dash-profit-mobile',
    components: {
        NCard,
        NIcon,
        NTabs,
        RbCoin,
        NDrawer,
        NButton,
        NTabPane,
        NSkeleton,
        NScrollbar,
        NDrawerContent,
        ArrowLeft12Regular,
        ArrowRight12Regular,
        ChevronLeft24Filled,
        ArrowSyncCircle16Regular,
    },
    setup(props, context) {

        // vars
        const info = ref();
        const currentEl = ref();
        const showMore = ref(false);
        const tab = ref('daily_profit');

        const letsShowMore = item => {
            info.value = item;
            showMore.value = true;
        };

        const mouseover = $event => {
            if (currentEl.value) {
                currentEl.value.classList.remove('right-0', 'opacity-100');
            };
            
            currentEl.value = $event.target;
            currentEl.value.classList.add('right-0', 'opacity-100');

            setTimeout(() => {
                document.addEventListener('click', mouseleave);
            }, 200);
            
        };

        const mouseleave = $event => {
            if (currentEl.value && currentEl.value !== $event.target) {
                currentEl.value.classList.remove('right-0', 'opacity-100');
                setTimeout(() => {
                    document.removeEventListener('click', mouseleave);
                }, 200);
            }
        };

        return {
            tab,
            info,
            showMore,
            mouseover,
            mouseleave,
            letsShowMore,
            ...general(props, context),
        };
    },
};
</script>

<style lang="scss" scoped>
:deep(.n-tabs) {
  .n-tabs-wrapper  {
    padding: 0 12px;
  }
  .n-tabs-tab .n-tabs-tab__label {
    font-size: 0.75rem !important;
    line-height: 1rem !important;
  }
}

:deep(.n-tabs-bar) {
  left: 0;
}

.profit:hover:active {
  background-color: yellow;
}
</style>