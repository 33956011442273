import { defineStore } from 'pinia';

export const useUser = defineStore('user', {
    state: () => ({ 
        profile: {
            name: 'Drew',
            lang: 'ru',
            id: 70419,
            email: 'karchev_sait@mail.ru',
        },
        build_timestamp: null,
        debug: true,
        auth: {
            token: '88ed0dec-81ec-11ed-9d2c-caaff8d58dd3',
        },
    }),
});