<template>
<span class="rb-cc rb-profit-coin" :class="classes"></span>
</template>

<script>
import { computed } from 'vue';

export default {
    name: 'RbCoin',
    props: {
        refs: {
            type: Object,
            default: () => ({}),
        },
        coin: {
            type: Object,
            default: null,
        },
        withName: {
            default: false,
        },
        seamless: {
            default: false,
        },
        inline: {
            default: true,
        },
    },
    setup(props) {
        const wrapStyles = computed(() => ({
            'text-align': props.inline ? 'initial' : 'center',
            'display': props.inline ? 'inline-block' : 'block',
        }));

        const coinTitle = 'Title';

        const classes = computed(() => props.coin?.code_name || 'rb-cc--unknown');

        return {
            classes,
            coinTitle,
            wrapStyles,
        };
    },
};
</script>


<style lang="scss">
@import '@/css/rb-cc_32x32.scss';

.rb-cc--unknown {
    background-image: url('https://app.revenuebot.io/images/coin--unknown.png');
}
</style>