<template>
<div class="min-h-screen">
    <div v-if="stats.localization && revenueStats" class="flex flex-col">
        <div class="flex justify-between items-center px-3 py-2 text-gray-600 dark:text-white/75">
            <div class="text-xs font-semibold">{{ revenueStats?.btc }} {{ env.profitSymbol.toUpperCase() }} / {{ revenueStats?.usdt || '---'}} USDT</div>

            <div class="text-xs">( {{ revenueStats.cycles?.completed }} {{ stats.localization['dashboard_profit_total_cycles_completed'] }} )</div>
        </div>

        <div
            v-for="(stat, coin) in Object.entries(revenueStats.coins).slice(0, 7)"
            :key="coin"
            class="w-full dark:odd:bg-[#242424] odd:bg-gray-50">
            <div class="py-[2px] px-3 flex flex-row justify-start items-center overflow-x-hidden relative">
                <div class="w-4/12 flex items-center text-gray-600 dark:text-white/75">
                    <rb-coin class="w-5 mr-3" :coin="currency(stat[0])" />
                    <div class="text-xs font-semibold uppercase">{{ stat[0] }}</div>
                </div>
                
                <div class="text-xs font-semibold" :class="stat[1][0] > 0 ? 'text-green-600' : 'text-red-500'">{{ stat[1][0] }}</div>

                <div
                    v-if="stat[0][1] !== env.profitSymbol"
                    class="flex flex-row opacity-60 items-center text-white justify-start absolute w-4/5 h-full bg-main right-[-72%] rounded-l-md transition-all cursor-pointer z-50"
                    @click="mouseover">
                    <n-icon class="absolute left-3 top-1/2 -translate-y-1/2 pointer-events-none">
                        <ChevronLeft24Filled />
                    </n-icon>

                    <div class="w-3/12 flex items-center ml-8">
                        <rb-coin class="mx-2 w-5" :coin="currency(env.profitSymbol)" />
                        <div class="text-xs font-semibold uppercase">{{ env.profitSymbol }}</div>
                    </div>
                    
                    <div class="text-xs font-semibold">{{ stat[1][1] }}</div>
                </div>
            </div>
        </div>
        <n-button
            v-if="Object.entries(revenueStats.coins).length > 7"
            strong
            secondary
            size="large"
            class="rounded-none"
            @click="showMore = true">
            <div class="flex">
                <span class="text-xs mr-2">{{ t('more') }}</span>
                <n-icon><ArrowRight12Regular /></n-icon>
            </div>
        </n-button>
    </div>

    <section class="px-3">
        <super-table
            v-if="init"
            showSearch
            class="mt-4"
            :filters="filters"
            :actions="actions"
            :refFilters="refFilters"
            :pager="currentTable.pager"
            :columns="columns"
            :records="currentTable.records"
            :filtersChanged="filtersChanged"
            columnsStorageName="__columns_stats"
            type="stats"
            @getData="botsGet"
            @doSomething="doSomething"
            @reset-filters="resetFilters"
            @applyFilters="changeFilter">
            <template #tableTitle>
                <div class="text-base text-left mb-2 text-gray-600 dark:text-white/75">
                    {{ stats.localization['trade_statistics_available_bots_title'] }}
                </div>
            </template>
            <template #stats>
                <n-card
                    size="small"
                    :bordered="false"
                    class="shadow bg-white dark:bg-darkCardBg rounded-md my-2"
                    content-style="padding-bottom: 4px;"
                    :segmented="{
                        content: true,
                        footer: 'soft'
                    }">
                    <div class="text-[10px] text-gray-600 dark:text-white/75 text-left flex flex-wrap">
                        <div class="flex items-center mr-4 mb-2">
                            <span class="whitespace-nowrap">{{ stats.localization['trade_statistics_bots_total'] }}</span>
                            <n-tag round size="tiny" :bordered="false" type="warning" class="ml-2">
                                <span class="text-yellow-500 font-semibold">{{ currentTable.stats.total }}</span>
                            </n-tag>
                        </div>

                        <div class="flex items-center mr-4 mb-2">
                            <span class="whitespace-nowrap">{{ stats.localization['trade_statistics_bots_running'] }}</span>
                            <n-tag round size="tiny" :bordered="false" type="success" class="ml-2">
                                <span class="text-green-500 font-semibold">{{ currentTable.stats.running }}</span>
                            </n-tag>
                        </div>
                        
                        <div class="flex items-center mr-4 mb-2">
                            <span class="whitespace-nowrap">{{ stats.localization['trade_statistics_bots_stopped'] }}</span>
                            <n-tag round size="tiny" :bordered="false" class="ml-2">
                                <span class="font-semibold">{{ currentTable.stats.stopped }}</span>
                            </n-tag>
                        </div>
                        
                        <div class="flex items-center mr-4 mb-2">
                            <span class="whitespace-nowrap">{{ stats.localization['trade_statistics_bots_error'] }}</span>
                            <n-tag round size="tiny" :bordered="false" type="error" class="ml-2">
                                <span class="text-red-400 font-semibold">{{ currentTable.stats.error }}</span>
                            </n-tag>
                        </div>
                    </div>
                </n-card>
            </template>
            <template #actionButtons>
                <section v-if="currentTable.records && stats.isBots" class="flex flex-wrap mt-4 mb-2">
                    <n-button
                        strong
                        size="tiny"
                        class="text-white/90 rounded-md mr-2 mb-2"
                        :color="gl.mainColor"
                        :disabled="!actionButtonsDisabled"
                        @click="letsShowConfirmation('start')">
                        {{ stats.localization['trade_statistics_bots_table_mass_start_button'] }}
                    </n-button>
                    <n-button
                        strong
                        size="tiny"
                        class="mr-2 mb-2 text-white/90 rounded-md"
                        :color="gl.mainColor"
                        :disabled="!actionButtonsDisabled"
                        @click="letsShowConfirmation('stop')">
                        {{ stats.localization['trade_statistics_bots_table_mass_stop_button'] }}
                    </n-button>
                    <n-button
                        strong
                        size="tiny"
                        class="mr-2 mb-2 text-white/90 rounded-md"
                        :color="gl.mainColor"
                        :disabled="!actionButtonsDisabled"
                        @click="letsShowConfirmation('pause')">
                        {{ stats.localization['trade_statistics_bots_table_mass_pause_button'] }}
                    </n-button>
                    <n-button
                        strong
                        size="tiny"
                        class="mr-2 mb-2 text-white/90 rounded-md"
                        :color="gl.mainColor"
                        :disabled="!actionButtonsDisabled"
                        @click="letsShowConfirmation('archive')">
                        {{ stats.localization['trade_statistics_bots_table_mass_archive_button'] }}
                    </n-button>
                    <n-button
                        strong
                        size="tiny"
                        class="mr-2 mb-2 text-white/90 rounded-md"
                        :color="gl.mainColor"
                        :disabled="!actionButtonsDisabled"
                        @click="letsShowConfirmation('delete')">
                        {{ stats.localization['trade_statistics_bots_table_mass_delete_button'] }}
                    </n-button>
                    <n-button
                        strong
                        size="tiny"
                        class="mr-2 mb-2 text-white/90 rounded-md"
                        :color="gl.mainColor"
                        :disabled="!actionButtonsDisabled"
                        @click="letsShowConfirmation('markLastAsCanceled')">
                        {{ stats.localization['trade_statistics_management_markcyclecanceled_button'] }}
                    </n-button>
                    <n-button
                        strong
                        size="tiny"
                        class="mr-2 mb-2 text-white/90 rounded-md"
                        :color="gl.mainColor"
                        :disabled="!actionButtonsDisabled"
                        @click="letsShowConfirmation('restart')">
                        {{ stats.localization['trade_statistics_management_restart_button'] }}
                    </n-button>
                    <n-button
                        strong
                        size="tiny"
                        class="mr-2 mb-2 text-white/90 rounded-md"
                        :color="gl.mainColor"
                        :disabled="!actionButtonsDisabled"
                        @click="letsShowConfirmation('force_stop')">
                        {{ stats.localization['trade_statistics_bots_table_mass_force_stop'] }}
                    </n-button>
                </section>
            </template>
        </super-table>
    </section>
</div>
<base-drawer-mobile
    v-if="stats.localization"
    :label="dashProfit.title"
    :x-padding="false"
    v-model:show="showMore">
    <template #default>
        <div
            v-for="(stat, coin) in Object.entries(revenueStats.coins)"
            :key="coin"
            class="w-full dark:odd:bg-[#242424] odd:bg-gray-50">
            <div class="py-[2px] px-3 flex flex-row justify-start items-center overflow-x-hidden relative">
                <div class="w-4/12 flex items-center text-gray-600 dark:text-white/75">
                    <rb-coin class="w-5 mr-3" :coin="currency(stat[0])" />
                    <div class="text-xs font-semibold uppercase">{{ stat[0] }}</div>
                </div>
                
                <div class="text-xs font-semibold" :class="stat[1][0] > 0 ? 'text-green-600' : 'text-red-500'">{{ stat[1][0] }}</div>

                <div
                    v-if="stat[0] !== env.profitSymbol"
                    class="flex flex-row opacity-60 hover:opacity-100 items-center text-white justify-start absolute w-4/5 h-full bg-main right-[-72%] rounded-l-md profit transition-all"
                    @click="mouseover">
                    <n-icon class="absolute left-3 top-1/2 -translate-y-1/2 pointer-events-none">
                        <ChevronLeft24Filled />
                    </n-icon>

                    <div class="w-3/12 flex items-center ml-8">
                        <rb-coin class="mx-2 w-5" :coin="currency(env.profitSymbol)" />
                        <div class="text-xs font-semibold uppercase">{{ env.profitSymbol }}</div>
                    </div>
                    
                    <div class="text-xs font-semibold">{{ stat[1][1] }}</div>
                </div>
            </div>
        </div>
    </template>
</base-drawer-mobile>

<base-dialog-mobile
    v-model:show="showConfirmation"
    @positive-click="showConfirmation = false, groupAction(actionType)"
    @negative-click="showConfirmation = false">
    <div class="text-md" v-html="refs.localization.confirmations.bot[actionType]"></div>
</base-dialog-mobile>
</template>

<script>
// general
import general from './general';

// vue
import { ref } from 'vue';

// icon
import { ChevronLeft24Filled } from '@vicons/fluent';

// components
import RbCoin from '@components/rb-coin';
import RbSwitch from '@components/rb-switch';
import FilterBots from '@components/filters/bots';
import FilterStates from '@components/filters/states';
import FilterStatuses from '@components/filters/statuses';
import FilterExchanges from '@components/filters/exchanges';
import SuperTable from '@components/super-table/mobile.vue';
import FilterTaksonomiya from '@components/filters/taksonomiya';

// UI
import {
    NTag,
    NIcon,
    NCard,
    NTabs,
    NButton,
    NTabPane,
    NCheckbox,
    NSkeleton,
    NScrollbar,
    NDatePicker,
    NRadioGroup,
    NRadioButton,
    useNotification } from 'naive-ui';

export default {
    name: 'trade-statistic-mobile',
    components: {
        NTag,
        NIcon,
        NCard,
        NTabs,
        RbCoin,
        NButton,
        RbSwitch,
        NTabPane,
        NSkeleton,
        NScrollbar,
        FilterBots,
        SuperTable,
        NDatePicker,
        FilterStates,
        NRadioGroup,
        NRadioButton,
        FilterStatuses,
        FilterExchanges,
        FilterTaksonomiya,
        ChevronLeft24Filled,
    },
    setup(props, context) {
        // vars
        const currentEl = ref();
        const showMore = ref(false);

        const mouseover = $event => {
            if (currentEl.value) {
                currentEl.value.classList.remove('right-0', 'opacity-100');
            };
            
            currentEl.value = $event.target;
            currentEl.value.classList.add('right-0', 'opacity-100');

            setTimeout(() => {
                document.addEventListener('click', mouseleave);
            }, 200);
            
        };

        const mouseleave = $event => {
            if (currentEl.value && currentEl.value !== $event.target) {
                currentEl.value.classList.remove('right-0', 'opacity-100');
                setTimeout(() => {
                    document.removeEventListener('click', mouseleave);
                }, 200);
            }
        };

        return {
            showMore,
            mouseover,
            ...general(...arguments),
        };
    },
};
</script>