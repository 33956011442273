<template>
<section class="pb-2">
    <super-table
        v-if="dataRefs"
        showSearch
        type="profile_balance_history"
        :loading="loading"
        :filters="filters"
        :refFilters="refFilters"
        :pager="dataRefs.pager"
        :columns="dataRefs.columns"
        :records="dataRefs.records"
        :filtersChanged="filtersChanged"
        columnsStorageName="__columns_marketplace_settings"
        @updateDate="updateDate"
        @getData="getTransactions"
        @applyFilters="changeFilter"
        @reset-filters="resetFilters">
        <template #afterSearch>
            <div class="flex items-center justify-start mx-2">
                <div class="hover:text-main dark:hover:text-main text-gray-600 dark:text-white/75 cursor-pointer mr-2" @click="downloadData('xls')">
                    <n-icon class="text-2xl">
                        <ClipboardDataBar24Regular />
                    </n-icon>
                    <div class="text-xs text-center -mt-2 font-bold">XLS</div>
                </div>
                <div class="hover:text-main dark:hover:text-main text-gray-600 dark:text-white/75 cursor-pointer ml-2" @click="downloadData('csv')">
                    <n-icon class="text-2xl">
                        <ClipboardDataBar24Regular />
                    </n-icon>
                    <div class="text-xs text-center -mt-2 font-bold">CSV</div>
                </div>
            </div>
        </template>
    </super-table>
    <rb-loader v-else class="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2" />
</section>
</template>

<script>
// vue
import { ref, reactive, computed, watch, onMounted } from 'vue';
import moment from 'moment';

// store
import { useGl } from '@/store/gl';
import { useRefs } from '@/store/refs';
import { useProfile } from '@store/profile';

// shared
import { CRUD_PAGER_FACTORY } from '@shared/factories';

// services
import ProfileService from '@services/profileService';
import LocalStorage from '@services/ts/localStorage';

// i18n
import { useI18n } from 'vue-i18n';

// icons
import { Warning24Filled, Dismiss16Filled, IosArrowRtl24Filled, ClipboardDataBar24Regular } from '@vicons/fluent';

// components
import RbInput from '@components/rb-input';
import RbSelect from '@components/rb-select';
import RbSwitch from '@components/rb-switch';
import FilterBots from '@components/filters/bots';
import SuperTable from '@components/super-table/mobile.vue';
import ButtonGroup from '@components/filters/button-group.vue';
import FilterExchanges from '@components/filters/exchanges.vue';
import FilterCheckable from '@components/filters/filter-checkable';
import FilterChangedDate from '@components/filters/changedDate.vue';

// editor
import Editor from '@tinymce/tinymce-vue';

// naive-ui
import {
    NIcon,
    NCard,
    NAlert,
    NButton,
    NDivider,
    NDatePicker,
    NCollapseTransition,
    useNotification } from 'naive-ui';

export default {
    name: 'balance-history-mobile',
    props: {
        tab: {
            type: String,
            required: true,
        },
    },
    components: {
        NIcon,
        NCard,
        NAlert,
        Editor,
        NButton,
        RbInput,
        RbSelect,
        NDivider,
        RbSwitch,
        SuperTable,
        FilterBots,
        ButtonGroup,
        NDatePicker,
        FilterCheckable,
        FilterExchanges,
        Warning24Filled,
        Dismiss16Filled,
        FilterChangedDate,
        IosArrowRtl24Filled,
        NCollapseTransition,
        ClipboardDataBar24Regular,
    },
    setup(props) {
        // store
        const gl = useGl();
        const refs = useRefs();
        const profile = useProfile();

        // ui
        const notification = useNotification();

        // i18n
        const { t } = useI18n();

        // vars
        const { _ } = window;
        const loading = ref(false);
        const show = ref(false);
        const dataRefs = ref();
        // filters
        
        const filters = reactive(CRUD_PAGER_FACTORY({
            bots: {},
            exchanges: [ -1 ],
            types: [ -1 ],
            states: [ -1 ],
            period: moment().subtract(7, 'days').format('YYYY-MM-DD') + ' ' + moment().add(7, 'days').format('YYYY-MM-DD'),
            transaction_types: [ -1 ],
            statuses: [ -1 ],
        }, {
            perPage: window.isValidJSON(LocalStorage.getItem('__filters_balance_history'))
                ? JSON.parse(LocalStorage.getItem('__filters_balance_history'))
                : 10,
        }));
        const resetFilters = () => {
            filters.filters.bots = {};
            filters.filters.exchanges = [ -1 ];
            filters.filters.types = [ -1 ];
            filters.filters.states = [ -1 ];
            filters.filters.period = moment().subtract(7, 'days').format('YYYY-MM-DD') + ' ' + moment().add(7, 'days').format('YYYY-MM-DD');
            filters.filters.transaction_types = [ -1 ];
            filters.filters.statuses = [ -1 ];

            getTransactions();
        };
        const filtersChanged = computed(() => {
            return Object.keys(filters.filters.bots).length
                || filters.filters.exchanges[0] !== -1
                || filters.filters.types[0] !== -1
                || filters.filters.states[0] !== -1
                || filters.filters.transaction_types[0] !== -1
                || filters.filters.statuses[0] !== -1;
        });

        const refFilters = computed(() => [
            {
                mode: 'radio_group',
                title: profile.localization['profile_account_balance_history_filters_types'],
                refs: profile.finOpTypes,
                value: filters.filters.types,
                filter: 'filters.types',
            }, {
                type: 'render',
                mode: 'date_picker',
                value: periods.value,
                filter: 'filters.period',
            }, {
                mode: 'bots',
                title: profile.localization['profile_account_balance_history_filters_bots'],
                refs: profile.botFilters,
                filter: 'filters.bots',
            }, {
                mode: 'exchanges',
                title: refs.localization.filters.labels.exchanges,
                refs: refs.exchanges,
                filter: 'filters.exchanges',
            }, {
                mode: 'checkbox_group',
                title: profile.localization['profile_account_balance_history_filters_transactions'],
                refs: profile.transactionTypes,
                filter: 'filters.transaction_types',
            }, {
                mode: 'checkbox_group',
                title: profile.localization['profile_account_balance_history_filters_statuses'],
                refs: profile.transactionStatuses,
                filter: 'filters.statuses',
            },
        ]);

        const periods = computed(() => {
            const arr = filters.filters.period.split(' ');

            return [new Date(arr[0]).getTime(), new Date(arr[1]).getTime()];
        });

        const changeFilter = ([ key, value ], update = false) => {
            const canUpdate = key === 'query' && !value && dataRefs.value?.pager.query;
            if (key !== 'page') {
                _.set(filters, 'page', 1);
            }

            if (key === 'perPage') {
                LocalStorage.setItem('__filters_balance_history', value);
            }

            _.set(filters, key, value !== null && value !== undefined ? value : [ -1 ]);
            
            if (update || canUpdate)
                getTransactions();
        };

        const updateDate = $event => {
            if ($event) {
                const firstDate = new Date($event[0]).toISOString().split('T')[0] !== filters.filters.period.split(' ')[0]
                    ? new Date($event[0]).toISOString().split('T')[0]
                    : filters.filters.period.split(' ')[0];

                const secondDate = new Date($event[1]).toISOString().split('T')[0] !== filters.filters.period.split(' ')[1]
                    ? new Date($event[1]).toISOString().split('T')[0]
                    : filters.filters.period.split(' ')[1];

                let value = `${firstDate} ${secondDate}`;
                changeFilter(['filters.period', value ]);
            }
        };

        const getTransactions = async () => {
            loading.value = true;

            try {
                dataRefs.value = ( await ProfileService.getTransactions({ pager: filters }) ).data;
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            };
            
            loading.value = false;
        };

        const downloadData = async format => {
            loading.value = true;

            try {
                const { redirectTo } = ( await ProfileService.getTransactions({
                    format,
                    pager: filters,
                }) ).data;

                window.location.href = redirectTo;
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            };

            loading.value = false;
        };

        onMounted(() => {
            if (!dataRefs.value)
                getTransactions();
        });

        return {
            gl,
            refs,
            show,
            profile,
            filters,
            periods,
            dataRefs,
            loading,
            refFilters,
            filtersChanged,
            updateDate,
            changeFilter,
            downloadData,
            resetFilters,
            getTransactions,
        };
    },
};
</script>