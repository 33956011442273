<template>
<div class="mt-4 text-black dark:text-white">
  <div v-if="label" class="text-xl mb-4">{{ label }}</div>
  <slot name="afterLabel"></slot>
  <div class="lg:flex lg:flex-row justify-between items-center">
    <div class="lg:flex lg:flex-row text-left items-center">
      <!-- <n-skeleton v-if="gl.loading" :width="260" :sharp="false" size="medium" /> -->
      <n-button
        strong
        :size="gl.screenWidth < 1024 ? 'small' : 'large'"
        color="#4949D9"
        class="text-sm lg:text-base"
        :disabled="localLoading"
        @click="showModal = true">
        {{ refs.localization.grids.tvs.modal_title }}
      </n-button>
      <div v-if="refresh" class="mt-4 lg:mt-0 lg:ml-6">
        <!-- <n-skeleton v-if="gl.loading" :width="300" :sharp="false" size="medium" /> -->
        <div class="flex justify-between lg:block">
          <n-checkbox
            size="large"
            color="#4949D9"
            class="text-sm text-left lg:text-base"
            :disabled="localLoading"
            :checked="autoUpdate"
            :label="refs.localization.autorefresh.autorefresh_label"
            @update:checked="autoUpdate = $event"/>
          <n-button
            strong
            :size="gl.screenWidth < 1024 ? 'small' : 'large'"
            class="lg:ml-4 text-sm lg:text-base"
            color="#4949D9"
            :disabled="localLoading"
            @click="update">
            {{ refs.localization.autorefresh.autorefresh_btn }}
          </n-button>
        </div>
      </div>
    </div>

    <slot name="beforeSearch"></slot>
    <div class="flex-grow mt-4 lg:mt-0 lg:px-4" v-if="filters?.query !== undefined && showSearch">
      <n-input
        clearable
        class="text-left"
        type="text"
        size="large"
        :value="filters.query"
        :placeholder="placeholder"
        @input="$emit('update:search', $event)"
        @keyup.enter="$emit('enter')"
				@keyup.esc="$emit('update:search', '')" />
    </div>
    <slot name="afterSearch"></slot>
    
    <grid-nav
        v-if="filters"
        class="mt-4 lg:mt-0"
        :loading="loading"
        :size="filters.perPage"
        @setPerPage="$emit('setPerPage', $event)" />
  </div>
  <Progress v-if="autoUpdate" class="mt-4" ref="progress" @done="doneTimer" />
</div>
<n-modal v-model:show="showModal" transform-origin="center">
  <n-card
    style="width: 650px"
    :bordered="false"
    size="huge"
    role="dialog"
    aria-modal="true">
    <template #header>
      <div class="flex flex-row items-center justify-between relative">
        <div class="text-2xl">{{ refs.localization.grids.tvs.modal_title }}</div>
        <n-button
          strong
          circle
          color="#4949D9"
          class="absolute -right-4"
          @click="showModal = false">
          <template #icon>
            <n-icon color="#fff" size="20"><Dismiss16Filled /></n-icon>
          </template>
        </n-button>
      </div>
    </template>
    <template #default>
      <div class="w-full flex flex-row flex-wrap">
        <template
            v-for="column in columns"
            :key="column.name">
            <n-checkbox
                v-if="column.name !== 'checked'"
                size="large"
                color="#4949D9"
                class="text-xl w-1/2 mb-4"
                :checked="!column.isHidden"
                :label="column.title"
                @update:checked="setCheck(column)" />
        </template>
      </div>
    </template>
  </n-card>
</n-modal>
</template>

<script>
// vue
import { ref, computed, watch, onMounted } from 'vue';

// store
import { useGl } from '@/store/gl';
import { useRefs } from '@/store/refs';
import { useMarketplace } from '@store/ts/marketplace';

// naive-ui
import {
    NCard,
    NIcon,
    NModal,
    NInput,
    NButton,
    NSkeleton,
    NCheckbox } from 'naive-ui';

// components
import Progress from '@components/base/progress.vue';
import GridNav from '@components/base/grid-nav.vue';

// icons
import { Dismiss16Filled } from '@vicons/fluent';

// services
import LocalStorage from '@services/ts/localStorage';

export default {
    name: 'GridSettings',
    emits: ['setPerPage', 'enter', 'update:search'],
    props: {
        label: String,
        loading: Boolean,
        searchPlaceholder: String,
        filters: {
            type: Object,
            required: true,
        },
        columns: {
            type: Array,
            required: true,
        },
        columnsStorageName: {
            type: String,
            required: true,
        },
        showSearch: {
            type: Boolean,
            default: true,
        },
        refresh: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        NCard,
        NIcon,
        NModal,
        NInput,
        NButton,
        GridNav,
        Progress,
        NSkeleton,
        NCheckbox,
        Dismiss16Filled,
    },
    setup(props, { emit }) {
        const gl = useGl();
        const refs = useRefs();
        const marketplace = useMarketplace();

        const progress = ref();
        const showModal = ref(false);
        const autoUpdate = ref(false);

        const localLoading = computed(() => gl.loading || props.loading);
        const placeholder = computed(() => props.searchPlaceholder || refs.localization.grids.search_label);

        watch(() => props.loading, v => {
            if (v && autoUpdate.value)
                progress.value.stop();
            else if (!v && autoUpdate.value)
                progress.value.start();
        });

        // watch([...props.columns.map((_, i) => () => props.columns[i].isHidden)], () => {
        //     // emit('changeDataColumn', props.columns);
        // });

        watch(() => props.columns, v => {
            columnsPrepare();
        });

        const doneTimer = () => {
            emit('getData');
        };

        const update = () => {
            if (autoUpdate.value) progress.value.stop();
            emit('getData');
        };

        const setCheck = column => {
            column.isHidden = !column.isHidden;

            saveStorageColumns();
        };

        const saveStorageColumns = () => {
            LocalStorage.setItem(props.columnsStorageName, JSON.stringify(props.columns));
        };

        const columnsPrepare = () => {
            const localColumns = JSON.parse(LocalStorage.getItem(props.columnsStorageName));

            if (localColumns) {
                localColumns.forEach(({ name, isHidden }) => {
                    const el = props.columns.find(({ name: id }) => id === name);
                    if (el)
                        el.isHidden = isHidden;
                });
            };
        };

        onMounted(() => {
            columnsPrepare();
        });

        return {
            gl,
            refs,
            progress,
            showModal,
            autoUpdate,
            marketplace,
            placeholder,
            localLoading,
            update,
            setCheck,
            doneTimer,
        };
    },
};
</script>