<template>
<div
    v-if="isFooter"
    class="h-full flex justify-center items-center text-main text-md dark:bg-darkCardBg bg-gray-50 font-semibold relative overflow-hidden">
    {{ dataFooter }} <div class="absolute w-full top-0 left-0 h-[2px] bg-main shadow-xl"></div>
</div>
<div
    v-else
    class="h-full flex flex-col justify-center items-center px-2"
    :class="classes">
    <n-icon
        v-if="!tagsList?.length"
        class="text-main cursor-pointer text-2xl"
        @click="show = !show">
        <Bookmark20Regular />
    </n-icon>
    <n-popover
        v-else
        trigger="hover"
        class="max-w-[500px] px-1"
        placement="left">
        <template #trigger>
            <n-icon
                class="text-main cursor-pointer text-2xl"
                @click="show = !show">
                <Bookmark20Filled />
            </n-icon>
        </template>
        <div class="flex">
            <n-tag
                v-for="item in tagsList"
                class="bg-main text-white mx-1"
                :key="item.id"
                :bordered="false">
                {{ item.title }}
            </n-tag>
        </div>
    </n-popover>

    <base-modal-desktop
        v-model:show="show">
        <template #default>
            <div class="flex">
                <rb-select
                    multiple
                    class="flex-grow"
                    :defaultValue="false"
                    :placeholder="refs.localization.tags.placeholder"
                    :options="tagsOptions"
                    @search="search"
                    v-model:value="localTags" />
                <n-button
                    v-if="showBtnAddNewTag"
                    class="ml-2 rounded-md text-white/90"
                    :color="gl.mainColor"
                    @click="addNewTag">
                    {{ refs.localization.tags.tagPlaceholder }}
                </n-button>
            </div>
        </template>
        <template #footer>
            <div class="flex justify-between items-center">
                <rb-checkbox
                    :label="'- ' + refs.localization.tags.doGridRefresh"
                    v-model:checked="tagsDoGridRefresh" />
                <n-button
                    class="ml-3 rounded-md text-white/90"
                    :color="gl.mainColor"
                    @click="updateTags">
                    {{ refs.localization.dialogs.ok }}
                </n-button>
            </div>
        </template>
    </base-modal-desktop>
</div>
</template>

<script>
// vue
import { ref, reactive, watch, computed } from 'vue';

// store
import { useGl } from '@/store/gl';
import { useRefs } from '@/store/refs';
import { useBots } from '@/store/bots';

// i18n
import { useI18n } from 'vue-i18n';

// services
import BotsService from'@services/bots';

// components
import RbSelect from '@components/rb-select';
import RbCheckbox from '@components/rb-checkbox';
import BaseModal from '@components/base/base-modal';

// icons
import { Bookmark20Regular, Bookmark20Filled } from '@vicons/fluent';

// naive-ui
import {
    NTag,
    NIcon,
    NButton,
    NDivider,
    NPopover,
    useMessage } from 'naive-ui';

export default {
    name: 'cell-tags',
    props: {
        isFooter: {
            type: Boolean,
            default: false,
            required: true,
        },
        dataFooter: {
            default: '',
        },
        row: {
            type: Object,
            required: true,
        },
        tags: {
            type: Array,
            required: true,
        },
        classes: {
            type: String,
        },
    },
    components: {
        NTag,
        NIcon,
        NButton,
        NPopover,
        NDivider,
        RbSelect,
        BaseModal,
        RbCheckbox,
        Bookmark20Filled,
        Bookmark20Regular,
    },
    setup(props, { emit }) {
        // store
        const gl = useGl();
        const refs = useRefs();
        const bots = useBots();

        // naive-ui
        const message = useMessage();

        // i18n
        const { t } = useI18n();

        // vars
        const { _ } = window;
        const show = ref(false);
        const searchValue = ref();
        const localTags = ref([]);
        const tagsDoGridRefresh = ref(true);
        const newTags = ref([]);

        watch(show, v => {
            if (!v) {
                searchValue.value = null;
                newTags.value.splice(0, newTags.value.length);
                localTags.value.splice(0, localTags.value.length);
            } else {
                props.row.tags.forEach(el => {
                    localTags.value.push(el);
                });
            }
        });

        const tagsOptions = computed(() => {
            const res = [...props.tags, ...newTags.value].map(el => ({
                value: el.id,
                label: el.title,
                ...el,
            }));

            return res;
        });

        const tagsList = computed(() => props.tags.filter(({ id }) => ~props.row.tags.indexOf(id)));
        const showBtnAddNewTag = computed(() => searchValue.value && !tagsOptions.value.find(({ label }) => searchValue.value === label));

        const updateTags = async () => {
            gl.showLoading = true;

            try {
                const result = await BotsService.updateTags({
                    id: props.row.id,
                    tags: [...props.tags, ...newTags.value].filter(({ id }) => ~localTags.value.indexOf(id)),
                });

                if (result) {
                    emit('updateTags', result.data.tags);

                    if (tagsDoGridRefresh.value)
                        emit('getData');
                }
            } catch {
                message.error(t('errorMessage'));
            };

            show.value = false;

            if (!tagsDoGridRefresh.value)
                gl.showLoading = false;
        };

        const search = $event => {
            searchValue.value = $event;
        };

        const addNewTag = () => {
            const id = `__new_tag_${Math.floor((Math.random() * 10000000))}`;
            newTags.value.push({id, title: searchValue.value });

            localTags.value.push(id);
        };

        return {
            gl,
            refs,
            show,
            tagsList,
            localTags,
            tagsOptions,
            searchValue,
            showBtnAddNewTag,
            tagsDoGridRefresh,
            search,
            addNewTag,
            updateTags,
        };
    },
};
</script>