// interfaces
import {
    IColumns,
    IRecords } from './interfaces';

// store interfaces
import {
    IRefs } from '@store/ts/marketplace/interfaces';

// vue
import { ref, computed, onMounted, reactive } from 'vue';

// services
import Marketplace from '@services/ts/marketplace';
import LocalStorage from '@services/ts/localStorage';

// shared
import { crudPagerFactory } from '@shared/ts/factories';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useDashBoard } from '@store/ts/dashBoard';
import { useMarketplace } from '@store/ts/marketplace';

// i18n
import { useI18n } from 'vue-i18n';

// router
import { useRoute, useRouter } from 'vue-router';

// naive-ui
import {
    useNotification } from 'naive-ui';

export default function (): any {
    // store
    const gl = useGl();
    const refs = useRefs();
    const dashBoard = useDashBoard();
    const marketplace = useMarketplace();

    // i18n
    const { t } = useI18n();

    // lodash
    const { _ } = window;

    // router
    const route = useRoute();
    const router = useRouter();

    // ui
    const notification = useNotification();

    // filters
    const filters = reactive(crudPagerFactory({}, {
        perPage: '*',
        sort_column: 'prio',
        sort_dir: 'desc',
    }));

    // vars
    const currentEchangeId = ref(0);
    const dashboardGet = reactive({
        records: Array<IRecords>(),
        columns: Array<IColumns>(),
    });

    const showModal = computed(() => !!route.params.bot_id);

    // exchanges from records
    const exchangesFromRecords = computed(() =>
        _.union(dashboardGet.records
            .map(({ exchange } : { exchange: number }): number => exchange)));

    // bots list
    const botsList = computed(() =>
        dashboardGet.records
            .filter(({ exchange } : { exchange: number }) : boolean => exchange == currentEchangeId.value));

    // сcolumns list
    const columnsList = computed(() =>
        dashboardGet.columns.filter(({ isHidden, name } : { isHidden: boolean, name: string }) : boolean => !isHidden && name !== 'actions'));

    // action column
    const actionColumn = computed(() => dashboardGet.columns.find(({ name } : { name: string}) => name == 'actions'));

    // getData
    const getData = async (): Promise<void> => {
        gl.showLoading = true;

        try {
            const result = ( await Marketplace.dashboardGet({
                pager: filters,
            }) )?.data;

            if (result) {
                dashboardGet.records = result.records.map((el: IRecords) => ({
                    ...el,
                    loading: false,
                }));
                dashboardGet.columns = result.columns;

                // set current exchange id
                currentEchangeId.value = result.records[0].exchange;
            }
        } catch {
            notification.error({
                content: t('errorMessage'),
                duration: 2500,
                keepAliveOnHover: true,
            });
        };

        gl.showLoading = false;
    };

    // exchanges
    const getExchange = (exchange: number) => refs.exchanges.find(el => el.id == exchange);

    // currency
    const getCurrency = (icon: string) => refs.currencies.find(el => el.id === icon);

    // buy bot
    const buyBot = async (bot: IRecords): Promise<void> => {
        bot.loading = true;

        if (!marketplace.refsLocalizationNotEmpty) {
            const result = await Marketplace.getRefs();

            if (result?.data) {
                marketplace.refs = result.data as IRefs;
            }
        }

        // open bot-review
        router.push({ name: 'home', params: { bot_id: bot.id } });

        bot.loading = false;
    };

    onMounted(() => {
        getData();
    });

    return {
        botsList,
        dashBoard,
        showModal,
        marketplace,
        columnsList,
        dashboardGet,
        actionColumn,
        currentEchangeId,
        exchangesFromRecords,
        buyBot,
        getData,
        getCurrency,
        getExchange,
    };
}