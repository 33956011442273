<template>
<section class="pb-2">
    <div class="text-xl text-left text-gray-700 dark:text-white/90 font-semibold">
        <n-skeleton v-if="!profile.localization" :width="160" :height="20" :sharp="false" />
        <template v-else>{{ profile.localization['profile/oauth/header'] }}</template>
    </div>
    <section class="flex mt-4 items-stretch">
        <n-card
            size="small"
            :bordered="false"
            class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg h-full"
            :segmented="{
                content: true,
                footer: 'soft'
            }">
            <div class="text-base text-left text-gray-600 dark:text-white/75 font-semibold">{{ profile.localization['profile/oauth/bind_new_acc/title'] }}</div>
            
            <div class="flex items-center mt-4">
                <rb-select
                    labelPosition="left"
                    :label="profile.localization['profile/oauth/bind_new_acc/provider/title']"
                    :options="oauthProvidersOptions"
                    v-model:value="id_provider" />
                <n-button
                    v-if="provider_bind.link"
                    strong
                    class="rounded-md text-white/90 ml-2"
                    :color="gl.mainColor"
                    @click="openLink(provider_bind.link)">
                    {{ provider_bind.anchor }}
                </n-button>
                <!-- <div class="flex-grow text-main text-2xl text-center">
                    <a v-show="provider_bind.link" :href="provider_bind.link" v-html="provider_bind.anchor" class="provider_bind_link"></a>
                </div> -->
            </div>
        </n-card>
    </section>

    <super-table
        v-if="dataRefs"
        showSearch
        :actions="actions"
        :filters="filters"
        :pager="dataRefs.pager"
        :columns="dataRefs.columns"
        :records="dataRefs.records"
        columnsStorageName="__columns_q-auth"
        @getData="oauthProviders"
        @doSomething="doSomething"
        @applyFilters="changeFilter">
    </super-table>
    <n-modal
        preset="dialog"
        transform-origin="center"
        :mask-closable="false"
        :title="refs.localization.confirmations.oauth.delete"
        :positive-text="refs.localization.dialogs.ok"
        :negative-text="refs.localization.dialogs.cancel"
        @positive-click="showConfirmation = false, onOauthProvidersDelete()"
        @negative-click="showConfirmation = false">
        <n-divider />
    </n-modal>
    <base-modal
        :width="500"
        :label="refs.localization.confirmations.oauth.delete"
        v-model:show="showConfirmation">
        <template #footer>
            <section class="flex justify-end">
                <n-button
                    secondary
                    size="medium"
                    type="default"
                    class="rounded-md"
                    @click="showConfirmation = false">
                    {{ refs.localization.dialogs.cancel }}
                </n-button>
                <n-button
                    strong
                    size="medium"
                    class="rounded-md text-white/90 ml-2"
                    :color="gl.mainColor"
                    @click="showConfirmation = false, onOauthProvidersDelete()">
                    {{ refs.localization.dialogs.ok }}
                </n-button>
            </section>
        </template>
    </base-modal>
</section>
</template>

<script>
// general
import general from './general';

// icons
import { Warning24Filled, Dismiss16Filled, IosArrowRtl24Filled } from '@vicons/fluent';

// components
import RbInput from '@components/rb-input';
import RbSelect from '@components/rb-select';
import RbSwitch from '@components/rb-switch';
import FilterBots from '@components/filters/bots';
import BaseModal from '@components/base/base-modal';
import SuperTable from '@components/super-table/desktop.vue';
import ButtonGroup from '@components/filters/button-group.vue';
import FilterExchanges from '@components/filters/exchanges.vue';
import FilterCheckable from '@components/filters/filter-checkable';
import FilterChangedDate from '@components/filters/changedDate.vue';

// editor
import Editor from '@tinymce/tinymce-vue';

// naive-ui
import {
    NIcon,
    NCard,
    NAlert,
    NModal,
    NButton,
    NDivider,
    NDatePicker,
    NCollapseTransition } from 'naive-ui';

export default {
    name: 'QAuth',
    props: {
        tab: {
            type: String,
            required: true,
        },
        myTab: {
            type: String,
            required: true,
        },
    },
    components: {
        NIcon,
        NCard,
        NModal,
        NAlert,
        Editor,
        NButton,
        RbInput,
        RbSelect,
        NDivider,
        RbSwitch,
        BaseModal,
        SuperTable,
        FilterBots,
        ButtonGroup,
        NDatePicker,
        FilterCheckable,
        FilterExchanges,
        Warning24Filled,
        Dismiss16Filled,
        FilterChangedDate,
        IosArrowRtl24Filled,
        NCollapseTransition,
    },
    setup(props) {

        return {
            ...general(...arguments),
        };
    },
};
</script>