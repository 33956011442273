import Api from './index';

export default class mainApi{

    static async getRefs() {
        return await Api.getData({
            action: 'get.refs',
        });
    };

    static async getEnv() {
        return await Api.getData({
            action: 'get.env',
        });
    };

    static async profileGetRefs() {
        return await Api.getData({
            action: 'profile.getRefs',
        });
    };

    static async profileGetFullInfo() {
        return await Api.getData({
            action: 'profile.getFullInfo',
        });
    };

    static async changeLang(payload) {
        return await Api.getData({
            payload,
            action: 'switch.lang',
        });
    };

    static async dashBoard(payload) {
        return await Api.getData({
            action: 'dashboard.get',
            payload,
        });
    };

    static async dashBoardRefs(lang) {
        return await Api.getData({
            action: 'dashboard.getRefs',
        });
    };
}