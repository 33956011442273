<template>
<n-card
    size="small"
    :bordered="false"
    class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg"
    :segmented="{
      content: true,
      footer: 'soft'
    }">
    <template #default>
        <div class="border border-dashed border-gray-600 rounded-md items-center pb-4">
            <div class="flex items-center justify-center px-3 my-2">
                <ApiKeysIcon class="fill-current w-12" />
            </div>
            <n-button
                strong
                class="text-white/90 rounded-md"
                :color="gl.mainColor"
                @click="show = true">
                + {{ apiKeys.localization.apikeys_title_button }}
            </n-button>
        </div>
    </template>
</n-card>

<base-drawer-mobile
    :label="mainButtonLabel"
    v-model:show="show">
    <template #default>
        <!-- <pre>{{ dataForm }}</pre> -->
        <rb-input
            id="name"
            :label="dataForm.name.title"
            :status="dataForm.name.status === 'error' ? 'error' : undefined"
            :msg="dataForm.name.msg"
            :placeholder="dataForm.name.placeholder"
            v-model:value="dataForm.name.value"
            @update:value="dataForm.name.status = null, dataForm.name.msg = null" />

        <rb-select
            v-if="mode === 'create'"
            class="mt-4"
            :label="dataForm.exchange.title"
            :options="exchangeOptions"
            :status="dataForm.exchange.status === 'error' ? 'error' : undefined"
            :msg="dataForm.exchange.msg"
            v-model:value="dataForm.exchange.value"
            @update:value="dataForm.exchange.status = null, dataForm.exchange.msg = null" />
        <div v-else class="text-left w-4/12 px-2">
            <div class="text-md">{{ dataForm.exchange.title }}</div>
            <div
                class="rb-td-stock-icon m-0 h-12"
                :class="`rb-td-stock-icon-${exchange(dataForm.exchange.value)?.code_name}`">
            </div>
        </div>

        <n-divider />
        <rb-input
            :label="dataForm.key.title"
            :status="dataForm.key.status === 'error' ? 'error' : undefined"
            :msg="dataForm.key.msg"
            :placeholder="dataForm.key.placeholder"
            v-model:value="dataForm.key.value"
            @update:value="dataForm.key.status = null, dataForm.key.msg = null" />
        <n-alert v-if="exchangeMetaInfo" class="rounded-md" type="warning" size="small">
            <div class="notes text-[10px]" v-html="exchangeMetaInfo"></div>
        </n-alert>
        <n-alert v-if="exchangeMeta2Info" class="rounded-md mt-4" type="warning" size="small">
            <div class="notes text-[10px]" v-html="exchangeMeta2Info"></div>
        </n-alert>

        <rb-input
            class="mt-4"
            :label="dataForm.sign.title"
            :status="dataForm.sign.status === 'error' ? 'error' : undefined"
            :msg="dataForm.sign.msg"
            :placeholder="dataForm.sign.placeholder"
            v-model:value="dataForm.sign.value"
            @update:value="dataForm.sign.status = null, dataForm.sign.msg = null" />
        
        <rb-checkbox
            class="mt-4"
            :label="dataForm.show_dashboard.title"
            v-model:checked="dataForm.show_dashboard.value" />
    </template>
    <template #footer>
        <!-- :disabled="!canCreateWallet" -->
        <n-button
            class="text-white/90 rounded-md w-full"
            @click="onCreateApiKey"
            :color="gl.mainColor" >
            {{ applyLabel }}
        </n-button>
    </template>
</base-drawer-mobile>
</template>

<script>
// vue
import { ref, reactive, computed, watch, onMounted } from 'vue';

// services
import ApiKeysService from'@services/apiKeysService';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useApiKeys } from '@store/apiKeys';


// i18n
import { useI18n } from 'vue-i18n';

// components
import RbInput from '@components/rb-input/mobile.vue';
import RbSelect from '@components/rb-select/mobile.vue';
import ApiKeysIcon from '@components/icons/api-keys.vue';
import RbCheckbox from '@components/rb-checkbox/mobile.vue';

// icons
import { Warning24Filled } from '@vicons/fluent';

// router
import { useRoute, useRouter } from 'vue-router';

// naive-ui
import {
    NIcon,
    NCard,
    NAlert,
    NButton,
    NDivider,
    NCollapse,
    NCollapseItem,
    useNotification } from 'naive-ui';

export default {
    name: 'apikeys-create-mobile',
    props: {
        data: Object,
    },
    components: {
        NIcon,
        NCard,
        NAlert,
        RbInput,
        NButton,
        NDivider,
        RbSelect,
        NCollapse,
        RbCheckbox,
        ApiKeysIcon,
        NCollapseItem,
        Warning24Filled,
    },
    setup(props, { emit }) {
        // store
        const gl = useGl();
        const refs = useRefs();
        const apiKeys = useApiKeys();

        // router
        const route = useRoute();
        const router = useRouter();

        // ui
        const notification = useNotification();

        // i18n
        const { t } = useI18n();

        // vars
        const show = ref(false);
        const init = ref(false);
        const collapseItem = ref();
        const mode = ref('create');
        const dataForm = reactive({});

        const mainButtonLabel = computed(() => props.data?.id ? apiKeys.localization.apikeys_update_button : apiKeys.localization.apikeys_title_button);
        const applyLabel = computed(() => props.data?.id ? apiKeys.localization.apikeys_update_button : apiKeys.localization.apikeys_add_button);
        const exchangeMetaInfo = computed(() => refs.exchanges.find(el => el.id === dataForm.exchange.value)?.meta);
        const exchangeMeta2Info = computed(() => refs.exchanges.find(el => el.id === dataForm.exchange.value)?.meta2);

        const exchangeOptions = computed(() => refs.exchanges.map(el => ({
            label: el.title,
            value: el.id,
            ...el,
        })));

        watch(() => route.params.id, v => {
            if (v)
                show.value = true;
        });

        watch(show, v => {
            if (v) {
                initModule();
            } else {
                emit('clear');
                mode.value = 'create';
                router.push({ name: 'api-keys' });
            }
        });

        const apiKeyssRefs = async () => {
            if (apiKeys.refs) return;

            gl.showLoading = true;

            try {
                apiKeys.refs = ( await ApiKeysService.getRefs() ).data;
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            };
            
            gl.showLoading = false;
        };

        const initModule = () => {
            const res = {
                name: props.data?.name || '',
                exchange: props.data?.exchange || -1,
                key: props.data?.key || '',
                sign: props.data?.sign ||  '',
                show_dashboard: props.data?.show_dashboard || false,
            };

            mode.value = props.data ? 'edit' : 'create';

            for (const [key, value] of Object.entries(res)) {
                if (key === 'name') {
                    dataForm[key] = {
                        value,
                        title: apiKeys.localization['apikeys_name_f'],
                        placeholder: apiKeys.localization['apikeys_name_i'],
                        status: undefined,
                        msg: undefined,
                        loading: false,
                        show: true,
                        customField: true,
                    };
                } else if (key === 'exchange') {
                    dataForm[key] = {
                        value,
                        title: apiKeys.localization['apikeys_exchange_f'],
                        placeholder: apiKeys.localization['apikeys_exchange_i'],
                        status: undefined,
                        msg: undefined,
                        loading: false,
                        show: true,
                        customField: true,
                    };
                } else if (key === 'key') {
                    dataForm[key] = {
                        value,
                        title: apiKeys.localization['apikeys_key_f'],
                        placeholder: apiKeys.localization['apikeys_key_i'],
                        status: undefined,
                        msg: undefined,
                        loading: false,
                        show: true,
                        customField: true,
                    };
                } else if (key === 'sign') {
                    dataForm[key] = {
                        value,
                        title: apiKeys.localization['apikeys_sign_f'],
                        placeholder: apiKeys.localization['apikeys_sign_i'],
                        status: undefined,
                        msg: undefined,
                        loading: false,
                        show: true,
                        customField: true,
                    };
                } else if (key === 'show_dashboard') {
                    dataForm[key] = {
                        value,
                        title: apiKeys.localization['show_dashboard_label'],
                        status: undefined,
                        msg: undefined,
                        loading: false,
                        show: true,
                        customField: true,
                    };
                };
            };
        };

        const hideShow = async ({ expanded }) => {
            if (expanded) {
                initModule();
            }
        };

        const exchange = exchange => refs.exchanges.find(el => el.id === exchange);

        const onCreateApiKey = async () => {
            gl.showLoading = true;

            const formPath = mode.value === 'create' ? 'apikeys.addNew' : 'apikeys.update';

            const record = {
                exchange: dataForm.exchange.value,
                name: dataForm.name.value,
                key: dataForm.key.value,
                sign: dataForm.sign.value,
                show_dashboard: dataForm.show_dashboard.value,
                _formPath: formPath,
            };

            let result;

            try {
                result = mode.value === 'create'
                    ? await ApiKeysService.addApiKeyToExchange(record.exchange, record)
                    : await ApiKeysService.update({
                        id: props.data.id,
                        ...record,
                    });

                if (!result.data.status) {
                    if (result.data?.errors_form) {
                        for (let key in dataForm) {
                            const fields = result.data.errors_form[formPath].fields;
                            const el = Object.keys(fields).find(el => el === key);

                            if (el) {
                                dataForm[key].status = 'error';
                                dataForm[key].msg = fields[el].msg;
                            } else {
                                dataForm[key].status = 'success';
                                dataForm[key].msg = undefined;
                            }
                        }
                    }

                    if (result.data?.errors) {
                        result.data.errors.forEach(el => {
                            notification.error({
                                content: el.msg,
                                duration: 2500,
                                keepAliveOnHover: true,
                            });
                        });
                    }
                } else {
                    show.value = false;

                    if (mode.value !== 'create') {
                        emit('updateApiKey', result.data.record);
                    }
                    // clean apiKeyModel
                    for (let key in dataForm)
                        dataForm[key].value = '';

                    // show messages
                    result.postMessages.forEach(el => {
                        notification[el.success ? 'success' : 'error']({
                            content: el.msg,
                            duration: 2500,
                            keepAliveOnHover: true,
                        });
                    });

                    emit('apiKeysGet');
                }
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            }

            gl.showLoading = false;
        };

        onMounted(async () => {
            await apiKeyssRefs();
            init.value = true;
        });

        return {
            gl,
            refs,
            mode,
            init,
            show,
            apiKeys,
            dataForm,
            applyLabel,
            collapseItem,
            exchangeOptions,
            mainButtonLabel,
            exchangeMetaInfo,
            exchangeMeta2Info,
            hideShow,
            exchange,
            onCreateApiKey,
        };
    },
};
</script>