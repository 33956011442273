<template>
<h4 class="text-left my-2 text-xl text-gray-600 dark:text-white/75 font-semibold">
    {{ refs.localization.sidebar.marketplace }}
</h4>
<n-tabs
    v-if="init"
    type="line"
    size="large"
    :value="currentTab"
    @update:value="changeTab">
    <n-tab-pane
        v-for="(tab, i) in tabs"
        :key="i"
        :name="tab.name"
        :disabled="loading">
        <template #tab>
            <div class="flex">
                {{ tab.title }}
                <n-tag size="small" v-if="tab.name === 'buy/free'" round type="warning" :bordered="false" class="ml-2">
                    <strong>Free</strong>
                </n-tag>
            </div>
        </template>
    <router-view></router-view>
    </n-tab-pane>
</n-tabs>
</template>

<script>
// general
import general from './general';

// UI
import {
    NTag,
    NCard,
    NTabs,
    NTabPane } from 'naive-ui';

export default {
    name: 'marketplace-desktop',
    components: {
        NTag,
        NCard,
        NTabs,
        NTabPane,
    },
    setup() {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>

<style lang="scss" scoped>

</style>