import Api from './index';

export default class Bots {

    static async getRefs(payload) {
        return await Api.getData({
            action: 'bots.getRefs',
            payload,
        });
    };

    static async get(payload) {
        return await Api.getData({
            action: 'bots.get',
            payload,
        });
    };

    static async checkCommissionTokenBalance(payload) {
        return await Api.getData({
            action: 'bots.checkCommissionTokenBalance',
            payload,
        });
    };

    static async createNewOrderMatrix(payload) {
        return await Api.getData({
            action: 'bots.createNewOrderMatrix',
            payload: {
                records: [payload],
            },
        });
    };

    static async getOrdersMatrixForLogarithmicFactor(payload) {
        return await Api.getData({
            action: 'bots.getOrdersMatrixForLogarithmicFactor',
            payload,
        });
    };

    static async getBotsAutoInvList(payload) {
        return await Api.getData({
            action: 'bots.getBotsAutoInvList',
            payload: {
                botInfo: payload,
            },
        });
    };

    static async addEmail(payload) {
        return await Api.getData({
            action: 'bots.addEmail',
            payload,
        });
    };

    static async addPhone(payload) {
        return await Api.getData({
            action: 'bots.addPhone',
            payload,
        });
    };

    static async addNew(payload) {
        return await Api.getData({
            action: 'bots.addNew',
            payload,
        });
    };

    static async botUpdate(payload) {
        return await Api.getData({
            action: 'bots.update',
            payload,
        });
    };

    static async updateTags(payload) {
        return await Api.getData({
            action: 'bots.updateTags',
            payload,
        });
    };

    static async canDoAction(payload) {
        return await Api.getData({
            action: 'bots.canDoAction',
            payload,
        });
    };

    static async action(payload) {
        return await Api.getData({
            action: 'bots.action',
            payload,
        });
    };

    static async delete(payload) {
        return await Api.getData({
            action: 'bots.delete',
            payload,
        });
    };

    static async groupAction(payload) {
        return await Api.getData({
            action: 'bots.groupAction',
            payload,
        });
    };

    static async getFullInfo(id) {
        return await Api.getData({
            action: 'bots.getFullInfo',
            payload: {
                id,
            },
        });
    };

    static async createNewPreset(payload) {
        return await Api.getData({
            action: 'bots.createNewPreset',
            payload,
        });
    };

    static async deletePreset(payload) {
        return await Api.getData({
            action: 'bots.deletePreset',
            payload,
        });
    };

    static async deleteOrderMatrix(payload) {
        return await Api.getData({
            action: 'bots.deleteOrderMatrix',
            payload,
        });
    };

    static async createNewFiltersPreset(payload) {
        return await Api.getData({
            action: 'bots.createNewFiltersPreset',
            payload,
        });
    };
}