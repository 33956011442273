<template>
<div
    v-if="isFooter"
    class="h-full flex justify-center items-center text-main text-md dark:bg-darkCardBg bg-gray-50 font-semibold relative overflow-hidden">
    {{ dataFooter }} <div class="absolute w-full top-0 left-0 h-[2px] bg-main shadow-xl"></div>
</div>
<div
    v-else
    class="h-full flex flex-col justify-center items-center px-2"
    :class="classes">
    <n-icon
        class="cursor-pointer text-xl text-main"
        @click="show = !show">
        <Bookmark20Filled v-if="tagsList?.length" />
        <Bookmark20Regular v-else />
    </n-icon>

    <base-modal-mobile
        v-model:show="show">
        <template #default>
            <rb-select
                multiple
                :auto-close="false"
                :defaultValue="false"
                :placeholder="refs.localization.tags.placeholder"
                :options="tagsOptions"
                @search="search"
                @showSelect="showSelect = $event"
                v-model:value="localTags">
                <template #afterSearch>
                    <n-button
                        v-if="showBtnAddNewTag"
                        class="ml-2 rounded-md text-white/90"
                        :color="gl.mainColor"
                        @click="addNewTag">
                        {{ refs.localization.tags.tagPlaceholder }}
                    </n-button>
                </template>
            </rb-select>
        </template>
        <template #footer>
            <div class="flex justify-between">
                <rb-checkbox
                    :label="'- ' + refs.localization.tags.doGridRefresh"
                    v-model:checked="tagsDoGridRefresh" />
                <n-button
                    class="ml-3 rounded-md text-white/90"
                    :color="gl.mainColor"
                    @click="updateTags">
                    {{ refs.localization.dialogs.ok }}
                </n-button>
            </div>
        </template>
    </base-modal-mobile>
</div>
</template>

<script>
// vue
import { ref, watch, computed } from 'vue';

// store
import { useGl } from '@/store/gl';
import { useRefs } from '@/store/refs';
import { useBots } from '@/store/bots';

// i18n
import { useI18n } from 'vue-i18n';

// services
import BotsService from'@services/bots';

// components
import RbSelect from '@components/rb-select/mobile.vue';
import RbCheckbox from '@components/rb-checkbox/mobile.vue';

// icons
import { Bookmark20Regular, Bookmark20Filled } from '@vicons/fluent';

// naive-ui
import {
    NTag,
    NIcon,
    NButton,
    NDivider,
    NPopover,
    useMessage } from 'naive-ui';

export default {
    name: 'cell-tags-mobile',
    props: {
        isFooter: {
            type: Boolean,
            default: false,
            required: true,
        },
        dataFooter: {
            default: '',
        },
        row: {
            type: Object,
            required: true,
        },
        tags: {
            type: Array,
            required: true,
        },
        classes: {
            type: String,
        },
    },
    components: {
        NTag,
        NIcon,
        NButton,
        NPopover,
        NDivider,
        RbSelect,
        RbCheckbox,
        Bookmark20Filled,
        Bookmark20Regular,
    },
    setup(props, { emit }) {
        // store
        const gl = useGl();
        const refs = useRefs();
        const bots = useBots();

        // naive-ui
        const message = useMessage();

        // i18n
        const { t } = useI18n();

        // vars
        const { _ } = window;
        const show = ref(false);
        const searchValue = ref();
        const localTags = ref([]);
        const newTags = ref([]);
        const showSelect = ref();
        const tagsDoGridRefresh = ref(true);

        watch(show, v => {
            if (v) {
                props.row.tags.forEach(el => {
                    localTags.value.push(el);
                });
            } else {
                localTags.value.length = 0;
                newTags.value.length = 0;
            }
        });

        watch(showSelect, v => {
            if (!v) {
                setTimeout(() => {
                    searchValue.value = null;
                }, 0);
            }
        });

        const tagsOptions = computed(() => {
            const res = [...props.tags, ...newTags.value].map(el => ({
                value: el.id,
                label: el.title,
                ...el,
            }));

            return res;
        });

        const tagsList = computed(() => props.tags.filter(({ id }) => ~props.row.tags.indexOf(id)));
        const showBtnAddNewTag = computed(() => searchValue.value && !tagsOptions.value.find(({ label }) => searchValue.value === label));

        const updateTags = async () => {
            gl.showLoading = true;

            try {
                const result = await BotsService.updateTags({
                    id: props.row.id,
                    tags: [...props.tags, ...newTags.value].filter(({ id }) => ~localTags.value.indexOf(id)),
                });

                if (result) {
                    emit('updateTags', result.data.tags);

                    if (tagsDoGridRefresh.value)
                        emit('getData');
                }
            } catch {
                message.error(t('errorMessage'));
            };

            show.value = false;

            if (!tagsDoGridRefresh.value)
                gl.showLoading = false;
        };

        const search = $event => {
            console.log('search');
            searchValue.value = $event;
        };

        const addNewTag = () => {
            const id = `__new_tag_${Math.floor((Math.random() * 10000000))}`;
            newTags.value.push({id, title: searchValue.value });

            localTags.value.push(id);
        };

        return {
            gl,
            refs,
            show,
            tagsList,
            localTags,
            showSelect,
            tagsOptions,
            showBtnAddNewTag,
            tagsDoGridRefresh,
            search,
            addNewTag,
            updateTags,
        };
    },
};
</script>