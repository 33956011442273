// vue
import { computed } from 'vue';

// icons
import Bots__total from '@components/svg/Bots__total.vue';
import Bots__running from '@components/svg/Bots__running.vue';
import Bots__stopped from '@components/svg/Bots__stopped.vue';
import Bots__error from '@components/svg/Bots__error.vue';

// router
import { useRouter } from 'vue-router';

// store
import { useGl } from '@store/ts/gl';
import { useDashBoard } from '@store/ts/dashBoard';

export default function (props, context) {
    // store
    const gl = useGl();
    const dashBoard = useDashBoard();

    // router
    const router = useRouter();

    // const loading = ref(false);
    const loading = computed(() => gl.loading || dashBoard.loading);

    const bots_stats = computed(() => [
        {
            active: true,
            icon: Bots__total,
            title: dashBoard.localization ? dashBoard.localization.dashboard_bots_total : '',
            count: dashBoard.gBotsStatsInfo ? dashBoard.gBotsStatsInfo.total : 0,
        }, {
            active: false,
            icon: Bots__running,
            title: dashBoard.localization ? dashBoard.localization.dashboard_bots_running : '',
            count: dashBoard.gBotsStatsInfo ? dashBoard.gBotsStatsInfo.running : 0,
            indicate: 'bg-green-600',
        }, {
            active: false,
            icon: Bots__stopped,
            title: dashBoard.localization ? dashBoard.localization.dashboard_bots_stopped : '',
            count: dashBoard.gBotsStatsInfo ? dashBoard.gBotsStatsInfo.stopped : 0,
            indicate: 'bg-gray-800',
        }, {
            active: false,
            icon: Bots__error,
            title: dashBoard.localization ?  dashBoard.localization.dashboard_bots_error : '',
            count: dashBoard.gBotsStatsInfo ? dashBoard.gBotsStatsInfo.error : 0,
            indicate: 'bg-red-600',
        },
    ]);

    return {
        gl,
        router,
        loading,
        dashBoard,
        bots_stats,
    };
}