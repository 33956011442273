<template>
<div
    v-if="isFooter"
    class="h-full flex justify-center items-center text-main text-md dark:bg-darkCardBg bg-gray-50 font-semibold relative overflow-hidden">
    {{ dataFooter }} <div class="absolute w-full top-0 left-0 h-[2px] bg-main shadow-xl"></div>
</div>
<template v-else-if="!data">
    <div class="h-full" :class="localClasses"></div>
</template>
<template v-else>
    <div
        v-if="type === 'text'"
        class="h-full flex justify-center items-center text-xs text-left px-5 relative"
        :class="localClasses"
        @click="$emit('click')">
        <template v-if="fieldName === 'name' && hasError">
            <n-icon
                class="text-red-500 cursor-pointer text-2xl mr-2"
                @click.prevent.stop="show = !show">
                <Warning20Regular />
            </n-icon>
            <base-modal-desktop
                v-model:show="show">
                <template #default>
                    <div class="text-gray-600 dark:text-white/75" v-html="row.exit_error"></div>
                </template>
            </base-modal-desktop>
        </template>
        <div class="w-full flex justify-start items-center">
            <template v-if="link">
                <router-link :to="link">{{ data }}</router-link>
            </template>
            <template v-else>
                <div v-html="data" class="w-full" :class="pofitLosses ? ('font-semibold ' + (+data >= 0 ? 'text-green-500' : 'text-red-500')) : ''"></div>
            </template>
            <n-icon v-if="interactive" class="text-main ml-2" size="18"><Link48Filled /></n-icon>
        </div>
        
        <slot name="afterData"></slot>
        <div v-if="vitData !== null" class="absolute right-2 top-1/2 -translate-y-1/2">
            <div v-if="vitData > 0" class="flex text-green-500">
                {{ vitData }}%
                <n-icon class="mt-1 ml-[1px]">
                    <ArrowUp12Filled />
                </n-icon>
            </div>

            <div v-else-if="vitData < 0" class="flex text-red-500">
                {{ vitData }}%
                <n-icon class="mt-1 ml-[1px]">
                    <ArrowDown12Filled />
                </n-icon>
            </div>

            <div v-else>{{ vitData }}%</div>
        </div>
    </div>
    <div
        v-else-if="type === 'date'"
        class="h-full flex flex-col justify-center items-center px-5"
        :class="localClasses"
        @click="$emit('click')">
        <template v-if="data">
            <div class="text-center font-semibold whitespace-nowrap">{{ data[0] }}</div>
            <div class="text-center opacity-70 whitespace-nowrap">{{ data[1] }}</div>
        </template>
    </div>
</template>
</template>

<script>
// vue
import { ref, computed, onMounted } from 'vue';

// naive
import { NIcon } from 'naive-ui';

// icons
import { ArrowUp12Filled, ArrowDown12Filled, Link48Filled, Warning20Regular }  from '@vicons/fluent';

export default {
    name: 'cell-text-desktop',
    props: {
        isFooter: {
            type: Boolean,
            default: false,
            required: true,
        },
        dataFooter: {
            default: '',
        },
        data: {
            required: true,
        },
        vitData: {
            default: null,
        },
        interactive: {
            dafult: false,
        },
        type: {
            default: 'text',
        },
        link: {
            type: Boolean,
            default: false,
        },
        classes: {
            type: String,
        },
        fieldName: {
            type: String,
        },
        noWhiteSpace: {
            type: Boolean,
            default: true,
        },
        pofitLosses: {
            type: Boolean,
            default: false,
        },
        hasError: {
            type: Boolean,
            default: false,
        },
        row: {
            type: Object,
            required: true,
        },
    },
    components: {
        NIcon,
        Link48Filled,
        ArrowUp12Filled,
        Warning20Regular,
        ArrowDown12Filled,
    },
    setup(props) {
        // vars
        const show = ref(false);

        const localClasses = computed(() => {
            return [
                props.interactive
                    ? 'underline cursor-pointer hover:no-underline hover:text-main font-semibold'
                    : null,
                props.noWhiteSpace
                    ? 'whitespace-nowrap'
                    : null,
                props.data?.length > 30
                    ? 'min-w-[220px] whitespace-pre-line'
                    : null,
                props.classes,
            ];
        });

        onMounted(() => {
            // console.log('data', props.data);
        });

        return {
            show,
            localClasses,
        };
    },
};
</script>

<style lang="scss" scoped>
:deep(.rbg-profile__type--2) {
    &:after {
        content: '▲';
        color: #5abc19;
        
    }
}

:deep(.rbg-profile__type--3) {
    &:after {
        content: '▼';
        color: #f3731e;
    }
}
</style>