// store
import { useGl } from '@store/ts/gl';
import { useEnv } from '@store/ts/env';
import { useRefs } from '@store/ts/refs';
import { useDashBoard } from '@store/ts/dashBoard';

// services
import ApiKeysService from'@services/apiKeysService';
import WalletsService from '@services/walletsService';

// vue
import { ref, reactive, watch, computed, onMounted } from 'vue';

export default function (props, context) {
    // store
    const gl = useGl();
    const env = useEnv();
    const refs = useRefs();
    const dashBoard = useDashBoard();

    // vars
    const { _ } = window;
    const show = ref(false);
    const percentage = ref(0);
    const stepPercentage = ref(0);
    const apiKeysRefs = ref([]);
    const localLoading = ref(false);
    const balancesTotal = ref();
    const apiKeysStats = ref();
    const apiKeysStatsLoading = ref(false);
    const totalBalances = ref();
    const showCreateApiKey = ref(false);
    const loading = reactive({
        // 
    });
    const currentExchange = ref(dashBoard.exchangeBalances ? dashBoard.exchangeBalances[0]?.exchange : null);

    watch(() => dashBoard.exchangeBalances?.length, () => {
        currentExchange.value = dashBoard.exchangeBalances[0]?.exchange;
    });

    // can update main block
    const canUpdate = computed(() =>
        apiKeysRefs.value
            ? apiKeysRefs.value.every(el => el.hasBalances)
            : false);


    const totalInfo = computed(() => [
        {
            label: dashBoard.localization.dashboard_exchange_balances_total_label + ':',
            type: 'totalAvailable',
            totalUsdt: usdtTotal.value.toFixed(2),
            totalBtc: btcTotal.value.toFixed(8),
        }, {
            label: dashBoard.localization.dashboard_exchange_balances_available_label + ':',
            type: 'totalAvailable',
            totalUsdt: usdt.value.toFixed(2),
            totalBtc: btc.value.toFixed(8),
        },  {
            type: 'pnl',
            label: dashBoard.localization.dashboard_exchange_balances_total_futures_pnl_label + ':',
            totalUsdt: totalPnl.value.toFixed(8),
        }, {
            type: 'info',
            label: dashBoard.localization.dashboard_exchange_balances_num_connected_exchanges_label + ':',
            value: apiKeysStats.value?.dashboard.exchanges || 0,
        }, {
            type: 'info',
            label: dashBoard.localization.dashboard_exchange_balances_num_connected_api_label + ':',
            value: apiKeysStats.value?.dashboard.total || 0,
        },
    ]);

    const totalPnl = computed(() => {
        return apiKeysRefs.value.length
            ? apiKeysRefs.value.reduce((accum, current) => (+current.pnl?.usdt || 0) + accum, 0)
            : 0;
    });

    const usdtTotal = computed(() => {
        return apiKeysRefs.value.length
            ? apiKeysRefs.value.reduce((accum, current) => (+current.balances_total?.usdt_total || 0) + accum, 0)
            : 0;
    });

    const btcTotal = computed(() => {
        return apiKeysRefs.value.length
            ? apiKeysRefs.value.reduce((accum, current) => (+current.balances_total?.btc_total || 0) + accum, 0)
            : 0;
    });

    const usdt = computed(() => {
        return apiKeysRefs.value.length
            ? apiKeysRefs.value.reduce((accum, current) => (+current.balances_total?.usdt || 0) + accum, 0)
            : 0;
    });

    const btc = computed(() => {
        return apiKeysRefs.value.length
            ? apiKeysRefs.value.reduce((accum, current) => (+current.balances_total?.btc || 0) + accum, 0)
            : 0;
    });

    const info = computed(() =>
        currentExchange.value
            ? dashBoard.exchangeBalances.find(({ exchange }) => currentExchange.value == exchange)
            : null );

    const exchange = exchange => refs.exchanges.find(el => el.id == exchange);
    const currency = icon => refs.currencies.find(el => el.id === icon);

    // getApiKeysList
    const getApiKeysList = async () => {
        localLoading.value = true;

        const records = ( await ApiKeysService.getApiKeysList({
            dashboard: true,
            statuses: [1],
        }) ).data.records;

        apiKeysRefs.value = records.map(el => ({
            ...el,
            loading: true,
            canUpdate: false,
            balances: {},
            hasBalances: false,
            error: null,
        }));

        stepPercentage.value = 100 / apiKeysRefs.value.length;
        
        localLoading.value = false;
    };

    const getAllBalances = async () => {
        for (let { id } of apiKeysRefs.value) {
            getBalances(id);
        }
    };

    // getBalances
    const getBalances = async id => {
        // current key
        const index = apiKeysRefs.value.findIndex(el => el.id === id);

        apiKeysRefs.value[index].loading = true;
        apiKeysRefs.value[index].errors = null;

        const prepare = await WalletsService.getBalances(id);

        if (prepare?.data) {
            if (!prepare.data.status) {
                apiKeysRefs.value[index].errors = prepare.data?.errors[0].msg;
            } else {
                apiKeysRefs.value[index] = {
                    ...apiKeysRefs.value[index],
                    ...prepare.data,
                    allBalances: {},
                };

                Object.keys(apiKeysRefs.value[index].balances).forEach(item => {
                    if (!item.includes('_total') && !Object.prototype.hasOwnProperty.call(apiKeysRefs.value[index].allBalances, item)) {
                        apiKeysRefs.value[index].allBalances[item] = {
                            [item]: (+apiKeysRefs.value[index].balances[item])?.toFixed(8),
                            [item + '_total']: (+apiKeysRefs.value[index].balances[item + '_total'])?.toFixed(8),
                        };
                    }
                });
            }
        }

        // set hasBalances
        apiKeysRefs.value[index].hasBalances = true;

        // set percentage for loading-bar
        percentage.value += stepPercentage.value;

        apiKeysRefs.value[index].loading = false;
    };

    const getApiKeysStats = async () => {
        apiKeysStats.value = ( await ApiKeysService.stats() ).data;
    };

    const getTotalBalances = async () => {
        apiKeysStatsLoading.value = true;

        totalBalances.value = ( await WalletsService.getTotalBalances(
            {
                pager: {
                    filters: {
                        running: true,
                    },
                },
            },
        ) ).data;

        apiKeysStatsLoading.value = false;
    };

    const getData = async () => {
        getApiKeysStats();
        getTotalBalances();
        await getApiKeysList();

        getAllBalances();
    };

    // const setLoadingOnApi = () => {
        
    // };

    const update = async () => {
        // apiKeysRefs.value = null;
        balancesTotal.value = null;
        percentage.value = 0;

        apiKeysRefs.value.forEach(el => {
            el.loading = true;
        });

        getData();
    };

    const updateKey = async key => {
        key.loading = true;
        await getBalances(key.id);
        key.loading = false;
    };

    const addNewKey = async key => {
        if (!apiKeysStats.value?.total.total) {
            getData();
        } else {
            if (key.show_dashboard) {
                apiKeysRefs.value.unshift({
                    ...key,
                    loading: true,
                    canUpdate: false,
                    balances: {},
                    hasBalances: false,
                    error: null,
                });
        
                updateKey(apiKeysRefs.value[0]);
            }
        }
    };

    onMounted(async () => {
        getData();
    });

    return {
        gl,
        env,
        show,
        info,
        loading,
        canUpdate,
        dashBoard,
        totalInfo,
        percentage,
        apiKeysRefs,
        apiKeysStats,
        localLoading,
        balancesTotal,
        totalBalances,
        currentExchange,
        showCreateApiKey,
        apiKeysStatsLoading,
        update,
        exchange,
        currency,
        updateKey,
        addNewKey,
        getTotalBalances,
    };
}