import Api from './index';

export default class Marketplace {

    static async getTickerMessage(payload) {
        return await Api.getData({
            action: 'stats.getTickerMessage',
            payload,
        });
    };
}