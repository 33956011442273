// vue
import { ref, computed, watch } from 'vue';

// store
import { useRefs } from '@store/ts/refs';

export default function (props: any, context: any) {
    // store
    const refs = useRefs();

    // vars
    const { _ } = window;
    const show = ref(false);

    watch(() => props.visible, v => {
        if (v) {
            show.value = true;
        } else {
            context.emit('changeFilter', [props.data.filter, [ -1 ]]);
        }
    });

    const label = computed(() => {
        const string = props.data.title[props.data.title.length - 1] === ':'
            ? props.data.title.slice(0, props.data.title.length - 1)
            : props.data.title;

        return string.replace('<br>', ' '); 
    });

    const options = computed(() => [
        {
            id: -1,
            title:  refs.localization.filters.all_select.all,
        }, ...props.data.refs,
    ]);

    const current = computed(() => {
        return options.value.find(({ id }) => _.get(props.filters, props.data.filter)[0] == id);
    });

    const handleChange = ($event: any) => {
        const v = isNaN($event.target.value) ? $event.target.value : +$event.target.value;
        context.emit('changeFilter', [ props.data.filter, [ v ] ]);
    };

    return {
        refs,
        show,
        label,
        options,
        current,
        _,
        handleChange,
    };
}