<template>
<section class="pb-2">
    <div class="flex justify-between items-center text-xl text-left text-gray-700 dark:text-white/90 font-semibold">
        <n-skeleton v-if="!profile.localization" :width="160" :height="20" :sharp="false" />
        <template v-else>{{ profile.localization.profile_account_info_title }}</template>

        <n-button
            strong
            size="tiny"
            class="ml-2 rounded-md"
            type="tertiary"
            @click="update"
            :disabled="updateLoading"
            :loading="updateLoading">
            {{ refs.localization.autorefresh.autorefresh_btn }}
        </n-button>
    </div>
    <n-card
        size="small"
        :bordered="false"
        class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg mt-4"
        :segmented="{
            content: true,
            footer: 'soft'
        }">
        <template #default>
            <section class="flex justify-start mb-4">
                <n-tag v-if="gl.timeToShutdown?.timeToShutdown || gl.timeToShutdown?.timeToStop" type="error" size="small" round>
                    <span class="mr-1">{{ gl.timeToShutdown.status === 2 ? refs.localization.header.stop : refs.localization.header.suspend }}</span>
                    <strong @click="showModal = true" class="underline cursor-pointer hover:no-underline">
                        {{ gl.timeToShutdown.status === 2 ? gl.timeToShutdown?.timeToStop : gl.timeToShutdown?.timeToShutdown }}
                    </strong>
                </n-tag>
            </section>
            <section class="flex">
                <div class="text-left">
                    <n-skeleton v-if="!profile.localization" class="inline-block" :width="50" :height="14" :sharp="false" />
                    <div v-else class="text-gray-500 dark:text-white/40">{{ profile.localization?.profile_account_info_status }}</div>

                    <n-skeleton v-if="loading" class="inline-block mt-1" :width="90" :height="24" :sharp="false" />
                    <div v-else class="text-gray-600 dark:text-white/75 text-base font-semibold mt-1">{{ refs.status }}</div>
                </div>
                <div class="text-left ml-6">
                    <n-skeleton v-if="!profile.localization" class="inline-block" :width="30" :height="14" :sharp="false" />
                    <div v-else class="text-gray-500 dark:text-white/40">{{ profile.localization?.account_info_id }}</div>

                    <n-skeleton v-if="loading" class="inline-block mt-1" :width="20" :height="24" :sharp="false" />
                    <div v-else class="text-gray-600 dark:text-white/75 text-base font-semibold mt-1">{{ env.ID }}</div>
                </div>
                <div class="text-left ml-6">
                    <n-skeleton v-if="!profile.localization" class="inline-block" :width="120" :height="14" :sharp="false" />
                    <div v-else class="text-gray-500 dark:text-white/40">{{ profile.localization?.profile_account_info_balance }}</div>

                    <n-skeleton v-if="loading" class="inline-block mt-1" :width="130" :height="24" :sharp="false" />
                    <div v-else class="flex items-center text-base font-semibold" :class="gl.balance?.balance > 0 ? 'text-green-600' : 'text-red-500'">
                        {{ gl.balance?.balance }}
                        <rb-coin class="w-5 ml-2" :coin="currency" />
                    </div>
                </div>
                <div class="flex-grow flex items-center justify-end">
                    <n-skeleton v-if="!profile.localization" class="inline-block mr-4" :width="160" :height="34" :sharp="false" />
                    <n-button
                        v-else
                        strong
                        class="mr-4 rounded-md text-white/90"
                        :color="gl.mainColor"
                        :disabled="loading"
                        @click="showModal = true">
                        + {{ profile.localization?.profile_account_info_topup_balance_button }}
                    </n-button>

                    <n-skeleton v-if="!profile.localization" class="inline-block mr-2" :width="160" :height="34" :sharp="false" />
                    <n-button
                        v-else
                        secondary
                        type="default"
                        class="mr-2 rounded-md"
                        :disabled="loading"
                        @click="$emit('change-tab', 'account_balance_history')">
                        {{ profile.localization?.profile_account_info_topup_history_button }}
                    </n-button>
                </div>
            </section>
        </template>
        <template #footer>
            <section class="w-full flex justify-between">
                <section class="flex">
                    <n-skeleton v-if="!refs.localization" class="inline-block mr-2" :width="160" :height="34" :sharp="false" />
                    <n-button
                        v-else
                        secondary
                        size="tiny"
                        type="default"
                        class="mr-2 rounded-md"
                        @click="window.open('https://desk.revenuebot.io/en/%D0%B1%D0%B0%D0%B7%D0%B0-%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B9', '_blank')">
                        {{ refs.localization.header.knowledge_base }}
                    </n-button>

                    <n-skeleton v-if="!refs.localization" class="inline-block mr-2" :width="160" :height="34" :sharp="false" />
                    <n-button
                        v-else
                        secondary
                        size="tiny"
                        type="default"
                        class="mr-2 rounded-md"
                        @click="window.open('https://appt.link/consultation_from_support/revenuebot', '_blank')">
                        {{ refs.localization.header.free_consultations }}
                    </n-button>

                    <n-skeleton v-if="!refs.localization" class="inline-block mr-2" :width="160" :height="34" :sharp="false" />
                    <n-button
                        v-else
                        secondary
                        size="tiny"
                        type="default"
                        class="mr-2 rounded-md"
                        @click="window.open('https://www.youtube.com/channel/UCrAyFAxvDzPIwYKgdSDwCFg', '_blank')">
                        {{ refs.localization.header.educational_videos }}
                    </n-button>
                </section>
                <n-skeleton v-if="!refs.localization" class="inline-block mr-2" :width="160" :height="34" :sharp="false" />
                <n-button
                    v-else
                    secondary
                    size="tiny"
                    type="default"
                    class="rounded-md"
                    @click="window.open(refs.lang === 'ru'
                            ? 'https://t.me/revenuebot_user'
                            : 'https://t.me/revenuebot_users_en', '_blank')">
                    <template #icon>
                        <telegram-icon class="fill-[#1A6DE0]" />
                    </template>
                    UsersChat
                </n-button>
            </section>
        </template>
    </n-card>

    <div class="text-base text-left text-gray-600 dark:text-white/75 font-semibold mt-6">
        <n-skeleton v-if="!profile.localization" :width="160" :height="20" :sharp="false" />
        <template v-else>{{ t('comission') }}</template>
    </div>
    <section class="flex mt-4 items-stretch">
        <div class="w-3/12 pr-2">
            <n-card
                size="small"
                :bordered="false"
                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg h-full"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">

                <template #default>
                    <section class="flex">
                        <div class="text-left">
                            <n-skeleton v-if="loading" class="inline-block mt-1" :width="20" :height="26" :sharp="false" />
                            <div
                                v-else
                                class="text-gray-600 dark:text-white/75 text-2xl font-semibold">
                                {{ (profile.fullInfo.commission).split(' %')[0] }}
                                <span class="text-base dark:text-white/50 -ml-1">%</span>
                            </div>

                            <n-skeleton v-if="!profile.localization" class="inline-block mt-1" :width="30" :height="14" :sharp="false" />
                            <div v-else class="text-gray-500 dark:text-white/40 mt-1">{{ profile.localization?.profile_account_info_commission }}</div>
                        </div>
                    </section>
                </template>
                <template #footer>
                    <section class="flex items-center text-left">
                        <div class="text-gray-500 dark:text-white/40 mt-1">
                            {{ profile.localization?.profile_account_info_commission_total_month_label || 'В текущем месяце списано' }}:</div>
                        <div 
                            class="flex items-center text-base text-lightMain ml-2 whitespace-nowrap mt-1">
                            <span class="font-semibold">{{ profile.fullInfo.current_month_usd }}</span>
                            <rb-coin class="w-5 ml-2" :coin="getCurrency('usdt')" />
                        </div>
                    </section>
                </template>
            </n-card>
        </div>
        <div class="w-3/12 px-2">
            <n-card
                size="small"
                :bordered="false"
                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg h-full"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">

                <template #default>
                    <section class="flex">
                        <div class="text-left">
                            <n-skeleton v-if="loading" class="inline-block mt-1" :width="20" :height="26" :sharp="false" />
                            <div
                                v-else
                                class="text-gray-600 dark:text-white/75 text-2xl font-semibold">
                                {{ (profile.fullInfo.commission_f).split(' %')[0] }}
                                <span class="text-base dark:text-white/50 -ml-1">%</span>
                            </div>

                            <n-skeleton v-if="!profile.localization" class="inline-block mt-1" :width="30" :height="14" :sharp="false" />
                            <div v-else class="text-gray-500 dark:text-white/40 mt-1">{{ profile.localization?.profile_account_info_commission_f }}</div>
                        </div>
                    </section>
                </template>
                <template #footer>
                    <section class="flex items-center text-left">
                        <div class="text-gray-500 dark:text-white/40 mt-1">
                            {{ profile.localization?.profile_account_info_commission_total_month_label || 'В текущем месяце списано' }}:</div>
                        <div 
                            class="flex items-center text-base text-lightMain ml-2 whitespace-nowrap mt-1">
                            <span class="font-semibold">{{ profile.fullInfo.current_month_usd_f }}</span>
                            <rb-coin class="w-5 ml-2" :coin="getCurrency('usdt')" />
                        </div>
                    </section>
                </template>
            </n-card>
        </div>
        <div class="w-3/12 px-2">
            <n-card
                size="small"
                :bordered="false"
                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg h-full"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">

                <template #default>
                    <section class="flex">
                        <div class="text-left">
                            <n-skeleton v-if="loading" class="inline-block mt-1" :width="20" :height="26" :sharp="false" />
                            <div
                                v-else
                                class="text-gray-600 dark:text-white/75 text-2xl font-semibold">
                                {{ (profile.fullInfo.commission_fc).split(' %')[0] }}
                                <span class="text-base dark:text-white/50 -ml-1">%</span>
                            </div>

                            <n-skeleton v-if="!profile.localization" class="inline-block mt-1" :width="30" :height="14" :sharp="false" />
                            <div v-else class="text-gray-500 dark:text-white/40 mt-1">{{ profile.localization?.profile_account_info_commission_fc }}</div>
                        </div>
                    </section>
                </template>
                <template #footer>
                    <section class="flex items-center text-left">
                        <div class="text-gray-500 dark:text-white/40 mt-1">
                            {{ profile.localization?.profile_account_info_commission_total_month_label || 'В текущем месяце списано' }}:</div>
                        <div 
                            class="flex items-center text-base text-lightMain ml-2 whitespace-nowrap mt-1">
                            <span class="font-semibold">{{ profile.fullInfo.current_month_usd_fc }}</span>
                            <rb-coin class="w-5 ml-2" :coin="getCurrency('usdt')" />
                        </div>
                    </section>
                </template>
            </n-card>
        </div>
        <div class="w-3/12 pl-2">
            <n-card
                size="small"
                :bordered="false"
                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg h-full"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">

                <template #default>
                    <section class="flex">
                        <div class="text-left">
                            <n-skeleton v-if="loading" class="inline-block mt-1" :width="20" :height="26" :sharp="false" />
                            <div
                                v-else
                                class="text-gray-600 dark:text-white/75 text-2xl font-semibold">
                                {{ (profile.fullInfo.commission_m).split(' %')[0] }}
                                <span class="text-base dark:text-white/50 -ml-1">%</span>
                            </div>

                            <n-skeleton v-if="!profile.localization" class="inline-block mt-1" :width="30" :height="14" :sharp="false" />
                            <div v-else class="text-gray-500 dark:text-white/40 mt-1">{{ profile.localization?.profile_account_info_commission_m }}</div>
                        </div>
                    </section>
                </template>
            </n-card>
        </div>
    </section>
</section>

<topup-balance
    v-model:show="showModal" />
</template>

<script>
// vue
import { h, ref, watch, computed } from 'vue';

// store
import { useGl } from '@/store/gl';
import { useEnv } from '@/store/env';
import { useRefs } from '@/store/refs';
import { useApiKeys } from '@store/apiKeys';
import { useProfile } from '@store/profile';

// i18n
import { useI18n } from 'vue-i18n';

// components
import RbCoin from '@components/rb-coin';
import TopupBalance from '@components/topup-balance';
import TelegramIcon from '@components/icons/telegram.vue';

// services
import Api from '@services/api';

// icons
import {
    Star12Filled,
    Copy16Regular,
    Dismiss16Filled,
    IosArrowRtl24Filled } from '@vicons/fluent';

// naive-ui
import {
    NTag,
    NIcon,
    NCard,
    NModal,
    NAlert,
    NSelect,
    NButton,
    NCollapseTransition,
    useMessage } from 'naive-ui';

export default {
    name: 'account-info',
    components: {
        NTag,
        NIcon,
        NCard,
        NAlert,
        NModal,
        RbCoin,
        NButton,
        NSelect,
        TopupBalance,
        Star12Filled,
        TelegramIcon,
        Copy16Regular,
        Dismiss16Filled,
        NCollapseTransition,
        IosArrowRtl24Filled,
    },
    setup() {
        // store
        const gl = useGl();
        const env = useEnv();
        const refs = useRefs();
        const profile = useProfile();

        // ui
        const message = useMessage();

        // i18n
        const { t } = useI18n();

        // vars
        const show = ref(true);
        const showModal = ref(false);
        const selectionCurrency = ref();
        const updateLoading = ref(false);

        watch(showModal, () => {
            setTimeout(() => {
                selectionCurrency.value = null;
            }, 300);
        });

        const currency = computed(() => refs.currencies.find(el => el.id === env.profitSymbol));
        const topupSelectMethods = computed(() => {
            if (!Array.isArray(env.topupMethods)) return [];

            return env.topupMethods.map(cur => {
                const curEntry = refs.currencies.find(({ id }) => id === cur.currency);
                if (curEntry) {
                    cur.label = cur.title;
                    cur.value = cur.id;

                    return {...curEntry, ...cur};
                }
                return null;
            }).filter(cur => cur);
        });


        const renderLabel = option => {
            return h(
                'div',
                {
                    style: {
                        display: 'flex',
                        alignItems: 'center',
                    },
                },
                [
                    h(RbCoin, {
                        class: 'my-1',
                        coin: option,
                    }),
                    h(
                        'div',
                        {
                            style: {
                                marginLeft: '12px',
                                padding: '4px 0',
                            },
                        },
                        [
                            h('div', null, [option.label]),
                        ],
                    ),
                ],
            );
        };
        const setSelectionCurrency = e => {
            selectionCurrency.value = topupSelectMethods.value.find(({ id }) => id === e);
        };

        const copy = s => {
            navigator.clipboard.writeText(s);
            message.success(t('copied'));
        };

        const update = async () => {
            updateLoading.value = true;

            await Api.getEnv();

            updateLoading.value = false;
        };

        const getCurrency = icon => refs.currencies.find(el => el.id === icon);

        return {
            gl,
            env,
            refs,
            show,
            window,
            profile,
            currency,
            showModal,
            updateLoading,
            selectionCurrency,
            t,
            copy,
            update,
            getCurrency,
            renderLabel,
            topupSelectMethods,
            setSelectionCurrency,
        };
    },
};
</script>