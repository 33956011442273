<template>
<section class="bg-main sticky bottom-0 rounded-t-lg mt-4 z-[2000] flex items-center justify-between py-3 px-1">
    <router-link
        v-for="(item, i) in items"
        :key="i"  
        :to="item.link"
        class="w-[12.5%] flex justify-center items-center">
            <div class="flex flex-col items-center h-full justify-start">
                <component
                    class="fill-[#ffffff]"
                    :class="`${routeNamePath?.includes(item.name) ? '' : 'opacity-50'} ${item.class}`"
                    :is="item.icon">
                </component>
                <!-- <div class="text-[8px]">{{ item.title }}</div> -->
            </div>
    </router-link>
</section>
</template>

<script>
// vue
import { ref, computed } from 'vue';

// store
import { useGl } from '@/store/gl';
import { useRefs } from '@store/ts/refs';

// i18n
import { useI18n } from 'vue-i18n';

// naive-ui
import { NPopover, NIcon, NSwitch, NSkeleton, NDivider } from 'naive-ui';

// router
import { useRoute } from 'vue-router';

// icons
import {
    WeatherMoon28Regular,
    WeatherSunny32Regular,
    ArrowCircleRight20Regular,
    ArrowCircleLeft20Regular } from '@vicons/fluent';

// storage
import LocalStorage from '@/services/LocalStorage';

// components
import BotsIcon from '@components/icons/bots.vue';
import HomeIcon from '@components/icons/home.vue';
import ApiKeysIcon from '@components/icons/api-keys.vue';
import WalletsIcon from '@components/icons/wallets.vue';
import ToolsIcon from '@components/icons/tools.vue';
import MarketplaceIcon from '@components/icons/marketplace.vue';
import QuickStartIcon from '@components/icons/quick-start.vue';
import TradeStatisticIcon from '@components/icons/trade-statistic.vue';

export default {
    name: 'left-panel',
    components: {
        NIcon,
        NSwitch,
        NDivider,
        NPopover,
        NSkeleton,
        WeatherMoon28Regular,
        WeatherSunny32Regular,
        ArrowCircleLeft20Regular,
        ArrowCircleRight20Regular,
    },
    setup() {
        // store
        const refs = useRefs();

        const fullWidth = ref(false);
        const route = useRoute();
        const gl = useGl();
        const theme = ref(false);
        const { t } = useI18n();

        const railStyle = ({
            focused,
            checked,
        }) => {
            const style = {};
            if (checked) {
                style.background = '#2B2C39';
                if (focused) {
                    style.boxShadow = '0';
                }
            } else {
                style.background = '#fff';
                if (focused) {
                    style.boxShadow = '0';
                }
            }
            return style;
        };

        const routeNamePath = computed(() => route.name?.split('.'));

        const items = computed(() => {
            return [
                {
                    icon: HomeIcon,
                    title: refs.localization.sidebar.dashboard,
                    active: routeNamePath.value?.includes('home'),
                    link: '/',
                    name: 'home',
                    class: 'w-4',
                }, {
                    icon: MarketplaceIcon,
                    title: refs.localization.sidebar.marketplace,
                    active: routeNamePath.value?.includes('marketplace'),
                    link: '/marketplace/buy',
                    name: 'marketplace',
                    class: 'w-4',
                }, {
                    icon: QuickStartIcon,
                    title: refs.localization.sidebar.quickstart,
                    active: routeNamePath.value?.includes('quick-start'),
                    link: '/quick-start',
                    name: 'quick-start',
                    class: 'w-5',
                }, {
                    icon: BotsIcon,
                    title: refs.localization.sidebar.bots,
                    active: routeNamePath.value?.includes('bots'),
                    link: '/bots',
                    name: 'bots',
                    class: 'w-4',
                }, {
                    icon: TradeStatisticIcon,
                    title: refs.localization.sidebar.trade_statistic,
                    active: routeNamePath.value?.includes('trade-statistic'),
                    link: '/trade-statistic',
                    name: 'trade-statistic',
                    class: 'w-4',
                }, {
                    icon: WalletsIcon,
                    title: refs.localization.sidebar.wallets,
                    active: routeNamePath.value?.includes('wallets'),
                    link: '/wallets',
                    name: 'wallets',
                    class: 'w-4',
                }, {
                    icon: ApiKeysIcon,
                    title: refs.localization.sidebar.api_keys,
                    active: routeNamePath.value?.includes('api-keys'),
                    link: '/api-keys',
                    name: 'api-keys',
                    class: 'w-4',
                }, {
                    icon: ToolsIcon,
                    title: refs.localization.sidebar.tools,
                    active: routeNamePath.value?.includes('tools'),
                    link: '/tools',
                    name: 'tools',
                    class: 'w-4',
                },
            ];});

        const changeTheme = () => {
            if (!~[...document.documentElement.classList].indexOf('dark')) {
                LocalStorage.setItem('darkTheme', true);
                gl.darkTheme = true;
            } else {
                LocalStorage.setItem('darkTheme', false);
                gl.darkTheme = false;
            }

            document.documentElement.classList.toggle('dark');
        };

        const bgStyle = (v, isMobile = false) => {
            if (routeNamePath.value?.includes(v))
                return gl.darkTheme
                    ? !isMobile ? 'bg-[#2B2C39] ' : ''
                    : !isMobile ? 'bg-[#f2f3f4] ' : '';
        };

        return {
            t,
            gl,
            theme,
            items,
            fullWidth,
            routeNamePath,
            overlap: ref(false),
            bgStyle,
            railStyle,
            changeTheme,
        };
    },
};
</script>