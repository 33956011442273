<template>
<div
    v-if="isFooter"
    class="h-full flex justify-center items-center text-main text-md dark:bg-darkCardBg bg-gray-50 font-semibold relative overflow-hidden">
    {{ dataFooter }} <div class="absolute w-full top-0 left-0 h-[2px] bg-main shadow-xl"></div>
</div>
<div
    v-else
    class="h-full flex flex-col justify-center items-center px-2"
    :class="classes">
    <n-icon
        v-if="row.error"
        class="text-red-500 cursor-pointer text-3xl"
        @click="show = !show">
        <Warning20Regular />
    </n-icon>
    <base-modal-desktop
        v-model:show="show">
        <template #default>
            <div class="text-gray-600 dark:text-white/75" v-html="row.exit_error"></div>
        </template>
    </base-modal-desktop>
</div>
</template>

<script>
// vue
import { ref } from 'vue';

// icons
import { Warning20Regular } from '@vicons/fluent';

// naive-ui
import { NIcon } from 'naive-ui';

export default {
    name: 'cell-error',
    props: {
        isFooter: {
            type: Boolean,
            default: false,
            required: true,
        },
        dataFooter: {
            default: '',
        },
        row: {
            type: Object,
            required: true,
        },
        classes: {
            type: String,
        },
    },
    components: {
        NIcon,
        Warning20Regular,
    },
    setup(props) {
        return {
            show: ref(false),
        };
    },
};
</script>