export function formatUnexistedCurrsAsUnknown(Refs, currs) {
    const refCurrs = Refs?.currencies || {};

    const formattedCurs = currs.map((curr, i) => {
        const lCurr = String(curr).trim();
        let theCurr = refCurrs.find(({ id }) => id === curr);
        
        if (!~theCurr) {
            const currLower = lCurr.toLowerCase();
            const currUpper = lCurr.toUpperCase();
            theCurr = {
                id: currLower,
                symbol: currUpper,
                title: `${currUpper} (${currUpper})`,
                status: 1,
                code_name: 'rb-cc--unknown',
                __idx: i,
            };
        }
		
        return theCurr;
    }).filter(c => c);
	
    return formattedCurs;
};

export function formatUnexistedCurrsAsUnknownObject(Refs, currs) {
    const currMap = {};
    formatUnexistedCurrsAsUnknown(Refs, currs)
        .forEach(curr => currMap[curr.id] = curr);
    return currMap;
};

export const getNavigatorLanguage = () => {
    if (navigator.languages && navigator.languages.length) {
        return navigator.languages[0];
    } else {
        return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
    }
};

function enqueScripts(sources) {
    let promise = new Promise((resolve, reject) => {
        function enqueScript() {
            let src = sources.shift() || '';
            if (!src) resolve();
			
            let script = document.createElement('script');
            script.setAttribute('src', `//${src}`);
            script.onload = enqueScript;
            document.head.appendChild(script);
        }
		
        enqueScript();
    });
	
    return promise;
}

export const localeDateOpts = {
    hourCycle: 'h23',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
};

export default {
    importJs(sources) {
        return enqueScripts([...sources]);
    },
};