
// vue
import { defineComponent } from 'vue';

// naive-ui
import {
    NIcon,
    NCard,
    NButton,
    NDivider } from 'naive-ui';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

// general
import general from './general';

// icons
import {
    ArrowLeft12Regular,
    ArrowRight12Regular } from '@vicons/fluent';

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, Autoplay, A11y } from 'swiper';

// install Swiper components
SwiperCore.use([Pagination, Navigation, A11y, Autoplay]);

// components
import RbSwitch from '@components/rb-switch/index.vue';
import BotReview from '@components/bot-review/index.vue';

export default defineComponent({
    name: 'ready-bots-desktop',
    components: {
        NCard,
        NIcon,
        Swiper,
        NButton,
        NDivider,
        RbSwitch,
        BotReview,
        SwiperSlide,
        ArrowLeft12Regular,
        ArrowRight12Regular,
    },
    setup() {
      
        return {
            ...general(),
        };
    },
});
