<template>
<n-checkbox
    class="flex rounded-md"
    :size="size"
    :checked="value"
    @update:checked="$emit('update:checked', $event)">
    <div class="flex items-center">
        <div class="text-md text-left" :class="[status === 'error' ? 'text-red-500' : '', nowrap ? 'whitespace-nowrap' : '']">{{ label }}</div>
        <n-popover
            v-if="help"
            scrollable
            trigger="hover" placement="top"
            class="rounded-md max-w-[500px] max-h-[300px]"
            :class="popoverType === 'info' ? 'bg-main' : 'bg-yellow-600'">
            <template #trigger>
                <n-icon
                    class="cursor-pointer text-lg mx-2"
                    :class="popoverType === 'info' ? 'text-main' : 'text-yellow-600'">
                    <QuestionCircle20Filled />
                </n-icon>
            </template>
            <span
                class="notes text-xs text-white"
                v-html="help">
            </span>
        </n-popover>
        <n-popover
            v-if="helpWarning"
            scrollable
            trigger="hover"
            class="max-w-[500px] max-h-[300px] rounded-md bg-yellow-600"
            placement="top">
            <template #trigger>
                <n-icon
                    class="cursor-pointer text-lg ml-2 text-yellow-600">
                    <Warning24Filled />
                </n-icon>
            </template>
            <span class="notes text-xs text-white" v-html="helpWarning"></span>
        </n-popover>
    </div>
</n-checkbox>
</template>

<script>
// naive-ui
import {
    NIcon,
    NPopover,
    NCheckbox } from 'naive-ui';

// icons
import { Warning24Filled, QuestionCircle20Filled } from '@vicons/fluent';

export default {
    name: 'rb-checkbox',
    emits: ['update:checked'],
    props: {
        size: {
            type: String,
            default: 'medium',
            validator: prop => ['tiny', 'small', 'medium', 'large'].includes(prop), 
        },
        value: {
            required: true,
        },
        label: {
            type: String,
        },
        help: {
            type: String,
        },
        helpWarning: {
            type: String,
        },
        status: {
            type: String,
            default: undefined,
        },
        msg: {
            type: String,
        },
        nowrap: {
            type: Boolean,
            default: true,
        },
        popoverType: {
            type: String,
            default: 'info',
            validator: prop => ['info', 'warning'].includes(prop), 
        },
    },
    components: {
        NIcon,
        NPopover,
        NCheckbox,
        Warning24Filled,
        QuestionCircle20Filled,
    },
    setup() {
    // 
    },
};
</script>

<style lang="scss" scoped>
:deep(.n-checkbox-box) {
    border-radius: 4px;
}
</style>