<template>
<rb-loader v-if="loading" class="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2" />
<section class="pb-2">
    <n-card
        size="small"
        :bordered="false"
        class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-md"
        :segmented="{
            content: true,
            footer: 'soft'
        }">
        <template #default>
            <div class="flex flex-wrap">
                <div
                    v-for="(notification, i) in profile.fullInfo.notifications"
                    :key="i + notification.name"
                    class="w-full mb-4 flex justify-start flex-wrap">
                    <div class="w-full text-xs whitespace-nowrap text-left text-gray-600 dark:text-white/75">{{ notification.title }}</div>

                    <n-button-group class="mt-2">
                        <template v-for="(item, i) in notification.items" :key="i">
                            <n-button
                                strong
                                secondary
                                size="small"
                                class="first:rounded-l-md last:rounded-r-md flex items-center"
                                @click="updateNotificationsValues(profile.fullInfo.notifications, { name: notification.name, id: item.id, reset: false })" >
                                <n-icon size="20" v-if="~notification.values.indexOf(item.id)" class="text-main mr-1"><Checkmark12Filled /></n-icon>
                                <span :class="~notification.values.indexOf(item.id) ? 'text-main' : 'text-gray-600 dark:text-white/75'">{{ item.title }}</span>
                            </n-button>
                        </template>
                        
                        <n-button
                            strong
                            secondary
                            size="small"
                            type="error"
                            class="first:rounded-l-md last:rounded-r-md flex items-center"
                            @click="updateNotificationsValues(profile.fullInfo.notifications, { name: notification.name, id: null, reset: true })" >
                            <n-icon size="20" v-if="!notification.values.length" class="text-red-500 mr-1"><Checkmark12Filled /></n-icon>
                            <span class="text-red-500">off</span>
                        </n-button>
                    </n-button-group>
                </div>
                <n-divider />

                <div v-if="profile.fullInfo.notification_reports">
                    <div>
                        <div class="text-md text-gray-600 dark:text-white/75 font-semibold">
                            {{ profile.fullInfo.notification_reports.notify_with.title }}
                        </div>
                        <n-button-group class="mt-2">
                            <template v-for="(item, i) in profile.fullInfo.notification_reports.notify_with.items" :key="i">
                                <n-button
                                    strong
                                    secondary
                                    size="small"
                                    class="first:rounded-l-md last:rounded-r-md flex items-center"
                                    @click="updateNotificationsValues2(profile.fullInfo.notification_reports.notify_with, { name: profile.fullInfo.notification_reports.notify_with.name, id: item.id, reset: false })" >
                                    <n-icon size="20" v-if="~profile.fullInfo.notification_reports.notify_with.values.indexOf(item.id)" class="text-main mr-1"><Checkmark12Filled /></n-icon>
                                    <span :class="~profile.fullInfo.notification_reports.notify_with.values.indexOf(item.id) ? 'text-main' : 'text-gray-600 dark:text-white/75'">{{ item.title }}</span>
                                </n-button>
                            </template>
                            
                            <n-button
                                strong
                                secondary
                                size="small"
                                type="error"
                                class="first:rounded-l-md last:rounded-r-md flex items-center"
                                @click="updateNotificationsValues2(profile.fullInfo.notification_reports.notify_with, { name: profile.fullInfo.notification_reports.notify_with.name, id: null, reset: true })" >
                                <n-icon size="20" v-if="!profile.fullInfo.notification_reports.notify_with.values.length" class="text-red-500 mr-1"><Checkmark12Filled /></n-icon>
                                <span class="text-red-500">off</span>
                            </n-button>
                        </n-button-group>
                    </div>

                    <n-button-group class="mt-2">
                        <template v-for="(item, i) in profile.fullInfo.notification_reports.period.items" :key="i">
                            <n-button
                                strong
                                secondary
                                size="small"
                                class="first:rounded-l-md last:rounded-r-md flex items-center"
                                :disabled="profile.fullInfo.notification_reports.notify_with.values.length == 0"
                                :color="~profile.fullInfo.notification_reports.period.values.indexOf(item.id) ? gl.mainColor : ''"
                                @click="updateNotificationsValues2(profile.fullInfo.notification_reports.period, { name: profile.fullInfo.notification_reports.period.name, id: item.id, reset: false })">
                                <n-icon size="20" v-if="~profile.fullInfo.notification_reports.period.values.indexOf(item.id)" class="text-main mr-1"><Checkmark12Filled /></n-icon>
                                <span :class="~profile.fullInfo.notification_reports.period.values.indexOf(item.id) ? 'text-main' : 'text-gray-600 dark:text-white/75'">{{ item.title }}</span>
                            </n-button>
                        </template>
                    </n-button-group>
                </div>
                <n-divider />

                <rb-select
                    class="pr-2 w-full"
                    :label="profile.localization['profile_notification_auto_remove_web_notifications_f']"
                    :options="notificationsAutoremoveListOptions"
                    v-model:value="profile.fullInfo.notifications_autoremove_value" />

                <rb-switch
                    class="mt-4"
                    :label="profile.localization['profile_notification_telegram_notifications']"
                    v-model:value="profile.fullInfo.tg_enabled"
                    @update:value="onTgNotificationChanged" />
            </div>
        </template>
    </n-card>
    <base-modal
        :width="400"
        v-model:show="showModal">
        <div class="notes" v-html="profile.fullInfo.tg_enable_message"></div>
        <template #footer>
            <div class="flex justify-end">
                <n-button
                    strong
                    class="rounded-md text-white/90"
                    :color="gl.mainColor"
                    @click="onToggleTgNotifications(profile.fullInfo.tg_enabled);">
                    {{ refs.localization.dialogs.ok }}
                </n-button>
            </div>
        </template>
    </base-modal>
</section>
<n-button
    strong
    size="large"
    class="rounded-md text-white/90 sticky bottom-0 w-full"
    :disabled="loading"
    :loading="loading"
    :color="gl.mainColor"
    @click="onUpdate">
    {{ profile.localization['profile_save_changes_button'] }}
</n-button>

<current-password
    :loading="loading"
    v-model:show="showPassModal"
    @confirm="onUpdate" />
</template>

<script>
// vue
import { ref, computed } from 'vue';

// store
import { useGl } from '@/store/gl';
import { useEnv } from '@/store/env';
import { useRefs } from '@/store/refs';
import { useProfile } from '@store/profile';

// i18n
import { useI18n } from 'vue-i18n';

// icons
import { Checkmark12Filled, Dismiss16Filled, IosArrowRtl24Filled } from '@vicons/fluent';

// services
import ProfileService from '@services/profileService';

// components
import RbInput from '@components/rb-input/mobile.vue';
import RbSelect from '@components/rb-select/mobile.vue';
import RbSwitch from '@components/rb-switch/mobile.vue';
import BaseModal from '@components/base-modal/mobile.vue';
import RbCheckboxGroup from '@components/rb-checkbox-group';
import CurrentPassword from '@components/current-password/mobile.vue';

// naive-ui
import {
    NIcon,
    NCard,
    NAlert,
    NButton,
    NDivider,
    NButtonGroup,
    useNotification,
    NCollapseTransition,
    useMessage } from 'naive-ui';

export default {
    name: 'notifications-mobile',
    components: {
        NIcon,
        NCard,
        NAlert,
        NButton,
        RbInput,
        RbSelect,
        NDivider,
        RbSwitch,
        BaseModal,
        NButtonGroup,
        RbCheckboxGroup,
        CurrentPassword,
        Dismiss16Filled,
        Checkmark12Filled,
        IosArrowRtl24Filled,
        NCollapseTransition,
    },
    setup() {
        // store
        const gl = useGl();
        const env = useEnv();
        const refs = useRefs();
        const profile = useProfile();

        // ui
        const message = useMessage();
        const notification = useNotification();

        // i18n
        const { t } = useI18n();

        // vars
        const loading = ref(false);
        const show = ref(false);
        const showModal = ref(false);
        const showPassModal = ref(false);

        const notificationsAutoremoveListOptions = computed(() => profile.notificationsAutoremoveList.map(el => ({
            label: el.title,
            value: +el.id,
            ...el,
        })));

        const updateNotificationsValues = ( arr, $event ) => {
            const notif = arr.find(({ name }) => name === $event.name);

            if (!notif) return;

            if ($event.reset) {
                notif.values.splice(0);
            } else {
                const i = notif.values.indexOf($event.id);

                !~i
                    ? notif.values.push($event.id)
                    : notif.values.splice(i, 1);
            }
        };

        const updateNotificationsValues2 = ( arr, $event ) => {
            if ($event.reset) {
                arr.values.splice(0);
            } else {
                const i = arr.values.indexOf($event.id);

                !~i
                    ? arr.values.push($event.id)
                    : arr.values.splice(i, 1);
            }
        };

        const getFullInfo = async (showLoading = true) => {
            if (showLoading)
                loading.value = true;

            try {
                profile.fullInfo = ( await ProfileService.getFullInfo() ).data;
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            };
            
            if (showLoading)
                loading.value = false;
        };

        const onToggleTgNotifications = async enable => {
            loading.value = true;

            try {
                const { data, postMessages } = await ProfileService.toggleTgNotifications({
                    enable,
                });

                if (postMessages)
                    postMessages.forEach(el => {
                        notification[el.success ? 'success' : 'error']({
                            content: el.msg,
                            duration: 2500,
                            keepAliveOnHover: true,
                        });
                    });

                if (data.status) {
                    await getFullInfo(false);
                    showModal.value = false;
                }
                    
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            };
            
            loading.value = false;
        };

        const onTgNotificationChanged = $event => {
            if ($event) {
                showModal.value = true;
            } else {
                onToggleTgNotifications($event);
            }
        };

        const onUpdate = async () => {
            loading.value = true;

            if (!profile.fullInfo.current_password || profile.errors.current_password ) {
                showPassModal.value = true;
                loading.value = false;
                return;
            }
        
            let payload = { ...profile.fullInfo, _formPath: 'profile.update' };
            payload.messengers = payload.messengers.map((messenger, index) => ({...messenger, _formPath: `messenger#${index}`}));

        
            const data = await ProfileService.update({ profile: payload, ...gl.stageExtra?.extra});
            if (!data) {
                showPassModal.value = false;
            } else {
                if (!data.data.status) {
                    profile.errors = data.data.errors_form['profile.update']?.fields;

                    if (!Object.keys(profile.errors).includes('current_password')) {
                        showPassModal.value = false;

                        // show messages
                        if (data.postMessages)
                            data.postMessages.forEach(el => {
                                if (el.success)
                                    notification.success({
                                        content: el.msg,
                                        duration: 2500,
                                        keepAliveOnHover: true,
                                    });
                            });
                    }
                } else {
                    profile.errors = {};
                    showPassModal.value = false;

                    for (const [ key, value ] of Object.entries(data.data)) {
                        profile.fullInfo[key] = value;
                    }

                    // show messages
                    if (data.postMessages)
                        data.postMessages.forEach(el => {
                            notification[el.success ? 'success' : 'error']({
                                content: el.msg,
                                duration: 2500,
                                keepAliveOnHover: true,
                            });
                        });
                }
            }
            loading.value = false;
        };

        return {
            gl,
            env,
            refs,
            show,
            loading,
            profile,
            showModal,
            showPassModal,
            notificationsAutoremoveListOptions,
            onUpdate,
            onTgNotificationChanged,
            onToggleTgNotifications,
            updateNotificationsValues,
            updateNotificationsValues2,
        };
    },
};
</script>