<template>
<n-drawer
    :show="show"
    width="100%"
    class="bg-gray-100 dark:bg-darkbg"
    @update:show="$emit('update:show', $event)">
    <n-drawer-content body-content-style="padding: 0 12px;">
        <div class="flex flex-col h-full">
            <div class="relative w-full bg-gray-100 dark:bg-darkbg text-sm text-center text-gray-600 dark:text-white/75 py-4">
                <n-icon
                    size="20"
                    class="text-gray-600 dark:text-white/75 absolute top-1/2 left-0 -translate-y-1/2"
                    @click="$emit('update:show', false)">
                    <ArrowLeft12Regular />
                </n-icon>
                {{ profile.localization['profile_password_current_password_i'] }}
            </div>
            <div class="pb-4 flex-grow overflow-y-scroll overscroll-none scrollbar-hide">
                <section class="my-3">
                    <rb-input
                        type="password"
                        size="large"
                        :status="profile.errors?.current_password ? 'error' : undefined"
                        :msg="profile.errors?.current_password?.msg"
                        :placeholder="profile.localization['profile_password_current_password_i']"
                        v-model:value="profile.fullInfo.current_password"
                        @update:value="profile.cleanError('current_password')" />
                </section>
                <n-button
                    strong
                    size="large"
                    class="rounded-md text-white/90 sticky bottom-0 w-full"
                    :color="gl.mainColor"
                    :disabled="!profile.fullInfo.current_password || loading"
                    :loading="loading"
                    @click="$emit('confirm')">
                    {{ profile.localization['profile_save_changes_button'] }}
                </n-button>
            </div>
        </div>
    </n-drawer-content>
</n-drawer>
</template>

<script>
// vue
import { ref, computed, watch, onMounted } from 'vue';

// services
import ProfileService from '@services/profileService';

// components
import RbInput from '@components/rb-input/mobile.vue';

// store
import { useGl } from '@store/ts/gl';
import { useEnv } from '@store/ts/env';
import { useRefs } from '@store/ts/refs';
import { useProfile } from '@store/profile';

// naive-ui
import {
    NIcon,
    NCard,
    NButton,
    NDrawer,
    NPopover,
    NSkeleton,
    NScrollbar,
    NDrawerContent,
    useMessage } from 'naive-ui';

// i18n
import { useI18n } from 'vue-i18n';

// router
import { useRouter } from 'vue-router';

// icons
import {
    Alert24Regular,
    Person16Regular,
    Password24Regular,
    PersonInfo16Filled,
    CaretDown12Filled,
    ArrowLeft12Regular,
    ArrowRight12Regular,
    MoneyHand24Regular,
    MoneyCalculator24Regular,
    ContentSettings24Regular,
    PeopleCommunityAdd20Regular,
    TabInprivateAccount24Regular }  from '@vicons/fluent';

export default {
    name: 'current=password-mobile',
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
    },
    components: {
        NIcon,
        NButton,
        NDrawer,
        RbInput,
        NDrawerContent,
        ArrowLeft12Regular,
    },
    setup() {
        // store
        const gl = useGl();
        const env = useEnv();
        const profile = useProfile();

        return {
            gl,
            profile,
        };
    },
};
</script>