<template>
<div v-if="visible" class="flex">
    <n-popover
        trigger="click"
        class="p-0 rounded-md"
        display-directive="show"
        style="height: 400px; max-width: 400px;"
        placement="bottom-start"
        v-model:show="show">
        <template #trigger>
            <n-button
                class="rounded-md flex-grow"
                @click="show = true">
                <section class="w-full flex justify-between items-center">
                    <div class="mr-4">{{ label }}</div>
                    <div class="flex justify-end items-center">
                        <span class="text-main mr-2">{{ checkeds || '' }}</span>
                        <n-icon size="14"><ArrowRight12Regular /></n-icon>
                    </div>
                </section>
            </n-button>
        </template>
        <template #default>
            <section class="bg-white dark:bg-darkCardBg overflow-hidden p-0 flex flex-col h-full">
                <div class="px-3 mt-2">
                    <rb-input
                        :placeholder="refs.localization.filters.master_slave.search"
                        v-model:value="searchFilter" />
                </div>
                <div class="flex-grow pb-2 overflow-y-auto">
                    <n-spin v-if="!options.length" class="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2" size="small" />
                    <div
                        v-else
                        v-for="(item, i) in options"
                        :key="i"
                        v-show="item.show"
                        class="dark:odd:bg-[#242424] odd:bg-gray-50 rounded-none cursor-pointer">
                        <div
                            class="w-full flex items-center px-3 py-2"
                            @click="item.checked =! item.checked">
                            <rb-checkbox :value="item.checked" />
                            <rb-coin class="w-6" :coin="item" />
                            <div class="text-gray-600 dark:text-white/75 ml-2">{{ item.title }}</div>
                        </div>
                    </div>
                </div>
                <div v-if="options.length" class="w-full flex justify-end items-center py-2 px-3">
                    <n-button
                        secondary
                        quaternary
                        type="default"
                        class="rounded-md flex items-center text-main"
                        @click="show = false">
                        {{ refs.localization.filters.general.filters_done_label }}
                    </n-button>
                </div>
            </section>
        </template>
    </n-popover>

    <n-button
        secondary
        type="default"
        class="px-2 ml-1 rounded-md"
        @click="$emit('setVisibleFilters', data.filter)">
        <n-icon size="24" class="text-red-500">
            <DeleteDismiss24Regular />
        </n-icon>
    </n-button>
</div>
</template>

<script>
// vue
import { defineComponent } from 'vue';

// general
import general from './general';

// naive-ui
import {
    NSpin,
    NIcon,
    NRadio,
    NButton,
    NDrawer,
    NPopover,
    NDrawerContent } from 'naive-ui';

// icons
import {
    ArrowLeft12Regular,
    ArrowRight12Regular,
    DeleteDismiss24Regular } from '@vicons/fluent';

// components
import RbCoin from '@components/rb-coin';
import RbInput from '@components/rb-input';
import RbCheckbox from '@components/rb-checkbox';

export default defineComponent({
    name: 'filter-coins-desktop',
    props: {
        data: {
            type: Object,
            required: true,
        },
        filters: {
            type: Object,
            required: true,
        },
        drawerId: {
            type: String,
            required: true,
        },
        visible: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        NSpin,
        NIcon,
        RbCoin,
        NRadio,
        NDrawer,
        NButton,
        RbInput,
        NPopover,
        RbCheckbox,
        NDrawerContent,
        ArrowLeft12Regular,
        ArrowRight12Regular,
        DeleteDismiss24Regular,
    },
    setup(props, context) {
        
        return {
            ...general(props, context),
        };
    },
});
</script>