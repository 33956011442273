import { defineStore } from 'pinia';

export const useWallets = defineStore('wallets', {
    state: () => ({
        refs: null,
        wallets: null,
        errorsForm: {},
    }),
    getters: {
        localization(state) {
            return state.refs?.localization[Object.keys(state.refs.localization)[0]];
        },
        mentorshipRequestStates(state) {
            return state.refs.help;
        },
        pager(state) {
            return state.wallets.pager;
        },
        columns(state) {
            return state.wallets.columns;
        },
        records(state) {
            return state.wallets.records;
        },
    },
});