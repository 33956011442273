<template>
<apikeys-create
    v-if="init"
    :data="apiKeyInfo"
    @apiKeysGet="apiKeysGet"
    @clear="apiKeyInfo = null"
    @updateApiKey="updateApiKey" />

<super-table
    v-if="init"
    showSearch
    :filters="filters"
    :refFilters="refFilters"
    :actions="actions"
    :pager="apiKeys.apiKeys.pager"
    :columns="columns"
    :records="records"
    :filtersChanged="filtersChanged"
    type="apiKeys"
    columnsStorageName="__columns_api_keys"
    @getData="apiKeysGet"
    @sortColumn="sortColumn"
    @doSomething="doSomething"
    @reset-filters="resetFilters"
    @applyFilters="changeFilter">
    <template #tableTitle>
        <div class="text-base text-left mb-2 text-gray-600 dark:text-white/75">
            {{ apiKeys.localization['apikeys_all_keys_title'] }}
        </div>
    </template>
    <template #actionButtons>
        <section v-if="apiKeys.records.length" class="flex">
            <n-button
                class="text-white/90 rounded-md"
                :color="gl.mainColor"
                :disabled="!actionButtonsDisabled"
                @click="apiKeysDelete(true)">
                {{ apiKeys.localization['apikeys_all_keys_table_mass_delete_button'] }}
            </n-button>
        </section>
    </template>
</super-table>
<base-dialog-desktop
    v-model:show="showModalDelete"
    @positive-click="apiKeysDelete"
    @negative-click="showModalDelete = false">
    <div class="text-md" v-html="refs.localization.confirmations.apikey.delete"></div>
</base-dialog-desktop>
</template>

<script>
// general
import general from './general';

// components
import SuperTable from '@components/super-table/desktop.vue';
import ApikeysCreate from '@components/apikeys-create/index.vue';

// UI
import {
    NCard,
    NTabs,
    NButton,
    NTabPane } from 'naive-ui';

export default {
    name: 'ApiKeys',
    components: {
        NCard,
        NTabs,
        NButton,
        NTabPane,
        SuperTable,
        ApikeysCreate,
    },
    setup(props, context) {
        
        return {
            ...general(arguments),
        };
    },
};
</script>