<template>
<section class="pb-2">
    <div class="text-xl text-left text-gray-700 dark:text-white/90 font-semibold">
        <n-skeleton v-if="!profile.localization" :width="160" :height="20" :sharp="false" />
        <template v-else>{{ profile.localization.profile_referral_info_title }}</template>
    </div>
    <section class="flex mt-4 items-stretch">
        <div class="w-[20%] pr-2">
            <n-card
                size="small"
                :bordered="false"
                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg h-full"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <template #default>
                    <section class="flex flex-col justify-between h-full text-left">
                        <div
                            class="text-main text-2xl font-semibold">
                            {{ fullInfo?.info.affiliate_commission }}
                            <span class="text-base dark:text-white/50 -ml-1">%</span>
                        </div>

                        <div class="text-gray-500 dark:text-white/40 mt-1">
                            {{ profile.localization['profile_referral_info_commission'].slice(0, -1) }}
                        </div>
                    </section>
                </template>
            </n-card>
        </div>
        <div class="w-[20%] px-2">
            <n-card
                size="small"
                :bordered="false"
                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg h-full"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <template #default>
                    <section class="flex flex-col justify-between h-full text-left">
                        <div
                            class="text-main text-2xl font-semibold">
                            {{ fullInfo?.info.total_referrals }}
                        </div>

                        <div class="text-gray-500 dark:text-white/40 mt-1">
                            {{ profile.localization['profile_referral_info_users_referred'].slice(0, -1) }}
                        </div>
                    </section>
                </template>
            </n-card>
        </div>
        <div class="w-[20%] px-2">
            <n-card
                size="small"
                :bordered="false"
                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg h-full"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <template #default>
                    <section class="flex flex-col justify-between h-full text-left">
                        <div
                            class="flex items-center text-green-600 text-xl font-semibold">
                            {{ fullInfo?.info.total_profit }}
                            <rb-coin class="w-5 ml-2" :coin="currency(env.profitSymbol)" />
                        </div>

                        <div class="text-gray-500 dark:text-white/40 mt-1">{{ profile.localization['profile_referral_info_total_profit'].slice(0, -1) }}</div>
                    </section>
                </template>
            </n-card>
        </div>
        <div class="w-[20%] px-2">
            <n-card
                size="small"
                :bordered="false"
                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg h-full"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <template #default>
                    <section class="flex flex-col justify-between h-full text-left">
                        <div
                            class="flex items-center text-green-600 text-xl font-semibold">
                            {{ fullInfo?.info.total_payed }}
                            <rb-coin class="w-5 ml-2" :coin="currency(env.profitSymbol)" />
                        </div>

                        <div class="text-gray-500 dark:text-white/40 mt-1">{{ profile.localization['profile_referral_info_total_payed'].slice(0, -1) }}</div>
                    </section>
                </template>
            </n-card>
        </div>
        <div class="w-[20%] pl-2">
            <n-card
                size="small"
                :bordered="false"
                class="overflow-hidden shadow bg-main rounded-lg h-full"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <template #default>
                    <section class="flex flex-col justify-between h-full text-left">
                        <div
                            class="flex items-center text-white text-xl font-semibold">
                            {{ fullInfo?.info.total_available }}
                            <rb-coin class="w-5 ml-2" :coin="currency(env.profitSymbol)" />
                        </div>

                        <div class="text-white font-semibold mt-1">{{ profile.localization['profile_referral_info_total_available'].slice(0, -1) }}</div>
                    </section>
                </template>
            </n-card>
        </div>
    </section>

    <super-table
        v-if="dataRefs"
        showSearch
        type="referral.program"
        :filters="filters"
        :pager="dataRefs.pager"
        :columns="dataRefs.columns"
        :records="dataRefs.records"
        columnsStorageName="__columns_referral-program"
        @getData="get"
        @applyFilters="changeFilter">
    </super-table>

    <div class="flex mt-4">
        <div class="w-6/12 pr-2">
            <n-card
                size="small"
                :bordered="false"
                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg h-full"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <template #default>
                    <div class="text-base text-left text-gray-600 dark:text-white/75 font-semibold">{{ profile.localization['profile_referral_info_referral_links_title'] }}</div>
                    <rb-select
                        class="mt-4"
                        :label="profile.localization['profile_referral_info_referral_links_link_type_f']"
                        :options="linkTypesOptions"
                        v-model:value="idx"
                        @update:value="onReferralLinkCode" />

                    <section v-if="linkTypes">
                        <div class="text-md text-left my-4 text-gray-600 dark:text-white/75" v-html="profile.localization['profile_referral_info_referral_links_link_code_info']"></div>

                        <n-card
                            v-for="linkType in linkTypes.htmlCodes"
                            :key="linkType.id"
                            class="bg-transparent mb-4 relative rounded-md"
                            content-style="padding: 0;">
                            <n-icon class="absolute top-2 right-2 cursor-pointer text-main" size="20" @click="copy(linkType)">
                                <Copy16Regular />
                            </n-icon>
                            <div class="text-xs pl-3 pr-8 py-2">
                                <n-code
                                    word-wrap
                                    :code="linkType"
                                    language="javascript" />
                            </div>
                            <!-- {{ linkType }} -->
                            <template v-if="idx === 1">
                                <n-divider class="m-0" />
                                <div class="text-main hover:underline px-3 py-2" v-html="linkType"></div>
                            </template>
                        </n-card>
                    </section>
                </template>
            </n-card>
        </div>
        <div class="w-6/12 pl-2">
            <n-card
                size="small"
                :bordered="false"
                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg h-full"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <template #default>
                    <div class="text-base text-left text-gray-600 dark:text-white/75 font-semibold">{{ profile.localization['profile_referral_info_referral_banners_title'] }}</div>

                    <div class="flex mt-4">
                        <rb-select
                            class="w-6/12 pr-2"
                            :label="profile.localization['profile_referral_info_referral_banners_banner_size_f']"
                            :options="bannerSizesOptions"
                            v-model:value="bannerSize"
                            @update:value="bannerSize = $event, curBanner = undefined" />
                        <rb-select
                            class="w-6/12 pl-2"
                            :disabled="!bannerSizeSelected"
                            :label="profile.localization['profile_referral_info_referral_banners_available_banners_f']"
                            :options="bannersForSize"
                            v-model:value="curBanner"
                            @update:value="onAvailableBanner" />
                    </div>

                    <section class="flex justify-end mt-4">
                        <n-button
                            v-if="curBanner !== undefined"
                            strong
                            size="large"
                            class="rounded-md text-white/90"
                            :color="gl.mainColor"
                            @click="showModal = true">
                            {{ profile.localization['profile_referral_info_referral_banners_available_banners_show_banner_button'] }}
                        </n-button>
                    </section>
                </template>
            </n-card>
        </div>
    </div>
    <base-modal
        :width="bannerSize === 1 ? 332 : 760"
        :label="availableBanner?.title"
        v-model:show="showModal">
        <template #default>
            <div>
                <div class="flex justify-center mb-4 rounded-md overflow-hidden" v-html="availableBanner.htmlCode"></div>

                <n-card
                    v-for="alternative in availableBanner.alternative"
                    :key="alternative.id"
                    class="bg-transparent mb-4 relative rounded-md"
                    content-style="padding: 0;">
                    <section class="px-3 py-3">
                        <div class="text-base text-gray-600 dark:text-white/75 font-semibold">{{ alternative.title }}</div>
                        <n-icon class="absolute top-2 right-2 cursor-pointer text-main" size="20" @click="copy(alternative.htmlCode)">
                            <Copy16Regular />
                        </n-icon>
                        <section class="mt-2">
                            <n-code
                                word-wrap
                                :code="alternative.htmlCode"
                                language="javascript" />
                        </section>
                    </section>
                </n-card>
            </div>
        </template>
    </base-modal>
</section>
</template>

<script>
// vue
import { ref, reactive, watch, computed, onMounted } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useEnv } from '@store/ts/env';
import { useRefs } from '@store/ts/refs';
import { useProfile } from '@store/profile';

// i18n
import { useI18n } from 'vue-i18n';

// shared
import { CRUD_PAGER_FACTORY } from '@shared/factories';

// icons
import { Warning24Filled, Dismiss16Filled, IosArrowRtl24Filled, Copy16Regular } from '@vicons/fluent';

// components
import RbCoin from '@components/rb-coin';
import RbInput from '@components/rb-input';
import RbSelect from '@components/rb-select';
import RbSwitch from '@components/rb-switch';
import BaseModal from '@components/base/base-modal';
import SuperTable from '@components/super-table/index.vue';

// services
import Referral from '@services/referral';

// editor
import Editor from '@tinymce/tinymce-vue';

// naive-ui
import {
    NCode,
    NIcon,
    NCard,
    NAlert,
    NButton,
    NDivider,
    NCollapseTransition,
    useNotification } from 'naive-ui';

export default {
    name: 'referral-program',
    props: {
        tab: {
            type: String,
            required: true,
        },
        myTab: {
            type: String,
            required: true,
        },
    },
    components: {
        NCode,
        NIcon,
        NCard,
        NAlert,
        Editor,
        RbCoin,
        NButton,
        RbInput,
        RbSelect,
        NDivider,
        RbSwitch,
        BaseModal,
        SuperTable,
        Copy16Regular,
        Warning24Filled,
        Dismiss16Filled,
        IosArrowRtl24Filled,
        NCollapseTransition,
    },
    setup(props) {
        // store
        const gl = useGl();
        const env = useEnv();
        const refs = useRefs();
        const profile = useProfile();

        // ui
        const notification = useNotification();

        // i18n
        const { t } = useI18n();

        // vars
        const { _ } = window;
        const idx = ref();
        const bannerSize = ref();
        const init = ref(false);
        const dataRefs = ref();
        const fullInfo = ref();
        const linkTypes = ref();
        const curBanner = ref();
        const showModal = ref(false);
        const availableBanner = ref();
        watch(() => props.tab, v => {
            if (props.myTab === v) {
                if (!dataRefs.value || !fullInfo.value)
                    getData();
            }
        });
        const filters = reactive(CRUD_PAGER_FACTORY());
        const AVAILABLE_BANNER_FACTORY = () => ({
            alternative: [],
        });

        const bannerSizeSelected = computed(() => bannerSize.value !== undefined && bannerSize.value !== -1);
        const bannerSizes = computed(() => bannerSizesOptions.value.find(({ value }) => bannerSize.value === value));

        const linkTypesOptions = computed(() => 
            fullInfo.value
                ? fullInfo.value?.linkTypes.map(el => ({
                    label: el.title,
                    value: +el.id,
                    ...el,
                })) : []);

        const bannerSizesOptions = computed(() => 
            fullInfo.value
                ? fullInfo.value?.bannerSizes.map(el => ({
                    label: el.title,
                    value: +el.id,
                    ...el,
                })) : []);

        const bannersForSize = computed(() => {
            if (bannerSizesOptions.value && bannerSize.value !== undefined) {
                if (bannerSizes.value) {
                    return bannerSizes.value.data?.map((el, i) => ({
                        label: el.title,
                        value: i,
                        ...el,
                    }));
                }
            }

            return [];
        });

        const get = async () => {
            gl.showLoading = true;

            try {
                dataRefs.value = ( await Referral.get(filters) ).data;
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            };
            
            gl.showLoading = false;
        };

        const getFullInfo = async () => {
            gl.showLoading = true;

            try {
                fullInfo.value = ( await Referral.getFullInfo() ).data;
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            };
            
            gl.showLoading = false;
        };

        const getData = async () => {
            gl.showLoading = true;

            await Promise.allSettled([
                get(),
                getFullInfo(),
            ]);

            init.value = true;
            gl.showLoading = false;
        };

        const changeFilter = ([ key, value ], update = false) => {
            const canUpdate = key === 'query' && !value && dataRefs.value?.pager.query;
            // if (key === 'perPage') {
            //     _.set(filters, 'page', 1);
            // };

            if (key !== 'page') {
                _.set(filters, 'page', 1);
            }

            _.set(filters, key, value !== null && value !== undefined ? value : [ -1 ]);
            
            if (update || canUpdate)
                get();
        };

        const currency = icon => refs.currencies.find(el => el.id === icon);

        const onReferralLinkCode = idx => {
            linkTypes.value = linkTypesOptions.value.find(({ value }) => value === idx);
        };

        const onAvailableBanner = idx => {
            if (parseInt(idx, 10) == -1 || !bannerSizeSelected.value) {
                availableBanner.value = AVAILABLE_BANNER_FACTORY();
                return;
            }
            availableBanner.value = bannerSizes.value?.data[curBanner.value];
        };

        const copy = s => {
            navigator.clipboard.writeText(s);
            notification.error({
                content: t('copied'),
                duration: 2500,
                keepAliveOnHover: true,
            });
        };

        onMounted(() => {
            if (props.myTab === 'referral_info') {
                if (!dataRefs.value || !fullInfo.value)
                    getData();
            }
        });

        return {
            gl,
            env,
            idx,
            refs,
            init,
            filters,
            profile,
            dataRefs,
            fullInfo,
            linkTypes,
            curBanner,
            showModal,
            bannerSize,
            bannersForSize,
            availableBanner,
            linkTypesOptions,
            bannerSizeSelected,
            bannerSizesOptions,
            onReferralLinkCode,
            get,
            copy,
            currency,
            changeFilter,
            onAvailableBanner,
        };
    },
};
</script>