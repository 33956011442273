import { defineStore } from 'pinia';
import { useEnv } from '../env';

// moment
import moment from 'moment';

// interfaces
import {
    IRefs,
    IRange } from './interfaces';

export const useRefs = defineStore('refs', {
    state: () => ({
        refs: {} as IRefs,
        tasks: {
            completed: false,
            enabled: false,
            steps: {
                api: false,
                bot: false,
                tg: false,
                tg_news: false,
            },
        },
    }),
    getters: {
        lang(state) {
            return Object.keys(state.refs.data.localization)[0];
        },
        langs(state) {
            return state.refs.data.langs;
        },
        header(state) {
            return state.refs.data.localization[Object.keys(state.refs.data.localization)[0]].header;
        },
        privateMessage(state) {
            return state.refs.data.localization[Object.keys(state.refs.data.localization)[0]].privateMessage;
        },
        status(state): string | undefined {
            const env = useEnv();
            const result = state.refs.data.user_statuses.find(({ id }) => id === env.statusCode);

            return result?.title;
        },
        currencies(state) {
            return state.refs.data.currencies;
        },
        exchanges(state) {
            return state.refs.data.exchanges;
        },
        localization(state) {
            return state.refs.data.localization[Object.keys(state.refs.data.localization)[0]];
        },
        botStatuses(state) {
            return state.refs.data.bot_statuses;
        },
        walletStatuses(state) {
            return state.refs.data.wallet_statuses;
        },
        botStates(state) {
            return state.refs.data.bot_states;
        },
        walletTypes(state) {
            return state.refs.data.wallet_types;
        },
        apikeyStatuses(state) {
            return state.refs.data.apikey_statuses;
        },
        timezones(state) {
            return state.refs.data.timezones;
        },
        ranges(state): IRange[] {
            const localization = state.refs.data.localization[Object.keys(state.refs.data.localization)[0]];
            return [
                {
                    id: 'today',
                    label: localization.filters.general.range_today,
                    value: [ moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD') ],
                }, {
                    id: 'yesterday',
                    label: localization.filters.general.range_yesterday,
                    value: [ moment().subtract(1, 'days').format('YYYY-MM-DD'), moment().subtract(1, 'days').format('YYYY-MM-DD') ],
                }, {
                    id: 'last_week',
                    label: localization.filters.general.range_last_week,
                    value: [ moment().subtract(6, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD') ],
                }, {
                    id: 'last_two_weeks',
                    label: localization.filters.general.range_last_two_weeks,
                    value: [ moment().subtract(14, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD') ],
                }, {
                    id: 'this_month',
                    label: localization.filters.general.range_this_month,
                    value: [ moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD') ],
                }, {
                    id: 'last_thirty_days',
                    label: localization.filters.general.range_last_thirty_days,
                    value: [ moment().subtract(30, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD') ],
                },
            ];
        },
        countTask(state): number {
            return Object.keys(state.tasks.steps).length;
        },
        countUnshiftedTask(state): number {
            return (Object.values(state.tasks.steps).filter(el => !el)).length;
        },
        countShiftedTask(state) {
            return (Object.values(state.tasks.steps).filter(el => el)).length;
        },
        percentShiftedTask(state) {
            return (Object.values(state.tasks.steps).filter(el => el)).length * 100 / Object.keys(state.tasks.steps).length;
        },
        tasksList(state) {
            const localization = state.refs.data.localization[Object.keys(state.refs.data.localization)[0]];
            return localization.tasks.tasks;
        },
        tasksSteps(state) {
            return state.tasks.steps;
        },
    },
});