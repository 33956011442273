<template>
<base-modal-desktop
    v-if="show"
    :label="gl.postDialogMessages[page - 1].title"
    :show="true"
    @update:show="gl.postDialogMessages = null">
    <template #default>
        <div v-html="gl.postDialogMessages[page - 1].msg"></div>
    </template>
    <template #footer>
        <div v-if="refs.localization" class="flex justify-end">
            <n-button
                class="text-white/90 rounded-md"
                :color="gl.mainColor"
                @click="dialogMessageMarkAsRead(gl.postDialogMessages[page - 1])">
                {{ refs.localization.dialogs.next }}
            </n-button>
            <n-button
                class="text-gray-600 dark:text-white/75 rounded-md ml-2"
                @click="close">
                {{ refs.localization.dialogs.cancel }}
            </n-button>
        </div>
    </template>
</base-modal-desktop>
</template>

<script>
// vue
import { ref, computed, watch } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';

// i18n
import { useI18n } from 'vue-i18n';

// services
import ProfileService from '@services/profileService';

// naive-ui
import {
    NIcon,
    NCard,
    NModal,
    NButton,
    NPagination,
    useNotification } from 'naive-ui';

// icons
import { Dismiss16Filled } from '@vicons/fluent';

export default {
    name: 'post-dialog-message-desktop',
    props: {
        label: {
            type: String,
            required: true,
        },
        width: {
            type: String,
            default: '600px',
        },
        height: {
            type: Number, String,
        },
        maskClosable: {
            type: Boolean,
            default: true,
        },
        showCLoseButton: {
            type: Boolean,
            default: true,
        },
        classes: {
            type: String,
        },
        lightDark: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        NIcon,
        NCard,
        NModal,
        NButton,
        NPagination,
        Dismiss16Filled,
    },
    setup() {
        // store
        const gl = useGl();
        const refs = useRefs();

        // ui
        const notification = useNotification();

        // vars
        const page = ref(1);
        const showModal = ref(true);

        // i18n
        const { t } = useI18n();

        const show = computed(() => !!gl.postDialogMessages && gl.postDialogMessages?.length);

        watch(show, () => {
            page.value = 1;
        });

        const close = () => {
            gl.postDialogMessages = null;
        };

        const dialogMessageMarkAsRead = async el => {
            try {
                if (el.need_feedback)
                    ProfileService.dialogMessageMarkAsRead({ id: el.id });

                const i = gl.postDialogMessages.findIndex(item => item.id === el.id);

                gl.postDialogMessages.splice(i, 1);

                if (!gl.postDialogMessages.length) {
                    close();
                }
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            };
        };

        return {
            gl,
            refs,
            page,
            show,
            showModal,
            close,
            dialogMessageMarkAsRead,
        };
    },
};
</script>

<style lang="scss" scoped>
:deep(a) {
    color: #1A6DE0 !important;
}

:deep(b) {
    color: #1A6DE0 !important;
}
</style>