<template>
<section class="pb-2">
    <div class="text-xl text-left text-gray-700 dark:text-white/90 font-semibold">
        <n-skeleton v-if="!profile.localization" :width="160" :height="20" :sharp="false" />
        <template v-else>{{ profile.localization.profile_referral_info_withdrawal_request_title }}</template>
    </div>
    <section class="flex mt-4">
        <div class="w-6/12 pr-2">
            <n-card
                size="small"
                :bordered="false"
                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg h-full"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <template #default>
                    <div class="text-base text-left text-gray-600 dark:text-white/75 font-semibold">
                        {{ profile.localization['profile/transfer/info_title'] }}
                    </div>

                    <rb-input
                        class="mt-4"
                        :status="errors?.id_acc ? 'error' : undefined"
                        :msg="errors?.id_acc?.msg"
                        :label="profile.localization['profile/transfer/id_acc']"
                        v-model:value="dataForm.id_acc"
                        @update:value="errors.cleanError('id_acc')" />

                    <rb-input
                        class="mt-4"
                        :status="errors?.amount ? 'error' : undefined"
                        :msg="errors?.amount?.msg"
                        :label="profile.localization['profile/transfer/amount']"
                        v-model:value="dataForm.amount"
                        @update:value="errors.cleanError('amount')" />

                    <rb-input
                        class="mt-4"
                        type="textarea"
                        :status="errors?.comment ? 'error' : undefined"
                        :msg="errors?.comment?.msg"
                        :label="profile.localization['profile/transfer/comment']"
                        v-model:value="dataForm.comment"
                        @update:value="errors.cleanError('comment')" />

                    <div class="flex justify-end mt-4">
                        <n-button
                            strong
                            class="rounded-md text-white/90"
                            :color="gl.mainColor"
                            @click="onInternalTransfer">
                            {{ profile.localization['profile/transfer/send'] }}
                        </n-button>
                    </div>
                </template>
            </n-card>
        </div>
        <div class="w-6/12 pl-2 flex flex-col">
            <n-card
                size="small"
                :bordered="false"
                class="overflow-hidden shadow bg-main rounded-lg"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <template #default>
                    <section class="flex flex-col justify-between h-full text-left">
                        <div
                            class="flex items-center text-white text-xl font-semibold">
                            {{ fullInfo?.info.total_available }}
                            <rb-coin class="w-5 ml-2" :coin="currency(env.profitSymbol)" />
                        </div>

                        <div class="text-white font-semibold mt-1">{{ profile.localization['profile_referral_info_total_available'].slice(0, -1) }}</div>
                    </section>
                </template>
            </n-card>
            <n-card
                size="small"
                :bordered="false"
                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg flex-grow mt-4"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <template #default>
                    <div class="text-base text-left text-gray-600 dark:text-white/75 font-semibold">
                        {{ profile.localization['profile_referral_info_withdrawal_request_title'] }}
                    </div>

                    <rb-input
                        class="mt-8"
                        :status="errors2?.withdraw_address ? 'error' : undefined"
                        :msg="errors2?.withdraw_address?.msg"
                        :label="profile.localization['profile_referral_info_withdrawal_request_btc_address_f']"
                        :placeholder="profile.localization['profile_referral_info_withdrawal_request_btc_address_i']"
                        v-model:value="dataForm2.withdraw_address"
                        @update:value="errors2.cleanError('withdraw_address')" />

                    <rb-input
                        class="mt-4"
                        :status="errors2?.withdraw_amount ? 'error' : undefined"
                        :msg="errors2?.withdraw_amount?.msg"
                        :label="profile.localization['profile/transfer/amount']"
                        v-model:value="dataForm2.withdraw_amount"
                        @update:value="errors2.cleanError('withdraw_amount')" />

                    <div class="flex justify-end mt-4">
                        <n-button
                            strong
                            class="rounded-md text-white/90"
                            :color="gl.mainColor"
                            @click="onRequestWithdraw">
                            {{ profile.localization['profile_referral_info_withdrawal_request_request_a_withdrawal_button'] }}
                        </n-button>
                    </div>
                </template>
            </n-card>
        </div>
    </section>
</section>
</template>

<script>
// general
import general from './general';

// icons
import { IosArrowRtl24Filled } from '@vicons/fluent';

// components
import RbInput from '@components/rb-input';
import RbCoin from '@components/rb-coin';

// naive-ui
import {
    NIcon,
    NCard,
    NAlert,
    NButton,
    NDivider,
    NCollapseTransition } from 'naive-ui';

export default {
    name: 'payments-request',
    props: {
        tab: {
            type: String,
            required: true,
        },
        myTab: {
            type: String,
            required: true,
        },
    },
    components: {
        NIcon,
        NCard,
        NAlert,
        RbCoin,
        NButton,
        RbInput,
        NDivider,
        IosArrowRtl24Filled,
        NCollapseTransition,
    },
    setup(props, context) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>