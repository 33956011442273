<template>
<section v-if="visible">
    <section class="flex w-full">
        <n-button
            class="rounded-md flex-grow"
            @click="show = true">
            <section class="w-full flex justify-between items-center">
                <div class="mr-4 text-xs">{{ label }}</div>
                <div class="flex justify-end items-center">
                    <span class="text-main mr-2 text-xs">{{ checkeds || '' }}</span>
                    <n-icon size="14"><ArrowRight12Regular /></n-icon>
                </div>
            </section>
        </n-button>

        <n-button
            secondary
            type="default"
            class="px-2 ml-1 rounded-md"
            @click="$emit('setVisibleFilters', data.filter)">
            <n-icon size="24" class="text-red-500">
                <DeleteDismiss24Regular />
            </n-icon>
        </n-button>
    </section>
    <n-drawer
        v-model:show="show"
        width="100%"
        class="bg-gray-100 dark:bg-darkbg"
        :auto-focus="false">
        <n-drawer-content body-content-style="padding: 0">
            <div class="flex justify-between items-center w-full bg-gray-100 dark:bg-darkbg text-sm text-center text-gray-600 dark:text-white/75 px-3 py-3">
                <n-icon
                    size="20"
                    class="text-gray-600 dark:text-white/75"
                    @click="show = false">
                    <ArrowLeft12Regular />
                </n-icon>
                <div class="flex justify-end">
                    <n-button
                        quaternary
                        size="small"
                        type="default"
                        class="rounded-md flex items-center text-main"
                        @click="show = false">
                        {{ t('done') }}
                    </n-button>
                </div>
            </div>
            <div class="px-3 py-1">
                <rb-input
                    :placeholder="refs.localization.filters.master_slave.search"
                    v-model:value="searchFilter" />
            </div>
            <div class="h-[calc(100%-88px)] pb-2 overflow-y-auto" v-if="allBots.length">
                <div v-if="!emptyBotsList" class="w-full px-3 pt-1 pb-2 flex justify-between">
                    <n-button
                        type="default"
                        size="small"
                        class="rounded-md flex items-center text-gray-600 dark:text-white/75"
                        @click="removeAll">
                        {{ refs.localization.filters.master_slave.btn__remove_all }}
                    </n-button>
                    <n-button
                        secondary
                        type="default"
                        size="small"
                        class="rounded-md flex items-center text-white/75 bg-main"
                        @click="addAll">
                        {{ refs.localization.filters.master_slave.btn__add_all }}
                    </n-button>
                </div>
                <div
                    v-for="item in allBots"
                    :key="item.value">
                    <template v-if="item.children.length">
                        <div class="flex justify-start items-center w-full px-3 sticky top-0 bg-white dark:bg-darkCardBg z-50 opacity-100">
                            <rb-checkbox
                                class="py-2"
                                :label="item.label"
                                :checked="isAllChecked(item)"
                                @update:checked="setCheckAll(item, $event)" />
                            <div class="text-main ml-1">({{ item.children.length }})</div>
                        </div>
                        
                        <template
                            v-for="bot in item.children"
                            :key="bot.value">
                            <rb-checkbox
                                class="w-full pl-6 pr-3 py-2 dark:odd:bg-[#242424] odd:bg-gray-50 rounded-none"
                                :label="bot.label"
                                v-model:checked="bot.checked"
                                @update:checked="setCheck" />
                        </template>
                    </template>
                </div>
                <div v-if="emptyBotsList" class="text-center py-4">No data</div>
            </div>
        </n-drawer-content>
    </n-drawer>
</section>
</template>

<script>
// vue
import { defineComponent } from 'vue';

// general
import general from './general';

// naive
import {
    NTag,
    NIcon,
    NButton,
    NDrawer,
    NDrawerContent } from 'naive-ui';

// components
import RbCoin from '@components/rb-coin';
import RbInput from '@components/rb-input/mobile.vue';
import RbCheckbox from '@components/rb-checkbox/mobile.vue';

// icons
import {
    ArrowLeft12Regular,
    ArrowRight12Regular,
    DeleteDismiss24Regular } from '@vicons/fluent';

export default defineComponent({
    name: 'bots-filter-mobile',
    props: {
        data: {
            type: Object,
            required: true,
        },
        filters: {
            type: Object,
            required: true,
        },
        visible: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        RbCoin,
        NTag,
        NIcon,
        NButton,
        NDrawer,
        RbInput,
        RbCheckbox,
        NDrawerContent,
        ArrowLeft12Regular,
        ArrowRight12Regular,
        DeleteDismiss24Regular,
    },
    setup(props, context) {

        return {
            ...general(props, context),
        };
    },
});
</script>

<style lang="scss" scoped>
@import '@/css/stock-icon.scss';

:deep(.n-button__content) {
  width: 100%;
  height: 100%;
}
</style>