// vue
import { ref, reactive, computed, watch } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';

export default function (props: any, context: any) {
    const gl = useGl();
    const refs = useRefs();

    // vars
    const { _ } = window;
    const searchFilter = ref();
    const show = ref(false);
    const init = ref(false);

    watch(show, v => {
        if (!v)
            addSelected();
    });

    watch(() => props.visible, v => {
        if (v) {
            show.value = true;
        } else {
            context.emit('changeFilter', [props.data.filter, [ -1 ]]);
        }
    });

    const checkeds = computed(() => _.get(props.filters, props.data.filter)[0] === -1
        ? refs.localization.filters.all_select.all
        : options.value.reduce((accum: number, el: any) => accum + +el.checked , 0));

    const label = computed(() =>
        props.data.title[props.data.title.length - 1] === ':'
            ? props.data.title.slice(0, props.data.title.length - 1)
            : props.data.title,
    );

    const options = computed(() => {
        return props.data.refs.map((el: any) => reactive({
            label: el.title,
            value: el.code_name,
            checked: _.get(props.filters, props.data.filter)[0] === -1 && init.value
                ? true
                : !!_.get(props.filters, props.data.filter)?.find((item: any) => item == el.id),
            ...el,
        })).filter((item: any) => !searchFilter.value
            ? true
            : item.label.toLowerCase().includes(searchFilter.value.toLowerCase()));
    });
    const allChecked = computed(() => options.value.every(({ checked } : { checked: boolean }) => checked));
    const noOneChecked = computed(() => options.value.every(({ checked } : { checked: boolean }) => !checked));

    const setCheckAll = ($event: any) => {
        options.value.forEach((el: any) => {
            el.checked = $event;
        });

        addSelected();
    };

    const setCheck = (option: any) => {
        option.checked = !option.checked;
        addSelected();
    };

    const addSelected = () => {
        if (allChecked.value) {
            context.emit('changeFilter', [ props.data.filter, [ -1 ] ]);
        } else if (noOneChecked.value) {
            init.value = false;
            return void context.emit('changeFilter', [ props.data.filter, [ -1 ] ]);
        } else {
            const arr = options.value.filter(({ checked }: { checked: boolean }) => checked).map(({ id }: { id: string | number }) => id);
            context.emit('changeFilter', [ props.data.filter, arr.length ? arr : [ -1 ] ]);
        }

        if (!init.value)
            init.value = true;
    };
        
    return {
        gl,
        refs,
        label,
        show,
        checkeds,
        options,
        allChecked,
        searchFilter,
        setCheck,
        setCheckAll,
    };
}