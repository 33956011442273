<template>
<div
    v-if="isFooter"
    class="h-full flex justify-center items-center text-main text-md dark:bg-darkCardBg bg-gray-50 font-semibold relative overflow-hidden">
    {{ dataFooter }} <div class="absolute w-full top-0 left-0 h-[2px] bg-main shadow-xl"></div>
</div>
<template v-else>
    <div
        class="h-full flex justify-center items-center px-2 whitespace-nowrap"
        :class="classes">
        *********************
        <n-popover
            trigger="click"
            placement="left"
            class="bg-main"
            :arrow-style="{
                backgroundColor: gl.mainColor,
            }">
            <template #trigger>
                <div class="px-1">
                    <n-icon class="inline cursor-pointer">
                        <QuestionCircle16Filled class="text-main text-2xl" />
                    </n-icon>
                </div>
            </template>
            <template #default>
                <div class="max-w-screen-sm text-white" v-html="data"></div>
            </template>
        </n-popover>
    </div>
</template>
</template>

<script>
// store
import { useGl } from '@store/ts/gl';

// naive-ui
import {
    NIcon,
    NButton,
    NPopover } from 'naive-ui';

// icons
import {
    QuestionCircle16Filled } from '@vicons/fluent';

export default {
    name: 'cell-key',
    props: {
        isFooter: {
            type: Boolean,
            default: false,
            required: true,
        },
        dataFooter: {
            default: '',
        },
        data: {
            required: true,
        },
        classes: {
            type: String,
        },
    },
    components: {
        NIcon,
        NButton,
        NPopover,
        QuestionCircle16Filled,
    },
    setup() {
        // store
        const gl = useGl();

        return {
            gl,
        };
    },
};
</script>