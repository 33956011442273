<template>
<section v-if="visible">
    <section class="flex w-full">
        <n-button
            class="rounded-md flex-grow"
            @click="show = true">
            <section class="w-full flex justify-between items-center">
                <div class="mr-4 text-xs">{{ label }}</div>
                <div class="flex justify-end items-center">
                    <span class="text-main mr-2 text-xs">{{ checkeds }}</span>
                    <n-icon size="14"><ArrowRight12Regular /></n-icon>
                </div>
            </section>
        </n-button>

        <n-button
            secondary
            type="default"
            class="px-2 ml-1 rounded-md"
            @click="$emit('setVisibleFilters', data.filter)">
            <n-icon size="24" class="text-red-500">
                <DeleteDismiss24Regular />
            </n-icon>
        </n-button>
    </section>

    <n-drawer
        v-model:show="show"
        width="100%"
        class="bg-gray-100 dark:bg-darkbg"
        :auto-focus="false"
        @after-enter="init = true">
        <n-drawer-content body-content-style="padding: 0">
            <div class="flex flex-col h-full">
                <div class="flex justify-between items-center w-full bg-gray-100 dark:bg-darkbg text-sm text-center text-gray-600 dark:text-white/75 px-3 py-3">
                    <n-icon
                        size="20"
                        class="text-gray-600 dark:text-white/75"
                        @click="show = false">
                        <ArrowLeft12Regular />
                    </n-icon>
                    <div class="flex justify-end">
                        <n-button
                            quaternary
                            size="small"
                            type="default"
                            class="rounded-md flex items-center text-main"
                            @click="show = false">
                            {{ refs.localization.filters.general.filters_done_label }}
                        </n-button>
                    </div>
                </div>
                <div class="pb-4 flex-grow overflow-y-scroll overscroll-none scrollbar-hide">
                    <div class="px-3 pb-2">
                        <rb-input
                            :placeholder="refs.localization.filters.master_slave.search"
                            v-model:value="searchFilter" />
                    </div>
                    <n-spin v-if="!options.length" class="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2" size="small" />
                    <div
                        v-else
                        v-for="(item, i) in options"
                        :key="i"
                        v-show="showItem(item)"
                        class="dark:odd:bg-[#242424] odd:bg-gray-50 rounded-none cursor-pointer">
                        <div
                            class="w-full flex items-center justify-between px-3 py-2"
                            @click="item.checked =! item.checked">
                            <div class="flex items-center">
                                <rb-coin class="w-4" :coin="item" />
                                <div class="text-xs text-gray-600 dark:text-white/75 ml-2">{{ item.title }}</div>
                            </div>
                            <n-icon v-if="item.checked" size="20" class="text-main">
                                <Checkmark12Filled />
                            </n-icon>
                        </div>
                    </div>
                </div>
            </div>
        </n-drawer-content>
    </n-drawer>
</section>
<!-- <div
    class="h-full flex justify-between items-center text-md text-gray-600 dark:text-white/75"
    @click="show = true">
    {{ label }}
    <div class="flex justify-end items-center">
        <span class="text-main mr-2">{{ checkeds }}</span>
        <n-icon size="16"><ArrowRight12Regular /></n-icon>
    </div>
</div>
<n-drawer
    v-model:show="show"
    :placement="placement"
    :trap-focus="false"
    display-directive="show"
    class="w-full h-full"
    to="#drawer-target"
    @after-enter="init = true">
    <n-drawer-content
        body-content-style="padding: 0; overflow: hidden;"
        class="bg-white dark:bg-darkCardBg overflow-hidden p-0 flex flex-col h-full">
        <div class="w-full flex justify-between items-center py-2 px-3">
            <n-icon
                size="16"
                class="cursor-pointer"
                @click="show = false">
                <ArrowLeft12Regular />
            </n-icon>

            <n-button
                quaternary
                size="small"
                type="default"
                class="rounded-md flex items-center text-main"
                @click="show = false">
                Готово
            </n-button>
        </div>
        <div class="px-3 pb-2">
            <rb-input
                :placeholder="refs.localization.filters.master_slave.search"
                v-model:value="searchFilter" />
        </div>
        <div class="h-[calc(100%-92px)] pb-2 overflow-y-auto relative">
            <n-spin v-if="!options.length" class="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2" size="small" />
            <div
                v-else
                v-for="(item, i) in options"
                :key="i"
                v-show="showItem(item)"
                class="dark:odd:bg-[#242424] odd:bg-gray-50 rounded-none cursor-pointer">
                <div
                    class="w-full flex items-center px-3 py-2"
                    @click="item.checked =! item.checked">
                    <rb-checkbox :value="item.checked" />
                    <rb-coin class="w-6" :coin="item" />
                    <div class="text-gray-600 dark:text-white/75 ml-2">{{ item.title }}</div>
                </div>
            </div>
        </div>
    </n-drawer-content>
</n-drawer> -->
</template>

<script>
// vue
import { defineComponent } from 'vue';

// general
import general from './general';

// naive-ui
import {
    NIcon,
    NRadio,
    NSpin,
    NButton,
    NDrawer,
    NDrawerContent } from 'naive-ui';

// icons
import {
    Checkmark12Filled,
    ArrowLeft12Regular,
    ArrowRight12Regular,
    DeleteDismiss24Regular } from '@vicons/fluent';

// components
import RbCoin from '@components/rb-coin';
import RbInput from '@components/rb-input';
import RbCheckbox from '@components/rb-checkbox';

export default defineComponent({
    name: 'filter-coins-desktop',
    props: {
        data: {
            type: Object,
            required: true,
        },
        filters: {
            type: Object,
            required: true,
        },
        visible: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        NSpin,
        NIcon,
        RbCoin,
        NRadio,
        NDrawer,
        NButton,
        RbInput,
        RbCheckbox,
        NDrawerContent,
        Checkmark12Filled,
        ArrowLeft12Regular,
        ArrowRight12Regular,
        DeleteDismiss24Regular,
    },
    setup(props, context) {
        
        return {
            ...general(props, context),
        };
    },
});
</script>

<style lang="scss" scoped>
:deep(.n-button__content) {
  width: 100%;
  height: 100%;
}
</style>