import Api from './index';

export default class Exchanges {

    static async getFullInfo(id) {
        return await Api.getData({
            action: 'exchanges.getFullInfo',
            payload: { id },
        });
    };

    static async getSymbolsForMarket(payload) {
        return await Api.getData({
            action: 'exchanges.getSymbolsForMarket',
            payload,
        });
    };

    static async getMarkets(payload) {
        return await Api.getData({
            action: 'exchanges.getMarkets',
            payload,
        });
    };
}