<template>
<div
    v-if="isFooter"
    class="h-full flex justify-center items-center text-main text-md dark:bg-darkCardBg bg-gray-50 font-semibold relative overflow-hidden">
    {{ dataFooter }} <div class="absolute w-full top-0 left-0 h-[2px] bg-main shadow-xl"></div>
</div>
<div
    v-else
    class="h-full flex justify-start items-center px-1"
    :class="classes">
    <span class="inline-block rb-td-stock-icon h-[16px]" :class="exchangeClass"></span>
</div>
</template>

<script>
// vue
import { computed } from 'vue';

// store
import { useRefs } from '@store/ts/refs';

export default {
    name: 'cell-exchange-mobile',
    props: {
        isFooter: {
            type: Boolean,
            default: false,
            required: true,
        },
        dataFooter: {
            default: '',
        },
        data: {
            required: true,
        },
        classes: {
            type: String,
        },
    },
    setup(props) {
        // store
        const refs = useRefs();

        const exchangeClass = computed(() => `rb-td-stock-icon-${exchange(props.data)?.code_name}`);

        const exchange = exchange => refs.exchanges.find(({ id }) => id === exchange);
        
        return {
            exchangeClass,
        };
    },
};
</script>