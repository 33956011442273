
import Api from './index';
import { CRUD_PAGER_FACTORY } from '@shared/factories';

export default class ApiKeys {
    static async addApiKeyToExchange(exchange, records) {
        return await Api.getData({
            action: 'apikeys.addNew',
            payload: {
                records: [records],
                pager: CRUD_PAGER_FACTORY({
                    exchanges: [exchange],
                    statuses: [-1],
                    states: [-1],
                }, {
                    perPage: '*',
                }),
            },
        });
    };

    static async getApiKeysList(filters = {}) {
        return await Api.getData({
            payload: {
                pager: CRUD_PAGER_FACTORY({
                    exchanges: [-1],
                    statuses: [-1],
                    states: [-1],
                    ...filters,
                }, {
                    perPage: '*',
                }),
            },
            action: 'apikeys.get',
        });
    };

    static async getRefs() {
        return await Api.getData({
            action: 'apikeys.getRefs',
        });
    };

    static async apiKeysGet(payload) {
        return await Api.getData({
            action: 'apikeys.get',
            payload,
        });
    };

    static async apiKeysCheck(id) {
        return await Api.getData({
            action: 'apikeys.check',
            payload: {
                id,
            },
        });
    };

    static async apiKeysDelete(payload) {
        return await Api.getData({
            action: 'apikeys.delete',
            payload,
        });
    };

    static async getFullInfo(id) {
        return await Api.getData({
            action: 'apikeys.getFullInfo',
            payload: {
                id,
            },
        });
    };

    static async update(record) {
        return await Api.getData({
            action: 'apikeys.update',
            payload: {
                record,
            },
        });
    };

    static async stats(record) {
        return await Api.getData({
            action: 'apikeys.stats',
            payload: {
                record,
            },
        });
    };
}