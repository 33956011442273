<template>
<mobile v-if="gl.isMobile" class="mt-8" />
<desktop v-else />
</template>

<script>
// vue
import { onMounted } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useDashBoard } from '@store/ts/dashBoard';

// services
import Api from '@services/api';

// components
import Desktop from './desktop.vue';
import Mobile from './mobile.vue';

// naive-ui
import { useNotification } from 'naive-ui';

// i18n
import { useI18n } from 'vue-i18n';

export default {
    name: 'news',
    components: {
        Mobile,
        Desktop,
    },
    setup() {
        // store
        const gl = useGl();
        const dashBoard = useDashBoard();

        // ui
        const notification = useNotification();

        // i18n
        const { t } = useI18n();
        
        const getNews = async () => {
            try {
                dashBoard.news = ( await Api.dashBoard({
                    dashboard_block: 'news',
                }) ).data.news;
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            }
        };

        onMounted(() => {
            getNews();
        });
    
        return {
            gl,
        };
    },
};
</script>