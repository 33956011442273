import { defineStore } from 'pinia';

export const useBots = defineStore('bots', {
    state: () => ({
        refs: null,
        loading: false,
        dataForm: {},
        dataTable: null,
        errorsForm: {},
        currentPreset: null,
    }),
    getters: {
        localization(state) {
            return state.refs?.localization[Object.keys(state.refs.localization)[0]];
        },
        help(state) {
            return state.refs.help;
        },
        algos(state) {
            return state.refs.algos;
        },
        presets(state) {
            return state.refs.presets;
        },
        notifications(state) {
            return state.refs.notifications;
        },
        settingsRefs(state) {
            return state.refs.settingsRefs;
        },
        ordersMatrix(state) {
            return state.refs.orders_matrix;
        },
        errorAutorestartTypes(state) {
            return state.refs.error_autorestart_types;
        },
        positionmodes(state) {
            return state.refs.positionmodes;
        },
        switchTypes(state) {
            return state.refs.switch_types;
        },
        coinLists(state) {
            return state.refs.coinLists;
        },
        coinListStrategies(state) {
            return state.refs.coin_list_strategies;
        },
        periodUnits(state) {
            return state.refs.period_units;
        },
        intervalUnits(state) {
            return state.refs.interval_units;
        },
        vaPresets(state) {
            return state.refs.vaPresets;
        },
        startFilters(state) {
            return state.refs.start_filters;
        },
        startFilterOpts(state) {
            return state.refs.start_filter_opts;
        },
        notificationsContacts(state) {
            return state.refs.notifications_contacts;
        },
        tags(state) {
            return state.refs?.tags;
        },
        pager(state) {
            return state.dataTable.pager;
        },
        columns(state) {
            return state.dataTable.columns;
        },
        records(state) {
            return state.dataTable.records;
        },
        filterPresets(state) {
            return state.refs.filter_presets;
        },
    },
});