<template>
  <mobile v-if="gl.isMobile" class="mt-4" />
  <desktop v-else />
</template>

<script>
import { useGl } from '@store/ts/gl';

// components
import Desktop from '@pages/quick-start/desktop.vue';
import Mobile from '@pages/quick-start/mobile.vue';

export default {
    name: 'QuickStart',
    components: {
        Mobile,
        Desktop,
    },
    setup() {
        const gl = useGl();
    
        return {
            gl,
        };
    },
};
</script>