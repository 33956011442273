<template>
<h4 class="text-left px-3 my-2 text-base text-gray-600 dark:text-white/75 font-semibold">
    {{ refs.localization.sidebar.marketplace }}
</h4>
<section class="min-h-screen">
    <n-tabs
        v-if="init"
        type="line"
        size="small"
        class="marketplace-n-tab"
        :value="currentTab"
        @update:value="changeTab">
        <n-tab-pane
            v-for="(tab, i) in tabs"
            :key="i"
            :name="tab.name"
            :disabled="loading">
            <template #tab>
                <div class="flex items-center">
                    {{ tab.title }}
                    <n-tag size="tiny" v-if="tab.name === 'buy/free'" round type="warning" :bordered="false" class="ml-2">
                        <strong class="text-[10px]">Free</strong>
                    </n-tag>
                </div>
            </template>
            <router-view></router-view>
        </n-tab-pane>
    </n-tabs>
</section>
</template>

<script>
// general
import general from './general';

// UI
import {
    NTag,
    NCard,
    NTabs,
    NTabPane } from 'naive-ui';

export default {
    name: 'marketplace-mobile',
    components: {
        NTag,
        NCard,
        NTabs,
        NTabPane,
    },
    setup() {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>

<style lang="scss" scoped>
:deep(.n-tabs) {

  // .n-tabs-nav {
  //   position: sticky;
  //   top: 56px;
  //   z-index: 100;
  //   background: red;
  // }
  .n-tabs-wrapper  {
    padding: 0 12px;
  }
  .n-tabs-tab .n-tabs-tab__label {
    font-size: 0.75rem !important;
    line-height: 1rem !important;
  }
}

:deep(.n-tabs-bar) {
  left: 0;
}
</style>