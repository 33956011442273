<template>
<div v-if="visible" class="flex">
    <n-popover
        trigger="click"
        class="p-0 rounded-md"
        style="height: 400px; min-width: 300px;"
        placement="bottom-start"
        v-model:show="show">
        <template #trigger>
            <n-button
                class="rounded-md flex-grow"
                @click="show = true">
                <section class="w-full flex justify-between items-center">
                    <div class="mr-4">{{ label }}</div>
                    <div class="flex justify-end items-center">
                        <span class="text-main mr-2">{{ checkeds || '' }}</span>
                        <n-icon size="14"><ArrowRight12Regular /></n-icon>
                    </div>
                </section>
            </n-button>
        </template>

        <template #default>
            <section class="bg-white dark:bg-darkCardBg overflow-hidden p-0 flex flex-col h-full">
                <div class="px-3 mt-2">
                    <rb-input
                        :placeholder="refs.localization.filters.master_slave.search"
                        v-model:value="searchFilter" />
                </div>
                <div v-if="!emptyBotsList" class="w-full px-3 mt-2 flex justify-between">
                    <n-button
                        size="small"
                        class="rounded-md flex items-center text-gray-600 dark:text-white/75"
                        @click="removeAll">
                        {{ refs.localization.filters.master_slave.btn__remove_all }}
                    </n-button>
                    <n-button
                        type="default"
                        size="small"
                        class="rounded-md flex items-center text-gray-600 dark:text-white/75"
                        @click="addAll">
                        {{ refs.localization.filters.master_slave.btn__add_all }}
                    </n-button>
                </div>
                <div class="flex-grow pb-2 overflow-y-auto" v-if="allBots.length">
                    <div
                        v-for="item in allBots"
                        :key="item.value">
                        <template v-if="item.children.length">
                            <div class="flex justify-start items-center w-full px-3 sticky top-0 bg-white dark:bg-darkCardBg z-50 opacity-100">
                                <rb-checkbox
                                    class="py-2"
                                    :label="item.label"
                                    :checked="isAllChecked(item)"
                                    @update:checked="setCheckAll(item, $event)" />
                                <div class="text-main ml-1">({{ item.children.length }})</div>
                            </div>
                            
                            <template
                                v-for="bot in item.children"
                                :key="bot.value">
                                <rb-checkbox
                                    class="w-full pl-6 pr-3 py-2 dark:odd:bg-[#242424] odd:bg-gray-50 rounded-none"
                                    :label="bot.label"
                                    v-model:checked="bot.checked"
                                    @update:checked="setCheck" />
                            </template>
                        </template>
                    </div>
                    <div v-if="emptyBotsList" class="text-center py-4">No data</div>
                </div>
                <div v-if="!emptyBotsList" class="w-full flex justify-end items-center py-2 px-3">
                    <n-button
                        secondary
                        quaternary
                        type="default"
                        class="rounded-md flex items-center text-main"
                        @click="show = false">
                        {{ refs.localization.filters.general.filters_done_label }}
                    </n-button>
                </div>
            </section>
        </template>
    </n-popover>

    <n-button
        secondary
        type="default"
        class="px-2 ml-1 rounded-md"
        @click="$emit('setVisibleFilters', data.filter)">
        <n-icon size="24" class="text-red-500">
            <DeleteDismiss24Regular />
        </n-icon>
    </n-button>
</div>
</template>

<script>
// general
import general from './general';

// vue
import { defineComponent } from 'vue';

// naive
import {
    NTag,
    NIcon,
    NButton,
    NDrawer,
    NPopover,
    NDropdown,
    NDrawerContent } from 'naive-ui';

// components
import RbCoin from '@components/rb-coin';
import RbInput from '@components/rb-input';
import RbCheckbox from '@components/rb-checkbox';

// icons
import {
    ArrowLeft12Regular,
    ArrowRight12Regular,
    DeleteDismiss24Regular } from '@vicons/fluent';

export default defineComponent({
    name: 'bots-filter-desktop',
    props: {
        data: {
            type: Object,
            required: true,
        },
        filters: {
            type: Object,
            required: true,
        },
        drawerId: {
            type: String,
            required: true,
        },
        visible: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        RbCoin,
        NTag,
        NIcon,
        NButton,
        NDrawer,
        RbInput,
        NPopover,
        NDropdown,
        RbCheckbox,
        NDrawerContent,
        ArrowLeft12Regular,
        ArrowRight12Regular,
        DeleteDismiss24Regular,
    },
    setup(props, context) {

        return {
            ...general(props, context),
        };
    },
});
</script>

<style lang="scss" scoped>
@import '@/css/stock-icon.scss';

:deep(.n-button__content) {
    width: 100%;
}
</style>`

<style lang="scss">
.n-popover__content {
    height: 100%;
}
</style>