<template>
<section class="text-left px-3">
    <h4 class="text-left my-2 text-base text-gray-600 dark:text-white/75 font-semibold">
        {{ refs.localization.tasks.title }}
    </h4>
    <n-alert class="mt-2 rounded-md" type="success">
        <div class="font-bold text-xs notes" v-html="refs.localization?.tasks?.info"></div>
    </n-alert>
    <section class="flex justify-between text-xs text-gray-500 dark:text-white/50 mt-6">
      <div>{{ refs.localization?.tasks?.done || "Выполнено" }}</div>
      <strong>{{ refs.countShiftedTask }} / {{ refs.countTask }}</strong>
    </section>

    <n-progress
      type="line"
      class="mt-2"
      :height="4"
      :percentage="refs.percentShiftedTask"
      :color="gl.mainColor"
      :show-indicator="false" />

    <div
        v-for="(item, i) in listOfTasks"
        :key="i"
        class="mb-2">
        <n-card
            size="small"
            :bordered="false"
            class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg mt-4 h-full"
            :segmented="{
              content: true,
              footer: 'soft',
            }">
          <template #header>
            <section class="flex justify-between items-center">
              <div class="text-left mt-2 text-sm text-gray-600 dark:text-white/75 font-semibold">
                <span class="opacity-50">{{ i + 1 }}.</span>&nbsp;
                <span class="notes" v-html="item.title"></span>
              </div>
              <n-tag v-if="item.done" round :bordered="false" type="success" size="small">
                {{ refs.localization?.tasks?.done }}
                <template #icon>
                  <n-icon>
                    <CheckmarkCircle12Filled />
                  </n-icon>
                </template>
              </n-tag>
              <n-tag v-else round :bordered="false" type="warning" size="small">
                {{ refs.localization?.tasks?.awaits_execution }}
                <template #icon>
                  <n-icon>
                    <DismissCircle16Filled />
                  </n-icon>
                </template>
              </n-tag>
            </section>
          </template>
          <template #default>
            <div class="notes text-xs" v-html="item.text"></div>
          </template>
        </n-card>
    </div>
</section>
</template>

<script>
// vue
import { defineComponent } from 'vue';

// general
import general from './general';

// naive-ui
import { NTag, NCard, NIcon, NProgress, NAlert } from 'naive-ui';

// icons
import { DismissCircle16Filled, CheckmarkCircle12Filled } from '@vicons/fluent';

export default {
    name: 'tasks-mobile',
    components: {
        NTag,
        NCard,
        NIcon,
        NAlert,
        NProgress,
        DismissCircle16Filled,
        CheckmarkCircle12Filled,
    },
    setup() {
        return {
            ...general(),
        };
    },
};
</script>
