<template>
<n-card
    size="small"
    :bordered="false"
    class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg"
    :segmented="{
      content: true,
      footer: 'soft'
    }">
    <template #default>
        <div class="border border-dashed border-gray-600 rounded-md flex flex-col items-center py-6">
            <WalletsIcon class="w-24 fill-current" />
            <n-button
                strong
                class="mt-4 text-white/90 rounded-md"
                :color="gl.mainColor"
                @click="show = true">
                + {{ wallets.localization.wallets_create_title_button }}
            </n-button>
        </div>
    </template>
</n-card>

<base-modal-desktop
    width="700px"
    :label="mainButtonLabel"
    v-model:show="show">
    <template #default>
        <div class="relative">
            <section class="flex">
                <rb-input
                    id="name"
                    class="w-6/12 pr-2"
                    :label="dataForm.name.title"
                    :status="dataForm.name.status === 'error' ? 'error' : undefined"
                    :msg="dataForm.name.msg"
                    :placeholder="dataForm.name.placeholder"
                    v-model:value="dataForm.name.value"
                    @update:value="dataForm.name.status = null, dataForm.name.msg = null" />
                
                <rb-select
                    v-if="mode === 'create'"
                    class="w-6/12 pl-2"
                    :label="dataForm.exchange.title"
                    :options="exchangeOptions"
                    :status="dataForm.exchange.status === 'error' ? 'error' : undefined"
                    :msg="dataForm.exchange.msg"
                    v-model:value="dataForm.exchange.value"
                    @update:value="dataForm.exchange.status = null, dataForm.exchange.msg = null" />
                <div v-else class="text-left w-4/12 px-2">
                    <div class="text-md">{{ dataForm.exchange.title }}</div>
                    <div
                        class="rb-td-stock-icon m-0 h-12"
                        :class="`rb-td-stock-icon-${exchange(dataForm.exchange.value)?.code_name}`">
                    </div>
                </div>
            </section>

            <template v-if="dataForm.currency.show">
                <n-divider />
                <div class="flex items-start">
                    <rb-currencies
                        v-if="mode === 'create'"
                        class="pr-2 w-6/12"
                        :label="dataForm.currency.title"
                        :options="currencyOptions"
                        :status="dataForm.currency.status === 'error' ? 'error' : undefined"
                        :msg="dataForm.currency.msg"
                        v-model:value="dataForm.currency.value"
                        @update:value="dataForm.currency.status = null, dataForm.currency.msg = null" />
                    <div v-else class="text-left w-4/12 pl-2">
                        <div class="text-me">{{ dataForm.currency.title }}</div>
                        <div class="flex items-center mt-3">
                            <rb-coin class="h-6" :coin="currency(dataForm.currency.value)" />
                            <div class="text-center font-semibold ml-2">{{ currency(dataForm.currency.value)?.title }}</div>
                        </div>
                    </div>
                    <div class="w-6/12 pl-2">
                        <rb-input
                            id="amount"
                            :helpWarning="wallets.localization.wallets_minimum_depo_info"
                            :label="dataForm.amount.title"
                            :status="dataForm.amount.status === 'error' ? 'error' : undefined"
                            :msg="dataForm.amount.msg"
                            :placeholder="dataForm.amount.placeholder"
                            v-model:value="dataForm.amount.value"
                            @update:value="dataForm.amount.status = null, dataForm.amount.msg = null">
                            <template #beforeInput>
                                <n-radio-group
                                    class="mr-4"
                                    v-model:value="dataForm.type">
                                    <n-radio-button
                                        v-for="option in walletTypesOptions"
                                        :key="option.value"
                                        :value="option.value"
                                        :label="option.label" />
                                </n-radio-group>
                            </template>
                        </rb-input>
                    </div>
                </div>

                <n-divider />
                <div class="flex flex-wrap items-end">
                    <rb-select
                        class="flex-grow pr-2"
                        :label="dataForm.api_key.title"
                        :options="apiKeysOptions"
                        :status="dataForm.api_key.status === 'error' ? 'error' : undefined"
                        :msg="dataForm.api_key.msg"
                        v-model:value="dataForm.api_key.value"
                        @update:value="dataForm.api_key.status = null, dataForm.api_key.msg = null" />
                    <div class="pl-2">
                        <div class="flex">
                            <n-button
                                class="text-white/90 rounded-md mr-2"
                                :color="gl.mainColor"
                                :disabled="!isExchangeSelected"
                                @click="walletApiKeyDialog = true">
                                + {{ wallets.localization.wallets_api_createnew }}
                            </n-button>
                            <n-button
                                class="ml-2 text-white/90 rounded-md"
                                :color="gl.mainColor"
                                :disabled="!canCheckBalance"
                                @click="onCheckBalance">
                                {{ wallets.localization.wallets_check_balance_button }}
                            </n-button>
                        </div>
                    </div>

                    <div class="w-full flex justify-end mt-4">
                        <rb-checkbox
                            :disabled="!canCheckBalance"
                            :label="wallets.localization.wallets_show_all_non_zero"
                            v-model:checked="showAllNonZeroBalances" />
                    </div>

                    <n-card
                        v-if="symbolBalance || symbolTotalBalance"
                        size="small"
                        :bordered="false"
                        class="overflow-hidden shadow rounded-md dark:bg-darkbg bg-white w-full mt-6"
                        :segmented="{
                            content: true,
                            footer: 'soft'
                        }">
                        <div class="w-full flex justify-between">
                            <n-statistic
                                v-if="symbolBalance"
                                tabular-nums
                                class="text-left"
                                :label="wallets.localization['wallets_symbol_balance__available_amount']">
                                <n-number-animation
                                    :from="0"
                                    :to="+symbolBalance"
                                    :precision="8"
                                />
                            </n-statistic>
                            <n-statistic
                                v-if="symbolTotalBalance"
                                tabular-nums
                                class="text-left"
                                :label="wallets.localization['wallets_symbol_balance__balance_with_orders']">
                                <n-number-animation
                                    :from="0"
                                    :to="+symbolTotalBalance"
                                    :precision="8"
                                />
                            </n-statistic>
                        </div>
                    </n-card>

                    <div
                        v-if="showAllNonZeroBalances && allSymbolsBalances"
                        class="flex flex-wrap items-stretch mt-6 w-full">
                        <div
                            v-for="(item, i) in allSymbolsBalances"
                            :key="i"
                            class="mb-4 w-6/12 even:pl-2 odd:pr-2">
                            <n-card
                                size="small"
                                :bordered="false"
                                class="overflow-hidden shadow rounded-md dark:bg-darkbg bg-white h-full"
                                :segmented="{
                                    content: true,
                                    footer: 'soft'
                                }">
                                <div class="flex text-gray-600 dark:text-white/75">
                                    <div class="w-3/12 flex flex-col items-center">
                                        <rb-coin class="h-6" :coin="currency(item.symbol)" />
                                        <div class="font-semibold mt-2 text-[10px] text-center">{{ currency(item.symbol)?.title }}</div>
                                    </div>

                                    <div class="w-9/12 text-xs">
                                        <div class="text-right">
                                            {{ wallets.localization.wallets_check_balance_available }}
                                            <span class="font-bold text-main">{{ item.available }}</span>
                                        </div>
                                        <div class="text-right">
                                            {{ wallets.localization.wallets_extra_balance__with_orders }}
                                            <span class="text-main">{{ item.total }}</span>
                                        </div>
                                    </div>
                                </div>
                            </n-card>
                        </div>
                    </div>
                </div>
            </template>
            
            <section>
                <n-divider />
                <rb-input
                    id="comment"
                    class="w-full"
                    type="textarea"
                    :label="dataForm.comment.title"
                    :status="dataForm.comment.status === 'error' ? 'error' : undefined"
                    :msg="dataForm.comment.msg"
                    :placeholder="dataForm.comment.placeholder"
                    v-model:value="dataForm.comment.value"
                    @update:value="dataForm.comment.status = null, dataForm.comment.msg = null" />
            </section>
        </div>
    </template>
    <template #footer>
        <div class="flex justify-end">
            <!-- :disabled="!canCreateWallet" -->
            <n-button
                secondary
                type="default"
                class="rounded-md text-gray-600 dark:text-white/75"
                @click="hideModal">
                {{ refs.localization.dialogs.cancel }}
            </n-button>
            <n-button
                class="ml-2 text-white/90 rounded-md"
                @click="onActionWallet"
                :loading="localLoading"
                :disabled="localLoading"
                :color="gl.mainColor" >
                {{ applyLabel }}
            </n-button>
        </div>
    </template>
</base-modal-desktop>

<base-modal-desktop
    v-model:show="walletApiKeyDialog">
    <template #headerContent>
        <span :class="`h-8 inline-block rb-td-stock-icon rb-td-stock-icon-${exchange(exchangeInfo.id).code_name}`"></span>
    </template>
    <template #default>
        <rb-input
            :label="apiKeyDataForm.name.title"
            :placeholder="apiKeyDataForm.name.placeholder"
            :status="apiKeyDataForm.name.status === 'error' ? 'error' : undefined"
            :msg="apiKeyDataForm.name.msg"
            v-model:value="apiKeyDataForm.name.value"
            @update:value="apiKeyDataForm.name.status = null, apiKeyDataForm.name.msg = null" />
        <n-divider />

        <rb-input
            :label="apiKeyDataForm.key.title"
            :placeholder="apiKeyDataForm.key.placeholder"
            :status="apiKeyDataForm.key.status === 'error' ? 'error' : undefined"
            :msg="apiKeyDataForm.key.msg"
            v-model:value="apiKeyDataForm.key.value"
            @update:value="apiKeyDataForm.key.status = null, apiKeyDataForm.key.msg = null" />
        <n-alert v-if="exchangeMetaInfo" class="rounded-md" type="warning" size="small">
            <div class="notes text-xs" v-html="exchangeMetaInfo"></div>
        </n-alert>
        <n-alert v-if="exchangeMeta2Info" class="rounded-md mt-4" type="warning" size="small">
            <div class="notes text-xs" v-html="exchangeMeta2Info"></div>
        </n-alert>
        <n-divider />

        <rb-input
            :label="apiKeyDataForm.sign.title"
            :placeholder="apiKeyDataForm.sign.placeholder"
            :status="apiKeyDataForm.sign.status === 'error' ? 'error' : undefined"
            :msg="apiKeyDataForm.sign.msg"
            v-model:value="apiKeyDataForm.sign.value"
            @update:value="apiKeyDataForm.sign.status = null, apiKeyDataForm.sign.msg = null" />
    </template>
    <template #footer>
        <div class="flex justify-end">
            <n-button
                class="rounded-md text-white/90"
                :color="gl.mainColor"
                @click="onCreateApiKey">
                {{ wallets.localization.wallets_api_createnew_modal_add_new_button }}
            </n-button>
        </div>
    </template>
</base-modal-desktop>
</template>

<script>
// vue
import { ref, reactive, computed, watch, onMounted } from 'vue';

// services
import ApiKeysService from'@services/apiKeysService';
import WalletsService from '@services/walletsService';
import ExchangesService from '@services/exchangesService';

// store
import { useGl } from '@/store/gl';
import { useBots } from '@/store/bots';
import { useRefs } from '@/store/refs';
import { useTools } from '@store/tools';
import { useWallets } from '@store/wallets';

// shared
import { WALLET_DATA_FORM_FACTORY } from '@shared/factories';
import { formatUnexistedCurrsAsUnknown } from '@shared/util';

// i18n
import { useI18n } from 'vue-i18n';

// components
import RbCoin from '@components/rb-coin';
import RbInput from '@components/rb-input';
import RbSelect from '@components/rb-select';
import RbCheckbox from '@components/rb-checkbox';
import BaseModal from '@components/base/base-modal';
import RbCurrencies from '@components/rb-currencies';
import WalletsIcon from '@components/icons/wallets.vue';

// icons
import { Warning24Filled } from '@vicons/fluent';

// router
import { useRoute, useRouter } from 'vue-router';

// naive-ui
import {
    NIcon,
    NCard,
    NAlert,
    NInput,
    NModal,
    NSpin,
    NSelect,
    NButton,
    NDivider,
    NPopover,
    NCheckbox,
    NCollapse,
    NStatistic,
    NRadioGroup,
    NRadioButton,
    NCollapseItem,
    NNumberAnimation,
    useNotification } from 'naive-ui';

export default {
    name: 'walletCreate',
    props: {
        data: Object,
    },
    components: {
        NIcon,
        NCard,
        NModal,
        NSpin,
        NAlert,
        RbCoin,
        RbInput,
        NButton,
        RbSelect,
        NDivider,
        NCollapse,
        BaseModal,
        NStatistic,
        RbCheckbox,
        WalletsIcon,
        NRadioGroup,
        NRadioButton,
        RbCurrencies,
        NCollapseItem,
        Warning24Filled,
        NNumberAnimation,
    },
    setup(props, { emit }) {
        // store
        const gl = useGl();
        const refs = useRefs();
        const wallets = useWallets();

        // ui
        const notification = useNotification();

        // router
        const route = useRoute();
        const router = useRouter();

        // i18n
        const { t } = useI18n();

        // vars
        const { _ } = window;
        const apiKey = ref();
        const show = ref(false);
        const mode = ref('create');
        const init = ref(false);
        const apiKeysRefs = ref();
        const exchangeInfo = ref();
        const collapseItem = ref();
        const localLoading = ref(false);
        const balancesRefs = ref({});
        const showAllNonZeroBalances = ref(true);
        const walletApiKeyDialog = ref(false);
        const dataForm = reactive({});

        watch(() => route.params.id, v => {
            if (v)
                show.value = true;
        });

        watch(show, v => {
            if (v) {
                initModule();
            } else {
                emit('clear');
                mode.value = 'create';
                router.push({ name: 'wallets' });
            }
        });

        watch(() => dataForm.exchange?.value, async v => {
            if (!init.value) return;

            localLoading.value = true;
            
            if (v != -1) {
                try {
                    if (mode.value === 'create')
                        dataForm.currency.value = -1;

                    await Promise.allSettled([
                        getFullInfo(v, [false, false]),
                        getApiKeysList(v, [false, false]),
                    ]);
                } catch {
                    notification.error({
                        content: t('errorMessage'),
                        duration: 2500,
                        keepAliveOnHover: true,
                    });
                };
            }

            localLoading.value = false;
        });

        watch(() => dataForm.api_key?.value, () => {
            balancesRefs.value = {};
        });

        // computeds
        const isExchangeSelected = computed(() => dataForm.exchange.value !== -1 && exchangeInfo.value);
        const apiKeySelected = computed(() => dataForm.api_key.value !== -1);
        const currencySelected = computed(() => dataForm.currency.value !== -1);
        const canCheckBalance = computed(() => isExchangeSelected.value && apiKeySelected.value);
        const exchangeOptions = computed(() => refs.exchanges.map(el => ({
            label: el.title,
            value: el.id,
            ...el,
        })));

        const currencyOptions = computed(() => Array.isArray(exchangeInfo.value?.currencies)
            ? formatUnexistedCurrsAsUnknown(refs, exchangeInfo.value.currencies).map(el => ({
                value: el.id,
                label: el.title,
                ...el,
            }))
            : []);

        const walletTypesOptions = computed(() => refs.walletTypes.map(el => ({
            label: el.title,
            value: el.id,
            ...el,
        })));

        const apiKeysOptions = computed(() => Array.isArray(apiKeysRefs.value?.records)
            ? apiKeysRefs.value.records.map(el => ({
                value: el.id,
                label: el.name,
                ...el,
            })) : []);

        const allSymbolsBalances = computed(() => {
            const curSymbol = dataForm.currency.value;
            let balancesMap = [], balancesCheckMap = {};
            
            _.forOwn(balancesRefs.value, (value, key) => {
                const [symbol, hasTotal] = key.split('_');
                const symEntry = _.get(balancesCheckMap, symbol, {available: 0.00, total: 0.00});
                
                if (hasTotal) {
                    symEntry.total = value;
                } else {
                    symEntry.available = value;
                }
                balancesCheckMap[symbol] = symEntry;
            });
            
            if (curSymbol && curSymbol != -1) {
                let curSymbolInfo = _.get(balancesCheckMap, curSymbol, {available: 0.00, total: 0.00});
                delete balancesCheckMap[curSymbol];
                balancesMap.push({symbol: curSymbol, ...curSymbolInfo});
            }
            
            _.forEach(Object.keys(balancesCheckMap).sort(), (symbol) => {
                const {available, total} = balancesCheckMap[symbol];
                
                if (parseFloat(total, 10) === 0) return;
                
                balancesMap.push({symbol, available, total});
            });
            
            return Object.keys(balancesRefs.value).length > 0 ? balancesMap : null;
        });

        const exchangeMetaInfo = computed(() => refs.exchanges.find(el => el.id === dataForm.exchange.value)?.meta);
        const exchangeMeta2Info = computed(() => refs.exchanges.find(el => el.id === dataForm.exchange.value)?.meta2);
        const canCreateWallet = computed(() => isExchangeSelected.value && currencySelected.value);

        const symbolBalance = computed(() => balancesRefs.value?.[dataForm.currency.value] || '');
        const symbolTotalBalance = computed(() => balancesRefs.value?.[`${dataForm.currency.value}_total`] || '');
        const mainButtonLabel = computed(() => props.data?.id ? wallets.localization.wallets_update_title_button : wallets.localization.wallets_create_title_button);
        const applyLabel = computed(() => props.data?.id ? wallets.localization.wallets_update_title_button : wallets.localization.wallets_create_title_button);

        const apiKeyDataForm = reactive({
            name: {
                value: '',
                title: wallets.localization['wallets_api_createnew_modal_name_f'],
                placeholder: wallets.localization['wallets_api_createnew_modal_name_i'],
                status: undefined,
                msg: undefined,
            },
            key: {
                value: '',
                title: wallets.localization['wallets_api_f'],
                placeholder: wallets.localization['wallets_api_createnew_modal_apikey_i'],
                status: undefined,
                msg: undefined,
            },
            sign: {
                value: '',
                title: wallets.localization['wallets_api_createnew_modal_sign_f'],
                placeholder: wallets.localization['wallets_api_createnew_modal_sign_i'],
                status: undefined,
                msg: undefined,
            },
        });

        const getFullInfo = async (id) => {
            localLoading.value = true;

            try {
                exchangeInfo.value = ( await ExchangesService.getFullInfo(id) ).data;
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            };

            localLoading.value = false;
        };

        const getApiKeysList = async (id = dataForm.exchange.value) => {
            localLoading.value = true;

            try {
                apiKeysRefs.value = ( await ApiKeysService.getApiKeysList({exchanges: [id]}) ).data;
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            };
            
            localLoading.value = false;
        };

        const walletsRefs = async () => {
            if (wallets.refs) return;

            localLoading.value = true;

            try {
                wallets.refs = ( await WalletsService.getRefs() ).data;
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            };
            
            localLoading.value = false;
        };

        const initModule = () => {
            const res = WALLET_DATA_FORM_FACTORY(props.data);
            mode.value = props.data ? 'edit' : 'create';

            for (const [key, value] of Object.entries(res)) {
                if (key === 'name') {
                    dataForm[key] = {
                        value,
                        title: wallets.localization['wallets_name_f'],
                        placeholder: wallets.localization['wallets_name_i'],
                        status: undefined,
                        msg: undefined,
                        loading: false,
                        show: true,
                        customField: true,
                    };
                } else if (key === 'comment') {
                    dataForm[key] = {
                        value,
                        title: wallets.localization['wallets_comment_f'],
                        placeholder: wallets.localization['wallets_comment_i'],
                        status: undefined,
                        msg: undefined,
                        loading: false,
                        show: true,
                        customField: true,
                    };
                } else if (key === 'exchange') {
                    dataForm[key] = {
                        value,
                        title: wallets.localization['wallets_exchange_f'],
                        placeholder: wallets.localization['wallets_exchange_i'],
                        status: undefined,
                        msg: undefined,
                        loading: false,
                        show: true,
                        customField: true,
                    };
                } else if (key === 'currency') {
                    dataForm[key] = {
                        value,
                        title: wallets.localization['wallets_currency_f'],
                        placeholder: wallets.localization['wallets_currency_i'],
                        status: undefined,
                        msg: undefined,
                        loading: false,
                        show: computed(() => isExchangeSelected.value),
                        customField: true,
                    };
                } else if (key === 'amount') {
                    dataForm[key] = {
                        value,
                        title: wallets.localization['wallets_amount_f'],
                        placeholder: wallets.localization['wallets_amount_i'],
                        status: undefined,
                        msg: undefined,
                        loading: false,
                        show: computed(() => isExchangeSelected.value),
                        customField: true,
                    };
                } else if (key === 'api_key') {
                    dataForm[key] = {
                        value,
                        title: wallets.localization['wallets_api_f'],
                        placeholder: wallets.localization['wallets_api_i'],
                        status: undefined,
                        msg: undefined,
                        loading: false,
                        show: computed(() => isExchangeSelected.value),
                        customField: true,
                    };
                } else dataForm[key] = value;
            };
        };

        const onCreateApiKey = async () => {
            gl.showLoading = true;

            const records = {
                exchange: dataForm.exchange.value,
                name: apiKeyDataForm.name.value,
                key: apiKeyDataForm.key.value,
                sign: apiKeyDataForm.sign.value,
                _formPath: 'walletApiKeyDialog',
            };

            const result = await ApiKeysService.addApiKeyToExchange(records.exchange, records);

            if (!result.data.status) {
                if (result.data?.errors_form) {

                    for (let key in apiKeyDataForm) {
                        const fields = result.data.errors_form['walletApiKeyDialog'].fields;
                        const el = Object.keys(fields).find(el => el === key);

                        if (el) {
                            apiKeyDataForm[key].status = 'error';
                            apiKeyDataForm[key].msg = fields[el].msg;
                        } else {
                            apiKeyDataForm[key].status = 'success';
                            apiKeyDataForm[key].msg = undefined;
                        }
                    }
                }
            } else {
                // clean apiKeyModel
                for (let key in apiKeyDataForm)
                    apiKeyDataForm[key].value = '';

                // show messages
                result.postMessages.forEach(el => {
                    notification[el.success ? 'success' : 'error']({
                        content: el.msg,
                        duration: 2500,
                        keepAliveOnHover: true,
                    });
                });

                walletApiKeyDialog.value = false;

                await getApiKeysList();

                // change api key
                dataForm.api_key.value = result.data.records[0].id;

                router.go(-1);
            }

            gl.showLoading = false;
        };

        const onActionWallet = async () => {
            gl.showLoading = true;

            const records = {
                name: dataForm.name.value,
                comment: dataForm.comment.value,
                currency: dataForm.currency.value,
                exchange: dataForm.exchange.value,
                type: dataForm.type,
                amount: dataForm.amount.value,
                api_key: dataForm.api_key.value,
                _formPath: 'wallets.addNew',
            };

            let prepare;

            try {
                prepare = mode.value === 'create'
                    ? await WalletsService.addNew(records)
                    : await WalletsService.udpate({
                        id: dataForm.id,
                        ...records,
                    });
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            }
            
            if (!prepare.data.status) {
                if (prepare.data?.errors_form) {

                    for (let key in dataForm) {
                        const fields = prepare.data.errors_form['wallets.addNew'].fields;
                        const el = Object.keys(fields).find(el => el === key);

                        if (el) {
                            dataForm[key].status = 'error';
                            dataForm[key].msg = fields[el].msg;
                        } else {
                            if (typeof dataForm[key] === 'object' && dataForm[key] !== null) {
                                dataForm[key].status = 'success';
                                dataForm[key].msg = undefined;
                            }
                        }
                    }
                }
            } else {
                show.value = false;
                if (mode.value !== 'create') {
                    emit('updateWallet', prepare.data.record);
                }
                // clean apiKeyModel
                for (let key in dataForm) {
                    if (typeof dataForm[key] === 'object' && dataForm[key] !== null)
                        dataForm[key].value = '';
                };

                // show messages
                prepare.postMessages.forEach(el => {
                    notification.success({
                        content: el.msg,
                        duration: 2500,
                        keepAliveOnHover: true,
                    });
                });
            }

            gl.showLoading = false;
        };

        const onCheckBalance = async () => {
            localLoading.value = true;

            const prepare = await WalletsService.getBalances(dataForm.api_key.value);

            if (!prepare.data.status) {
                prepare.data.errors.forEach(({ msg }) => {
                    notification.error({
                        content: msg,
                        duration: 2500,
                        keepAliveOnHover: true,
                    });
                });

                // reset balancesRefs
                balancesRefs.value = null;
            } else {
                balancesRefs.value = prepare.data.balances;
            }

            localLoading.value = false;
        };

        const exchange = exchange => refs.exchanges.find(el => el.id === exchange);
        const currency = icon => refs.currencies.find(el => el.id === icon);

        // function handleClick(dontHide) {
        //     if (dontHide) {
        //         return void initModule();
        //     }

        //     collapseItem.value.handleClick(new Event('click'));
        // };

        const hideShow = async ({ expanded }) => {
            if (expanded) {
                initModule();
            }
        };

        const hideModal = () => {
            if (route.params.id) {
                router.go(-1);
            }
            show.value = false;
        };

        onMounted(async () => {
            await walletsRefs(false);

            if (route.params.id) {
                show.value = true;
            }
            init.value = true;
        });

        return {
            gl,
            init,
            refs,
            mode,
            show,
            router,
            wallets,
            dataForm,
            applyLabel,
            localLoading,
            collapseItem,
            balancesRefs,
            exchangeInfo,
            symbolBalance,
            apiKeyDataForm,
            apiKeysOptions,
            currencyOptions,
            exchangeOptions,
            canCreateWallet,
            mainButtonLabel,
            canCheckBalance,
            exchangeMetaInfo,
            exchangeMeta2Info,
            isExchangeSelected,
            walletTypesOptions,
            walletApiKeyDialog,
            symbolTotalBalance,
            allSymbolsBalances,
            showAllNonZeroBalances,
            hideShow,
            exchange,
            currency,
            hideModal,
            onActionWallet,
            onCheckBalance,
            onCreateApiKey,
        };
    },
};
</script>