import Api from './index';
import { CRUD_PAGER_FACTORY } from '@shared/factories';

export default class WalletsService {

    static async getWalletsList(filters = {}) {
        return await Api.getData({
            action: 'wallets.get',
            payload: {
                pager: CRUD_PAGER_FACTORY({
                    exchanges: [-1],
                    currencies: [-1],
                    apikeys: [-1],
                    pairs: [-1],
                    statuses: [-1],
                    states: [-1],
                    ...filters,
                }, {
                    perPage: '*',
                }),
            },
        });
    };

    static async addNew(records) {
        return await Api.getData({
            action: 'wallets.addNew',
            payload: {
                records: [records],
                pager: CRUD_PAGER_FACTORY(),
            },
        });
    };

    static async udpate(record) {
        return await Api.getData({
            action: 'wallets.update',
            payload: {
                record,
            },
        });
    };

    static async getBalances(apikey) {
        return await Api.getData({
            action: 'wallets.getBalances',
            payload: {
                record: {
                    apikey,
                },
            },
        });
    };

    static async getRefs() {
        return await Api.getData({
            action: 'wallets.getRefs',
        });
    };

    static async walletsGet(payload) {
        return await Api.getData({
            action: 'wallets.get',
            payload,
        });
    };

    static async walletsDelete(payload) {
        return await Api.getData({
            action: 'wallets.delete',
            payload,
        });
    };

    static async getFullInfo(id) {
        return await Api.getData({
            action: 'wallets.getFullInfo',
            payload: { id },
        });
    };

    static async getTotalBalances(payload) {
        return await Api.getData({
            action: 'wallets.getTotalBalances',
            payload,
        });
    };    
}