<template>
<h4 class="text-left my-2 text-xl text-gray-600 dark:text-white/75 font-semibold">
    <n-skeleton v-if="loading" class="inline-block my-2" :width="215" :height="20" :sharp="false" />
    <template v-else>{{ refs.localization.sidebar.tools }}</template>
</h4>

<template v-if="loading">
    <section class="flex mt-3">
        <n-skeleton class="inline-block" :width="150" :height="20" :sharp="false" />
        <n-skeleton class="inline-block ml-12" :width="150" :height="20" :sharp="false" />
        <n-skeleton class="inline-block ml-12" :width="150" :height="20" :sharp="false" />
    </section>
    <n-divider class="mt-2" />
</template>
<n-tabs
    v-else
    type="line"
    size="large"
    :value="currentTab"
    @update:value="changeTab">
    <n-tab-pane
        v-for="(tab, i) in tabs"
        :key="i"
        :name="tab.name"
        :tab="tab.title"
        :disabled="loading">
    <router-view></router-view>
    </n-tab-pane>
</n-tabs>
</template>

<script>
// general
import general from './general';

// UI
import {
    NCard,
    NTabs,
    NDivider,
    NTabPane,
    NSkeleton } from 'naive-ui';

export default {
    name: 'ToolsMain',
    components: {
        NCard,
        NTabs,
        NDivider,
        NTabPane,
        NSkeleton,
    },
    setup(props, context) {
        return {
            ...general(arguments),
        };
    },
};
</script>