<template>
<n-modal
    :show="show"
    :mask-closable="maskClosable"
    @update:show="$emit('update:show', $event)"
    transform-origin="center">
    <n-card
        size="small"
        role="dialog"
        aria-modal="true"
        class="bg-white dark:bg-darkCardBg rounded-lg"
        :segmented="{
          content: true,
          footer: 'soft'
        }"
        :style="`width: ${width}px`">
        <template #header>
            <div class="relative min-h-[30px]">
                <div class="text-lg text-center text-gray-600 dark:text-white/80">{{ label }}</div>
                <slot name="headerContent"></slot>

                <n-button
                    v-if="showCLoseButton"
                    circle
                    secondary
                    size="small"
                    type="default"
                    class="absolute -right-1 top-1/2 -translate-y-1/2"
                    @click="$emit('update:show', false)">
                    <template #icon>
                        <n-icon class="text-gray-600 dark:text-white" size="16"><Dismiss16Filled /></n-icon>
                    </template>
                </n-button>
            </div>
        </template>
        <template #default><slot name="default"></slot></template>
        <template #footer><slot name="footer"></slot></template>
    </n-card>
</n-modal>
</template>

<script>
// vue
import { ref } from 'vue';

// store
import { useGl } from '@/store/gl';

// naive-ui
import {
    NIcon,
    NCard,
    NModal,
    NAlert,
    NInput,
    NSelect,
    NButton,
    NDivider,
    NPopover,
    NCheckbox,
    useMessage,
    NInputNumber,
    NButtonGroup } from 'naive-ui';

// icons
import { Dismiss16Filled } from '@vicons/fluent';

export default {
    name: 'base-modal',
    props: {
        label: {
            type: String,
            required: true,
        },
        show: {
            type: Boolean,
            default: false,
        },
        width: {
            type: [ Number, String ],
            default: 800,
        },
        maskClosable: {
            type: Boolean,
            default: true,
        },
        showCLoseButton: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        NIcon,
        NCard,
        NModal,
        NButton,
        Dismiss16Filled,
    },
    setup() {
    // store
        const gl = useGl();

        return {
            gl,
        };
    },
};
</script>