<template>
    <template v-if="dashBoard.revenueStatsIsNotEmpty">
        <mobile v-if="gl.isMobile" class="mt-4" />
        <desktop v-else class="mt-6"/>
    </template>
</template>

<script>
// vue
import { onMounted } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useDashBoard } from '@store/ts/dashBoard';

// services
import Api from '@services/api';

// naive-ui
import { useNotification } from 'naive-ui';

// i18n
import { useI18n } from 'vue-i18n';

// components
import Desktop from './desktop.vue';
import Mobile from './mobile.vue';

export default {
    name: 'dash-profit',
    components: {
        Mobile,
        Desktop,
    },
    setup() {
        // store
        const gl = useGl();
        const dashBoard = useDashBoard();

        // ui
        const notification = useNotification();

        // i18n
        const { t } = useI18n();

        const getRevenueStats = async () => {
            try {
                dashBoard.revenueStats = ( await Api.dashBoard({
                    dashboard_block: 'revenue_stats',
                }) ).data.revenue_stats;
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            }
        };

        onMounted(() => {
            getRevenueStats();
        });
    
        return {
            gl,
            dashBoard,
        };
    },
};
</script>