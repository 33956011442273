<template>
<base-drawer-mobile
    v-if="init"
    :label="mainButtonLabel"
    :show="show"
    @update:show="$emit('close')">
    <template #default>
        <rb-input
            id="name"
            :label="dataForm.name.title"
            :status="dataForm.name.status === 'error' ? 'error' : undefined"
            :msg="dataForm.name.msg"
            :placeholder="dataForm.name.placeholder"
            v-model:value="dataForm.name.value"
            @update:value="dataForm.name.status = null, dataForm.name.msg = null" />

        <rb-select
            v-if="mode === 'create'"
            class="mt-2"
            :label="dataForm.exchange.title"
            :options="exchangeOptions"
            :status="dataForm.exchange.status === 'error' ? 'error' : undefined"
            :msg="dataForm.exchange.msg"
            v-model:value="dataForm.exchange.value"
            @update:value="dataForm.exchange.status = null, dataForm.exchange.msg = null" />
        <div v-else class="text-left">
            <div class="text-md">{{ dataForm.exchange.title }}</div>
            <div
                class="rb-td-stock-icon m-0 h-12"
                :class="`rb-td-stock-icon-${exchange(dataForm.exchange.value)?.code_name}`">
            </div>
        </div>

        <n-divider />
        <rb-input
            :label="dataForm.key.title"
            :status="dataForm.key.status === 'error' ? 'error' : undefined"
            :msg="dataForm.key.msg"
            :placeholder="dataForm.key.placeholder"
            v-model:value="dataForm.key.value"
            @update:value="dataForm.key.status = null, dataForm.key.msg = null" />
        <n-alert v-if="exchangeMetaInfo" class="rounded-md" type="warning" size="small">
            <div class="notes text-xs" v-html="exchangeMetaInfo"></div>
        </n-alert>
        <n-alert v-if="exchangeMeta2Info" class="rounded-md mt-4" type="warning" size="small">
            <div class="notes text-xs" v-html="exchangeMeta2Info"></div>
        </n-alert>

        <n-divider />
        <rb-input
            :label="dataForm.sign.title"
            :status="dataForm.sign.status === 'error' ? 'error' : undefined"
            :msg="dataForm.sign.msg"
            :placeholder="dataForm.sign.placeholder"
            v-model:value="dataForm.sign.value"
            @update:value="dataForm.sign.status = null, dataForm.sign.msg = null" />
    </template>
    <template #footer>
        <div class="w-full flex justify-between items-center">
            <rb-checkbox
                class="mr-4"
                :label="dataForm.show_dashboard.title"
                v-model:checked="dataForm.show_dashboard.value" />
            <!-- :disabled="!canCreateWallet" -->
            <n-button
                size="small"
                class="text-white/90 rounded-md"
                :disabled="localLoading"
                :loading="localLoading"
                @click="onCreateApiKey"
                :color="gl.mainColor" >
                {{ applyLabel }}
            </n-button>
        </div>
    </template>
</base-drawer-mobile>
</template>

<script>
// components
import RbInput from '@components/rb-input/mobile.vue';
import RbSelect from '@components/rb-select/mobile.vue';
import RbCheckbox from '@components/rb-checkbox/mobile.vue';
import ApiKeysIcon from '@components/icons/api-keys.vue';

// icons
import { Warning24Filled } from '@vicons/fluent';

// general function
import general from './general';

// naive-ui
import {
    NIcon,
    NCard,
    NAlert,
    NButton,
    NDivider,
    NCollapse,
    NCollapseItem } from 'naive-ui';

export default {
    name: 'rb-apikeys-create',
    props: {
        data: Object,
        show: {
            type: Boolean,
            default: false,
        },
        showDashboard: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        NIcon,
        NCard,
        NAlert,
        RbInput,
        NButton,
        NDivider,
        RbSelect,
        NCollapse,
        RbCheckbox,
        ApiKeysIcon,
        NCollapseItem,
        Warning24Filled,
    },
    setup(props, context) {
        return {
            ...general(props, context),
        };
    },
};
</script>