import { defineStore } from 'pinia';

export const useProfile = defineStore('profile', {
    state: () => ({
        refs: null,
        fullInfo: null,
        errors: {},
    }),
    getters: {
        localization(state) {
            return state.refs?.localization[Object.keys(state.refs.localization)[0]];
        },
        languages(state) {
            return state.refs.languages;
        },
        messengers(state) {
            return state.refs.messengers;
        },
        statViews(state) {
            return state.refs.stat_views;
        },
        finOpTypes(state) {
            return state.refs.finOpTypes;
        },
        botFilters(state) {
            return state.refs.bot_filters;
        },
        transactionTypes(state) {
            return state.refs.transaction_types;
        },
        transactionStatuses(state) {
            return state.refs?.transaction_statuses;
        },
        bannerSizes(state) {
            return state.refs?.bannerSizes;
        },
        oauthProviders(state) {
            return state.refs?.oauth_providers;
        },
        notificationsAutoremoveList(state) {
            return state.refs?.notifications_autoremove_list;
        },
        twoFactorAuthTypes(state) {
            return state.refs?.two_factor_auth_types.map(el => ({
                ...el,
                id: el.id == 0 ? false : el.id,
            }));
        },
    },
    actions: {
        cleanError(key) {
            delete this.errors[key];
        },
    },
});