<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_214_41133)">
    <path d="M15.9583 24.3125C15.2795 24.3125 14.7292 23.7622 14.7292 23.0833V13.25C14.7292 12.5712 15.2795 12.0208 15.9583 12.0208H23.3333C24.0122 12.0208 24.5625 12.5711 24.5625 13.25V23.0833C24.5625 23.7622 24.0122 24.3125 23.3333 24.3125H15.9583ZM3.66667 14.4792C2.98782 14.4792 2.4375 13.9288 2.4375 13.25V3.41667C2.4375 2.73782 2.98782 2.1875 3.66667 2.1875H11.0417C11.7205 2.1875 12.2708 2.73782 12.2708 3.41667V13.25C12.2708 13.9288 11.7205 14.4792 11.0417 14.4792H3.66667ZM9.19792 12.0208C9.53734 12.0208 9.8125 11.7457 9.8125 11.4062V5.26042C9.8125 4.92099 9.53734 4.64583 9.19792 4.64583H5.51042C5.17099 4.64583 4.89583 4.92099 4.89583 5.26042V11.4062C4.89583 11.7457 5.17099 12.0208 5.51042 12.0208H9.19792ZM3.66667 24.3125C2.98782 24.3125 2.4375 23.7622 2.4375 23.0833V18.1667C2.4375 17.4878 2.98782 16.9375 3.66667 16.9375H11.0417C11.7205 16.9375 12.2708 17.4878 12.2708 18.1667V23.0833C12.2708 23.7622 11.7205 24.3125 11.0417 24.3125H3.66667ZM4.89583 21.2396C4.89583 21.579 5.17099 21.8542 5.51042 21.8542H9.19792C9.53734 21.8542 9.8125 21.579 9.8125 21.2396V20.0104C9.8125 19.671 9.53734 19.3958 9.19792 19.3958H5.51042C5.17099 19.3958 4.89583 19.671 4.89583 20.0104V21.2396ZM17.1875 21.2396C17.1875 21.579 17.4627 21.8542 17.8021 21.8542H21.4896C21.829 21.8542 22.1042 21.579 22.1042 21.2396V15.0938C22.1042 14.7543 21.829 14.4792 21.4896 14.4792H17.8021C17.4627 14.4792 17.1875 14.7543 17.1875 15.0938V21.2396ZM14.7292 3.41667C14.7292 2.73782 15.2795 2.1875 15.9583 2.1875H23.3333C24.0122 2.1875 24.5625 2.73782 24.5625 3.41667V8.33333C24.5625 9.01218 24.0122 9.5625 23.3333 9.5625H15.9583C15.2795 9.5625 14.7292 9.01218 14.7292 8.33333V3.41667ZM17.8021 4.64583C17.4627 4.64583 17.1875 4.92099 17.1875 5.26042V6.48958C17.1875 6.82901 17.4627 7.10417 17.8021 7.10417H21.4896C21.829 7.10417 22.1042 6.82901 22.1042 6.48958V5.26042C22.1042 4.92099 21.829 4.64583 21.4896 4.64583H17.8021Z" />
    </g>
    <defs>
    <clipPath id="clip0_214_41133">
    <rect width="24" height="24" />
    </clipPath>
    </defs>
  </svg>
</template>
