<template>
<section class="flex flex-col h-full">
    <div class="flex justify-between">
        <div class="flex items-center text-base text-left text-gray-600 dark:text-white/75 font-semibold">
            <n-skeleton v-if="!dashBoard.localization" :width="160" :height="20" :sharp="false" />
            <template v-else>{{ dashBoard.localization.dashboard_news }}</template>

            <n-skeleton v-if="!dashBoard.localization" class="inline-block ml-6 rounded-2xl" :width="125" :height="34" :sharp="false" />
            <div v-else>
              <n-button
                strong
                round
                secondary
                class="ml-6"
                type="default"
                :disabled="loading"
                @click="goTo('https://revenuebot.io/blog/')">
                {{ dashBoard.localization.dashboard_news_archive }}
              </n-button>
            </div>

            <n-skeleton v-if="!dashBoard.localization" class="inline-block ml-4" :width="66" :height="22" :sharp="false" />
            <n-button
                v-else
                strong
                size="tiny"
                class="ml-4 rounded-md"
                type="tertiary"
                @click="update"
                :loading="updateLoading"
                :disabled="updateLoading">
                {{ dashBoard.localization.dashboard_account_info_auto_refresh_button }}
            </n-button>
        </div>

        <div class="flex justify-end">
            <n-button
                circle
                secondary
                id="prev-news-slide"
                class="">
                <n-icon
                    size="15"
                    class="text-gray-600 dark:text-white/75">
                    <ArrowLeft12Regular />
                </n-icon>
            </n-button>
            <n-button
                circle
                secondary
                id="next-news-slide"
                class="ml-3">
                <n-icon
                    size="15"
                    class="text-gray-600 dark:text-white/75">
                    <ArrowRight12Regular />
                </n-icon>
            </n-button>
        </div>
    </div>

    <section class="w-full">
        <section v-if="!dashBoard.localization || !dashBoard.getNews || updateLoading" class="flex mt-4">
            <n-skeleton class="rounded-lg h-[200px] mr-1 w-4/12" :sharp="false" />
            <n-skeleton class="rounded-lg h-[200px] mx-1 w-4/12" :sharp="false" />
            <n-skeleton class="rounded-lg h-[200px] ml-1 w-4/12" :sharp="false" />
        </section>
        <template v-else>
            <!-- :pagination="{
                clickable: true,
                dynamicBullets: true,
                dynamicMainBullets: 4
            }" -->
            <swiper
                :slides-per-view="3"
                :space-between="8"
                :navigation="{
                    nextEl: '#next-news-slide',
                    prevEl: '#prev-news-slide',
                }"
                :autoplay="{
                    delay: 2000,
                    disableOnInteraction: false
                }"
                class="w-full pb-4 mt-4" >
                <swiper-slide v-for="item in dashBoard.getNews" :key="item.id">
                    <n-card
                        size="small"
                        class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg h-[200px]"
                        content-style="padding: 0;"
                        :bordered="false"
                        :segmented="{
                            content: true,
                            footer: 'soft'
                        }">
                        <section class="flex h-full p-2">
                            <img :src="item.img_url" class="w-[185px] h-[185px] object-cover rounded-lg" />

                            <div class="flex-grow text-left px-4">
                                <a
                                    :href="item.url"
                                    class="inline-block w-full text-left text-gray-600 dark:text-white/75 font-semibold overflow-ellipsis overflow-hidden hover:underline"
                                    target="_blank">
                                    {{ item.title }}
                                </a>
                                <div class="text-sm mt-2 dark:text-white/50" v-html="item.body.slice(0, 100) + ( item.body.length > 100 ? '...' : '' )"></div>
                            </div>
                        </section>
                    </n-card>
                </swiper-slide>
            </swiper>
        </template>
    </section>
</section>
</template>

<script>
// general
import general from './general';

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, Autoplay, A11y } from 'swiper';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

// naive-ui
import {
    NIcon,
    NCard,
    NButton,
    NSkeleton,
    NCarousel } from 'naive-ui';

// icons
import { ArrowLeft12Regular, ArrowRight12Regular } from '@vicons/fluent';

// install Swiper components
SwiperCore.use([Pagination, Navigation, A11y, Autoplay]);

export default {
    name: 'news-desktop',
    components: {
        NIcon,
        NCard,
        NButton,
        NCarousel,
        NSkeleton,
        Swiper,
        SwiperSlide,
        ArrowLeft12Regular,
        ArrowRight12Regular,
    },
    setup(props, context) {
        
        return {
            ...general(props, context),
        };
    },
};
</script>