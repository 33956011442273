<template>
<n-popover
    trigger="hover"
    class="max-w-[500px] bg-main rounded-md"
    placement="top">
    <template #trigger>
        <n-icon
            class="text-main cursor-pointer text-xl">
            <QuestionCircle20Filled />
        </n-icon>
    </template>
    <span class="text-xs text-white notes" v-html="data"></span>
</n-popover>
</template>

<script>
// vue
import { computed } from 'vue';

// naive-ui
import {
    NIcon,
    NInput,
    NDivider,
    NPopover } from 'naive-ui';

// icons
import { Warning24Filled, QuestionCircle20Filled } from '@vicons/fluent';

export default {
    name: 'rb-help',
    props: {
        data: {
            type: String,
            required: true,
            default: '',
        },
        helpWarning: {
            type: String,
        },
    },
    components: {
        NIcon,
        NPopover,
        QuestionCircle20Filled,
    },
    setup() {
    // 
    },
};
</script>