<template>
<div class="text-center">
    <rb-coin :coin="currency" />
    <div class="text-main text-xl font-semibold">{{ value }}</div>
    <div class="uppercase">{{ currency.symbol }}</div>
</div>
</template>

<script>
import RbCoin from '@/components/rb-coin';

export default {
    name: 'RbDepo',
    components: {
        RbCoin,
    },
    props: {
        refs: {
            type: Object,
            default: () => ({}),
        },
        value: {
            default: 0,
        },
        currency: null,
    },  
    setup() {
    // 
    },
};
</script>