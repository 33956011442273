<template>
<div v-if="visible" class="flex">
    <n-popover
        trigger="click"
        class="p-0 rounded-md"
        style="height: 400px; max-width: 400px;"
        placement="bottom-start"
        v-model:show="show">
        <template #trigger>
            <n-button
                class="rounded-md flex-grow"
                @click="show = true">
                <section class="w-full flex justify-between items-center">
                    <div class="mr-4">{{ label }}</div>
                    <div class="flex justify-end items-center">
                        <span class="text-main mr-2">{{ checkeds || '' }}</span>
                        <n-icon size="14"><ArrowRight12Regular /></n-icon>
                    </div>
                </section>
            </n-button>
        </template>
        <template #default>
            <section class="bg-white dark:bg-darkCardBg overflow-hidden p-0 flex flex-col h-full">
                <div class="px-3 mt-2">
                    <rb-input
                        :placeholder="refs.localization.filters.master_slave.search"
                        v-model:value="searchFilter" />
                </div>
                <div v-if="options.length" class="w-full px-3 py-2 dark:odd:bg-[#242424] odd:bg-gray-50 rounded-md sticky top-0 z-50">
                    <rb-checkbox
                        :checked="allChecked"
                        :label="refs.localization.filters.all_select.all"
                        @update:checked="setCheckAll" />
                </div>
                <div class="flex-grow flex flex-wrap pb-2 overflow-y-auto px-3">
                    <div
                        v-for="option in options"
                        :key="option.value"
                        class="w-6/12 even:pl-1 odd:pr-1 mt-2">
                        <n-card
                            size="small"
                            class="rounded-lg h-full bg-white dark:bg-darkCardBg cursor-pointer border-opacity-50"
                            :class="option.checked ? 'border border-main' : ''"
                            @click="setCheck(option)"
                            :segmented="{
                                content: true,
                                footer: 'soft'
                            }">
                            <section class="w-full h-full text-left">
                                <div class="w-full flex items-center justify-start">
                                    <rb-checkbox
                                        :checked="option.checked" />
                                    <span
                                        class="inline-block rb-td-stock-icon h-7 max-w-[90px]"
                                        :class="`rb-td-stock-icon-${option.code_name}`">
                                    </span>
                                </div>
                                <a
                                    class="text-main hover:underline text-xs hover:opacity-100"
                                    :class="option.checked ? 'opacity-100' : 'opacity-70'"
                                    :href="option.url" target="_blank">
                                    {{ option.url }}
                                </a>
                            </section>
                        </n-card>
                    </div>
                    <div v-if="!options.length" class="w-full text-center py-4">No data</div>
                </div>
                <div v-if="options.length" class="w-full flex justify-end items-center py-2 px-3">
                    <n-button
                        secondary
                        quaternary
                        type="default"
                        class="rounded-md flex items-center text-main"
                        @click="show = false">
                        {{ refs.localization.filters.general.filters_done_label }}
                    </n-button>
                </div>
            </section>
        </template>
    </n-popover>

    <n-button
        secondary
        type="default"
        class="px-2 ml-1 rounded-md"
        @click="$emit('setVisibleFilters', data.filter)">
        <n-icon size="24" class="text-red-500">
            <DeleteDismiss24Regular />
        </n-icon>
    </n-button>
</div>
</template>

<script>
// general
import general from './general';

// vue
import { defineComponent } from 'vue';

// naive
import {
    NIcon,
    NCard,
    NDrawer,
    NButton,
    NPopover,
    NDrawerContent } from 'naive-ui';

// components
import RbInput from '@components/rb-input';
import RbCheckbox from '@components/rb-checkbox';

// icons
import {
    ArrowLeft12Regular,
    ArrowRight12Regular,
    DeleteDismiss24Regular  } from '@vicons/fluent';


export default defineComponent({
    name: 'Filter',
    props: {
        data: {
            type: Object,
            required: true,
        },
        filters: {
            type: Object,
            required: true,
        },
        drawerId: {
            type: String,
            required: true,
        },
        visible: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        NIcon,
        NCard,
        RbInput,
        NDrawer,
        NButton,
        NPopover,
        RbCheckbox,
        NDrawerContent,
        ArrowLeft12Regular,
        ArrowRight12Regular,
        DeleteDismiss24Regular,
    },
    setup(props, context) {
        
        return {
            ...general(props, context),
        };
    },
});
</script>

<style lang="scss" scoped>
@import '@/css/stock-icon.scss';

:deep(.n-button) .n-button__content {
  width: 100%;
  height: 100%;
}
</style>

<style lang="scss">
.n-popover__content {
    height: 100%;
}
</style>