<template>
<section class="pb-2">
    <div class="text-xl text-left text-gray-700 dark:text-white/90 font-semibold">
        <n-skeleton v-if="!profile.localization" :width="160" :height="20" :sharp="false" />
        <template v-else>{{ profile.localization.profile_main_info_title }}</template>
    </div>
    <n-card
        :bordered="false"
        class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg mt-4"
        :segmented="{
            content: true,
            footer: 'soft'
        }">
        <template #default>
            <div class="flex flex-wrap">
                <section class="mb-6 pr-2 w-4/12 text-left">
                    <div class="text-md whitespace-nowrap text-gray-600 dark:text-white/75">{{ profile.localization['profile_main_info_email'] }}</div>
                    <div class="text-xl mt-3 font-semibold">{{ profile.fullInfo.email }}</div>
                </section>
                
                <section class="mb-6 px-2 w-4/12">
                    <rb-input
                        id="profile__full_name"
                        :status="profile.errors?.full_name ? 'error' : undefined"
                        :msg="profile.errors?.full_name?.msg"
                        :label="profile.localization['profile_main_info_fullname_f']"
                        :placeholder="profile.localization['profile_main_info_fullname_i']"
                        v-model:value="profile.fullInfo.full_name"
                        @update:value="profile.cleanError('full_name')" />
                </section>

                <section class="mb-6 px-2 w-4/12">
                    <rb-input
                        :label="profile.localization['profile_main_info_company_f']"
                        :placeholder="profile.localization['profile_main_info_company_i']"
                        v-model:value="profile.fullInfo.company" />
                </section>
                
                <section class="mb-4 pr-2 w-4/12">
                    <rb-select
                        :label="profile.localization['profile_main_info_timezone']"
                        :options="timezonesOptions"
                        v-model:value="profile.fullInfo.timezone" />
                </section>

                <section class="mb-4 px-2 w-4/12">
                    <rb-input
                        :label="profile.localization['profile_main_info_phone_number_f']"
                        :placeholder="profile.localization['profile_main_info_phone_number_i']"
                        v-model:value="profile.fullInfo.phone" />
                </section>

                <section class="mb-4 px-2 w-4/12">
                    <rb-select
                        :label="profile.localization['profile_main_info_preferred_language']"
                        :options="languagesOptions"
                        v-model:value="profile.fullInfo.language" />
                </section>
            </div>
        </template>
    </n-card>

    <div class="text-base text-left text-gray-600 dark:text-white/75 font-semibold mt-6">
        <n-skeleton v-if="!profile.localization" :width="160" :height="20" :sharp="false" />
        <template v-else>{{ profile.localization['profile_main_info_additional_contacts_title'] }}</template>
    </div>
    <section class="flex flex-wrap mt-4 items-stretch">
        <div
            v-for="(messenger, i) in profile.fullInfo.messengers"
            :key="i"
            class="odd:pr-1 even:pl-1 mb-2 w-6/12">
            <n-card
                :bordered="false"
                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg relative"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <n-icon
                    class="text-red-500 text-2xl ml-4 cursor-pointer absolute top-2 right-2"
                    @click="onRemoveMessenger(i)">
                    <DeleteDismiss24Regular />
                </n-icon>
                
                <div class="flex mt-4">
                    <rb-select
                        class="w-6/12 pr-1"
                        :label="profile.localization['profile_main_info_additional_contacts_contact_type_f']"
                        :options="messengersOptions"
                        v-model:value="messenger.id" />
                    <rb-input
                        class="w-6/12 pl-1"
                        :label="messengerName(messenger.id)"
                        :placeholder="messengerName(messenger.id)"
                        :disabled="messenger.id == -1"
                        v-model:value="messenger.value" />
                </div>
            </n-card>
        </div>
        <div class="odd:pr-1 even:pl-1 mb-2 w-6/12">
            <n-button
                secondary
                size="large"
                type="default"
                class="w-full h-full rounded-md min-h-[126px]"
                @click="onAddMessenger">
                + {{ profile.localization['profile_main_info_additional_contacts_add_contact_button'] }}
            </n-button>
        </div>
    </section>

    <div class="text-base text-left text-gray-600 dark:text-white/75 font-semibold mt-6">
        <n-skeleton v-if="!profile.localization" :width="160" :height="20" :sharp="false" />
        <template v-else>{{ profile.localization['profile/dashboard_settings/title'] }}</template>
    </div>
    <n-card
        :bordered="false"
        class="shadow bg-white dark:bg-darkCardBg rounded-lg mt-4"
        :segmented="{
            content: true,
            footer: 'soft'
        }">
        <rb-select
            class="w-full"
            :label="profile.localization['profile/dashboard_settings/stat_views/label']"
            :options="statViewsOptions"
            v-model:value="profile.fullInfo.stat_view" />
    </n-card>

    <div class="text-base text-left text-gray-600 dark:text-white/75 font-semibold mt-6">
        <n-skeleton v-if="!profile.localization" :width="160" :height="20" :sharp="false" />
        <template v-else>{{ profile.localization['profile/dashboard_settings/enabled_blocks'] }}</template>
    </div>
    <n-card
        :bordered="false"
        class="shadow bg-white dark:bg-darkCardBg rounded-lg mt-4"
        :segmented="{
            content: true,
            footer: 'soft'
        }">
        <section class="flex flex-wrap">
            <div
                v-for="block in profile.fullInfo.dashboard_blocks"
                :key="block.id"
                class="py-2 pr-4">
                <rb-switch
                    :id="'dbblock_'+block.id"
                    :label="block.title[0].toUpperCase() + block.title.slice(1).toLowerCase()"
                    v-model:value="block.enabled" />
            </div>

            <rb-switch
                id="is_chat_enabled"
                class="py-2 pr-4"
                :label="profile.localization['profile/chat/label'][0].toUpperCase() + profile.localization['profile/chat/label'].slice(1)"
                v-model:value="profile.fullInfo.is_chat_enabled" />
        </section>
    </n-card>

    <n-card
        :bordered="false"
        class="shadow bg-white dark:bg-darkCardBg rounded-lg mt-4"
        :segmented="{
            content: true,
            footer: 'soft'
        }">
        <section>
            <rb-input
                class="w-full"
                :label="profile.localization['profile/main_info/tv_ips']"
                :placeholder="profile.localization['profile/main_info/tv_ips/placeholder']"
                v-model:value="profile.fullInfo.tv_ips" />

            <n-divider />
        </section>

        <section>
            <rb-switch
                id="autorestart"
                :label="profile.localization['profile/autorestart/label']"
                v-model:value="profile.fullInfo.autorestart" />
        </section>
    </n-card>
</section>

<!-- <n-alert type="default" :show-icon="false">
    <template #header>
        <div class="flex items-center font-semibold cursor-pointer uppercase" @click="show = !show">
            <span class="text-xl">{{ profile.localization['profile_main_info_title'] }}</span>
            <n-icon class="text-base ml-2 -mt-1 transition transform" :class="show ? 'rotate-90' : ''">
                <IosArrowRtl24Filled />
            </n-icon>
        </div>
        <n-collapse-transition :show="show">
            <n-card class="bg-transparent mt-8">

                

            </n-card>
        </n-collapse-transition>
    </template>
</n-alert> -->
</template>

<script>
// general
import general from './general';

// icons
import { Dismiss16Filled, IosArrowRtl24Filled, DeleteDismiss24Regular } from '@vicons/fluent';

// components
import RbInput from '@components/rb-input';
import RbSelect from '@components/rb-select';
import RbSwitch from '@components/rb-switch';

// naive-ui
import {
    NIcon,
    NCard,
    NAlert,
    NButton,
    NDivider,
    NCollapseTransition } from 'naive-ui';

export default {
    name: 'main-info',
    components: {
        NIcon,
        NCard,
        NAlert,
        NButton,
        RbInput,
        RbSelect,
        NDivider,
        RbSwitch,
        Dismiss16Filled,
        IosArrowRtl24Filled,
        NCollapseTransition,
        DeleteDismiss24Regular,
    },
    setup(props, context) {
        
        return {
            ...general(props, context),
        };
    },
};
</script>