<template>
<section v-if="visible">
    <section class="flex w-full">
        <n-button
            class="rounded-md flex-grow"
            @click="show = true">
            <section class="w-full flex justify-between items-center">
                <div class="mr-4 text-xs">{{ label }}</div>
                <div class="flex justify-end items-center">
                    <span class="text-main mr-2 text-xs">{{ current.title.length > 15 ? current.title.slice(0, 15) + '..' : current.title }}</span>
                    <n-icon size="14"><ArrowRight12Regular /></n-icon>
                </div>
            </section>
        </n-button>

        <n-button
            secondary
            type="default"
            class="px-2 ml-1 rounded-md"
            @click="$emit('setVisibleFilters', data.filter)">
            <n-icon size="24" class="text-red-500">
                <DeleteDismiss24Regular />
            </n-icon>
        </n-button>
    </section>

    <n-drawer
        v-model:show="show"
        width="100%"
        class="bg-gray-100 dark:bg-darkbg"
        :auto-focus="false">
        <n-drawer-content body-content-style="padding: 0">
            <div class="flex flex-col h-full">
                <div class="flex justify-between items-center w-full bg-gray-100 dark:bg-darkbg text-sm text-center text-gray-600 dark:text-white/75 px-3 py-3">
                    <n-icon
                        size="20"
                        class="text-gray-600 dark:text-white/75"
                        @click="show = false">
                        <ArrowLeft12Regular />
                    </n-icon>
                    <div class="flex justify-end">
                        <n-button
                            quaternary
                            size="small"
                            type="default"
                            class="rounded-md flex items-center text-main"
                            @click="show = false">
                            {{ refs.localization.filters.general.filters_done_label }}
                        </n-button>
                    </div>
                </div>
                <div class="pb-4 flex-grow overflow-y-scroll overscroll-none scrollbar-hide">
                  <div
                      v-for="(item, i) in options"
                      :key="i"
                      class="w-full px-3 py-2 dark:odd:bg-[#242424] odd:bg-gray-50">
                      <n-radio
                          :checked="_.get(filters, data.filter)[0] == item.id"
                          :value="item.id"
                          name="basic-demo"
                          class="w-full text-xs"
                          @change="handleChange">
                          {{ item.title }}
                      </n-radio>
                  </div>
                </div>
            </div>
        </n-drawer-content>
    </n-drawer>
</section>
</template>

<script>
// vue
import { defineComponent } from 'vue';

// general
import general from './general';

// naive-ui
import {
    NIcon,
    NRadio,
    NButton,
    NDrawer,
    NDrawerContent} from 'naive-ui';

// icons
import {
    ArrowLeft12Regular,
    ArrowRight12Regular,
    DeleteDismiss24Regular } from '@vicons/fluent';

export default defineComponent({
    name: 'radio-group-mobile',
    props: {
        data: {
            type: Object,
            required: true,
        },
        filters: {
            type: Object,
            required: true,
        },
        visible: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        NIcon,
        NRadio,
        NDrawer,
        NButton,
        NDrawerContent,
        ArrowLeft12Regular,
        ArrowRight12Regular,
        DeleteDismiss24Regular,
    },
    setup(props, context) {
        
        return {
            ...general(props, context),
        };
    },
});
</script>

<style lang="scss" scoped>
:deep(.n-button__content) {
  width: 100%;
  height: 100%;
}
</style>