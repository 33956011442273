// vue
import { h, ref, computed } from 'vue';

// i18n
import { useI18n } from 'vue-i18n';

// components
import RbSwitch from '@components/rb-switch/index.vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';

// naive-ui
import {
    NRadioGroup,
    NRadioButton } from 'naive-ui';

// components
import RbCheckbox from '@components/rb-checkbox/index.vue';

export default function (props: any, context: any) {
    // i18n
    const { t } = useI18n();

    // store
    const gl = useGl();
    const refs = useRefs();

    // vars
    const showDropdownRef = ref(false);
    const visibleFilters = ref(
        props.refFilters
            .filter((el: any) => !!el?.seeable)
            .map(({ filter }: { filter: string }) => filter ));

    const modifiedRefFilters = computed(() => {
        const result = props.refFilters.map(( el: any, i: number ) => {
            return {
                key: i,
                label: el.title,
                indexNumber: ['exchanges', 'bots', 'checkbox_group', 'date_picker'].includes(el.mode) ? 0 : 1,
                visible: visibleFilters.value.includes(el.filter),
                ...el,
            };
        })
            .sort((a: any, b: any) => a.indexNumber - b.indexNumber )
            .sort(({ mode }: { mode: string }) => mode === 'date_picker' ? -1 : 1 );

        const idx = result.findIndex(({ indexNumber }: { indexNumber: number }) => indexNumber > 0);
        if (~idx) {
            result.splice(idx, 0, {
                type: 'divider',
                key: 'd1',
            });
        }

        return result;
    });

    const renderDropdownLabel = (option: any) => {
        if (option.mode == 'filter_switch') {
            return h('div', {
                class: 'w-full h-full flex justify-between items-center mr-3 py-3',
            }, [
                option.label,
                h(RbSwitch, {
                    'value': option.value,
                    'onUpdate:value': ($event: any) => {
                        context.emit('changeFilter', [option.filter, $event]);
                    },
                }),
            ]);
        } else if (option.mode == 'filter_button_groupe') {
            const childs = [];
            for (let el of option.refs) {
                childs.push(h(NRadioButton, {
                    value: el.value,
                    label: el.label,
                }));
            };

            return h('div', {
                class: 'w-full h-full flex items-center justify-between mr-3 py-3',
            }, [
                option.label,
                h(NRadioGroup, {
                    'size': 'small',
                    'class': 'rounded-md',
                    'value': option.value,
                    'onUpdate:value': $event => {
                        context.emit('changeFilter', [ option.filter, $event ]);
                    },
                }, childs),
            ]);
        } else {
            return h(RbCheckbox, {
                label: option.label,
                checked: option.visible,
                class: 'w-full h-full items-center',
                onUpdateChecked: () => {
                    setVisibleFilters(option.filter);
                    showDropdownRef.value = false;
                },
            });
        }
        
    };

    const setVisibleFilters = (filter: string) => {
        const idx = visibleFilters.value.findIndex((el : string) => el == filter);

        ~idx
            ? visibleFilters.value.splice(idx, 1)
            : visibleFilters.value.push(filter);
    };

    const onUpdateDate = ($event: { value: number[], range?: string }, { filter }: { filter: string }) => {
        // console.log('$event', $event, 'filter', filter);
        let value = `${new Date($event.value[0]).toISOString().split('T')[0]} ${new Date($event.value[1]).toISOString().split('T')[0]}`;
        context.emit('changeFilter', [ filter, value, $event.range ]);
    };

    const getButtonGroupOptions = (filter: any) => {
        const childs = [];
        for (let el of filter.refs) {
            childs.push({
                value: el.value,
                label: el.label,
            });
        };

        return childs;
    };

    const resetAllFilters = () => {
        visibleFilters.value = [];
        context.emit('reset-filters');
    };

    return {
        t,
        gl,
        refs,
        visibleFilters,
        showDropdownRef,
        modifiedRefFilters,
        onUpdateDate,
        resetAllFilters,
        setVisibleFilters,
        renderDropdownLabel,
        getButtonGroupOptions,
    };
}