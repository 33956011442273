import { defineStore } from 'pinia';

export const useDashBoard = defineStore('dashBoard', {
    state: () => ({
        refs: null,
        data: null,
        loading: false,
        botsStatsInfo: null,
        revenueStats: null,
        news: null,
    }),
    getters: {
        localization(state) {
            return state.refs?.data.localization[Object.keys(state.refs.data.localization)[0]];
        },
        getNews(state) {
            return state.news;
        },
        enabled_blocks(state) {
            return state.data?.data.enabled_blocks;
        },
        dynamicBlocks(state) {
            return state.data?.data.dynamic_blocks;
        },
        bot_stats(state){
            return state.data?.data.bot_stats;
        },
        revenue_stats(state){
            return state.revenueStats;
        },
        exchangeBalances(state){
            return state.data?.data.exchange_balances;
        },
        gBotsStatsInfo(state) {
            return state.botsStatsInfo;
        },
    },
});