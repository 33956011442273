<template>
  <section class="text-left">
    <h4 class="text-left mt-2 text-xl text-gray-600 dark:text-white/75 font-semibold">
      {{ refs.localization?.tasks?.title || "Задания" }}
    </h4>
    <n-alert class="mt-2 rounded-lg" type="success">
        <div class="font-bold text-sm notes" v-html="refs.localization?.tasks?.info"></div>
    </n-alert>
    <section class="flex justify-between text-gray-500 dark:text-white/50 mt-6">
      <div>{{ refs.localization?.tasks?.done || "Выполнено" }}</div>
      <strong>{{ refs.countShiftedTask }} / {{ refs.countTask }}</strong>
    </section>

    <n-progress
      type="line"
      class="mt-2"
      :height="4"
      :percentage="refs.percentShiftedTask"
      :color="gl.mainColor"
      :show-indicator="false"
    />

    <section class="flex flex-wrap items-stretch">
      <div
        v-for="(item, i) in listOfTasks"
        :key="i"
        class="odd:pr-2 even:pl-2 w-6/12 mb-4"
      >
        <n-card
          size="small"
          :bordered="false"
          class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg mt-4 h-full"
          :segmented="{
            content: true,
            footer: 'soft',
          }"
        >
          <template #header>
            <section class="flex justify-between items-center">
              <div class="text-left mt-2 text-base text-gray-600 dark:text-white/75 font-semibold">
                <span class="opacity-50">{{ i + 1 }}.</span>&nbsp;
                <span class="notes" v-html="item.title"></span>
              </div>
              <n-tag v-if="item.done" round :bordered="false" type="success" size="small">
                {{ refs.localization?.tasks?.done }}
                <template #icon>
                  <n-icon>
                    <CheckmarkCircle12Filled />
                  </n-icon>
                </template>
              </n-tag>
              <n-tag v-else round :bordered="false" type="warning" size="small">
                {{ refs.localization?.tasks?.awaits_execution }}
                <template #icon>
                  <n-icon>
                    <DismissCircle16Filled />
                  </n-icon>
                </template>
              </n-tag>
            </section>
          </template>
          <template #default>
            <div class="notes" v-html="item.text"></div>
          </template>
        </n-card>
      </div>
    </section>
  </section>
</template>

<script>
// general
import general from './general';

// naive-ui
import { NTag, NCard, NIcon, NProgress, NAlert } from 'naive-ui';

// icons
import { DismissCircle16Filled, CheckmarkCircle12Filled } from '@vicons/fluent';

export default {
    components: {
        NTag,
        NCard,
        NIcon,
        NAlert,
        NProgress,
        DismissCircle16Filled,
        CheckmarkCircle12Filled,
    },
    setup() {
        return {
            ...general(),
        };
    },
};
</script>
