<template>
<section class="pb-2">
    <div class="text-xl text-left text-gray-700 dark:text-white/90 font-semibold">
        <n-skeleton v-if="!profile.localization" :width="160" :height="20" :sharp="false" />
        <template v-else>{{ profile.localization['profile_account_balance_history_title'] }}</template>
    </div>
    <super-table
        v-if="dataRefs"
        showSearch
        type="profile_balance_history"
        :filters="filters"
        :refFilters="refFilters"
        :pager="dataRefs.pager"
        :columns="dataRefs.columns"
        :records="dataRefs.records"
        :filtersChanged="filtersChanged"
        columnsStorageName="__columns_balance_history"
        @updateDate="updateDate"
        @getData="getTransactions"
        @applyFilters="changeFilter"
        @reset-filters="resetFilters">
        <template #afterSearch>
            <div class="flex items-center justify-start mx-2">
                <div class="hover:text-main dark:hover:text-main text-gray-600 dark:text-white/75 cursor-pointer mr-2" @click="downloadData('xls')">
                    <n-icon class="text-2xl">
                        <ClipboardDataBar24Regular />
                    </n-icon>
                    <div class="text-xs text-center -mt-2 font-bold">XLS</div>
                </div>
                <div class="hover:text-main dark:hover:text-main text-gray-600 dark:text-white/75 cursor-pointer ml-2" @click="downloadData('csv')">
                    <n-icon class="text-2xl">
                        <ClipboardDataBar24Regular />
                    </n-icon>
                    <div class="text-xs text-center -mt-2 font-bold">CSV</div>
                </div>
            </div>
        </template>
    </super-table>
</section>
</template>

<script>
// general
import general from './general';

// icons
import { Warning24Filled, Dismiss16Filled, IosArrowRtl24Filled, ClipboardDataBar24Regular } from '@vicons/fluent';

// components
import RbInput from '@components/rb-input';
import RbSelect from '@components/rb-select';
import RbSwitch from '@components/rb-switch';
import FilterBots from '@components/filters/bots';
import SuperTable from '@components/super-table/desktop.vue';
import ButtonGroup from '@components/filters/button-group.vue';
import FilterExchanges from '@components/filters/exchanges.vue';
import FilterCheckable from '@components/filters/filter-checkable';
import FilterChangedDate from '@components/filters/changedDate.vue';

// editor
import Editor from '@tinymce/tinymce-vue';

// naive-ui
import {
    NIcon,
    NCard,
    NAlert,
    NButton,
    NDivider,
    NDatePicker,
    NCollapseTransition } from 'naive-ui';

export default {
    name: 'balance-history-desktop',
    props: {
        tab: {
            type: String,
            required: true,
        },
        myTab: {
            type: String,
            required: true,
        },
    },
    components: {
        NIcon,
        NCard,
        NAlert,
        Editor,
        NButton,
        RbInput,
        RbSelect,
        NDivider,
        RbSwitch,
        SuperTable,
        FilterBots,
        ButtonGroup,
        NDatePicker,
        FilterCheckable,
        FilterExchanges,
        Warning24Filled,
        Dismiss16Filled,
        FilterChangedDate,
        IosArrowRtl24Filled,
        NCollapseTransition,
        ClipboardDataBar24Regular,
    },
    setup(props, context) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>