<template>
<section>
    <n-alert v-if="exchangeMeta2Info" class="rounded-md mb-4" type="warning" size="small">
        <div class="notes text-[10px]" v-html="exchangeMeta2Info"></div>
    </n-alert>
    <div class="flex items-center justify-between">
        <div class="flex flex-wrap items-center">
            <div
                class="text-xs text-gray-600 dark:text-white/75 whitespace-nowrap">
                {{ mainTitle }}
            </div>
            <n-popover
                trigger="click"
                class="max-w-[240px] rounded-md bg-main"
                placement="top">
                <template #trigger>
                    <n-icon
                        class="text-main cursor-pointer text-lg ml-2">
                        <QuestionCircle20Filled />
                    </n-icon>
                </template>
                <span class="notes text-xs text-white" v-html="help"></span>
            </n-popover>
        </div>

        <n-radio-group size="small" v-model:value="apiOptions">
            <n-radio-button
                v-for="option in options"
                :key="option.value"
                :value="option.value"
                :label="option.label"
            />
        </n-radio-group>
    </div>

    <section class="mt-4">
        <template v-if="apiOptions === 'select'">
            <rb-select
                filterable
                popoverType="warning"
                :label="selectTitle"
                :options="apiKeys"
                :value="apiKeyValue"
                :loading="loading"
                :disabled="loading || !apiKeys.length"
                :help="exchangeMetaInfo"
                @update:value="$emit('update:modelValue', $event)" />
        </template>
        <template v-else>
            <rb-input
                v-for="(apiKey, key) in apiKeyModel"
                :key="key"
                class="mb-4"
                :status="apiKey.status === 'error' ? 'error' : undefined"
                :msg="apiKey.msg"
                :label="apiKey.title"
                :disabled="loading"
                :placeholder="apiKey.placeholder"
                v-model:value="apiKey.value"
                @update:value="apiKey.status = null, apiKey.msg = null" />

            <div class="flex justify-end items-center">
                <n-popover
                    v-if="exchangeMeta2Info"
                    style="width: 200px" trigger="click"
                    class="rounded-md bg-yellow-600">
                    <template #trigger>
                        <n-icon
                            class="cursor-pointer text-xl mx-2 text-yellow-600">
                            <QuestionCircle20Filled />
                        </n-icon>
                    </template>
                    <span
                        class="notes text-[10px] text-white"
                        v-html="exchangeMeta2Info">
                    </span>
                </n-popover>
                <n-button
                    strong
                    class="rounded-md text-white/90"
                    :color="gl.mainColor"
                    :loading="addApiLoading"
                    :disabled="createApiDisable || addApiLoading"
                    @click="onCreateApiKey">
                    {{ buttonLabel }}
                </n-button>
            </div>
        </template>
    </section>
</section>
</template>

<script>
// vue
import { ref, reactive, computed, watch, onMounted } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useBots } from '@store/bots';
import { useQuickStart } from '@store/quickStart';

// i18n
import { useI18n } from 'vue-i18n';

// icons
import { Warning24Filled, QuestionCircle20Filled } from '@vicons/fluent';

// services
import ApiKeysService from '@services/apiKeysService';

// components
import RbInput from '@components/rb-input/mobile.vue';
import RbSelect from '@components/rb-select/mobile.vue';

// naive-ui
import {
    NIcon,
    NAlert,
    NInput,
    NSelect,
    NButton,
    NPopover,
    useMessage,
    NRadioGroup,
    NRadioButton } from 'naive-ui';

export default {
    name: 'api-keys-mobile',
    emits: ['update:modelValue'],
    props: {
        modelValue: [String, Number],
        selectTitle: String,
        mainTitle: String,
        emptyText: String,
        name: String,
        help: String,
        exchange: {
            type: Object,
            required: true,
        },
        apiKeysRefs: {
            type: Array,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        mode: {
            type: String,
            default: 'quick-start',
            validator: prop => ['quick-start', 'bots', 'marketplace'].includes(prop), 
        },
    },
    components: {
        NIcon,
        NAlert,
        NInput,
        NButton,
        RbInput,
        NSelect,
        RbSelect,
        NPopover,
        NRadioGroup,
        NRadioButton,
        Warning24Filled,
        QuestionCircle20Filled,
    },
    setup(props, { emit }) {
        // store
        const gl = useGl();
        const refs = useRefs();
        const bots = useBots();
        const quickStart = useQuickStart();

        // i18n
        const { t } = useI18n();

        // naive-ui
        const message = useMessage();

        // vars
        const select = ref('');
        const apiKeyValue = ref(props.modelValue);
        const addApiLoading = ref(false);
        const apiOptions = ref('select');

        watch(() => props.modelValue, v => {
            v === -1 && apiOptions.value === 'select'
                ? apiKeyValue.value = null
                : apiKeyValue.value = v;
        });

        watch(() => props.name, v => {
            if (apiOptions.value !== 'select')
                apiKeyModel.name.value = v;
        });

        watch(apiOptions, v => {
            if (v === 'select') {
                if (props.modelValue === -1)
                    setTimeout(() => {
                        if (select.value)
                            apiKeyValue.value = null;
                    }, 0);
            } else {
                apiKeyModel.name.value = props.name;
                emit('update:modelValue', -1);
            }  
        });

        const options = computed(() => {
            return [
                {
                    value: 'select',
                    label: t('assignmentTypes[0]'),
                }, {
                    value: 'create',
                    label: t('assignmentTypes[1]'),
                },
            ];
        });

        const apiKeys = computed(() => props.apiKeysRefs?.length ? props.apiKeysRefs.map(el => ({
            value: el.id,
            label: el.name,
            ...el,
        })) : []);

        const createApiDisable = computed(() => {
            let disable = false;

            for (let key in apiKeyModel) {
                if (!apiKeyModel[key].value) {
                    disable = true;
                    return disable;
                }
            }

            return disable;
        });

        const exchangeMetaInfo = computed(() => exchange(props.exchange.value)?.meta);
        const exchangeMeta2Info = computed(() => exchange(props.exchange.value)?.meta2);
        const buttonLabel = computed(() => {
            if (props.mode === 'quick-start') {
                return quickStart.localization['quick_start/api_key/create_new_button'];
            } else if (props.mode === 'bots') {
                return bots.localization['bots_new_apikey_add_button'];
            }

            return null;
        });

        const nameContent = computed(() => {
            if (props.mode === 'quick-start') {
                return {
                    title: quickStart.localization['quick_start/api_key_name/label'],
                    placeholder: quickStart.localization['quick_start/api_key_name/placeholder'],
                };
            } else if (props.mode === 'bots') {
                return {
                    title: bots.localization['bots_new_apikey_name_f'],
                    placeholder: bots.localization['bots_new_apikey_name_i'],
                };
            }

            return null;
        });

        const keyContent = computed(() => {
            if (props.mode === 'quick-start') {
                return {
                    title: quickStart.localization['quick_start/api_key_value/label'],
                    placeholder: quickStart.localization['quick_start/api_key_value/placeholder'],
                };
            } else if (props.mode === 'bots') {
                return {
                    title: bots.localization['bots_new_apikey_key_f'],
                    placeholder: bots.localization['bots_new_apikey_key_i'],
                };
            }

            return null;
        });

        const signContent = computed(() => {
            if (props.mode === 'quick-start') {
                return {
                    title: quickStart.localization['quick_start/api_key_sign/label'],
                    placeholder: quickStart.localization['quick_start/api_key_sign/placeholder'],
                };
            } else if (props.mode === 'bots') {
                return {
                    title: bots.localization['bots_new_apikey_sign_f'],
                    placeholder: bots.localization['bots_new_apikey_sign_i'],
                };
            }

            return null;
        });

        const apiKeyModel = reactive({
            name: {
                value: props.name + '',
                title: nameContent.value.title,
                placeholder: nameContent.value.placeholder,
                status: undefined,
                msg: undefined,
            },
            key: {
                value: '',
                title: keyContent.value.title,
                placeholder: keyContent.value.placeholder,
                status: undefined,
                msg: undefined,
            },
            sign: {
                value: '',
                title: signContent.value.title,
                placeholder: signContent.value.placeholder,
                status: undefined,
                msg: undefined,
            },
        });

        watch(() => apiKeyModel.name.value, () => {
            if (apiKeyModel.name.status === 'error') {
                apiKeyModel.name.status = undefined;
                apiKeyModel.name.msg = undefined;
            }
        });

        watch(() => apiKeyModel.key.value, () => {
            if (apiKeyModel.key.status === 'error') {
                apiKeyModel.key.status = undefined;
                apiKeyModel.key.msg = undefined;
            }
        });

        watch(() => apiKeyModel.sign.value, () => {
            if (apiKeyModel.sign.status === 'error') {
                apiKeyModel.sign.status = undefined;
                apiKeyModel.sign.msg = undefined;
            }
        });

        const exchange = (exchange) => {
            return refs.exchanges.find(el => el.id === exchange);
        };

        const onCreateApiKey = async () => {
            addApiLoading.value = true;

            const records = {
                exchange: props.exchange.value,
                name: apiKeyModel.name.value,
                key: apiKeyModel.key.value,
                sign: apiKeyModel.sign.value,
                _formPath: 'apiKey',
            };

            const result = await ApiKeysService.addApiKeyToExchange(records.exchange, records);

            if (!result.data.status) {
                if (result.data?.errors_form) {

                    for (let key in apiKeyModel) {
                        const fields = result.data.errors_form.apiKey.fields;
                        const el = Object.keys(fields).find(el => el === key);

                        if (el) {
                            apiKeyModel[key].status = 'error';
                            apiKeyModel[key].msg = fields[el].msg;
                        } else {
                            apiKeyModel[key].status = 'success';
                            apiKeyModel[key].msg = undefined;
                        }
                    }
                }
            } else {
                // clean apiKeyModel
                for (let key in apiKeyModel)
                    apiKeyModel[key].value = '';

                // show messages
                result.postMessages.forEach(el => {
                    message[el.success ? 'success' : 'error'](el.msg);
                });

                // change api key
                emit('setApiKey', result.data.records[0]);

                // change active tab
                apiOptions.value = 'select';
            }

            addApiLoading.value = false;
        };

        onMounted(() => {
            // if (props.modelValue === -1)
            //     apiKeyValue.value = null;
        });

        return {
            gl,
            select,
            options,
            apiKeys,
            quickStart,
            apiOptions,
            apiKeyModel,
            apiKeyValue,
            buttonLabel,
            addApiLoading,
            createApiDisable,
            exchangeMetaInfo,
            exchangeMeta2Info,
            t,
            onCreateApiKey,
        };
    },
};
</script>