import Api from './index';

export default class Marketplace {

    static async describeInternal(payload) {
        return await Api.getData({
            action: 'realm.describeInternal',
            payload,
        });
    };

    static async completeInternal(payload) {
        return await Api.getData({
            action: 'realm.completeInternal',
            payload,
        });
    };
}