import Api from './index';

export default class WalletsService {

    static async getRefs() {
        return await Api.getData({
            action: 'stats.getRefs',
        });
    };

    static async botsGet(payload) {
        return await Api.getData({
            action: 'stats.bots.get',
            payload,
        });
    };

    static async getShortInfo(id) {
        return await Api.getData({
            action: 'stats.bots.getShortInfo',
            payload: { id },
        });
    };

    static async cancelOrders(payload) {
        return await Api.getData({
            action: 'stats.mng.cancelOrders',
            payload,
        });
    };

    static async getRateFinishCycle(id) {
        return await Api.getData({
            action: 'stats.mng.getRateFinishCycle',
            payload: { id },
        });
    };

    static async recalcRateForProfit(payload) {
        return await Api.getData({
            action: 'stats.mng.recalcRateForProfit',
            payload,
        });
    };

    static async resetFixOrder(payload) {
        return await Api.getData({
            action: 'stats.mng.resetFixOrder',
            payload,
        });
    };

    static async cyclesGet(payload) {
        return await Api.getData({
            action: 'stats.cycles.get',
            payload,
        });
    };

    static async markLastAsCanceled(id) {
        return await Api.getData({
            action: 'stats.mng.markLastAsCanceled',
            payload: { id },
        });
    };

    static async ordersGet(payload) {
        return await Api.getData({
            action: 'stats.orders.get',
            payload,
        });
    };

    static async addInsuranceOrder(payload) {
        return await Api.getData({
            action: 'stats.mng.addInsuranceOrder',
            payload,
        });
    };

    static async clearCycles(id) {
        return await Api.getData({
            action: 'stats.bots.clearCycles',
            payload: {
                id,
            },
        });
    };
}