<template>
<section>
    <div class="flex items-center">
        <div class="text-md text-gray-600 dark:text-white/75 whitespace-nowrap">{{ title }}</div>
        <n-popover
            v-if="recommendedPairsInfo"
            trigger="hover"
            class="max-w-[200px] rounded-md bg-yellow-600"
            placement="right">
            <template #trigger>
                <n-icon
                    class="cursor-pointer text-lg ml-2 text-yellow-600">
                    <QuestionCircle20Filled />
                </n-icon>
            </template>
            <span class="notes text-xs text-white" v-html="recommendedPairsInfo"></span>
        </n-popover>
    </div>
<!--  overflow-x-auto v-x-scroll mt-4 -->
    <div class="flex justify-start items-stretch mt-4">
        <label
            v-for="(curPair, i) in recommendedPairs.slice(0, 3)"
            class="cursor-pointer border border-transparent hover:border-main p-2 rounded-md max-w-[30%]"
            :class="[{'not-avail': curPair.disabled || loading || disabled}, curPair.id === modelValue && !curPair.disabled ? 'border-main bg-main bg-opacity-25' : '']"
            :key="i">
            <input
                type="radio"
                class="hidden"
                :value="curPair.id"
                :checked="curPair.id === modelValue"
                :disabled="curPair.disabled || disabled"
                @change="$emit('update:modelValue', $event.target.value)"/>
            <div class="flex items-center">
                <rb-coin class="w-6" :coin="currency(curPair.pair, false)[0]" />
                <div class="text-center px-2 whitespace-nowrap">{{ curPair.pair }}</div>
                <rb-coin class="w-6" :coin="currency(curPair.pair, false)[1]" />
            </div>
        </label>
        <n-popover
            v-if="recommendedPairs.length > 3"
            trigger="hover"
            placement="right"
            class="max-w-[500px] rounded-md">
            <template #trigger>
                <div class="flex flex-grow ml-2">
                    <n-button
                        strong
                        secondary
                        class="px-1 h-full rounded-md ">
                        <span class="font-semibold text-base -mt-1">...</span>
                    </n-button>
                </div>
            </template>
            <template #default>
                <section class="flex flex-wrap">
                    <div v-for="(curPair, i) in recommendedPairs" :key="i" class="w-4/12">
                        <label
                            class="cursor-pointer border border-transparent hover:border-main p-2 rounded-md block"
                            :class="[{'not-avail': curPair.disabled || loading || disabled}, curPair.id === modelValue && !curPair.disabled ? 'border-main bg-main bg-opacity-25' : '']">
                            <input
                                type="radio"
                                class="hidden"
                                :value="curPair.id"
                                :checked="curPair.id === modelValue"
                                :disabled="curPair.disabled || disabled"
                                @change="$emit('update:modelValue', $event.target.value)"/>
                            <div class="flex items-center justify-center">
                                <rb-coin class="w-6" :coin="currency(curPair.pair, false)[0]" />
                                <div class="text-center px-3 whitespace-nowrap">{{ curPair.pair }}</div>
                                <rb-coin class="w-6" :coin="currency(curPair.pair, false)[1]" />
                            </div>
                        </label>
                    </div>
                </section>
            </template>
        </n-popover>
    </div>

    <rb-pair-select
        class="mt-4"
        popoverType="warning"
        :disabled="disabled || loading"
        :loading="loading"
        :help="howToChooseInfo"
        :options="pairsRefsOptions"
        :label="label + ':'"
        :value="modelValue !== -1 ? modelValue : undefined"
        :status="status === 'error' ? 'error' : undefined"
        :msg="msg"
        @update:value="$emit('update:modelValue', $event == undefined ? -1 : $event)" />

    <n-alert v-if="tickerMessage" class="my-4" type="warning" size="small">
        <div class="notes text-xs" v-html="tickerMessage"></div>
    </n-alert>

    <!-- <n-divider />
    <label class="mt-4 w-6/12 flex flex-wrap justify-between">
        <div class="w-2/12 flex justify-between mt-2">
            <div class="text-base whitespace-nowrap" :class="status === 'error' ? 'text-red-400' : ''">{{ label }}</div>
            <n-popover v-if="dataHelp" trigger="hover" placement="right">
                <template #trigger>
                    <n-icon class="text-main cursor-pointer text-2xl mx-4">
                        <QuestionCircle20Filled />
                    </n-icon>
                </template>
                <span>{{ dataHelp }}</span>
            </n-popover>
        </div>
        <div class="w-10/12">
            <n-select
                filterable
                :status="status"
                :options="pairsRefsOptions"
                :placeholder="`- ${t('select')} -`"
                :render-label="renderLabel"
                :loading="loading"
                :disabled="loading"
                :value="modelValue !== -1 ? modelValue : undefined"
                @update:value="$emit('update:modelValue', $event)"/>
            <div v-if="howToChooseInfo" class="notes mt-2" v-html="howToChooseInfo"></div>
        </div>
        <slot name="messages"></slot>
        <div v-if="status === 'error'" class="w-full mt-2 text-red-400 text-right">{{ msg }}</div>
    </label> -->

    
</section>
</template>

<script>
// vue
import { h, ref, computed } from 'vue';

// store
import { useRefs } from '@/store/refs';

// components
import RbCoin from '@components/rb-coin';
import GridNav from '@components/base/grid-nav.vue';
import RbPairSelect from '@components/rb-pair-select';

// i18n
import { useI18n } from 'vue-i18n';

// naive-ui
import {
    NCard,
    NIcon,
    NAlert,
    NInput,
    NButton,
    NSelect,
    NDivider,
    NPopover,
    NCheckbox,
    useMessage } from 'naive-ui';

// icons
import { Warning24Filled, QuestionCircle20Filled } from '@vicons/fluent';

export default {
    name: 'RbPairs',
    emits: ['setPerPage', 'update:modelValue'],
    props: {
        title: String,
        loading: Boolean,
        tickerMessage: String,
        label: String,
        dataHelp: String,
        gridNavLabel: String,
        recommendedPairsInfo: String,
        howToChooseInfo: String,
        disabled: Boolean,
        modelValue: {
            type: [String, Number],
            required: true,
        },
        pager: {
            type: Object,
            required: true,
        },
        recommendedPairsRef: {
            type: Array,
            required: true,
        },
        pairsRefs: {
            type: Array,
            required: true,
        },
        status: {
            type: String,
            default: undefined,
        },
        msg: {
            type: String,
        },
    },
    components: {
        NIcon,
        NAlert,
        RbCoin,
        NButton,
        NSelect,
        GridNav,
        NDivider,
        NPopover,
        RbPairSelect,
        Warning24Filled,
        QuestionCircle20Filled,
    },
    setup(props, { emit }) {
        // store
        const refs = useRefs();

        // i18n
        const { t } = useI18n();

        const recommendedPairs = computed(() => {
            let res = Array.isArray(props.recommendedPairsRef) ?
                props.recommendedPairsRef
                : [];

            if (res.length) {
                const arr = [];
                res.forEach(el => {
                    if (!~arr.findIndex(item => item.id === el.id)) {
                        arr.push(el);
                    }
                });
                res = arr;
            };

            return res;

            // return res.slice(0, props.pager.perPage);
        });

        const currency = (icon, first = true) => {
            
            const lIcon = first ? icon.split('/').shift().trim() : icon.split('/');

            const res = Array.isArray(lIcon)
                ? [...lIcon.map(curIcon => refs.currencies.find(el => el.id === curIcon.split('__')[0]))]
                : refs.currencies.find(el => el.id === lIcon);

            return res;
        };

        const hasApplicablePairClass = (pair) => !!props.pairsRefs.some(({ id }) => id === pair.pair);

        const pairsRefsOptions = computed(() => {
            return props.pairsRefs.map(cur => {
                return {
                    label: cur.title,
                    value: cur.id,
                    ...cur,
                };
            });
        });

        const renderLabel = (option) => {
            return h(
                'div',
                {
                    class: 'flex items-center py-1',
                },
                [
                    h(RbCoin, {
                        class: 'w-4',
                        coin: currency(option.value, false)[0],
                    }),
                    h(
                        'div',
                        {
                            class: 'px-3',
                        },
                        [
                            h('span', {
                                class: 'text-xs',
                            }, option.label.split('/')[0]),
                            h('span', {
                                class: 'opacity-50 px-1',
                            }, '/'),
                            h('span',  {
                                class: 'text-xs',
                            }, option.label.split('/')[1]),
                        ],
                    ),
                    h(RbCoin, {
                        class: 'w-4',
                        coin: currency(option.value, false)[1],
                    }),
                ],
            );
        };
        
        return {
            recommendedPairs,
            pairsRefsOptions,
            t,
            currency,
            renderLabel,
            hasApplicablePairClass,
        };
    },
};
</script>

<style lang="scss" scoped>
.not-avail {
  color: #888!important;
  cursor: not-allowed!important;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
</style>