<template>
<section class="flex flex-wrap items-stretch px-3">
    <div
        v-for="(item, i) in bots_stats"
        :key="i"
        class="w-6/12 odd:pr-1 even:pl-1 mb-2">
        <n-card
            size="small"
            :bordered="false"
            content-style="padding: 0;"
            class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg h-full"
            :segmented="{
              content: true,
              footer: 'soft'
            }">
            <section class="flex flex-col justify-center items-center pb-1">
                <n-skeleton v-if="loading" class="inline-block mt-3" :width="40" :height="50" :sharp="false" />
                <component v-else :is="item.icon" class="w-10 -mt-1" :class="[item.active ? 'fill-[#1A6DE0]' : 'fill-current', 'inline']"></component>
                <div class="text-base -mt-2 flex flex-row items-center justify-center" :class="[item.active ? 'text-main font-semibold' : '']">
                    <n-skeleton v-if="loading" class="mt-4" :width="80" :height="14" :sharp="false" />
                    <template v-else>
                        <div class="mr-2 text-xs">{{ item.title }}</div>
                        <div v-if="item.indicate" class="shadow w-3 h-3 bg-white flex flex-row items-center justify-center rounded-full">
                            <div :class="[item.indicate, 'w-2', 'h-2', 'rounded-full']"></div>
                        </div>
                    </template>
                </div>
                <n-skeleton v-if="loading" class="inline-block mt-2 mb-1" :width="40" :height="16" :sharp="false" />
                <p v-else :class="[item.active ? 'text-main font-semibold' : '', 'text-xl lg:text-2xl']">{{ item.count }}</p>

                <div v-if="item.count == 0 && item.active" class="px-2 w-full">
                    <n-skeleton v-if="loading" class="inline-block my-1 w-full rounded-md" :height="34" :sharp="false" />
                    <n-button
                        v-else
                        strong
                        secondary
                        class="rounded-md my-1 w-full"
                        @click="router.push({ name: 'bots.create' })">
                        + {{ dashBoard.localization?.dashboard_bots_add_new }}
                    </n-button>
                </div>
            </section>
        </n-card>
    </div>
</section>
</template>

<script>
// general
import general from './general';

// naive-ui
import {
    NCard,
    NButton,
    NSkeleton } from 'naive-ui';

export default {
    name: 'block-bots-mobile',
    components: {
        NCard,
        NButton,
        NSkeleton,
    },
    setup(props, context) {

        return {
            ...general(props, context),
        };
    },
};
</script>