import { defineStore } from 'pinia';

export const useEnv = defineStore('env', {
    state: () => ({
        data: {},
    }),
    getters: {
        notifications(state) {
            return state.data.data.notifications;
        },
        balance(state) {
            return state.data.data.env.balance;
        },
        email(state) {
            return state.data.data.env.email;
        },
        ID(state) {
            return state.data.data.env.id;
        },
        formattedTime(state) {
            const d = new Date(state.data.data.env.timestamp);
            return d.getHours() + ':' + d.getMinutes();
        },
        formattedDate(state) {
            return new Date(state.data.data.env.timestamp).toLocaleDateString({
                year: 'numeric', month: 'short', day: 'numeric', 
            });
        },
        timeToShutdown(state) {
            return state.data.data.env.timeToShutdown;
        },
        profitSymbol(state) {
            return state.data.data.env.profit_symbol;
        },
        topupMethods(state) {
            return state.data.data.env.topup_methods;
        },
        statusCode(state) {
            return state.data.data.env.status;
        },
        language(state) {
            return state.data.data?.env.lang;
        },
    },
});