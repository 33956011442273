<template>
<section v-if="dashBoard.localization" class="w-full">
    <section class="w-full flex items-center justify-end text-base text-left text-gray-600 dark:text-white/75 font-semibold">
        <!-- <n-skeleton v-if="!dashBoard.localization" :width="215" :height="20" :sharp="false" />
        <template v-else>{{ dashBoard.localization['dashboard_exchange_balances_title'] }}</template> -->
        <n-button
            strong
            size="tiny"
            class="ml-2 rounded-md"
            type="tertiary"
            @click="update"
            :disabled="loading || updateLoading"
            :loading="updateLoading">
            {{ dashBoard.localization.dashboard_account_info_auto_refresh_button }}
        </n-button>
    </section>
    <section class="w-full flex mt-2">
        <div
            v-for="(item, i) in data"
            :key="i"
            class="w-6/12 pr-1 pl-1 first:pl-0 last:pr-0 h-[350px]">
            <n-card
                size="small"
                :bordered="false"
                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg h-full"
                content-style="padding: 0;"
                :segmented="{
                  content: true,
                  footer: 'soft'
                }">
                <template #header>
                    <div v-if="item.title" class="flex justify-center text-sm text-gray-600 dark:text-white/75 font-semibold">
                      <n-skeleton v-if="loading" :width="215" :height="20" :sharp="false" />
                      <template v-else>{{ item.title }}</template>
                    </div>
                </template>
                <div class="flex flex-col h-[306px]">
                  <div v-if="loading" class="h-[80%]">
                      <div
                          v-for="(n, i) in 6"
                          :key="i"
                          class="w-full dark:odd:bg-[#242424] odd:bg-gray-50 h-[40px]">
                      </div>
                  </div>
                  <n-scrollbar v-else class="h-[80%]">
                    <div
                        v-for="(stat, i) in item.stats"
                        :key="i"
                        class="w-full dark:odd:bg-[#242424] odd:bg-gray-50">
                        <div class="py-1 flex flex-row justify-start items-center overflow-x-hidden relative">
                            <div class="w-4/12 flex items-center">
                                <rb-coin class="mx-2 w-6" :coin="currency(stat[2])" />
                                <div class="text-sm font-semibold uppercase">{{ stat[2] }}</div>
                            </div>
                            
                            <div class="text-sm font-semibold" :class="stat[0] > 0 ? 'text-green-600' : 'text-red-500'">{{ stat[0] }}</div>

                            <div
                                v-if="stat[2] !== env.profitSymbol"
                                class="flex flex-row opacity-60 hover:opacity-100 items-center text-white justify-start absolute w-4/5 h-full bg-main right-[-72%] rounded-l-xl hover:right-0 transition-all cursor-pointer">
                                <n-icon class="absolute left-2 top-1/2 -translate-y-1/2">
                                    <ChevronLeft24Filled />
                                </n-icon>

                                <div class="w-3/12 flex items-center ml-6">
                                    <rb-coin class="mx-2 w-6" :coin="currency(env.profitSymbol)" />
                                    <div class="text-sm font-semibold uppercase">{{ env.profitSymbol }}</div>
                                </div>
                                
                                <div class="text-sm font-semibold">{{ stat[1] }}</div>
                            </div>
                        </div>
                    </div>
                  </n-scrollbar>
                  <div class="h-[20%] flex flex-col justify-center items-center">
                      <n-skeleton v-if="loading" :width="200" :height="18" :sharp="false" />
                      <div v-else class="text-sm font-semibold">{{ item.data?.btc }} {{ env.profitSymbol.toUpperCase() }} / {{item.data?.usdt || '---'}} USDT</div>

                      <n-skeleton v-if="loading" class="mt-2" :width="130" :height="16" :sharp="false" />
                      <div v-else class="text-sm">{{ item.ciclos }}</div>
                  </div>
                </div>
            </n-card>
        </div>
    </section>
</section>
</template>

<script>
// general
import general from './general';

// components
import RbCoin from '@components/rb-coin';

// icons
import { ChevronLeft24Filled } from '@vicons/fluent';

import {
    NCard,
    NIcon,
    NButton,
    NSkeleton,
    NScrollbar } from 'naive-ui';

export default {
    name: 'dash-profit-desktop',
    components: {
        NCard,
        NIcon,
        RbCoin,
        NButton,
        NSkeleton,
        NScrollbar,
        ChevronLeft24Filled,
    },
    setup(props, context) {
        
        return {
            ...general(props, context),
        };
    },
};
</script>