<template>
<section class="pb-2">
    <section>
        <div
            class="flex flex-col transition-all mt-4 w-full">
            <n-card
                size="small"
                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-md"
                :bordered="false"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <template #default>
                    <section>
                        <div class="w-full">
                            <div
                                class="text-xs text-left"
                                :class="profile.errors?.two_factor_auth ? 'text-red-400' : 'text-gray-600 dark:text-white/75'">
                                {{ profile.localization['profile_password_two_factor_authentication_type'] }}
                            </div>
                            <n-alert
                                v-if="profile.fullInfo.two_factor_auth == 'gauth' && !profile.fullInfo.two_factor_auth_gauth_qr && !profile.fullInfo.two_factor_auth_gauth_code"
                                class="rounded-md my-2 w-full"
                                type="success">
                                <div class="font-md font-semibold">
                                    {{ profile.localization['profile_password_two_factor_authentication_ga_result_message'] }}
                                </div>
                            </n-alert>
                            <n-radio-group
                                id="profile__two_factor_auth"
                                class="mt-2"
                                v-model:value="dataForm.two_factor_auth" size="large"
                                @update:value="profile.cleanError('two_factor_auth')">
                                <n-radio
                                    v-for="item in profile.twoFactorAuthTypes"
                                    class="mb-2"
                                    :key="item.id"
                                    :value="item.id">
                                    {{ item.title }}
                                </n-radio>
                            </n-radio-group>
                            <div v-if="profile.errors?.two_factor_auth" class="w-full mt-2 text-red-400 text-right">
                                {{ profile.errors?.two_factor_auth?.msg }}
                            </div>
                        </div>

                        <rb-input
                            v-if="dataForm.two_factor_auth == 'sms'"
                            class="w-full mt-4"
                            :status="profile.errors?.two_factor_auth_phone ? 'error' : undefined"
                            :msg="profile.errors?.two_factor_auth_phone?.msg"
                            :label="profile.localization['profile_password_two_factor_authentication_sms_phone_number_f']"
                            v-model:value="dataForm.two_factor_auth_phone"
                            @update:value="profile.cleanError('two_factor_auth_phone')" />
                    </section>
                </template>
            </n-card>
            <n-card
                size="small"
                class="shadow bg-white dark:bg-darkCardBg rounded-md mt-4"
                :bordered="false"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <template #default>
                    <rb-input
                        id="profile__new_password"
                        type="password"
                        :status="profile.errors?.new_password ? 'error' : undefined"
                        :msg="profile.errors?.new_password?.msg"
                        :label="profile.localization['profile_password_new_password_f']"
                        :placeholder="profile.localization['profile_password_new_password_i']"
                        v-model:value="dataForm.new_password"
                        @update:value="profile.cleanError('new_password')" />
                    <rb-input
                        id="profile__re_new_password"
                        class="mt-4"
                        type="password"
                        :status="profile.errors?.re_new_password ? 'error' : undefined"
                        :msg="profile.errors?.re_new_password?.msg"
                        :label="profile.localization['profile_password_re_enter_new_password_f']"
                        :placeholder="profile.localization['profile_password_re_enter_new_password_i']"
                        v-model:value="dataForm.re_new_password"
                        @update:value="profile.cleanError('re_new_password')" />
                </template>
            </n-card>
        </div>

        <div
            v-show="dataForm.two_factor_auth == 'gauth' && profile.fullInfo.two_factor_auth_gauth_qr && profile.fullInfo.two_factor_auth_gauth_code"
            class="transition-all w-full mt-4">
            <n-card
                size="small"
                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg h-full"
                :bordered="false"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <template #default>
                    <section class="flex justify-center flex-wrap">
                        <span class="w-full text-left text-xs text-gray-600 dark:text-white/75">
                            1. {{ profile.localization['profile_password_two_factor_authentication_ga_install_info'] }}
                        </span>
                        <div class="flex justify-between mt-2">
                            <a href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8" class="mr-2 rounded-md overflow-hidden" target="_blank">
                                <img src="@assets/img/appstore.png">
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" class="ml-2 rounded-md overflow-hidden" target="_blank">
                                <img src="@assets/img/gplay.png">
                            </a>
                        </div>
                        <n-divider />

                        
                        <span class="w-full text-left text-xs text-gray-600 dark:text-white/75">
                            2. {{ profile.localization['profile_password_two_factor_authentication_ga_scan_info'] }}
                        </span>
                        <img class="mt-2" :src="profile.fullInfo.two_factor_auth_gauth_qr">
                        <span class="w-full text-left text-xs text-gray-600 dark:text-white/75 mt-2">
                            {{ profile.localization['profile_password_two_factor_authentication_ga_enter_code_info'] }}
                        </span>
                        <div
                            class="mt-2 w-full flex flex-row items-center justify-between bg-gray-100 dark:bg-darkbg text-main p-2 rounded-md text-md cursor-pointer"
                            @click="copy(profile.fullInfo.two_factor_auth_gauth_code)">
                            {{ profile.fullInfo.two_factor_auth_gauth_code }}
                            <n-icon class="ml-4" size="25">
                                <Copy16Regular />
                            </n-icon>
                        </div>

                        <n-alert class="bg-[#eaf0fe] dark:bg-blue-900 dark:bg-opacity-20 rounded-md mt-2" type="info">
                            <div class="text-sm">{{ profile.localization.profile_password_two_factor_authentication_ga_recover_info }}</div>
                        </n-alert>
                    </section>
                </template>
            </n-card>
        </div>
    </section>
</section>
<n-button
    strong
    size="large"
    class="rounded-md text-white/90 sticky bottom-0 w-full"
    :disabled="loading"
    :loading="loading"
    :color="gl.mainColor"
    @click="onUpdate">
    {{ profile.localization['profile_save_changes_button'] }}
</n-button>

<current-password
    :loading="loading"
    v-model:show="showPassModal"
    @confirm="onUpdate" />
</template>

<script>
// general
import general from './general';

// icons
import { Dismiss16Filled, IosArrowRtl24Filled, Copy16Regular } from '@vicons/fluent';

// components
import RbInput from '@components/rb-input/mobile.vue';
import RbSelect from '@components/rb-select/mobile.vue';
import RbSwitch from '@components/rb-switch/mobile.vue';
import CurrentPassword from '@components/current-password/mobile.vue';

// naive-ui
import {
    NIcon,
    NCard,
    NRadio,
    NAlert,
    NButton,
    NDivider,
    NRadioGroup,
    NCollapseTransition } from 'naive-ui';

export default {
    name: 'password-mobile',
    components: {
        NIcon,
        NCard,
        NRadio,
        NAlert,
        NButton,
        RbInput,
        RbSelect,
        NDivider,
        RbSwitch,
        NRadioGroup,
        Copy16Regular,
        Dismiss16Filled,
        CurrentPassword,
        IosArrowRtl24Filled,
        NCollapseTransition,
    },
    setup() {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>