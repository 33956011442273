// vue
import { h, ref, reactive, computed, watch } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
// import { useStats } from '@store/stats';

// i18n
import { useI18n } from 'vue-i18n';

export default function (props: any, context: any) {
    const gl = useGl();
    const refs = useRefs();
    // const stats = useStats();

    const { t } = useI18n();
  
    // vars
    const show = ref(false);
    const valueRef = ref({} as any);
    const searchFilter = ref();

    watch(() => props.visible, v => {
        if (v) {
            show.value = true;
        } else {
            context.emit('changeFilter', [props.data.filter, {}]);
        }
    });

    watch(show, v => {
        if (!v)
            addSelected();
    });

    const label = computed(() =>
        props.data.title[props.data.title.length - 1] === ':'
            ? props.data.title.slice(0, props.data.title.length - 1)
            : props.data.title,
    );

    const checkeds = computed(() => allBots.value.reduce((accum: number, el: any) => {
        const count = el.children.reduce((accum2: any, child: any) => {
            return accum2 + +child.checked;
        }, 0);

        return accum + count;
    }, 0));

    const allBots = computed(() => {
        const res = <any>[];

        props.data.refs.forEach((el: any, i: number) => {
            const curExchange = exchange(el.exchange);

            if (curExchange)
                res.push(reactive({
                    id: curExchange.id,
                    label: curExchange.title,
                    show: true,
                    checked: false,
                    children: el.inner.map((item: any) => ({
                        label: item.title,
                        value: item.id,
                        checked: !!props.filters.filters.bots[curExchange.id]?.find((bot: any) => bot === item.id),
                    })).filter((item: any) => !searchFilter.value
                        ? true
                        : item.label.toLowerCase().includes(searchFilter.value.toLowerCase())),
                }));
        });
      
        return res;
    });

    const emptyBotsList = computed(() => {
        return allBots.value.every((el: any) => !el?.children.length);
    });

    const exchange = (exchange: any) => refs.exchanges.find(el => el.id == exchange);

    const setCheckAll = (obj: any, $event: any) => {
        obj.checked = $event;

        obj.children.forEach((el: any) => {
            el.checked = $event;
        });

        addSelected();
    };

    const setCheck = (obj: any, bot: any) => {
        addSelected();
    };

    const addAll = () => {
        valueRef.value = {};

        allBots.value.forEach((item: any) => {
            item.checked = true;
            item.children.forEach((child: any) => {
                child.checked = true;
                valueRef.value[item.id]
                    ? valueRef.value[item.id].push(child.value)
                    : valueRef.value[item.id] = [child.value];
            });
        });
    };

    const removeAll = () => {
        valueRef.value = {};

        allBots.value.forEach((item: any) => {
            item.checked = false;
            item.children.forEach((child: any) => {
                child.checked = false;
                valueRef.value = {};
            });
        });
    };
  
    const addSelected = () => {
        valueRef.value = {};

        allBots.value.forEach(({ id, children } : { id: number | string, children: any }) => {
            children.forEach(({ checked, value } : { checked: boolean, value: any }) => {
                if (checked) {
                    valueRef.value[id]
                        ? valueRef.value[id].push(value)
                        : valueRef.value[id] = [value];
                };
            });
        });

        context.emit('changeFilter', [props.data.filter, valueRef.value]);
    };

    const isAllChecked = (obj: any) => obj.children.every(({ checked } : { checked: boolean }) => checked);

    return {
        gl,
        show,
        refs,
        // stats,
        label,
        allBots,
        checkeds,
        searchFilter,
        emptyBotsList,
        t,
        addAll,
        setCheck,
        removeAll,
        setCheckAll,
        isAllChecked,
    };
}