export default function ({ name, isSortable }) {
    if (!isSortable) return;

    if ([
        'id',
        'depo',
        'type',
        'rate',
        'price',
        'sl_pct',
        'sales',
        'owner',
        'error',
        'mentor',
        'mentee',
        'profit',
        'status',
        'amount',
        'volume',
        'cycle_id',
        'exchange',
        'number_id',
        'provider',
        'profit_pct',
        'exchange_id',
        'profit_day',
        'profit_week',
        'profit_month',
        'profit_year',
        'price_amount',
        'rate_cover',
        'profit_usdt',
        'running_bots',
        'cycle_profit',
        'total_profit',
        'config_price',
        'vi_1_rb 1h-1m',
        'vi_3_rb 3h-1m',
        'vi_5_rb 6h-1m',
        'vi_7_rb 24h-1m',
        'rb_market 24h',
        'profit_percent',
        'profit_wo_comm',
        'comm_amount_btc',
        'rate_change_count',
        'price_amount_usdt',
        'rate_change_percent',
        'amount_processed',
        'mentorship_price',
        'comm_amount_usdt',
        'exchange_order_id',
        '_cycles_completed',
        'first_coin_revenue',
        'total_profit_usdt',
        'first_order_indent',
        'rb_comm_amount_btc',
        'second_coin_revenue',
        'rb_comm_amount_usdt',
        'profit_usdt_wo_comm',
        'total_comm_amount_btc',
        'total_comm_amount_usdt',
        'total_rb_comm_amount_btc',
        'total_rb_comm_amount_usdt',
        'total_profit_wo_comm',
        'total_profit_usdt_wo_comm'].includes(name)) {
        return (v1, v2) => {
            if (v1?.type === 'footer' || v2?.type === 'footer') return 0;

            const num1 = +v1[name] || 0;
            const num2 = +v2[name] || 0;
            
            return num1 - num2;
        };
    } else if (['period_info', 'interval_info'].includes(name)) {
        return (v1, v2) => {
            if (v1?.type === 'footer' || v2?.type === 'footer') return 0;

            const num1 = +v1[name].split(' ')[0] || 0;
            const num2 = +v2[name].split(' ')[0]  || 0;
            
            return num1 - num2;
        };
    } else if ([
        'info',
        'data',
        'tags',
        'name',
        'sign',
        'algo',
        'long',
        'notes',
        'c_key',
        'filter',
        'comment',
        'exchanges',
        'order_type',
        'order_type2',
        'reg_referrer',
        'autoswitcher',
        'has_feedback',
        'cancel_reason',
        'autoinversion',
        'last_cycle_status'].includes(name)) {
        return (v1, v2) => {
            return v1?.type === 'footer' || v2?.type === 'footer'
                ? 0
                : v1[name]?.length - v2[name]?.length;
        };
    } else if ([
        'tg',
        'ts',
        'url',
        'pair',
        'risk',
        'email',
        'wallet',
        'contact',
        'subject',
        'question',
        'currency',
        'profit_coin'].includes(name)) {
        return (v1, v2) => {
            if (v1?.type === 'footer' || v2?.type === 'footer')
                return 0;

            if (v1[name].toLowerCase() < v2[name].toLowerCase()) {
                return -1;
            }
            if (v1[name].toLowerCase() > v2[name].toLowerCase()) {
                return 1;
            }
            return 0;
        };
    } else if (['state', 'exit_error'].includes(name)) {
        return (v1, v2) => {
            if (v1?.type === 'footer' || v2?.type === 'footer')
                return 0;

            if (typeof v1[name] === 'number' && typeof v2[name] === 'number') {
                return v1[name] - v2[name];
            } else {
                if ((v1[name]?.toLowerCase() || '') < (v2[name]?.toLowerCase() || '')) {
                    return -1;
                }
                if ((v1[name]?.toLowerCase() || '') > (v2[name]?.toLowerCase() || '')) {
                    return 1;
                }
    
                return 0;
            }
        };
    } else if ([
        // 'ts',
        'expire',
        'works_since',
        'date_created',
        'date_stopped',
        'date_updated',
        'last_visit_date',
        'date_restarted'].includes(name)) {
        return (v1, v2) => {
            return v1?.type === 'footer' || v2?.type === 'footer'
                ? 0
                : new Date(v1[name]) - new Date(v2[name]);
        };
    } else if ([
        'cycles',
        'cycle_id__order_id',
        'first_coin__first_coin_comm',
        'second_coin__second_coin_comm'].includes(name)) {
        return (v1, v2) => {
            if (v1?.type === 'footer' || v2?.type === 'footer') return 0;

            const var1 = v1[name].split('/').reduce(( accum, value ) => +value + accum, 0);
            const var2 = v2[name].split('/').reduce(( accum, value ) => +value + accum, 0);

            return var1 - var2;
        };
    } else if (['order_matrix', 'cycle_lasts'].includes(name)) {
        return (v1, v2) => {
            if (v1?.type === 'footer' || v2?.type === 'footer') return 0;

            const var1 = +v1[name]?.split(' ')[0] || 0;
            const var2 = +v2[name]?.split(' ')[0] || 0;

            return var1 - var2;
        };
    } else if (name === 'prices') {
        return (v1, v2) => {
            if (v1?.type === 'footer' || v2?.type === 'footer') return 0;

            const var1 = v1[name]?.split('\n').reduce((accum, el) => +el + accum, 0) || 0;
            const var2 = +v2[name]?.split('\n').reduce((accum, el) => +el + accum, 0) || 0;

            return var1 - var2;
        };
    } else if (name === 'sl') {
        return (v1, v2) => {
            if (v1?.type === 'footer' || v2?.type === 'footer') return 0;

            const var1 = v1[name]?.split(' ').reduce((accum, el) => +el + accum, 0) || 0;
            const var2 = +v2[name]?.split(' ').reduce((accum, el) => +el + accum, 0) || 0;

            return var1 - var2;
        };
    } else if (['in_market'].includes(name)) {
        return (v1, v2) => {
            return v1?.type === 'footer' || v2?.type === 'footer'
                ? 0
                : v1[name] - v2[name];
        };
    } else if (name === 'current_orders_info') {
        console.log(arguments);
        return (v1, v2, k) => {
            if (v1?.type === 'footer' || v2?.type === 'footer') return 0;

            const num1 = (+v1[name].split('/')[0] + 1) || -1;
            const num2 = (+v2[name].split('/')[0] + 1) || -1;

            return num1 - num2;
        };
        
    }
};