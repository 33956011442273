<template>
<section class="flex content-between">
    <div
        v-for="(item, i) in bots_stats"
        :key="i"
        class="w-3/12 pl-1 pr-1 first:pl-0 last:pr-0">
        <n-card
            size="small"
            :bordered="false"
            class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg h-full"
            :segmented="{
              content: true,
              footer: 'soft'
            }">
            <section class="h-full flex flex-col justify-center items-center">
                <n-skeleton v-if="loading" class="inline-block" :width="45" :height="45" :sharp="false" />
                <component v-else :is="item.icon" class="w-10 h-14" :class="[item.active ? 'fill-[#1A6DE0]' : 'fill-current', 'inline']"></component>
                <div class="text-base flex flex-row items-center justify-center" :class="[item.active ? 'text-main font-semibold' : '']">
                    <n-skeleton v-if="loading" class="mt-4" :width="130" :height="20" :sharp="false" />
                    <template v-else>
                        <div class="mr-2">{{ item.title }}</div>
                        <div v-if="item.indicate" class="shadow w-3 h-3 bg-white flex flex-row items-center justify-center rounded-full">
                            <div :class="[item.indicate, 'w-2', 'h-2', 'rounded-full']"></div>
                        </div>
                    </template>
                </div>
                <n-skeleton v-if="loading" class="inline-block mt-3" :width="50" :height="25" :sharp="false" />
                <p v-else :class="[item.active ? 'text-main font-semibold' : '', 'text-2xl lg:text-2xl']">{{ item.count }}</p>

                <template v-if="item.count == 0 && item.active">
                    <n-skeleton v-if="loading" class="inline-block my-1 w-full rounded-md" :height="34" :sharp="false" />
                    <n-button
                        v-else
                        strong
                        secondary
                        class="rounded-md my-1 w-full"
                        @click="router.push({ name: 'bots.create' })">
                        + {{ dashBoard.localization?.dashboard_bots_add_new }}
                    </n-button>
                </template>
            </section>
        </n-card>
    </div>
</section>
</template>

<script>
// general
import general from './general';

// naive-ui
import {
    NCard,
    NButton,
    NSkeleton } from 'naive-ui';

export default {
    name: 'block-bots-desktop',
    components: {
        NCard,
        NButton,
        NSkeleton,
    },
    setup(props, context) {

        return {
            ...general(props, context),
        };
    },
};
</script>