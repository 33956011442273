<template>
<rb-loader v-if="loading" class="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2" />
<n-tabs
    v-else
    type="line" animated>
    <n-tab-pane
        :tab="profile.localization['profile_referral_info_withdrawal_request_title']"
        :name="profile.localization['profile_referral_info_withdrawal_request_title']">
        <n-card
            size="small"
            :bordered="false"
            class="overflow-hidden shadow bg-main rounded-lg"
            :segmented="{
                content: true,
                footer: 'soft'
            }">
            <template #default>
                <section class="flex flex-col justify-between h-full text-left">
                    <div
                        class="flex items-center text-white text-xl font-semibold">
                        {{ fullInfo?.info.total_available }}
                        <rb-coin class="w-5 ml-2" :coin="currency(env.profitSymbol)" />
                    </div>

                    <div class="text-white font-semibold mt-1 text-md">{{ profile.localization['profile_referral_info_total_available'].slice(0, -1) }}</div>
                </section>
            </template>
        </n-card>

        <rb-input
            class="mt-4"
            :status="errors2?.withdraw_address ? 'error' : undefined"
            :msg="errors2?.withdraw_address?.msg"
            :label="profile.localization['profile_referral_info_withdrawal_request_btc_address_f']"
            :placeholder="profile.localization['profile_referral_info_withdrawal_request_btc_address_i']"
            v-model:value="dataForm2.withdraw_address"
            @update:value="errors2.cleanError('withdraw_address')" />

        <rb-input
            class="mt-4"
            :status="errors2?.withdraw_amount ? 'error' : undefined"
            :msg="errors2?.withdraw_amount?.msg"
            :label="profile.localization['profile/transfer/amount']"
            v-model:value="dataForm2.withdraw_amount"
            @update:value="errors2.cleanError('withdraw_amount')" />

        <n-button
            strong
            class="rounded-md text-white/90 w-full mt-4"
            :color="gl.mainColor"
            :loading="localLoading"
            :disabled="localLoading"
            @click="onRequestWithdraw">
            {{ profile.localization['profile_referral_info_withdrawal_request_request_a_withdrawal_button'] }}
        </n-button>
    </n-tab-pane>
    <n-tab-pane
        :auto-focus="false"
        :tab="profile.localization['profile/transfer/info_title']"
        :name="profile.localization['profile/transfer/info_title']">
        <rb-input
            class="mt-4"
            :status="errors?.id_acc ? 'error' : undefined"
            :msg="errors?.id_acc?.msg"
            :label="profile.localization['profile/transfer/id_acc']"
            v-model:value="dataForm.id_acc"
            @update:value="errors.cleanError('id_acc')" />

        <rb-input
            class="mt-4"
            :status="errors?.amount ? 'error' : undefined"
            :msg="errors?.amount?.msg"
            :label="profile.localization['profile/transfer/amount']"
            v-model:value="dataForm.amount"
            @update:value="errors.cleanError('amount')" />

        <rb-input
            class="mt-4"
            type="textarea"
            :status="errors?.comment ? 'error' : undefined"
            :msg="errors?.comment?.msg"
            :label="profile.localization['profile/transfer/comment']"
            v-model:value="dataForm.comment"
            @update:value="errors.cleanError('comment')" />

        <n-button
            strong
            class="rounded-md text-white/90 mt-4 w-full"
            :color="gl.mainColor"
            :loading="localLoading"
            :disabled="localLoading"
            @click="onInternalTransfer">
            {{ profile.localization['profile/transfer/send'] }}
        </n-button>
    </n-tab-pane>
</n-tabs>
</template>

<script>
// general
import general from './general';

// icons
import { IosArrowRtl24Filled } from '@vicons/fluent';

// components
import RbInput from '@components/rb-input/mobile.vue';
import RbCoin from '@components/rb-coin';

// naive-ui
import {
    NTabs,
    NIcon,
    NCard,
    NAlert,
    NButton,
    NDivider,
    NTabPane,
    NCollapseTransition } from 'naive-ui';

export default {
    name: 'payments-request-mobile',
    props: {
        tab: {
            type: String,
            required: true,
        },
        myTab: {
            type: String,
            required: true,
        },
    },
    components: {
        NTabs,
        NIcon,
        NCard,
        NAlert,
        RbCoin,
        NButton,
        RbInput,
        NDivider,
        NTabPane,
        IosArrowRtl24Filled,
        NCollapseTransition,
    },
    setup(props, context) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>