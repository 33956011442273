<template>
<div
    v-if="isFooter"
    class="h-full flex justify-center items-center text-main text-md dark:bg-darkCardBg bg-gray-50 font-semibold relative overflow-hidden">
    {{ dataFooter }} <div class="absolute w-full top-0 left-0 h-[2px] bg-main shadow-xl"></div>
</div>
<div
    v-else
    class="relative h-full w-full overflow-hidden text-center flex flex-col justify-center items-center"
    :class="classes">
    <div
        v-if="data !== null"
        class="w-full flex items-center justify-center"
        :class="secondData !== null && data != secondData ? 'px-4' : 'px-2'">
        <div v-if="leverage && leverage != 'NA'" class="text-md text-red-500 font-semibold whitespace-nowrap mr-2">
            {{ leverage + (leverage[leverage.length - 1] != 'x' ? 'x' : '') }}
        </div>
        <div class="flex flex-col items-center">
            <rb-coin class="w-5" :coin="currency(coin)" />
            <div class="text-center">
                <div
                    class="text-sm font-semibold whitespace-nowrap"
                    :class="interactive ? 'underline hover:no-underline cursor-pointer text-main' : ''">{{ data }}</div>
                <div class="uppercase text-xs opacity-70 whitespace-nowrap">{{ profitSymbol }}</div>
            </div>
        </div>
    </div>

    <div
        v-if="secondData !== null && data != secondData"
        class="absolute w-[calc(100%+15px)] h-full bg-main cursor-pointer rounded-r-lg hover:rounded-r-none -left-full hover:left-0 transition-all">
        <div class="w-[calc(100%-15px)] h-full text-center flex flex-col justify-center items-center px-3 text-white/90">
            <rb-coin class="w-5" :coin="currency(secondCoin)" />
            <div class="text-sm font-semibold mt-2">{{ secondData }}</div>
        </div>
        <n-icon class="absolute right-0 top-1/2 -translate-y-1/2 rotate-180 text-white/90">
            <ChevronLeft24Filled />
        </n-icon>
    </div>
</div>
</template>

<script>
// components
import RbCoin from '@components/rb-coin';

// store
import { useRefs } from '@store/ts/refs';

// naive-ui
import {
    NIcon } from 'naive-ui';

// icons
import {
    ChevronLeft24Filled,
    ClipboardDataBar24Regular } from '@vicons/fluent';

export default {
    name: 'cell-profit-coin',
    props: {
        isFooter: {
            type: Boolean,
            default: false,
            required: true,
        },
        dataFooter: {
            default: '',
        },
        coin: {
            type: String,
            required: true,
        },
        secondCoin: {
            type: String,
        },
        profitSymbol: {
            type: String,
        },
        seconProfitSymbol: {
            type: String,
        },
        data: {
            default: '',
        },
        secondData: {
            default: null,
        },
        classes: {
            type: String,
        },
        interactive: {
            dafult: false,
        },
        leverage: {
            type: String,
        },
    },
    components: {
        NIcon,
        RbCoin,
        ChevronLeft24Filled,
    },
    setup() {
        // store
        const refs = useRefs();

        const currency = icon => refs.currencies.find(el => el.id === icon);
        // return
        // row[el.name] === null
        //     ? null
        //     : h('div', {
        //         class: 'relative h-full w-full overflow-hidden text-center flex flex-col justify-center items-center',
        //     }, [
        //         h(RbCoin, {
        //             coin: currency(row[[el.name] + '_symbol']),
        //         }),
        //         h('div', {
        //             class: 'text-base text-main font-semibold mt-1',
        //         }, row[el.name]),
        //         h('div', {
        //             class: '-mt-1 uppercase',
        //         }, row[[el.name] + '_coin']),
        //         row[el.name] != row[el.name + '_btc']
        //             ? h('div', {
        //                 class: 'absolute w-[calc(100%+15px)] h-full bg-main cursor-pointer rounded-r-lg hover:rounded-r-none -left-full hover:left-0 transition-all',
        //                 style: '',
        //             }, [
        //                 h('div', {
        //                     class: 'w-[calc(100%-15px)] h-full text-center flex flex-col justify-center items-center px-3',
        //                 }, [
        //                     h(RbCoin, {
        //                         coin: currency(row[[el.name] + '_btc_coin']),
        //                     }),
        //                     h('div', {
        //                         class: 'text-sm font-semibold mt-2',
        //                     }, row[[el.name] + '_btc']),
        //                 ]),
        //                 h(NIcon, {
        //                     class: 'absolute right-0 top-1/2 -translate-y-1/2 rotate-180 text-base',
        //                 }, [
        //                     h(ChevronLeft24Filled),
        //                 ]),
        //             ]) : null,
        //     ]);

        return {
            currency,
        };
    },
};
</script>