import { defineStore } from 'pinia';

export const useTools = defineStore('tools', {
    state: () => ({
        refs: null,
        presets: null,
        statsFullInfo: null,
        currentPreset: null,
        currentPresetId: null,
        configPresets: [],
        orderMatrixPresets: [],
        filterPresets: [],
    }),
    getters: {
        localization(state) {
            return state.refs?.localization[Object.keys(state.refs.localization)[0]];
        },
        help(state) {
            return state.refs.help;
        },
        pairsInfo(state) {
            return state.statsFullInfo?.pairsInfo;
        },
        statPeriods(state) {
            return state.refs.stat_periods;
        },
        coinListStrategies(state) {
            return state.refs.coin_list_strategies;
        },
        coinLists(state) {
            return state.refs.coinLists;
        },
        periodUnits(state) {
            return state.refs.period_units;
        },
        intervalUnits(state) {
            return state.refs.interval_units;
        },
        vaPresets(state) {
            return state.refs.vaPresets;
        },
    },
});