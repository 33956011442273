// vue
import { ref, computed, watch, reactive, onMounted } from 'vue';

// services
import toolsService from '@services/tools';
import QuickStartService from '@services/quickStart';
import ApiKeysService from '@services/apiKeysService';
import WalletsService from '@services/walletsService';
import ExchangesService from '@services/exchangesService';
import TradeStatisticService from '@services/tradeStatisticService';

// i18n
import { useI18n } from 'vue-i18n';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useTools } from '@store/tools';
import { useQuickStart } from '@store/quickStart';

// router
import { useRoute, useRouter } from 'vue-router';

// shared
import { CRUD_PAGER_FACTORY } from '@shared/factories';

// UI
import { useMessage } from 'naive-ui';

export default function (props, context) {
    // router
    const route = useRoute();
    const router = useRouter();

    // store
    const gl = useGl();
    const refs = useRefs();
    const tools = useTools();
    const quickStart = useQuickStart();

    // vars
    const init = ref(false);
    const botId = ref();
    const apiKey = ref();
    const walletsRefs = ref();
    const apiKeysRefs = ref();
    const exchangeInfo = ref();
    const tickerMessage = ref();
    const createLoading = ref(false);
    const botMessage = ref();
    const pager = reactive(CRUD_PAGER_FACTORY());

    const dataForm = reactive({
        name: {
            value: '',
            status: undefined,
            msg: undefined,
            loading: false,
            show: true,
        },
        simulate: {
            value: false,
            placeholder: undefined,
            status: undefined,
            msg: undefined,
            loading: false,
            show: false,
        },
        exchange: {
            value: -1,
            placeholder: undefined,
            status: undefined,
            msg: undefined,
            loading: false,
            show: false,
        },
        api_key: {
            value: -1,
            placeholder: undefined,
            status: undefined,
            msg: undefined,
            loading: false,
            show: false,
            name: '',
        },
        pair: {
            value: -1,
            status: undefined,
            msg: undefined,
            loading: false,
        },
        algo: {
            value: -1,
            status: undefined,
            msg: undefined,
            loading: false,
        },
        wallet: {
            value: -1,
            status: undefined,
            msg: undefined,
            loading: false,
            name: '',
        },
        leverage: {
            value: 0,
            status: undefined,
            msg: undefined,
            loading: false,
        },
        // positionmode: {
        //     value: 1,
        //     status: undefined,
        //     msg: undefined,
        //     loading: false,
        // },
        order_matrix: {
            value: -1,
            // title: quickStart.localization['quick_start/order_matrix/label'],
            status: undefined,
            msg: undefined,
            loading: false,
        },
        part_orders_enabled: {
            value: false,
            // title: quickStart.localization['quick_start/matrix/part_orders_enabled'],
            placeholder: undefined,
            status: undefined,
            msg: undefined,
            loading: false,
            show: false,
        },
        part_orders_value: {
            value: 1,
            status: undefined,
            msg: undefined,
        },
        part_orders_unstopable: {
            value: false,
            status: undefined,
            msg: undefined,
            // title: quickStart.localization['quick_start/matrix/part_orders_unstopable/label'],
        },
        trade_mode: {
            value: -1,
            status: undefined,
            msg: undefined,
            // title: quickStart.localization['trade_mode'],
            loading: false,
        },
        enable_filters: {
            value: true,
            status: undefined,
            msg: undefined,
            // title: quickStart.localization['quick_start/enable_filters/label'],
            loading: false,
        },
        comm_type: {
            value: 'standard',
            status: undefined,
            msg: undefined,
        },
    });

    watch(() => dataForm.exchange.value, async v => {
        dataForm.api_key.value = -1;
        dataForm.pair.value = -1;
        dataForm.algo.value = -1;
        dataForm.wallet.value = -1;
        dataForm.leverage.value = 1;
        dataForm.order_matrix.value = -1;
        dataForm.trade_mode.value = -1;
      
        exchangeInfo.value = null;
        apiKeysRefs.value = null;
        walletsRefs.value = null;
      
        if (v != -1) {
            dataForm.api_key.name = `${exchange(dataForm.exchange.value).title} — Quick Start`;
            dataForm.wallet.name = `${exchange(dataForm.exchange.value).title} — Quick Start`;
          
            // get Api Keys for this exchange
            dataForm.exchange.loading = true;
            try {
                apiKeysRefs.value = ( await ApiKeysService.getApiKeysList({
                    exchanges: [v],
                    statuses: [1],
                }) ).data.records;
            } catch {
                message.error(t('errorMessage'));
                dataForm.exchange.loading = false;
            };
          
            // get Pairs for this Exchange
            exchangeInfo.value = ( await ExchangesService.getFullInfo(v) ).data;
          
            dataForm.comm_type.value = exchangeCommissionCoin.value ? 'internal' : 'standard';

            dataForm.exchange.loading = false;
        }
    });

    watch(() => dataForm.pair.value, async v => {
        dataForm.algo.value = -1;
        dataForm.wallet.value = -1;
        dataForm.leverage.value = 1;
        dataForm.trade_mode.value = -1;
        dataForm.order_matrix.value = -1;
      
        tickerMessage.value = null;
        walletsRefs.value = null;
      
        if (v !== -1) {
            dataForm.pair.loading = true;

            if (isExchangeSelected.value && isPairSelected.value) {
                TradeStatisticService.getTickerMessage({
                    exchange_id: dataForm.exchange.value,
                    pair: v?.split('__')[0],
                })
                    .then(({ message }) => {
                        tickerMessage.value = message;
                    })
                    .catch(() => {
                        message.error(t('errorMessage'));
                        dataForm.pair.loading = false;
                    });
            }
          
            if (dataForm.api_key.value !== -1) {
                try {
                    await getWalletsRefs(v);
                } catch {
                    message.error(t('errorMessage'));
                    dataForm.pair.loading = false;
                };
            }

            dataForm.pair.loading = false;
        }
    });

    watch(() => dataForm.algo.value, () => {
        dataForm.wallet.value = -1;
        dataForm.leverage.value = 1;
        dataForm.order_matrix.value = -1;
        dataForm.trade_mode.value = -1;
    });

    watch(() => dataForm.api_key.value, v => {
        if (v === -1) {
            dataForm.pair.value = -1;
            dataForm.algo.value = -1;
            dataForm.wallet.value = -1;
            dataForm.leverage.value = 1;
            dataForm.order_matrix.value = -1;
            dataForm.trade_mode.value = -1;
        }
    });

    watch(() => dataForm.wallet.value, () => {
        dataForm.trade_mode.value = -1;
    });

    // ui
    const message = useMessage();

    // i18n
    const { t } = useI18n();

    const exchangesSorted = computed(() => {
        const data = Object.values(refs.exchanges)
            .sort((e1, e2) => e1.title > e2.title ? 1 : -1);

        return data.map(el => ({
            value: el.id,
            label: el.title,
            ...el,
        }));
    });

    const algosOptions = computed(() => quickStart.algos.map(el => ({
        value: el.id,
        label: el.title,
        ...el,
    })));

    const exchangeTradeModes = computed(() => {
        const arr = isExchangeFutures.value
            ? quickStart.tradeModesF
            : quickStart.tradeModes;

        return arr.map(el => ({
            value: el.id,
            label: el.title,
            ...el,
        }));
    });
  
    const compMatrixMaxCountAllowed = computed(() => compMatrixMaxCount.value > 0);
    const compMatrixMaxCount = computed(() => {

        const ordersMatrix = quickStart.ordersMatrix.find(el => el.id == dataForm.order_matrix.value);
        const matrix_count = parseInt(ordersMatrix?.matrix_count || ordersMatrix?.matrix.length, 10);

        return matrix_count;
    });

    const pairWalletsRefs = computed(() => {
        if (!walletsRefs.value || dataForm.algo.value == -1) return [];
        return walletsRefs.value.filter(w => w.currency == coinSymbolForWallet.value);
    });

    const coinSymbolForWallet = computed(() => {
        const [fSymbol, sSymbol] = pairSymbols.value;
        if (isExchangeCoin.value) return fSymbol;
        if (isExchangeFutures.value) return sSymbol;
        return dataForm.algo.value === 'long' ? sSymbol : (dataForm.algo.value === 'short' ? fSymbol : null);
    });

    const walletModelInfo = computed(() => ({
        name: {
            title: quickStart.localization['quick_create/wallet/name/label'],
            placeholder: quickStart.localization['quick_create/wallet/name/placeholder'],
        },
        amount: {
            title: quickStart.localization['quick_create/wallet/amount/label'],
        },
    }));

    const positionmodeInfo = computed(() => {
        // if (dataForm.positionmode.value === 1) {
        //     return quickStart.localization['quick_start/positionmode/help1'];
        // } else if (dataForm.positionmode.value === 2) {
        //     return quickStart.localization['quick_start/positionmode/help2'];
        // }

        return '';
    });

    const isBotNameValid = computed(() => !!dataForm.name.value.trim());
    const isExchangeSelected = computed(() => dataForm.exchange.value !== -1);
    const isApiKeySelected = computed(() => dataForm.api_key.value !== -1);
    const isPairSelected = computed(() => dataForm.pair.value !== -1);
    const isAlgoSelected = computed(() => dataForm.algo.value !== -1);
    const isWalletSelected = computed(() => dataForm.wallet.value !== -1);
    const isOrdersMatrixSelected = computed(() => dataForm.order_matrix.value !== -1);
    const isTradeModeSelected = computed(() => dataForm.trade_mode.value !== -1);

    const showExchange = computed(() => isBotNameValid.value);
    const showApiKey = computed(() => showExchange.value && isExchangeSelected.value);
    const showPair = computed(() => showApiKey.value && isApiKeySelected.value);
    const showAlgo = computed(() => showPair.value && isPairSelected.value);
    const showWallet = computed(() => showAlgo.value && isAlgoSelected.value);

    const leverageRefs = computed(() => exchangeInfo.value?.leverage || {});
    const leverageMinMax = computed(() => leverageRefs.value[dataForm.pair.value] || {});

    const isExchangeFutures = computed(() => !!refs.exchanges.find(({ id }) => id === dataForm.exchange.value)?.futures);
    watch(() => isExchangeFutures.value, v => {
        if (v) dataForm.simulate.value = false;
    });

    watch(() => isExchangeSelected.value, v => {
        console.log('kkk');
        if (v && init.value) {
            setTimeout(() => {
                const top = document.querySelector('#api_key').getBoundingClientRect().top;
                document.querySelector('#main_container').scrollTo({
                    top,
                    behavior: 'smooth',
                });
            }, 200);
        };
    });

    watch(() => isApiKeySelected.value, v => {
        if (v && init.value) {
            setTimeout(() => {
                const top = document.querySelector('#pair').getBoundingClientRect().top;
                document.querySelector('#main_container').scrollTo({
                    top,
                    behavior: 'smooth',
                });
            }, 200);
        };
    });

    watch(() => isPairSelected.value, v => {
        if (v && init.value) {
            setTimeout(() => {
                document.querySelector('#main_container').scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            }, 200);
        };
    });

    // const canCreateBot = computed(() => true);
    const canCreateBot = computed(() => isBotNameValid.value && isExchangeSelected.value && isApiKeySelected.value && isPairSelected.value
    && isAlgoSelected.value && isWalletSelected.value && isOrdersMatrixSelected.value && isTradeModeSelected.value);

    const exchangeMeta3Info = computed(() => exchange(dataForm.exchange.value)?.meta3);
    const isExchangeCoin = computed(() => exchangeInfo.value?.extra.coin == 1);
    const pairSymbols = computed(() => String(dataForm.pair.value).split('/').map(s => s.trim()));
    const skipPositionMode = computed(() => !!refs.exchanges.find(({ id }) => id === dataForm.exchange.value)?.skip_position_mode);
    const exchangeCommissionCoin = computed(() => !!refs.exchanges.find(({ id }) => id === dataForm.exchange.value)?.commissionCoin);

    // pairs
    const pairsRefs = computed(() => exchangeInfo.value?.pairs || []);
    const recommendedPairsRef = computed(() => tools.pairsInfo.map((el, i) => ({
        id: el.pair,
        disabled: !~pairsRefs.value.findIndex(({ id }) => id === el.pair),
        // disabled: el.exchange !== dataForm.exchange.value || !pairsRefs.value.some(({ id }) => id === el.pair),
        ...el,
    })) || []);

    const positionmodes = computed(() => quickStart.positionmodes.map(el => ({
        label: el.title,
        value: +el.id,
    })) || []);

    const ordersMatrixOrdered = computed(() => quickStart.ordersMatrix?.map(el => ({
        label: el.title,
        value: +el.id,
    })) || []);

    const exchange = exchange => refs.exchanges.find(el => el.id === exchange);

    const getData = async () => {
        gl.showLoading = true;

        if (!tools.statsFullInfo)
            tools.statsFullInfo = ( await toolsService.getStatsFullInfo({ stat_period: 1 }) ).data;
          
        quickStart.refs = await QuickStartService.getRefs();

        gl.showLoading = false;
    };

    const setApiKey = ($event) => {
        dataForm.api_key.value = $event.id;
        apiKeysRefs.value.splice(0, 1, $event);
    };

    const getWalletsRefs = async (v) => {
        walletsRefs.value = ( await WalletsService.getWalletsList({
            exchanges: [dataForm.exchange.value],
            // apikeys: [dataForm.api_key.value],
            // pairs: [v?.split('__')[0]],
            pairs: [v],
        }) ).data.records;

        dataForm.wallet.value = -1;
    };

    const setWallet = async wallet => {
        walletsRefs.value.splice(0, 1, wallet);
    };

    const onCreateBot = async (doStart, navToEdit) => {
        createLoading.value = true;

        const payload = {
            do_start: doStart,
            simulate: dataForm.simulate.value,
            name: dataForm.name.value,
            exchange: dataForm.exchange.value,
            api_key: dataForm.api_key.value,
            pair: dataForm.pair.value,
            algo: dataForm.algo.value,
            comm_type: dataForm.comm_type.value,
            wallet: dataForm.wallet.value,
            leverage: dataForm.leverage.value,
            // positionmode: dataForm.positionmode.value,
            positionmode_oneposition: true,
            positionmode_reduce: true,
            order_matrix: dataForm.order_matrix.value,
            part_orders_enabled: dataForm.part_orders_enabled.value,
            part_orders_value: dataForm.part_orders_value.value,
            part_orders_unstopable: dataForm.part_orders_unstopable.value,
            trade_mode: dataForm.trade_mode.value,
            enable_filters: dataForm.enable_filters.value,
            _formPath: 'quickStart',
        };

        let prepare;

        try {
            prepare = await QuickStartService.quickCreateBot(payload);

            // show messages
            if (prepare) {
                if (Array.isArray(prepare.postMessages)) {
                    prepare.postMessages.forEach(el => {
                        message[el.success ? 'success' : 'error'](el.msg);
                    });
                }

                if (prepare.data?.errors_form) {
                    for (let key in dataForm) {
                        const fields = prepare.data.errors_form.quickStart.fields;
                        const el = Object.keys(fields).find(el => el === key);

                        if (el) {
                            dataForm[key].status = 'error';
                            dataForm[key].msg = fields[el].msg;
                        } else {
                            dataForm[key].status = 'success';
                            dataForm[key].msg = undefined;
                        }
                    }
                } else {
                    botId.value = prepare.data.id;
                    botMessage.value = prepare.data.message;

                    if (navToEdit) return router.replace({ name: 'bots.edit', params: { id: botId.value }});
                }
            }
        } catch {
            message.error(t('errorMessage'));
        };

        createLoading.value = false;
    };

    const resetAndCreateNew = () => {
        dataForm.name.value = '';
        dataForm.simulate.value = false;
        dataForm.exchange.value = -1;
        dataForm.api_key.value = -1;
        dataForm.pair.value = -1;
        dataForm.algo.value = -1;
        dataForm.wallet.value = -1;
        dataForm.leverage.value = 1;
        // dataForm.positionmode.value = 1;
        dataForm.order_matrix.value = -1;
        dataForm.part_orders_enabled.value = false;
        dataForm.part_orders_value.value = 1;
        dataForm.part_orders_unstopable.value = false;
        dataForm.trade_mode.value = -1;
        dataForm.enable_filters.value = true;
        dataForm.comm_type.value = 'standard';

        botId.value = null;
        botMessage.value = null;
    };

    onMounted(async () => {
        await getData();

        init.value = true;
    });

    return {
        gl,
        init,
        refs,
        botId,
        showPair,
        showAlgo,
        dataForm,
        pairsRefs,
        showApiKey,
        botMessage,
        quickStart,
        showWallet,
        apiKeysRefs,
        canCreateBot,
        showExchange,
        algosOptions,
        createLoading,
        tickerMessage,
        positionmodes,
        leverageMinMax,
        isBotNameValid,
        isAlgoSelected,
        isPairSelected,
        pairWalletsRefs,
        exchangesSorted,
        walletModelInfo,
        isWalletSelected,
        isApiKeySelected,
        skipPositionMode,
        positionmodeInfo,
        exchangeMeta3Info,
        isExchangeFutures,
        isExchangeSelected,
        exchangeTradeModes,
        compMatrixMaxCount,
        recommendedPairsRef,
        coinSymbolForWallet,
        ordersMatrixOrdered,
        isTradeModeSelected,
        isOrdersMatrixSelected,
        compMatrixMaxCountAllowed,
        exchange,
        setWallet,
        setApiKey,
        onCreateBot,
        resetAndCreateNew,
    };
}