import { defineStore } from 'pinia';

export const useProfileFullInfo = defineStore('profileFullInfo', {
    state: () => ({
        data: {},
    }),
    getters: {
        notifications(state) {
            return state.data.data.notifications;
        },
        email(state) {
            return state.data.data.email;
        },
        statusCode(state) {
            return state.data.data.status;
        },
    },
});