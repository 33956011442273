<template>
    <h4 class="text-left px-3 my-2 text-base text-gray-600 dark:text-white/75 font-semibold">
    <n-skeleton v-if="loading" class="inline-block my-2" :width="150" :height="20" :sharp="false" />
    <template v-else>{{ refs.localization.sidebar.tools }}</template>
</h4>

<section class="min-h-screen">
    <template v-if="loading">
        <section class="flex mt-2 px-3">
            <n-skeleton class="inline-block" :width="130" :height="18" :sharp="false" />
            <n-skeleton class="inline-block ml-6" :width="140" :height="18" :sharp="false" />
            <n-skeleton class="inline-block ml-6" :width="120" :height="18" :sharp="false" />
        </section>
        <n-divider class="mt-2" />
    </template>
    <n-tabs
        v-else
        type="line"
        size="small"
        class="stats-n-tab"
        :value="currentTab"
        @update:value="changeTab">
        <n-tab-pane
            v-for="(tab, i) in tabs"
            :key="i"
            :name="tab.name"
            :tab="tab.title"
            :disabled="loading">
            <router-view></router-view>
        </n-tab-pane>
    </n-tabs>
</section>
</template>

<script>
// general
import general from './general';

// UI
import {
    NCard,
    NTabs,
    NDivider,
    NTabPane,
    NSkeleton } from 'naive-ui';

export default {
    name: 'ToolsMain',
    components: {
        NCard,
        NTabs,
        NTabPane,
        NDivider,
        NSkeleton,
    },
    setup(props, context) {
        return {
            ...general(arguments),
        };
    },
};
</script>

<style lang="scss" scoped>
:deep(.n-tabs) {

  // .n-tabs-nav {
  //   position: sticky;
  //   top: 56px;
  //   z-index: 100;
  //   background: red;
  // }
  .n-tabs-wrapper  {
    padding: 0 12px;
  }
  .n-tabs-tab .n-tabs-tab__label {
    font-size: 0.75rem !important;
    line-height: 1rem !important;
  }
}

:deep(.n-tabs-bar) {
  left: 0;
}
</style>