<template>
<n-card
    v-if="show"
    size="small"
    role="dialog"
    aria-modal="true"
    header-style="padding: 8px 12px;"
    class="rounded-lg fixed bottom-4 left-24 z-[999] shadow-xl"
    :class="[classes, lightDark ? 'bg-white dark:bg-darkCardBg' : 'bg-white dark:bg-darkCardBg']"
    :segmented="{
      content: true,
      footer: 'soft'
    }"
    style="width: 600px;">
    <template #header>
        <div class="text-left text-main text-sm py-2">{{ gl.postAdviceMessages[page - 1].title }}</div>
    </template>
    <template #default>
        <div class="text-left notes" v-html="gl.postAdviceMessages[page - 1].msg"></div>
    </template>
    <template #footer>
        <div class="flex justify-between items-center">
            <n-pagination v-model:page="page" :page-slot="7" :page-count="gl.postAdviceMessages?.length" />
            <div class="flex">
                <n-button
                    class="text-white/90 rounded-md"
                    :color="gl.mainColor"
                    @click="adviceMessageMarkAsRead(gl.postAdviceMessages[page - 1])">
                    {{ refs.localization.dialogs.read }}
                </n-button>
                <n-button
                    class="text-gray-600 dark:text-white/75 rounded-md ml-2"
                    @click="close">
                    {{ refs.localization.dialogs.close }}
                </n-button>
            </div>
        </div>
    </template>
</n-card>
</template>

<script>
// vue
import { ref, computed, watch } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';

// i18n
import { useI18n } from 'vue-i18n';

// services
import ProfileService from '@services/profileService';

// naive-ui
import {
    NIcon,
    NCard,
    NModal,
    NButton,
    NPagination,
    useNotification } from 'naive-ui';

// icons
import { Dismiss16Filled } from '@vicons/fluent';

export default {
    name: 'post-advice-message-desktop',
    props: {
        label: {
            type: String,
            required: true,
        },
        width: {
            type: String,
            default: '600px',
        },
        height: {
            type: Number, String,
        },
        maskClosable: {
            type: Boolean,
            default: true,
        },
        showCLoseButton: {
            type: Boolean,
            default: true,
        },
        classes: {
            type: String,
        },
        lightDark: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        NIcon,
        NCard,
        NModal,
        NButton,
        NPagination,
        Dismiss16Filled,
    },
    setup() {
        // store
        const gl = useGl();
        const refs = useRefs();

        // ui
        const notification = useNotification();

        // vars
        const page = ref(1);

        // i18n
        const { t } = useI18n();

        const show = computed(() => !!gl.postAdviceMessages && gl.postAdviceMessages?.length);

        watch(show, () => {
            page.value = 1;
        });

        const close = () => {
            gl.postAdviceMessages = null;

            try {
                ProfileService.adviceMessageDeferAll({ defer: true });
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            };
        };

        const adviceMessageMarkAsRead = async el => {
            try {
                if (el.need_feedback)
                    ProfileService.adviceMessageMarkAsRead({ id: el.id });

                const i = gl.postAdviceMessages.findIndex(item => item.id === el.id);

                gl.postAdviceMessages.splice(i, 1);

                if (!gl.postAdviceMessages.length) {
                    close();
                }
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            };
        };

        return {
            gl,
            refs,
            page,
            show,
            close,
            adviceMessageMarkAsRead,
        };
    },
};
</script>

<style lang="scss" scoped>
:deep(a) {
    color: #1A6DE0 !important;
}

:deep(b) {
    color: #1A6DE0 !important;
}
</style>