// vue
import { ref, computed, watch, reactive, onMounted, onUnmounted } from 'vue';

// services
import toolsService from '@services/tools';
import ProfileService from '@services/profileService';
import QuickStartService from '@services/quickStart';
import ApiKeysService from '@services/apiKeysService';
import WalletsService from '@services/walletsService';
import ExchangesService from '@services/exchangesService';
import TradeStatisticService from '@services/tradeStatisticService';

// i18n
import { useI18n } from 'vue-i18n';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useTools } from '@store/tools';
import { useQuickStart } from '@store/quickStart';

// router
import { useRoute, useRouter } from 'vue-router';

// shared
import { CRUD_PAGER_FACTORY } from '@shared/factories';

// UI
import { useMessage } from 'naive-ui';

export default function () {
    // router
    const route = useRoute();
    const router = useRouter();

    // store
    const gl = useGl();
    const refs = useRefs();

    // vars
    const timer = ref();

    // list of tasks
    const listOfTasks = computed(() => {
        return Object.entries(refs.tasksSteps).map(([key, value]) => {
            return {
                done: value,
                ...refs.tasksList[key],
            };
        }).sort((a, b) => a.prio - b.prio);
    });

    onMounted(() => {
        timer.value = setInterval(async () => {
            if (refs.tasks.completed) {
                clearInterval(timer.value);
                return;
            }
            
            refs.tasks = (await ProfileService.getBonusStepStatus()).data.data;
        }, 60000);
    });

    onUnmounted(() => {
        clearInterval(timer.value);
    });
  
    return {
        gl,
        refs,
        listOfTasks,
    };
}