// vue
import { ref, computed, reactive, watch } from 'vue';

// store
import { useRefs } from '@store/ts/refs';

export default function (props: any, context: any) {
    // store
    const refs = useRefs();

    // vars
    const { _ } = window;
    const show = ref(false);

    watch(show, v => {
        if (!v)
            addSelected();
    });

    watch(() => props.visible, v => {
        if (v) {
            show.value = true;
        } else {
            context.emit('changeFilter', [props.data.filter, [ -1 ]]);
        }
    });

    const allChecked = computed(() => options.value.every(({ checked }: { checked: boolean }) => checked));

    const checkeds = computed(() => allChecked.value
        ? refs.localization.filters.all_select.all
        : options.value.reduce((accum: number, el: any) => accum + +el.checked , 0));

    const label = computed(() =>
        props.data.label[props.data.label.length - 1] === ':'
            ? props.data.label.slice(0, props.data.label.length - 1)
            : props.data.label,
    );

    const options = computed(() => props.data.refs.map((el: any) => {
        return reactive({
            // checked: !!_.get(props.filters, props.data.filter)?.find(exchange => exchange == el.id),
            checked:
              _.get(props.filters, props.data.filter)[0] == -1
                  ? true
                  : !!~_.get(props.filters, props.data.filter)?.findIndex((item: string | number) => item == el.id),
            ...el,
        });
    }));

    const setCheckAll = ($event: any) => {
        options.value.forEach((el: any) => {
            el.checked = $event;
        });
    };

    const addSelected = () => {
        if (allChecked.value) {
            context.emit('changeFilter', [ props.data.filter, [ -1 ] ]);
        } else {
            const arr = options.value.filter(({ checked }: { checked: boolean }) => checked).map(({ id }: { id: string | number }) => id);
            context.emit('changeFilter', [ props.data.filter, arr.length ? arr : [ -1 ] ]);
        }
    };

    return {
        show,
        refs,
        label,
        options,
        checkeds,
        allChecked,
        _,
        setCheckAll,
    };
}