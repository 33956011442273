
// vue
import { defineComponent } from 'vue';

// general
import general from './general';

// naive-ui
import {
    NCard,
    NIcon,
    NButton,
    NDrawer,
    NSkeleton,
    NDropdown,
    NRadioButton,
    NRadioGroup,
    NDrawerContent} from 'naive-ui';

// components
import Bots from '@components/filters/bots/desktop.vue';
import Exchanges from '@components/filters/exchanges/desktop.vue';
import RadioGroup from '@components/filters/radio-group/desktop.vue';
import DatePicker from '@components/filters/date-picker/desktop.vue';
import RbSwitch from '@components/rb-switch/index.vue';
import CheckboxGroup from '@components/filters/checkbox-group/desktop.vue';
import FilterCoins from '@components/filters/filter-coins/desktop.vue';

// icons
import {
    Cart20Regular,
    Filter20Filled,
    ColumnEdit20Filled,
    DeleteDismiss24Regular } from '@vicons/fluent';

export default defineComponent({
    name: 'filters-desktop',
    props: {
        refFilters: {
            type: [ Object, Array ],
        },
        filters: {
            type: Object,
            required: true,
        },
        filtersChanged: {
            type: Boolean,
            default: false,
        },
        filterId: {
            type: String,
            required: true,
        },
    },
    components: {
        Bots,
        NIcon,
        NCard,
        NDrawer,
        NButton,
        NDropdown,
        NSkeleton,
        RbSwitch,
        Exchanges,
        DatePicker,
        RadioGroup,
        FilterCoins,
        NRadioButton,
        NRadioGroup,
        CheckboxGroup,
        Cart20Regular,
        NDrawerContent,
        Filter20Filled,
        ColumnEdit20Filled,
        DeleteDismiss24Regular,
    },
    setup(props, context) {
        
        return {
            ...general(props, context),
        };
    },
});


// export default {
//     name: 'filters-desktop',
//     props: {
//         refFilters: {
//             type: [ Object, Array ],
//         },
//         filters: {
//             type: Object,
//             required: true,
//         },
//         filtersChanged: {
//             type: Boolean,
//             default: false,
//         },
//         filterId: {
//             type: String,
//             required: true,
//         },
//     },
//     components: {
//         NIcon,
//         NDrawer,
//         NButton,
//         NDropdown,
//         NDrawerContent,
//         Filter20Filled,
//     },
//     setup(props, { emit }) {
//         // store
//         const gl = useGl();
//         const refs = useRefs();
//         const marketplace = useMarketplace();

//         // i18n
//         const { t } = useI18n();

//         // vars
//         const showDrawer = ref();
//         const autoHeight = ref();
//         const visible = ref();
//         const showDropdownFilters = ref(false);
//         watch(showDropdownFilters, () => {
//             showDrawer.value = false;
//         });
//         // const filters = computed(() => props.refFilters.map((el, i) => ({
//         //     label: el.title,
//         //     key: i,
//         //     ...el,
//         // })));

//         const filtersList = computed(() => {
//             if (!props.refFilters) return;

//             let res = props.refFilters.map((el, i) => {
//                 if (el.mode === 'date_picker') {
//                     return {
//                         render: () => h(DatePicker, {
//                             data: el,
//                             class: 'px-1 mt-1',
//                             filters: props.filters,
//                             onSetVisibile: $event => visible.value = $event,
//                             onUpdateDate: $event => {
//                                 let value = `${new Date($event[0] + 86400000).toISOString().split('T')[0]} ${new Date($event[1] + 86400000).toISOString().split('T')[0]}`;
//                                 emit('changeFilter', [el.filter, value]);
//                             },
//                         }),
//                         label: el.title,
//                         key: i,
//                         ...el,
//                     };
//                 }

//                 return {
//                     label: el.title,
//                     key: i,
//                     ...el,
//                 };
//             });

//             const arr = [];

//             res.forEach((el, i) => {
//                 arr.push(el);

//                 if (i < res.length - 1) {
//                     arr.push({
//                         key: 'header-divider',
//                         type: 'divider',
//                     });
//                 }
//             });

//             arr.unshift({
//                 type: 'render',
//                 render: () => h('div', {
//                     class: 'cursor-default mb-1',
//                 }, [
//                     h('div', {
//                         class: 'px-[14px] flex justify-between items-center font-semibold text-gray-600 dark:text-white/75 mt-2',
//                     }, [
//                         t('filters'),
//                         props.filtersChanged
//                             ? h(NButton, {
//                                 secondary: true,
//                                 type: 'error',
//                                 size: 'tiny',
//                                 class: 'rounded-md flex items-center',
//                                 onClick: () => {
//                                     showDropdownFilters.value = false;
//                                     emit('reset-filters');
//                                 },
//                             },  t('resetSettings'))
//                             : null ,
//                     ]),
//                     h(NDivider, { class: 'my-0 py-0 mt-3' }),
//                 ]),
//             });

//             arr.push({
//                 type: 'render',
//                 render: () => h('div', null, [
//                     h(NDivider, { class: 'my-0 py-0 mt-1' }),
//                     h('div', {
//                         class: 'px-2 flex justify-end items-center pt-2 pb-1',
//                     }, [
//                         h(NButton, {
//                             secondary: true,
//                             type: 'default',
//                             class: 'rounded-md flex items-center text-gray-600 dark:text-white/75 mr-3',
//                             onClick: () => showDropdownFilters.value = false,
//                         }, refs.localization.filters.all_select.btn__cancel),
//                         h(NButton, {
//                             secondary: true,
//                             type: 'default',
//                             class: 'rounded-md flex items-center text-main dark:text-white/75 dark:bg-main bg-white',
//                             onClick: () => {
//                                 showDropdownFilters.value = false;
//                                 emit('getData');
//                             },
//                         }, refs.localization.filters.all_select.btn__apply),
//                     ]),
//                 ]),
//             });

//             return arr;
//         });

//         const renderDropdownLabel = option => {
//             if (option.mode === 'radio_group') {
//                 return h(RadioGroup, {
//                     data: option,
//                     drawerId: props.filterId,
//                     filters: props.filters,
//                     onShowDrawer: v => {
//                         showDrawer.value = v[0];
//                         autoHeight.value = v[1];
//                     },
//                     onChangeFilter: $event => emit('changeFilter', $event),
//                 });
//             } else if (option.mode === 'bots') {
//                 return h(Bots, {
//                     data: option,
//                     drawerId: props.filterId,
//                     filters: props.filters,
//                     onShowDrawer: v => {
//                         showDrawer.value = v[0];
//                         autoHeight.value = v[1];
//                     },
//                     onChangeFilter: $event => emit('changeFilter', $event),
//                 });
//             } else if (option.mode === 'exchanges') {
//                 return h(Exchanges, {
//                     data: option,
//                     drawerId: props.filterId,
//                     filters: props.filters,
//                     onShowDrawer: v => {
//                         showDrawer.value = v[0];
//                         autoHeight.value = v[1];
//                     },
//                     onChangeFilter: $event => {
//                         emit('changeFilter', $event);
//                     },
//                 });
//             } else if (option.mode === 'checkbox_group') {
//                 return h(CheckboxGroup, {
//                     data: option,
//                     drawerId: props.filterId,
//                     filters: props.filters,
//                     onShowDrawer: v => {
//                         showDrawer.value = v[0];
//                         autoHeight.value = v[1];
//                     },
//                     onChangeFilter: $event => emit('changeFilter', $event),
//                 });
//             } else if (option.mode === 'filter_coins') {
//                 return h(FilterCoins, {
//                     data: option,
//                     drawerId: props.filterId,
//                     filters: props.filters,
//                     onShowDrawer: v => {
//                         showDrawer.value = v[0];
//                         autoHeight.value = v[1];
//                     },
//                     onChangeFilter: $event => emit('changeFilter', $event),
//                 });
//             } else if (option.mode === 'filter_switch') {
//                 return h('div', {
//                     class: 'w-full h-full flex justify-between items-center',
//                 }, [
//                     option.label,
//                     h(RbSwitch, {
//                         'value': option.value,
//                         'onUpdate:value': $event => {
//                             emit('changeFilter', [option.filter, $event]);
//                         },
//                     }),
//                 ]);
//             } else if (option.mode === 'filter_button_groupe') {
//                 const childs = [];
//                 for (let el of option.refs) {
//                     childs.push(h(NRadioButton, {
//                         value: el.value,
//                         label: el.label,
//                     }));
//                 };

//                 return h('div', {
//                     class: 'w-full h-full flex items-center justify-between',
//                 }, [
//                     option.label,
//                     h(NRadioGroup, {
//                         'size': 'small',
//                         'class': 'rounded-md',
//                         'value': option.value,
//                         'onUpdate:value': $event => {
//                             emit('changeFilter', [option.filter, $event ]);
//                         },
//                     }, childs),
//                 ]);
//             } else {
//                 return h('div', {
//                     class: 'w-full h-full items-center',
//                 }, option.label);
//             }
//         };

//         return {
//             visible,
//             autoHeight,
//             showDrawer,
//             filtersList,
//             showDropdownFilters,
//             t,
//             renderDropdownLabel,
//         };
//     },
// };
