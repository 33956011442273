<template>
<base-drawer-mobile
    v-if="refs.localization"
    :label="refs.localization.topUpBalance.dialog_title"
    :show="show"
    @update:show="$emit('update:show', $event)">
    <!-- v-if="!selectionCurrency" -->
    <n-alert type="info" class="rounded-md" :show-icon="false">
        <div class="text-xs">{{ refs.localization.topUpBalance.select_method }}</div>
    </n-alert>
    <section class="flex my-4">
        <div
            class="border hover:border-main rounded-md cursor-pointer flex items-center justify-center w-4/12 mx-1 first:ml-0 last:mr-0 p-2"
            :class="currentMethodPay == 'Stripe' ? 'border-main' : 'border-gray-200 dark:border-gray-800'"
            @click="currentMethodPay = 'Stripe'">
            <img class="w-4/12" src="@assets/icons/visa.svg" />
            <img class="w-4/12" src="@assets/icons/mastercard.svg" />
        </div>
        <div
            class="border hover:border-main rounded-md cursor-pointer flex items-center justify-center w-4/12 mx-1 first:ml-0 last:mr-0 p-2"
            :class="currentMethodPay == 'PayPal' ? 'border-main' : 'border-gray-200 dark:border-gray-800'"
            @click="currentMethodPay = 'PayPal'">
            <img class="w-7/12" src="@assets/icons/paypal.svg" />
        </div>
        <div
            class="border hover:border-main rounded-md cursor-pointer flex items-center justify-center w-4/12 mx-1 first:ml-0 last:mr-0 p-2"
            :class="currentMethodPay == 'crypt' ? 'border-main' : 'border-gray-200 dark:border-gray-800'"
            @click="currentMethodPay = 'crypt'">
            <rb-coin class="w-6" :coin="currency(env.profitSymbol)" />
            <rb-coin class="w-6 mx-3" :coin="currency('eth')" />
            <rb-coin class="w-6" :coin="currency('usdt')" />
        </div>
    </section>
    <template v-if="currentMethodPay == 'crypt'">
        <div class="text-gray-600 dark:text-white/75 text-xs">
            {{ refs.localization.topUpBalance.topup_method }}
        </div>
        <div class="flex mt-2">
            <n-button
                tertiary
                class="rounded-md flex-grow btn-currencys"
                @click="showCurrencys = true">
                <div class="w-full flex justify-between items-center">
                    <div class="flex items-center" v-if="selectionCurrency">
                        <rb-coin class="w-5" :coin="currency(selectionCurrency.currency)" />
                        <span class="text-gray-600 dark:text-white/75 text-xs pl-2">
                            {{ selectionCurrency.title.length > 14 && !selectionCurrency?.account ? selectionCurrency.title.slice(0, 14) + '...' : selectionCurrency.title }}
                        </span>
                    </div>
                    <span v-else class="text-gray-600/50 dark:text-white/40">{{ t('select') }}</span>
                    <n-icon size="18"><CaretDown12Filled /></n-icon>
                </div>
            </n-button>
            <n-button
                v-if="selectionCurrency && !selectionCurrency?.account"
                class="rounded-md text-white/90 ml-2"
                :color="gl.mainColor"
                :loading="loadings.generateWallet"
                :disabled="loadings.generateWallet"
                @click="generateWallet">
                {{ refs.localization.topUpBalance?.generate_wallet_label || 'Сгенерировать кошелек' }}
            </n-button>
        </div>
        
        <template v-if="selectionCurrency?.account">
            <div
                class="flex flex-row items-center justify-between bg-white dark:bg-darkCardBg text-main p-4 rounded-md text-xs mt-4"
                @click="copy(selectionCurrency.account)">
                {{ selectionCurrency.account }}
                <n-icon class="ml-4" size="20"><Copy16Regular /></n-icon>
            </div>
            <div class="bg-white dark:bg-darkCardBg p-4 mt-2 text-xs rounded-md" v-html="selectionCurrency.message"></div>
            <div class="bg-white dark:bg-darkCardBg p-4 mt-2 rounded-md flex justify-center">
                <img class="w-9/12" :src="'data:image/png;base64,'+selectionCurrency.qrcode" />
            </div>
        </template>

        <base-drawer-mobile
            :label="refs.localization.topUpBalance.topup_method"
            v-model:show="showCurrencys">
            <section
                class="pb-4 flex content-start justify-between flex-wrap flex-grow overflow-y-scroll overscroll-none">
                <div
                    v-for="item in topupSelectMethods"
                    :key="item.id"
                    :class="item.id == selectionCurrency?.id ? 'border border-main' : ''"
                    class="px-2 w-[calc(50%-4px)] py-1 dark:bg-[#292929] bg-gray-200 rounded-md flex items-center mb-2"
                    @click="setSelectionCurrency(item.id)">
                    <rb-coin class="w-5" :coin="currency(item.currency)" />
                    <span class="text-gray-600 dark:text-white/75 text-xs pl-2">{{ item.title }}</span>
                </div>
            </section>
        </base-drawer-mobile>
    </template>
    <template v-else-if="currentMethodPay == 'PayPal'">
        <n-alert class="rounded-md mb-4" type="warning" size="small">
            <div class="notes text-[10px]">
                <div>
                    {{ refs.localization.topUpBalance?.min_replenishment_amount || 'Минимальная сумма пополнения:' }}
                    <strong>{{ topUpBalanceDefaultAmounts.min_amount }} USDT</strong>
                </div>
                <div>
                    {{ refs.localization.topUpBalance?.max_replenishment_amount || 'Максимальная сумма пополнения:' }}
                    <strong>{{ topUpBalanceDefaultAmounts.max_amount }} USDT</strong>
                </div>
            </div>
        </n-alert>
        <rb-input
            type="price"
            :label="refs.localization.topUpBalance.enter_amount + ':'"
            :status="dataForm.PayPal.status"
            :msg="dataForm.PayPal.msg"
            v-model:value="dataForm.PayPal.value"
            @update:value="dataForm.PayPal.status = undefined, dataForm.PayPal.msg = undefined" />
    </template>
    <template v-else>
        <n-alert class="rounded-md mb-4" type="warning" size="small">
            <div class="notes text-[10px]">
                <div>
                    {{ refs.localization.topUpBalance?.min_replenishment_amount || 'Минимальная сумма пополнения:' }}
                    <strong>{{ topUpBalanceDefaultAmounts.min_amount }} USDT</strong>
                </div>
                <div>
                    {{ refs.localization.topUpBalance?.max_replenishment_amount || 'Максимальная сумма пополнения:' }}
                    <strong>{{ topUpBalanceDefaultAmounts.max_amount }} USDT</strong>
                </div>
            </div>
        </n-alert>
        <rb-input
            type="price"
            :label="refs.localization.topUpBalance.enter_amount + ':'"
            :status="dataForm.Stripe.status"
            :msg="dataForm.Stripe.msg"
            v-model:value="dataForm.Stripe.value"
            @update:value="dataForm.Stripe.status = undefined, dataForm.Stripe.msg = undefined" />
    </template>
    <template v-if="currentMethodPay != 'crypt'" #footer>
        <n-button
            class="rounded-md text-white/90 w-full text-center"
            :color="gl.mainColor"
            @click="btns[currentMethodPay].fn">
            {{ btns[currentMethodPay].label }}
        </n-button>
    </template>
</base-drawer-mobile>
</template>

<script>
// general
import general from './general';

// icons
import {
    Copy16Regular,
    CaretDown12Filled,
    ArrowLeft12Regular } from '@vicons/fluent';

// naive-ui
import {
    NIcon,
    NAlert,
    NSelect,
    NButton } from 'naive-ui';
    
// components
import RbCoin from '@components/rb-coin';
import RbInput from '@components/rb-input/mobile.vue';

export default {
    name: 'topup-balance-desktop',
    components: {
        NIcon,
        RbCoin,
        NAlert,
        NSelect,
        NButton,
        RbInput,
        Copy16Regular,
        CaretDown12Filled,
        ArrowLeft12Regular,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
      
        return {
            ...general(props),
        };
    },
};
</script>

<style lang="scss" scoped>
.btn-currencys :deep(.n-button__content) {
  width: 100%;
}
</style>