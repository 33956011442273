// vue
import { ref, computed } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useEnv } from '@store/ts/env';
import { useRefs } from '@store/ts/refs';
import { useProfile } from '@store/profile';

// naive-ui
import { useNotification } from 'naive-ui';

// i18n
import { useI18n } from 'vue-i18n';

export default function (props, context) {
// store
    const gl = useGl();
    const env = useEnv();
    const refs = useRefs();
    const profile = useProfile();

    // ui
    const notification = useNotification();

    // i18n
    const { t } = useI18n();

    // vars
    const show = ref(false);

    const timezonesOptions = computed(() => Object.entries(refs.timezones).map(([ key, value ]) => {
        return value.length
            ? ({
                type: 'group',
                label: key,
                key,
                children: [ ...value.map(el => ({
                    label: el.name,
                    value: el.id,
                    ...el,
                })) ],
            })
            : ({
                label: value[0].name,
                value: value[0].id,
                ...value[0],
            });
    }).sort((v1, v2) => {
        if (v1.label.toLowerCase() < v2.label.toLowerCase()) {
            return -1;
        }
        if (v1.label.toLowerCase() > v2.label.toLowerCase()) {
            return 1;
        }
        return 0;
    }));

    const languagesOptions = computed(() => profile.languages.map(el => ({
        label: el.title,
        value: +el.id,
        ...el,
    })));

    const messengersOptions = computed(() => profile.messengers.map(el => ({
        label: el.title,
        value: el.id,
        ...el,
    })));

    const statViewsOptions = computed(() => profile.statViews.map(el => ({
        label: el.title,
        value: el.id,
        ...el,
    })));

    const messengerName = id => {
        if (!id || id == -1){
            return '? ';
        }

        let hint = profile.messengers.find(el => el.id === id);

        return hint ? `${hint.title}: ` : '';
    };

    const onRemoveMessenger = i => {
        profile.fullInfo.messengers.splice(i, 1);
    };

    const onAddMessenger = () => {
        profile.fullInfo.messengers.push({id: -1, value: ''});
    };

    return {
        gl,
        env,
        refs,
        show,
        profile,
        notification,
        statViewsOptions,
        languagesOptions,
        timezonesOptions,
        messengersOptions,
        t,
        messengerName,
        onAddMessenger,
        onRemoveMessenger,
    };
}