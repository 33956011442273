<template>
<div class="flex items-center" :class="classes">
    <div
        class="text-xs lg:text-sm text-gray-600 dark:text-white/75 whitespace-nowrap label"
        :class="labelClasses"
        v-html="label">
    </div>
    <n-popover
        v-if="help"
        trigger="hover"
        class="max-w-[500px] bg-main rounded-md"
        placement="top">
        <template #trigger>
            <n-icon
                class="text-main cursor-pointer text-xl"
                :class="helpPosition === 'right' ? 'order-2 ml-2' : 'order-first'">
                <QuestionCircle20Filled />
            </n-icon>
        </template>
        <span class="text-xs text-white" v-html="help"></span>
    </n-popover>
    <n-popover
        v-if="helpWarning"
        trigger="hover"
        class="max-w-[500px] rounded-md bg-yellow-600"
        placement="top">
        <template #trigger>
            <n-icon
                class="cursor-pointer text-base lg:text-lg text-yellow-600"
                :class="helpPosition === 'right' ? 'order-3 ml-2' : 'order-2'">
                <Warning24Filled />
            </n-icon>
        </template>
        <span class="notes text-xs text-white" v-html="helpWarning"></span>
    </n-popover>
</div>
</template>

<script>
// vue
import { computed } from 'vue';

// naive-ui
import {
    NIcon,
    NInput,
    NDivider,
    NPopover } from 'naive-ui';

// icons
import { Warning24Filled, QuestionCircle20Filled } from '@vicons/fluent';

export default {
    name: 'rb-title',
    props: {
        classes: {
            type: String,
        },
        label: {
            type: String,
            required: true,
        },
        status: {
            type: String,
            default: undefined,
        },
        help: {
            type: String,
        },
        helpWarning: {
            type: String,
        },
        helpPosition: {
            type: String,
            default: 'right',
        },
    },
    components: {
        NIcon,
        NPopover,
        Warning24Filled,
        QuestionCircle20Filled,
    },
    setup(props) {
        const labelClasses = computed(() => {
            return [
                props.status === 'error' ? 'text-red-400' : '',
                props.helpPosition === 'right' ? 'order-first' : 'order-last ml-2',
            ];
        });

        return {
            labelClasses,
        };
    },
};
</script>