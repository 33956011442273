<template>
<section>
    <div class="flex flex-wrap">
        <div
            v-if="label"
            class="text-xs text-left"
            :class="[status === 'error' ? 'text-red-400' : 'text-gray-600 dark:text-white/75', nowrap ? 'whitespace-nowrap' : '']">
            {{ label }}
        </div>
        <slot name="afterTitle"></slot>
        <n-popover
            v-if="help"
            scrollable
            trigger="click"
            class="max-w-[200px] max-h-[200px] rounded-md"
            :class="popoverType === 'info' ? 'bg-main' : 'bg-yellow-600'">
            <template #trigger>
                <n-icon
                    class="text-lg ml-2"
                    :class="popoverType === 'info' ? 'text-main' : 'text-yellow-600'">
                    <QuestionCircle20Filled />
                </n-icon>
            </template>
            <span class="notes text-[10px] text-white" v-html="help"></span>
        </n-popover>
    </div>
    <n-button
        :size="size"
        :loading="loading"
        :disabled="loading || disabled"
        tertiary
        class="select-button w-full rounded-md pl-2 pr-2"
        :class="label ? 'mt-2' : ''"
        @click="showDrawer = true">
        <div class="w-full flex justify-between items-center">
            <div
                v-if="value !== undefined && value !== null"
                class="flex items-center w-[calc(100%-24px)] overflow-x-auto scrollbar-hide"
                :class="multiple ? '' : ''">
                <template v-if="multiple">
                    <template v-if="currentValue.length">
                        <div
                            v-for="checked in currentValue"
                            :key="checked"
                            class="rounded-md h-full bg-main bg-opacity-50 border-opacity-50 border border-main p-1 mr-2">
                            {{ checked.label }}
                        </div>
                    </template>
                    <template v-else>
                        <span class="text-gray-600 dark:text-white/75 text-xs pl-2">{{ placeholder }}</span>
                    </template>
                </template>
                <template v-else>
                    <span class="text-gray-600 dark:text-white/75 text-xs pl-2">
                        {{ currentValue?.label || placeholder }}
                    </span>
                </template>
            </div>
            <span v-else
                class="text-gray-600/50 dark:text-white/40">{{ placeholder }}</span>
            <n-icon size="18"><CaretDown12Filled /></n-icon>
        </div>
    </n-button>
    <div v-if="status === 'error'" class="text-[10px] w-full mt-2 text-red-400 text-right">{{ msg }}</div>
    <n-drawer
        v-model:show="showDrawer"
        width="100%"
        display-directive="show"
        class="bg-gray-100 dark:bg-darkbg"
        :auto-focus="false"
        @after-enter="showDrawerContent = true">
        <n-drawer-content body-content-style="padding: 0">
            <div class="flex flex-col h-full">
                <div class="w-full flex justify-between px-3 items-center bg-gray-100 dark:bg-darkbg text-sm text-center text-gray-600 dark:text-white/75 py-2">
                    <n-icon
                        size="20"
                        class="text-gray-600 dark:text-white/75"
                        @click="showDrawer = false">
                        <ArrowLeft12Regular />
                    </n-icon>
                    <rb-input v-if="showSearch" :value="query" @update:value="search" class="ml-3" />
                    <slot name="afterSearch"></slot>
                    <n-button
                        quaternary
                        class="bg-transparent text-main pr-0"
                        @click="showDrawer = false">
                        {{ t('done') }}
                    </n-button>
                </div>
                <div class="pb-4 flex-grow overflow-y-scroll overscroll-none scrollbar-hide">
                    <template v-if="render === 'depo'">
                        <template
                            v-for="(item, i) in optionsRef"
                            :key="i">
                            <div
                                v-if="item.show"
                                class="w-full flex justify-between items-center pl-3 pr-3 py-2 rounded-none dark:odd:bg-[#242424] odd:bg-gray-50"
                                @click="setValue(item)">
                                <div class="flex-grow flex items-center justify-between">
                                    {{ item.label }}
                                    <div class="flex items-center">
                                        <div class="text-sm font-semibold">{{ item.amount }}</div>
                                        <rb-coin class="w-4 mx-2" :coin="item" />
                                        <div class="uppercase opacity-50">{{ item.currency }}</div>
                                    </div>
                                </div>
                                <n-icon v-if="isChecked(item)" size="20" class="text-main ml-2">
                                    <Checkmark12Filled />
                                </n-icon>
                            </div>
                        </template>
                    </template>
                    <template v-else-if="render === 'currencies'">
                        <template
                            v-for="(item, i) in optionsRef"
                            :key="i">
                            <div
                                v-if="item.show"
                                class="w-full flex justify-between items-center pl-3 pr-3 py-2 rounded-none dark:odd:bg-[#242424] odd:bg-gray-50"
                                @click="setValue(item)">
                                <div class="flex-grow flex items-center justify-start">
                                    <rb-coin class="w-4 mx-2" :coin="item" />
                                    {{ item.label }}
                                </div>
                                <n-icon v-if="isChecked(item)" size="20" class="text-main ml-2">
                                    <Checkmark12Filled />
                                </n-icon>
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <template
                            v-for="(item, i) in optionsRef"
                            :key="i">
                            <template v-if="item.type === 'group'">
                                <div
                                    v-if="item.show"
                                    class="text-gray-600 dark:text-white/75 sticky top-0 text-sm pl-3 py-2 bg-white dark:bg-darkCardBg z-50 opacity-100">
                                    {{ item.label }}
                                </div>
                                <template
                                    v-for="child in item.children"
                                    :key="child.value">
                                    <div
                                        v-if="child.show"
                                        class="w-full flex justify-between items-center pl-6 pr-3 py-2 rounded-none"
                                        @click="setValue(child)">
                                        {{ child.label }}
                                        <n-icon v-if="isChecked(child)" size="20" class="text-main">
                                            <Checkmark12Filled />
                                        </n-icon>
                                    </div>
                                </template>
                            </template>
                            <template v-else>
                                <div
                                    v-if="item.show"
                                    class="w-full flex justify-between items-center pl-6 pr-3 py-2 rounded-none dark:odd:bg-[#242424] odd:bg-gray-50"
                                    @click="setValue(item)">
                                    {{ item.label }}
                                    <n-icon v-if="isChecked(item)" size="20" class="text-main">
                                        <Checkmark12Filled />
                                    </n-icon>
                                </div>
                            </template>
                            <!-- dark:odd:bg-[#242424] odd:bg-gray-50 -->
                            
                        </template>
                    </template>
                </div>
            </div>
        </n-drawer-content>
    </n-drawer>
</section>
</template>

<script>
import { ref, computed, watch, onMounted } from 'vue';

// i18n
import { useI18n } from 'vue-i18n';

// naive-ui
import {
    NIcon,
    NSelect,
    NButton,
    NDrawer,
    NPopover,
    NDrawerContent } from 'naive-ui';

// components
import RbCoin from '@components/rb-coin';
import RbInput from '@components/rb-input/mobile.vue';

// icons
import {
    Checkmark12Filled,
    CaretDown12Filled,
    ArrowLeft12Regular,
    QuestionCircle20Filled } from '@vicons/fluent';

export default {
    name: 'rb-select',
    emits: ['update:value'],
    props: {
        label: {
            type: String,
        },
        labelWidth: {
            type: [ String, Number ],
        },
        labelPosition: {
            type: String,
            default: 'top',
            validator: prop => ['left', 'top'].includes(prop),
        },
        size: {
            type: String,
            default: 'medium',
            validator: prop => ['tiny', 'small', 'medium', 'large'].includes(prop),
        },
        render: {
            type: String,
            validator: prop => ['currencies', 'pair', 'depo'].includes(prop),
        },
        help: {
            type: String,
        },
        largeHelp: {
            type: Boolean,
        },
        autoClose: {
            type: Boolean,
            default: true,
        },
        helpPosition: {
            type: String,
            default: 'right',
        },
        value: {
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        placeholder: {
            type: String,
            default: () => useI18n().t('select'),
        },
        clearable: {
            type: Boolean,
        },
        multiple: {
            type: Boolean,
        },
        defaultValue: {
            type: Boolean,
            default: -1,
        },
        loading: {
            type: Boolean,
        },
        hideSelect: {
            type: Boolean,
        },
        status: {
            type: String,
            default: undefined,
        },
        msg: {
            type: String,
        },
        disabled: {
            type: Boolean,
        },
        showSearch: {
            type: Boolean,
            default: true,
        },
        nowrap: {
            type: Boolean,
            default: true,
        },
        popoverType: {
            type: String,
            default: 'info',
            validator: prop => ['info', 'warning'].includes(prop), 
        },
    },
    components: {
        NIcon,
        RbCoin,
        RbInput,
        NDrawer,
        NSelect,
        NButton,
        NPopover,
        NDrawerContent,
        Checkmark12Filled,
        CaretDown12Filled,
        ArrowLeft12Regular,
        QuestionCircle20Filled,
    },
    setup(props, { emit }) {
        // vars
        const { _ } = window;

        // i18n
        const { t } = useI18n();
        const query = ref('');
        const showDrawer = ref(false);
        const localValue = ref([]);

        watch(showDrawer, v => {
            emit('showSelect', v);
        });

        const optionsRef = computed(() => {
            const res = props.options.map(el => {
                el.label = '' + el.label;
                
                if (el.type === 'group') {
                    el.children.forEach(child => {
                        child.show = child.label.toLowerCase().includes(query.value.toLowerCase());
                    });

                    el.show = el.children.some(child => child.show);
                } else {
                    if (props.showSearch) {
                        if (typeof el.label === 'string') {
                            el.show = el.label.toLowerCase().includes(query.value.toLowerCase());
                        } else {
                            el.show = el.label.includes(query.value);
                        }
                    } else el.show = true;
                }
                
                return el;
            });

            return res;
        });

        const currentValue = computed(() => {
            if (props.multiple) {
                const result = [];

                for (const item of optionsRef.value) {
                    if (item.value != props.value && Object.prototype.hasOwnProperty.call(item, 'children')) {
                        const child = item.children.find(({ value }) => {
                            if (Array.isArray(props.value))
                                return ~props.value.findIndex(el => el == value);
                        });

                        if (child) {
                            result.push(child);
                        }
                    } else if (Array.isArray(props.value) && ~props.value.findIndex(el => el == item.value)) {
                        result.push(item);
                    }
                }

                return result;
            } else {
                let result;

                for (const item of optionsRef.value) {
                    if (item.value != props.value && Object.prototype.hasOwnProperty.call(item, 'children')) {
                        const child = item.children.find(({ value }) => value == props.value);

                        if (child) {
                            result = child;
                            break;
                        }
                    } else if (item.value == props.value) {
                        result = item;
                        break;
                    }
                }

                return result;
            }
        });

        const addOrRemove = (array, value) => {
            var index = array.indexOf(value);

            if (index === -1) {
                array.push(value);
            } else {
                array.splice(index, 1);
            }

            return array;
        };

        const setValue = v => {
            if (props.multiple) {
                const copy = Array.isArray(props.value)
                    ? _.cloneDeep(props.value)
                    : [ props.value ];

                emit('update:value', addOrRemove(copy, v.value));
            } else {
                emit('update:value', v.value);
                if (props.autoClose)
                    showDrawer.value = false;
            }
        };

        const isChecked = v => {
            return Array.isArray(props.value)
                ? ~props.value.findIndex(el => el == v.value)
                : props.value == v.value;
        };

        const search = $event => {
            query.value = $event;
            emit('search', $event);
        };

        return {
            query,
            showDrawer,
            localValue,
            optionsRef,
            currentValue,
            t,
            search,
            setValue,
            isChecked,
        };
    },
};
</script>

<style lang="scss" scoped>
.select-button :deep(.n-button__content) {
    width: 100%;
}
</style>