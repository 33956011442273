<template>
<div class="flex flex-wrap items-center">
    <div class="flex items-center mr-4" v-if="label">
        <div class="text-gray-600 dark:text-white/75 text-xs">{{ label }}</div>
        <n-popover
            v-if="help"
            trigger="hover"
            :placement="helpPosition"
            :style="largeHelp ? 'width: 500px' : ''">
            <template #trigger>
              <n-icon class="text-main cursor-pointer text-xl mx-2">
                <QuestionCircle20Filled />
              </n-icon>
            </template>
            <span v-html="help"></span>
        </n-popover>
    </div>
    <n-switch
        class="mt-[1px]"
        :size="size"
        :value="value"
        :disabled="disabled"
        @update:value="$emit('update:value', $event)">
        <template #checked>
            <div class="text-xs" :class="label ? '' : 'pl-3'">{{ checkedText }}</div>
        </template>
        <template #unchecked>
            <div class="text-xs">{{ unCheckedText }}</div>
        </template>
    </n-switch>
</div>
</template>

<script>
// naive-ui
import {
    NIcon,
    NSwitch,
    NPopover } from 'naive-ui';

// icons
import { QuestionCircle20Filled } from '@vicons/fluent';

export default {
    name: 'rb-switch',
    emits: ['update:value'],
    props: {
        label: {
            type: String,
            required: true,
        },
        help: {
            type: String,
        },
        largeHelp: {
            type: Boolean,
        },
        helpPosition: {
            type: String,
            default: 'right',
        },
        size: {
            type: String,
            default: 'small',
        },
        value: {
            required: true,
        },
        checkedText: {
            default: 'YES',
        },
        unCheckedText: {
            default: 'NO',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        NIcon,
        NSwitch,
        NPopover,
        QuestionCircle20Filled,
    },
    setup() {
    // 
    },
};
</script>