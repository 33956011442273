// axios
import axios from 'axios';

// interfaces
interface IPayload {
    method?: string,
    action: string,
    payload?: {
        [key: string]: any,
    }
}

interface IPostAdviceMessages {
    id: number,
    msg: string,
    need_feedback: boolean,
    route: string,
    title: string,
}

interface IResponse {
    action: string,
    data: {
        [key: string]: any,
    },
    lang: string,
    postDialogMessages: Array<IPostAdviceMessages>,
    datetime: string,
}

// store
import { useGl } from '@store/ts/gl';
import { useUser } from '@store/ts/user';
import { useEnv } from '@store/ts/env';

const { _ } = window;

const $axios = axios.create({
    baseURL: 'https://app.revenuebot.io/private/api',
    // baseURL: 'https://app-c0.revenuebot.io/private/api',
    withCredentials: true,
    timeout: 600000,
});

$axios.interceptors.response.use((response: any) => {
    const gl = useGl();

    if (response.data?.logout && process.env.production) {
        const { redirectTo } = response.data.logout;
        window.location.href = redirectTo;
        return;
    }

    if (response.data.data && response.data.data.stage) {
        gl.stage = response.data.data.stage;
        gl.stageState = 'awaitng';
        gl.showLoading = false;

        return new Promise((resolve: any) => {
            const check2FAState = setInterval(() => {
                if (gl.stageState === 'inactive') {
                    clearInterval(check2FAState);

                    const config = {
                        ...JSON.parse(response.config.data),
                        data: {
                            ...JSON.parse(response.config.data).data,
                            ...gl.stageExtra.extra,
                        },
                    };

                    resolve($axios({
                        method: 'post',
                        data: config,
                    }));
                } else if (gl.stageState === 'canceled') {
                    clearInterval(check2FAState);
                    resolve();
                }
            }, 200);
        });
    }

    if (response.data?.build_timestamp) {
        if (gl.new_build) return;

        // eslint-disable-next-line no-cond-assign
        if (gl.build_timestamp != response.data?.build_timestamp) {
            gl.build_timestamp = response.data.build_timestamp;
            const message = response.data?.message || 'New updates available, RB will be autorefreshed now...';
            const autorefresh_in = response.data?.autorefresh_in;
            gl.setNewBuild({ build_timestamp: response.data.build_timestamp, message, autorefresh_in });
            document.querySelector('.lds-ellipsis__container')!.classList.add('hidden');
        }
        
        return new Promise(resolve => {
            const check2FAState = setInterval(() => {
                // 
            }, 11000);
        });
    }

    if (response.data?.postAdviceMessages) {
        gl.postAdviceMessages = response.data.postAdviceMessages;
    }

    if (response.data?.postDialogMessages) {
        gl.postDialogMessages = response.data.postDialogMessages;
    }

    

    return response;
}, error => {
    return Promise.reject(error);
});

export default class Api {
    static BASE_URL = 'https://app.revenuebot.io/private/api';
    static withCredentials = true;
    static timeout = 600000;

    static async getData({ payload, method = 'post', action }: IPayload): Promise<IResponse | undefined>  {
        const { auth } = useUser();
        const env = useEnv();
        const gl = useGl();

        const data = {
            token: auth.token,
            // extra: auth.extra,
            action,
            lang: env.language,
            version: '2.0.0',
            build_timestamp: gl.build_timestamp,
            data: payload,
        };

        try {
            gl.canAutoUpdate = false;

            const result = await $axios({
                method,
                data,
            });

            gl.canAutoUpdate = true;

            return result.data;
        } catch (e) {
            console.log('e', e);
            gl.serverError = true;

            setTimeout(() => {
                gl.serverError = false;
            }, 1000);
        }
    }
};