<template>
<div v-if="label" class="text-md text-gray-600 dark:text-white/75 whitespace-nowrap">{{ label }}</div>
<n-select
    filterable
    :class="label ? 'mt-2' : ''"
    :options="options"
    :value="modelValue"
    :render-tag="renderTag"
    :render-label="renderLabel"
    :placeholder="`- ${t('select')} -`"
    @update:value="$emit('update:modelValue', $event)"/>
</template>

<script>
// vue
import { h, ref, reactive, computed, watch, onMounted } from 'vue';

// naive-ui
import { NSelect } from 'naive-ui';

// components
import RbCoin from '@components/rb-coin';

// i18n
import { useI18n } from 'vue-i18n';

export default {
    name: 'RbWalletSelect',
    emits: ['update:modelValue'],
    components: {
        RbCoin,
        NSelect,
    },
    props: {
        modelValue: [String, Number],
        placeholder: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: '',
        },
        currencies: {
            type: Object,
            required: true,
        },
        items: {
            default: () => [],
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n();

        const deposit = ref();

        const options = computed(() => props.items.map(el => ({
            label: el.name,
            value: el.id,
            class: 'w-full',
            code_name: 'rb-cc__' + el.currency,
            ...el,
        })));

        const renderTag = ({ option }) => {
            return h('div', {
                class: 'w-full flex flex-row items-center',
            }, [
                h('span', {
                    class: 'text-base flex-grow',
                }, option.name),
                h('span', {
                    class: 'text-md text-main font-semibold',
                }, option.amount),
                h(RbCoin, {
                    coin: option,
                    class: 'ml-2 w-6',
                }),
                h('span', {
                    class: 'ml-2 mr-2 uppercase',
                }, option.currency),
            ]);
        };

        const renderLabel = option => {
            return h('div', {
                class: 'w-full pr-6 flex flex-row items-center',
            }, [
                h('span', {
                    class: 'text-sm flex-grow',
                }, option.name),
                h('span', {
                    class: 'text-md font-semibold',
                }, option.amount),
                h(RbCoin, {
                    coin: option,
                    class: 'ml-2 w-6',
                }),
                h('span', {
                    class: 'ml-2 mr-2 uppercase',
                }, option.currency),
            ]);
        };

        return {
            options,
            deposit,
            t,
            renderTag,
            renderLabel,
        };
    },
};
</script>

<style lang="scss">
.n-base-select-menu .n-base-select-option .n-base-select-option__content {
    width: 100%;
}
</style>