<template>
<section>
    <h4 class="text-xs text-gray-600 dark:text-white/75 text-left">{{ label }}</h4>
    <n-button
        secondary
        type="default"
        class="rounded-md flex items-center w-full mt-2 px-3 custom-button"
        @click="show = true">
        <div class="w-full flex justify-between items-center font-semibold">
            <span v-if="isSomeRange">{{ isSomeRange.label }}</span>
            <div v-else class="text-sm">
                <span>{{ new Date(data.value[0]).toISOString().split('T')[0] }}</span>
                <span class="px-2 opacity-50">&mdash;</span>
                <span>{{ new Date(data.value[1]).toISOString().split('T')[0] }}</span>
            </div>

            <n-icon size="20">
                <CalendarLtr32Regular />
            </n-icon>
        </div>
    </n-button>
    <!-- <n-button
        secondary
        size="large"
        type="default"
        class="rounded-md flex items-center w-full"
        @click="show = true">
        <div>{{ new Date(data.value[0]).toISOString().split('T')[0] }}</div>
        <div class="px-6 opacity-50">/</div>
        <div>{{ new Date(data.value[1]).toISOString().split('T')[0] }}</div>
    </n-button> -->
    
    <n-drawer
        v-model:show="show"
        width="100%"
        class="bg-gray-100 dark:bg-darkbg"
        :auto-focus="false">
        <n-drawer-content body-content-style="padding: 0" class="px-3">
            <section class="flex flex-col h-full">
                <div class="flex-grow">
                    <div class="flex flex-wrap mt-3">
                        <n-button
                            v-for="(range, i) in ranges"
                            :key="i"
                            strong
                            size="tiny"
                            class="rounded-md mb-2"
                            :class="[i < ranges.length - 1 ? 'mr-2' : 'mr-0', isSomeRange?.id == range.id ? 'text-white/90' : '']"
                            :color="isSomeRange?.id == range.id ? gl.mainColor : null"
                            @click="$emit('updateDate', { value: range.value, range: range.id })">
                            {{ range.label }}
                        </n-button>
                    </div>

                    <n-date-picker
                        panel
                        class="mt-2 block w-full rounded-md"
                        :actions="null"
                        type="daterange"
                        size="large"
                        value-format="yyyy-MM-dd"
                        :value="data.value"
                        @update:value="$emit('updateDate', { value: [$event[0] + 86400000, $event[1] + 86400000] } )" />
                </div>

                <n-button
                    secondary
                    quaternary
                    type="default"
                    class="rounded-md text-main w-full mb-3"
                    @click="show = false">
                    {{ refs.localization.filters.general.filters_done_label }}
                </n-button>
            </section>
            <!-- <div class="flex flex-col h-full">
                <div class="flex justify-between items-center w-full bg-gray-100 dark:bg-darkbg text-sm text-center text-gray-600 dark:text-white/75 px-3 py-3">
                    <n-icon
                        size="20"
                        class="text-gray-600 dark:text-white/75"
                        @click="show = false">
                        <ArrowLeft12Regular />
                    </n-icon>
                    <div class="flex justify-end">
                        <n-button
                            quaternary
                            size="small"
                            type="default"
                            class="rounded-md flex items-center text-main"
                            @click="show = false">
                            Готово
                        </n-button>
                    </div>
                </div>
                <div class="pb-4 flex-grow overflow-y-scroll overscroll-none scrollbar-hide">
                    <section class="flex px-3">
                        <n-popover
                            :overlap="overlap"
                            placement="bottom-end"
                            trigger="click">
                            <template #trigger>
                                <div class="w-6/12 pr-1">
                                    <n-button
                                        secondary
                                        size="large"
                                        type="default"
                                        class="rounded-md flex items-center w-full"
                                        @click="show = true">
                                        {{ new Date(data.value[0]).toISOString().split('T')[0] }}
                                    </n-button>
                                </div>
                            </template>
                            <n-date-picker
                                panel
                                :actions="null"
                                type="date"
                                size="large"
                                :value="data.value[0]"
                                @update:value="$emit('updateDate', [ $event + 86400000, data.value[1] ])" />
                        </n-popover>

                        <n-popover
                            :overlap="overlap"
                            placement="bottom-end"
                            trigger="click">
                            <template #trigger>
                                <div class="w-6/12 pl-1">
                                    <n-button
                                        secondary
                                        size="large"
                                        type="default"
                                        class="rounded-md flex items-center w-full"
                                        @click="show = true">
                                        {{ new Date(data.value[1]).toISOString().split('T')[0] }}
                                    </n-button>
                                </div>
                            </template>
                            <n-date-picker
                                panel
                                :actions="null"
                                type="date"
                                size="large"
                                :value="data.value[1]"
                                @update:value="$emit('updateDate', [ data.value[0], $event + 86400000 ])" />
                        </n-popover>
                    </section>
                </div>
            </div> -->
        </n-drawer-content>
    </n-drawer>
</section>
</template>

<script>
// vue
import { defineComponent } from 'vue';

// general
import general from './general';

// icons
import {
    ArrowLeft12Regular,
    ArrowRight12Regular,
    CalendarLtr32Regular } from '@vicons/fluent';

// naive-ui
import {
    NIcon,
    NDrawer,
    NButton,
    NPopover,
    NDatePicker,
    NDrawerContent } from 'naive-ui';

export default defineComponent({
    name: 'date-picker-mobile',
    props: {
        data: {
            type: Object,
            required: true,
        },
        filters: {
            type: Object,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
    },
    components: {
        NIcon,
        NDrawer,
        NButton,
        NPopover,
        NDatePicker,
        NDrawerContent,
        ArrowLeft12Regular,
        CalendarLtr32Regular,
    },
    setup(props, context) {
        
        return {
            ...general(props, context),
        };
    },
});
</script>

<style lang="scss" scoped>
.custom-button :deep(.n-button__content) {
    width: 100%;
}
</style>