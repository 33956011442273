export default {
    ENV: process.env.NODE_ENV,
	
    DUBEG: true,
	
    BACKEND: {
        VERSION: '1.0.0',
        ENDPOINT: 'https://app.revenuebot.io/private/api',
        // ENDPOINT: process.env.NODE_ENV == 'production' ? "https://app.revenuebot.io/private/api" : "//wp.dock/rb/endpoint.php",
    },
	
    COOKIES: {
        DOMAIN: process.env.NODE_ENV == 'production' ? '.revenuebot.io' : '.localhost:8080',
        // DOMAIN: ".revenuebot.io",
        PATH: '/',
    },
	
    //PROXY_MAP: /*process.env.NODE_ENV !== 'production' ? {} :*/ {
    // PROXY_MAP: process.env.NODE_ENV !== 'production' ? {} : {
    //     [API.TOOLS.VA.RUN_PRESET]: 'https://vaapp.revenuebot.io/private/api/tools/va/runPreset/',
    // },
};