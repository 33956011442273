<template>
<section class="pb-2">
    <div class="text-xl text-left text-gray-700 dark:text-white/90 font-semibold">
        <n-skeleton v-if="!profile.localization" :width="160" :height="20" :sharp="false" />
        <template v-else>{{ profile.localization.profile_password_title }}</template>
    </div>
    <section class="flex justify-between items-stretch">
        <div
            class="flex flex-col transition-all mt-4"
            :class="profile.fullInfo.two_factor_auth == 'gauth' && profile.fullInfo.two_factor_auth_gauth_qr && profile.fullInfo.two_factor_auth_gauth_code ? 'w-8/12 pr-2' : 'w-full'">
            <n-card
                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg"
                :bordered="false"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <template #default>
                    <section class="flex justify-between">
                        <div
                            class="flex flex-col items-start"
                            :class="profile.fullInfo.two_factor_auth == 'sms' ? 'w-6/12' : 'w-full'">
                            <div
                                class="text-md text-left"
                                :class="profile.errors?.two_factor_auth ? 'text-red-400' : 'text-gray-600 dark:text-white/75'">
                                {{ profile.localization['profile_password_two_factor_authentication_type'] }}
                            </div>
                            <n-alert
                                v-if="profile.fullInfo.two_factor_auth == 'gauth' && !profile.fullInfo.two_factor_auth_gauth_qr && !profile.fullInfo.two_factor_auth_gauth_code"
                                class="rounded-md my-2 w-full"
                                type="success">
                                <div class="font-md font-semibold">
                                    {{ profile.localization['profile_password_two_factor_authentication_ga_result_message'] }}
                                </div>
                            </n-alert>
                            <n-radio-group
                                id="profile__two_factor_auth"
                                class="mt-2"
                                v-model:value="profile.fullInfo.two_factor_auth"
                                @update:value="profile.cleanError('two_factor_auth')">
                                <n-radio
                                    v-for="item in profile.twoFactorAuthTypes"
                                    :key="item.id"
                                    :value="item.id">
                                    {{ item.title }}
                                </n-radio>
                            </n-radio-group>
                            <div v-if="profile.errors?.two_factor_auth" class="w-full mt-2 text-red-400 text-right">
                                {{ profile.errors?.two_factor_auth?.msg }}
                            </div>
                        </div>

                        <rb-input
                            v-if="profile.fullInfo.two_factor_auth == 'sms'"
                            class="w-6/12 pl-2"
                            :status="profile.errors?.two_factor_auth_phone ? 'error' : undefined"
                            :msg="profile.errors?.two_factor_auth_phone?.msg"
                            :label="profile.localization['profile_password_two_factor_authentication_sms_phone_number_f']"
                            v-model:value="profile.fullInfo.two_factor_auth_phone"
                            @update:value="profile.cleanError('two_factor_auth_phone')" />
                    </section>
                </template>
            </n-card>
            <n-card
                class="flex-grow overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg mt-4"
                :bordered="false"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <template #default>
                    <section class="flex">
                        <rb-input
                            class="w-6/12 pr-2"
                            type="password"
                            :status="profile.errors?.new_password ? 'error' : undefined"
                            :msg="profile.errors?.new_password?.msg"
                            :label="profile.localization['profile_password_new_password_f']"
                            :placeholder="profile.localization['profile_password_new_password_i']"
                            v-model:value="profile.fullInfo.new_password"
                            @update:value="profile.cleanError('new_password')" />
                        <rb-input
                            class="w-6/12 pl-2"
                            type="password"
                            :status="profile.errors?.re_new_password ? 'error' : undefined"
                            :msg="profile.errors?.re_new_password?.msg"
                            :label="profile.localization['profile_password_re_enter_new_password_f']"
                            :placeholder="profile.localization['profile_password_re_enter_new_password_i']"
                            v-model:value="profile.fullInfo.re_new_password"
                            @update:value="profile.cleanError('re_new_password')" />
                    </section>
                </template>
            </n-card>
        </div>

        <div
            v-show="profile.fullInfo.two_factor_auth == 'gauth' && profile.fullInfo.two_factor_auth_gauth_qr && profile.fullInfo.two_factor_auth_gauth_code"
            class="transition-all w-4/12 pl-2 mt-4">
            <n-card
                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg h-full"
                :bordered="false"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <template #default>
                    <section class="flex justify-center flex-wrap">
                        <span class="text-left text-gray-600 dark:text-white/75">
                            1. {{ profile.localization['profile_password_two_factor_authentication_ga_install_info'] }}
                        </span>
                        <div class="flex justify-between mt-2">
                            <a href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8" class="mr-2 rounded-md overflow-hidden" target="_blank">
                                <img src="@assets/img/appstore.png">
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" class="ml-2 rounded-md overflow-hidden" target="_blank">
                                <img src="@assets/img/gplay.png">
                            </a>
                        </div>
                        <n-divider />

                        
                        <span class="text-left text-gray-600 dark:text-white/75">
                            2. {{ profile.localization['profile_password_two_factor_authentication_ga_scan_info'] }}
                        </span>
                        <img class="mt-2" :src="profile.fullInfo.two_factor_auth_gauth_qr">
                        <span class="text-left text-gray-600 dark:text-white/75 mt-2">
                            {{ profile.localization['profile_password_two_factor_authentication_ga_enter_code_info'] }}
                        </span>
                        <div
                            class="mt-2 w-full flex flex-row items-center justify-between bg-gray-100 dark:bg-darkbg text-main p-2 rounded-md text-md cursor-pointer"
                            @click="copy(profile.fullInfo.two_factor_auth_gauth_code)">
                            {{ profile.fullInfo.two_factor_auth_gauth_code }}
                            <n-icon class="ml-4" size="25">
                                <Copy16Regular />
                            </n-icon>
                        </div>

                        <n-alert class="bg-[#eaf0fe] dark:bg-blue-900 dark:bg-opacity-20 rounded-md mt-2 text-xs" type="info">
                            <div>{{ profile.localization.profile_password_two_factor_authentication_ga_recover_info }}</div>
                        </n-alert>
                    </section>
                </template>
            </n-card>
        </div>
    </section>
</section>
</template>

<script>
// general
import general from './general';

// icons
import { Dismiss16Filled, IosArrowRtl24Filled, Copy16Regular } from '@vicons/fluent';

// components
import RbInput from '@components/rb-input';
import RbSelect from '@components/rb-select';
import RbSwitch from '@components/rb-switch';

// naive-ui
import {
    NIcon,
    NCard,
    NRadio,
    NAlert,
    NButton,
    NDivider,
    NRadioGroup,
    NCollapseTransition } from 'naive-ui';

export default {
    name: 'password',
    components: {
        NIcon,
        NCard,
        NRadio,
        NAlert,
        NButton,
        RbInput,
        RbSelect,
        NDivider,
        RbSwitch,
        NRadioGroup,
        Copy16Regular,
        Dismiss16Filled,
        IosArrowRtl24Filled,
        NCollapseTransition,
    },
    setup() {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>