// vue
import { h, ref, reactive, computed, watch, onMounted } from 'vue';

// services
import ApiKeysService from '@services/apiKeysService';
import LocalStorage from '@services/ts/localStorage';

// shared
import { CRUD_PAGER_FACTORY } from '@shared/factories';

// i18n
import { useI18n } from 'vue-i18n';

// store
import { useGl } from '@/store/gl';
import { useRefs } from '@/store/refs';
import { useApiKeys } from '@store/apiKeys';

// router
import { useRoute, useRouter } from 'vue-router';

// UI
import { 
    NCheckbox,
    useNotification } from 'naive-ui';

export default function (props, context) {
    // store
    const gl = useGl();
    const refs = useRefs();
    const apiKeys = useApiKeys();

    // router
    const route = useRoute();
    const router = useRouter();

    // vars
    const { _ } = window;
    const apiKeyId = ref();
    const init = ref(false);
    const apiKeyInfo = ref();
    const showModalDelete = ref(false);

    // storage filters
    const storeFilterKey = '___filters_api_keys';
    let storeFilters = LocalStorage.getItem(storeFilterKey);

    // set filter settings
    if (storeFilters == null) {
        LocalStorage.setItem(storeFilterKey, CRUD_PAGER_FACTORY({
            exchanges: [-1],
            states: [-1],
            statuses: [1],
        }));

        storeFilters = LocalStorage.getItem(storeFilterKey);
    }

    // filters
    const filters = reactive({
        ...storeFilters,
    });

    const filtersChanged = computed(() => {
        return filters.filters.states[0] !== -1
            || filters.filters.exchanges[0] !== -1
            || filters.filters.statuses[0] !== -1;
    });

    const resetFilters = () => {
        filters.filters.states = [ -1 ];
        filters.filters.exchanges = [ -1 ];
        filters.filters.statuses = [ -1 ];
        
        apiKeysGet();
    };

    const refFilters = computed(() => [
        {
            mode: 'exchanges',
            title: refs.localization.filters.apiKeys.filters_api_keys_exchanges_label,
            refs: refs.exchanges,
            filter: 'filters.exchanges',
            seeable: filters.filters.exchanges[0] !== -1,
        }, {
            mode: 'checkbox_group',
            title: refs.localization.filters.apiKeys.filters_api_keys_statuses_label,
            refs: refs.apikeyStatuses,
            filter: 'filters.statuses',
            seeable: filters.filters.statuses[0] !== -1,
        },
    ]);

    // ui
    const notification = useNotification();

    // i18n
    const { t } = useI18n();

    const actions = computed(() => ['api_keys__play', 'api_keys__edit', 'api_keys__delete']);
    const actionButtonsDisabled = computed(() => records.value ? records.value.some(({ checked }) => checked) : false);
    const columns = computed(() => {
        if (apiKeys.columns) {
            const arr = [...apiKeys.columns].filter(({ title }) => title);

            arr.unshift({
                key: 'checked',
                name: 'checked',
                isHidden: false,
                title() {
                    return h(NCheckbox, {
                        checked: records.value.every(({ checked }) => checked),
                        onUpdateChecked(v) {
                            records.value.forEach(el => el.checked = v);
                        },
                    });
                },
            });

            return arr;
        } else return [];
    });

    const records = computed(() => {
        if (apiKeys.records) {
            const arr = [...apiKeys.records].map(el => {
                const c_key = el.key;
                delete el.key;

                return reactive({
                    ...el,
                    c_key,
                    checked: false,
                    loadings: {
                        show_dashboard: false,
                    },
                });
            });

            return arr;
        } else return [];
    });

    const apiKeyssRefs = async (showLoading = true) => {
        if (showLoading)
            gl.showLoading = true;

        try {
            apiKeys.refs = ( await ApiKeysService.getRefs() ).data;
        } catch {
            notification.error({
                content: t('errorMessage'),
                duration: 2500,
                keepAliveOnHover: true,
            });
        };
        
        if (showLoading)
            gl.showLoading = false;
    };

    const apiKeysGet = async (showLoading = true) => {
        if (showLoading)
            gl.showLoading = true;

        // save filters
        LocalStorage.setItem(storeFilterKey, filters);
            
        try {
            const result = await ApiKeysService.apiKeysGet({
                pager: filters,
            });

            if (apiKeys.apiKeys) {
                Object.keys(result.data).forEach(el => {
                    if(!['columns'].includes(el)) {
                        apiKeys.apiKeys[el] = result.data[el];
                    }
                });
            } else {
                apiKeys.apiKeys = result.data;
            }
        } catch {
            notification.error({
                content: t('errorMessage'),
                duration: 2500,
                keepAliveOnHover: true,
            });
        };

        if (showLoading)
            gl.showLoading = false;
    };

    const getAll = async () => {
        gl.showLoading = true;

        await Promise.allSettled([
            apiKeyssRefs(false),
            apiKeysGet(false),
        ]);

        gl.showLoading = false;
    };

    const sortColumn = $event => {
        _.set(
            filters,
            'sort_column',
            $event.columnKey !== undefined && $event.order !== undefined
                ? $event.columnKey
                : '');

        _.set(
            filters,
            'sort_dir',
            $event.order !== undefined
                ? $event.order
                : '');

        apiKeysGet();
    };

    const changeFilter = ([ key, value ], update = false) => {
        const canUpdate = key === 'query' && !value && apiKeys.apiKeys.pager.query;

        if (key !== 'page') {
            _.set(filters, 'page', 1);
        }

        if (key === 'perPage') {
            LocalStorage.setItem('__filters_api_keys', value);
        }

        _.set(filters, key, value != null && value != undefined ? value : [ -1 ]);

        if (update || canUpdate)
            apiKeysGet();
    };

    const apiKeysCheck = async id => {
        gl.showLoading = true;
            
        try {
            const result = await ApiKeysService.apiKeysCheck(id);

            if (result?.postMessages) {
                result.postMessages.forEach(({ success, msg }) => {
                    notification[success ? 'success' : 'error']({
                        content: msg,
                        duration: 2500,
                        keepAliveOnHover: true,
                    });
                });
            } else {
                result.data.messages.forEach(({ msg }) => {
                    notification[result.data.status ? 'success' : 'error']({
                        content: msg,
                        duration: 2500,
                        keepAliveOnHover: true,
                    });
                });
            }
        } catch {
            notification.error({
                content: t('errorMessage'),
                duration: 2500,
                keepAliveOnHover: true,
            });
        };

        gl.showLoading = false;
    };

    const apiKeysDelete = async (some = false) => {
        gl.showLoading = true;

        try {
            const ids = some
                ? records.value
                    .filter(({ checked }) => checked)
                    .map(({ id }) => id)
                : [ apiKeyId.value ];

            const result = await ApiKeysService.apiKeysDelete({ ids });

            // show messages
            result.postMessages.forEach(el => {
                notification[el.success ? 'success' : 'error']({
                    content: el.msg,
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            });

            if (result?.data.status) {
                apiKeyId.value = null;
                showModalDelete.value = false;

                records.value.forEach(el => el.checked = false);

                await apiKeysGet(false);
            }
        } catch {
            notification.error({
                content: t('errorMessage'),
                duration: 2500,
                keepAliveOnHover: true,
            });
        };
        
        gl.showLoading = false;
    };

    const getFullInfo = async id => {
        gl.showLoading = true;

        try {
            apiKeyInfo.value = ( await ApiKeysService.getFullInfo(id) ).data;
        } catch {
            notification.error({
                content: t('errorMessage'),
                duration: 2500,
                keepAliveOnHover: true,
            });
        };

        gl.showLoading = false;
    };

    const toggleShowDashBoard = async value => {
        const fullInfo = ( await ApiKeysService.getFullInfo(value.id) ).data;
                
        const record = {
            id: fullInfo.id,
            exchange: fullInfo.exchange,
            name: fullInfo.name,
            key: fullInfo.key,
            sign: fullInfo.sign,
        };

        const result = ( await ApiKeysService.update({
            ...record,
            show_dashboard: !fullInfo.show_dashboard,
            _formPath: 'apikeys.update',
        }) ).data;

        if (!result.status) {
            if (result?.errors_form) {
                Object.values(result.errors_form['apikeys.update'].fields).forEach(({ msg }) => {
                    notification.error({
                        content: msg,
                        duration: 2500,
                        keepAliveOnHover: true,
                    });
                });
            }
        }

        const i = apiKeys.records.findIndex(key => key.id === result.record.id);

        if (~i) {
            Object.keys(apiKeys.records[i]).forEach(key => {
                apiKeys.records[i][key] = result.record[key];
            });
        }
    };

    const doSomething = ([ action, value ]) => {
        const fns = {
            play: () => {
                apiKeysCheck(value);
            },
            delete: () => {
                apiKeyId.value = value;
                showModalDelete.value = true;
            },
            edit: async () => {
                await getFullInfo(value);
                
                setTimeout(() => {
                    router.push({ name: 'api-keys', params: { id: value }});
                });
            },
            updateApiKey: async () => {
                value.loadings.show_dashboard = true;

                await toggleShowDashBoard(value);

                value.loadings.show_dashboard = false;
            },
        };

        fns[action]();
    };

    const updateApiKey = data => {
        const i = apiKeys.records.findIndex(({ id }) => id === data.id);

        if (~i)
            for (const [ key, value] of Object.entries(apiKeys.records[i])) {
                apiKeys.records[i][key] = data[key];
            }
    };

    onMounted(async () => {
        await getAll();

        init.value = true;
    });

    return {
        gl,
        init,
        refs,
        apiKeys,
        filters,
        actions,
        columns,
        records,
        refFilters,
        apiKeyInfo,
        filtersChanged,
        showModalDelete,
        actionButtonsDisabled,
        apiKeysGet,
        sortColumn,
        doSomething,
        changeFilter,
        updateApiKey,
        resetFilters,
        apiKeysDelete,
    };
}