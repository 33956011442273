import Api from './index';

export default class Twostage {
    static async cancel(payload) {
        return await Api.getData({
            payload,
            action: 'twostage.cancel',
        });
    };

    static async execute(payload) {
        return await Api.getData({
            payload,
            action: 'twostage.verify',
        });
    };
}