<template>
<section>
    <rb-select
        label-position="left"
        :label="mainLabel"
        :options="options"
        v-model:value="walletOptions" />

    <div class="mt-6 text-left" :class="classBlock">
        <template v-if="walletOptions === 'select'">
            <rb-select
                render="depo"
                popoverType="warning"
                :help="depoInfo"
                :label="selectLabel"
                :options="selectOptions"
                :value="modelValue !== -1 ? modelValue : null"
                @update:value="$emit('update:modelValue', $event)" />
            
            <slot name="messages"></slot>
            
            <div v-if="symbolBalance || symbolTotalBalance" class="w-full flex justify-between mt-6">
                <n-statistic v-if="symbolBalance" :label="symbolBalanceLabel" tabular-nums>
                    <n-number-animation
                        :from="0"
                        :to="+symbolBalance"
                        :precision="8"
                    />
                </n-statistic>
                <n-statistic v-if="symbolTotalBalance" :label="symbolTotalBalanceLabel" tabular-nums>
                    <n-number-animation
                        :from="0"
                        :to="+symbolTotalBalance"
                        :precision="8"
                    />
                </n-statistic>
            </div>
            <div class="mt-4 flex justify-end">
                <n-button
                    strong
                    class="rounded-md text-white/90"
                    :color="gl.mainColor"
                    :disabled="!isWalletSelected || checkBalanceLoading"
                    :loading="checkBalanceLoading"
                    @click="onCheckBalance">
                    {{ buttonCheckLabel }}
                </n-button>
            </div>
        </template>
        <template v-else>
            <div
                v-for="(field, key) in walletModel"
                :key="key"
                class="flex items-end">
                <rb-input
                    class="mb-4"
                    :status="field.status === 'error' ? 'error' : undefined"
                    :msg="field.msg"
                    :label="field.title"
                    :placeholder="field.placeholder"
                    v-model:value="field.value"
                    @update:value="field.status = null, field.msg = null" />
                <div v-if="coinSymbolForWallet && key === 'amount'" class="flex items-center ml-4 mb-4">
                    <rb-coin class="w-6" :coin="currency(coinSymbolForWallet)" />
                    <span class="text-sm text-gray-600 dark:text-white/75 font-semibold uppercase ml-2 whitespace-nowrap">{{ coinSymbolForWallet }}</span>
                </div>
            </div>
            

            <div class="flex flex-wrap justify-end mt-4">
                <n-button
                    strong
                    class="rounded-md text-white/90"
                    :color="gl.mainColor"
                    :loading="loadingCreateWallet"
                    :disabled="createDiabled || loadingCreateWallet"
                    @click="onCreateWallet">
                    + {{ buttonCreateLabel }}
                </n-button>
            </div>
        </template>
    </div>
    <slot name="alerts"></slot>
</section>
</template>

<script>
// vue
import { h, ref, reactive, computed, watch, onMounted } from 'vue';

// i18n
import { useI18n } from 'vue-i18n';

// naive-ui
import {
    NIcon,
    NInput,
    NAlert,
    NSelect,
    NButton,
    NPopover,
    useMessage,
    NStatistic,
    NRadioGroup,
    NRadioButton,
    NNumberAnimation } from 'naive-ui';

// icons
import { Warning24Filled, QuestionCircle20Filled } from '@vicons/fluent';

// components
import RbCoin from '@components/rb-coin';
import RbInput from '@components/rb-input/mobile.vue';
import RbSelect from '@components/rb-select/mobile.vue';

// services
import WalletsService from '@services/walletsService';

// store
import { useGl } from '@/store/gl';
import { useEnv } from '@/store/env';
import { useRefs } from '@/store/refs';
import { useMarketplace } from '@store/ts/marketplace';

export default {
    name: 'RbAlgoSelect',
    emits: ['update:modelValue'],
    props: {
        name: String,
        modelValue: String,
        loading: Boolean,
        buttonCheckLabel: String,
        buttonCreateLabel: String,
        symbolBalanceLabel: String,
        symbolTotalBalanceLabel: String,
        classBlock: String,
        depoInfo: String,
        mainData: {
            type: Object,
            required: true,
        },
        apiKey: {
            type: [String, Number],
            required: true,
        },
        selectLabel: {
            type: String,
            required: true,
        },
        mainLabel: {
            type: String,
            required: true,
        },
        alertLabel: {
            type: String,
        },
        tabOptionsRef: {
            type: Array,
            required: true,
        },
        selectOptionsRef: {
            type: [Array, Object],
            required: true,
        },
        walletModelInfo: {
            type: Array,
            required: true,
        },
        coinSymbolForWallet: {
            required: true,
        },
    },
    components: {
        NIcon,
        NInput,
        RbCoin,
        NAlert,
        NSelect,
        NButton,
        RbInput,
        RbSelect,
        NPopover,
        NStatistic,
        NRadioGroup,
        NRadioButton,
        Warning24Filled,
        NNumberAnimation,
        QuestionCircle20Filled,
    },
    setup(props, { emit }) {
        // i18n
        const { t } = useI18n();

        // store
        const gl = useGl();
        const refs = useRefs();

        // naive-ui
        const message = useMessage();

        // vars
        const balancesRefs = ref();
        const walletOptions = ref('select');
        const checkBalanceLoading = ref(false);
        const loadingCreateWallet = ref(false);

        const options = computed(() =>props.tabOptionsRef.map(el => ({
            value: el.id,
            label: el.title,
            ...el,
        })));

        const selectOptions = computed(() => props.selectOptionsRef.map(el => ({
            label: el.name,
            value: +el.id,
            class: 'w-full',
            code_name: 'rb-cc__' + el.currency,
            ...el,
        })));

        const isWalletSelected = computed(() => props.modelValue != -1);
        const walletCurrency = computed(() => {
            if (!props.selectOptionsRef.length) return -1;
            let localWallet = props.selectOptionsRef.find(({ id }) => id == props.modelValue);
            return localWallet?.currency || -1;
        });
        const symbolBalance = computed(() => balancesRefs.value?.[walletCurrency.value] || '');

        const symbolTotalBalance = computed(() => balancesRefs.value?.[`${walletCurrency.value}_total`] || '');

        const createDiabled = computed(() => !walletModel.name.value || !walletModel.amount.value);

        watch(() => props.modelValue, () => {
            balancesRefs.value = null;
        });

        const renderTag = ({ option }) => {
            return h('div', {
                class: 'w-full flex flex-row items-center',
            }, [
                h('span', {
                    class: 'text-sm flex-grow',
                }, option.name),
                h('span', {
                    class: 'text-sm text-main font-semibold',
                }, option.amount),
                h(RbCoin, {
                    coin: option,
                    class: 'ml-4 w-4',
                }),
                h('span', {
                    class: 'uppercase ml-2 opacity-50',
                }, option.currency),
            ]);
        };

        const renderLabel = (option) => h('div', {
            class: 'w-full py-1 flex flex-row items-center',
        }, [
            h('span', {
                class: 'text-xs flex-grow',
            }, option.name),
            h('span', {
                class: 'text-sm font-semibold',
            }, option.amount),
            h(RbCoin, {
                coin: option,
                class: 'ml-2 w-4',
            }),
            h('span', {
                class: 'uppercase ml-2 mr-8 opacity-50',
            }, option.currency ),
        ]);

        const walletModel = reactive({
            name: {
                value: '',
                title: props.walletModelInfo.name.title,
                placeholder: props.walletModelInfo.name.placeholder,
                status: undefined,
                msg: undefined,
                class: 'flex-grow w-auto',
                currency: undefined,
            },
            amount: {
                value: '',
                title: props.walletModelInfo.amount.title,
                placeholder: '',
                status: undefined,
                msg: undefined,
                class: 'w-[100px]',
                currency: undefined,
            },
        });

        const onCheckBalance = async () => {
            checkBalanceLoading.value = true;

            const prepare = await WalletsService.getBalances(props.mainData.api_key.value);

            if (!prepare.data.status) {
                prepare.data.errors.forEach(({ msg }) => {
                    message['error'](msg);
                });

                // reset balancesRefs
                balancesRefs.value = null;
            } else {
                balancesRefs.value = prepare.data.balances;
            }

            checkBalanceLoading.value = false;
        };

        const onCreateWallet = async () => {
            loadingCreateWallet.value = true;

            const records = {
                name: walletModel.name.value,
                comment: 'Quick Start',
                currency: props.coinSymbolForWallet,
                exchange: props.mainData.exchange.value,
                type: 1,
                amount: walletModel.amount.value,
                api_key: props.mainData.api_key.value,
                _formPath: 'wallets',
            };

            const prepare = await WalletsService.addNew(records);

            if (!prepare.data.status) {
                if (prepare.data?.errors_form) {

                    for (let key in walletModel) {
                        const fields = prepare.data.errors_form.wallets.fields;
                        const el = Object.keys(fields).find(el => el === key);

                        if (el) {
                            walletModel[key].status = 'error';
                            walletModel[key].msg = fields[el].msg;
                        } else {
                            walletModel[key].status = 'success';
                            walletModel[key].msg = undefined;
                        }
                    }
                }
            } else {
                // clean apiKeyModel
                for (let key in walletModel)
                    walletModel[key].value = '';

                // show messages
                prepare.postMessages.forEach(el => {
                    message[el.success ? 'success' : 'error'](el.msg);
                });

                // console.log('data', data);

                const walletEntry = prepare.data.records[0];
                if (!walletEntry) return;

                emit('setWallet', walletEntry);
                
                walletOptions.value = 'select';
                emit('update:modelValue', +walletEntry.id);
                
            }

            loadingCreateWallet.value = false;
        };

        const currency = (icon) => {
            return refs.currencies.find(el => el.id === icon);
        };

        onMounted(() => {
            walletModel.name.value = props.mainData.name.value + ' — Quick Start';
        });

        return {
            gl,
            options,
            walletModel,
            createDiabled,
            selectOptions,
            walletOptions,
            symbolBalance,
            isWalletSelected,
            symbolTotalBalance,
            checkBalanceLoading,
            loadingCreateWallet,
            t,
            currency,
            renderTag,
            renderLabel,
            onCreateWallet,
            onCheckBalance,
        };
    },
};
</script>

<style lang="scss" scoped>
:deep(.n-statistic) {
  .n-statistic-value .n-statistic-value__content {
    font-size: 18px !important;
  }
}
</style>