
import { defineComponent } from 'vue';

import { useGl } from '@store/ts/gl';

// components
import Desktop from './desktop.vue';
import Mobile from './mobile.vue';

export default defineComponent({
    name: 'ready-bots',
    components: {
        Mobile,
        Desktop,
    },
    setup() {
        const gl = useGl();
      
        return {
            gl,
        };
    },
});
